import React from 'react';
import fetchival from 'fetchival';
const axios = require('axios');
const request = require('request');


// test images
var defaultThumbnail = require('../../assets/img/seedsmanTransparent.png');

// IMGUR API


const memeMatchScripts = {

//-----------------------------------------------------**** GENERAL MEMECARD ****--------------------------------------------------------//

  // AWAIT / ASYNC VERSION OF THIS BELOW TODO
  // Returns a thumbnail URL appropriate for media type passed in
  // async word in front of function fucks it up
  makeThumbnail: async function(format, submissionLink) {
    switch (format) {
      case 'TIKTOK':

        var tikTokPrefix = 'https://www.tiktok.com/oembed?url=';
        var oembdURL = tikTokPrefix + submissionLink;

        var thumbReturn = this.getTikTokThumbnailURL(oembdURL);

        return thumbReturn;

      case 'IMAGE':

        // TODO: if image is "gallery", get direct link

        const imgurThumbnailURL = this.getImgurThumbnailURL(submissionLink)
        return imgurThumbnailURL; // TODO this aint make sense

      // TODO: other formats
        

    }
  },

  getMatchStatusIcon: function(matchStatus) {
    // TODO:
  },

  getTopperBadge: function(format, prompt) {
    // TODO:
  },

  getMemeCardColor : function(format) {
    switch (format) {
      case 'TIKTOK':
        return 'black';
      case 'TWEET':
        return 'blue';
      case 'YOUTUBE':
        return 'red';
      case 'IMAGE':
        return 'orange';
        // return 'red';
      case 'GIF':
        return 'green';
  }
    
  },




//-----------------------------------------------------**** TIKTOK ****--------------------------------------------------------//

  getTikTokVideoNumber: function(tikTokURL) {
    return tikTokURL.substr(tikTokURL.lastIndexOf('/') + 1);
  },


  getTikTokThumbnailURL: async function(oembdURL) {
    const text = await axios.get(oembdURL);
    // console.log("text.data");
    // console.log(text.data);
    const link = text.data.thumbnail_url;
    return link;
},

  getShortenedTiktokLink: function() {
    // TODO
  },

//-----------------------------------------------------**** IMAGES ****--------------------------------------------------------//

getImgurThumbnailURL: function(imgurImageLink) {
  return imgurImageLink;
  // TODO
},


// const CLIENT_ID = 'de44c1f57f60e41';
// const apiBase = 'https://api.imgur.com/3';
// const request = fetchival(apiBase, {
//   mode: 'cors',
//   headers: {
//     Authorization: 'Client-ID ' + CLIENT_ID
//   }
// });

// export function searchGallery(section = 'hot', sort = 'viral', page = 0) {

//   return request('gallery/' + section + '/' + sort + '/' + page).get();

// }



//-----------------------------------------------------**** OTHER / GENERAL ****--------------------------------------------------------//

  // sends GET HTTP request, returns text of destination page
  httpGet: async function(URL) {

    axios.get(URL).then(resp => {
      // console.log("resp:");
      // console.log(resp);
      // console.log("response data:")
      // console.log(resp.data);
      return resp.data;
    });
  },
};


export default memeMatchScripts;
