import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateCurrMatch } from '../../actions/lobbyActions.js';

// reactstrap components
import { Button } from 'reactstrap';

// Scripts for Smart Contract Interactions
import contractScripts from './contractScripts.js';

class EndMatchButton extends React.Component {
  componentDidMount() {
    
  }

  sendEndTX = async () => {
    await contractScripts.endActiveMatch(this.props.account, this.props.provider, this.props.lobby, this.props.paid)
    // console.log("EndMatchButton.jsx: Match succesfully ended");
    // this.props.updateCurrMatch(newMatchNumber);
  }

  launchLoginModal = () => {
    const modalOpen = true;
    this.props.toggleLoginModal(modalOpen)
  }

  getButtonFunction = () => {
    
    if (this.props.loginComplete) {
      return this.sendEndTX
    }

    else {
      return this.launchLoginModal
    }
  }


  render() {

    const relevantID = this.props.matchContainer ? "matchContainerCancelButton" : "startMatchButton";
    const buttonText = this.props.text;

    const onClickFunction = this.getButtonFunction();

    return (
      <Button onClick={onClickFunction} id={relevantID} color="startMatch" type="btn"> 
        { buttonText }
      </Button>
    );
  }
}

EndMatchButton.propTypes = {
    updateCurrMatch: PropTypes.func.isRequired,
    account: PropTypes.string,
    provider: PropTypes.string,
    lobby: PropTypes.string,
    paid: PropTypes.bool,
    currMatch: PropTypes.object,
    matches: PropTypes.array.isRequired,
  };
  
  const mapStateToProps = state => ({
    account: state.profile.account,
    provider: state.profile.provider, 
    lobby: state.lobbyInfo.lobby,
    paid: state.lobbyInfo.paid,
    currMatch: state.lobbyInfo.currMatch,
    matches: state.lobbyInfo.matches,
  });
  
export default connect(mapStateToProps, { updateCurrMatch })(EndMatchButton);

