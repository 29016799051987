import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';
import { ethers } from 'ethers';
// wagmi imports: see list of usable Hooks at https://wagmi.sh/react/hooks/useAccount
import { useAccount, useBalance, useBlockNumber, useNetwork, useProvider, useWebSocketProvider, useContractEvent } from 'wagmi'
// RainbowKit imports
import { useConnectModal, useAccountModal, useChainModal } from '@rainbow-me/rainbowkit';
import { base, baseSepolia } from '../../variables/chains.js';

// Smart Contract .json ABI (used in listening for Smart-Contract Events)
import MW_XP from '../../contractsABI/MW_XP_ABI.json';
import ACCOUNTS from '../../contractsABI/ACCOUNTS_ABI.json';
import LOBBIESANDPROPOSALS from '../../contractsABI/LOBBIESANDPROPOSALS_ABI.json';
import MATCHES from '../../contractsABI/MATCHES_ABI.json';
// Smart Contract Addresses
import { MW_XP_ADDRESS, ACCOUNTS_ADDRESS, LOBBIESANDPROPOSALS_ADDRESS, MATCHES_ADDRESS, DEFAULT_BLOCKCHAIN_ID } from '../../variables/CONTRACT_ADDRESSES.js';

export function WagmiHooksHOC(Component) {
    return function WrappedComponent(props) {

        // EVENTS (wagmi) START **********************************************************************************
        // currently used in MemewarsSite.jsx (TODO)
        // TODO --> Should these be in contractScripts.js ?

        // react-router
        const { params } = useParams();
        // console.log("params: " + params)

        // TODO: update to filter for specific lobbies (When there are multiple)
        // MULTILOBBIESTODO
        const useListenForNewMatch = (callbackFunction) => {
          console.log("useListenForNewMatch() - invoked")
          useContractEvent({
            address: MATCHES_ADDRESS,
            abi: MATCHES,
            eventName: 'NewMatch',
            listener(event, node, label, owner) {
              console.log("EVENT – NewMatch")
              console.log(event, node, label, owner)
              callbackFunction(event); // TODO: test
            },
          })
        };
        
        // EVENTS (wagmi) END ************************************************************************************


        // wagmi hooks
        const { address, isConnecting, isDisconnected } = useAccount({
          onConnect({ address, connector, isReconnected }) {
            console.log("Connected – address: " + address)
          },
          onDisconnect() {
            console.log("Wallet Disconnected")
          }
        });

        const balance = useBalance({
            address: address,
        })

        const { blocknumber, isError, isLoading } = useBlockNumber()
        const { chain, chains } = useNetwork()

        // console.log("chains: ")
        // console.log(chains)
        // console.log("chain: ")
        // console.log(chain)

        // console.log("Footer-HooksHOC – chain: " + chain);
        // const connectedChain = chain == undefined ? "notConnected" : chain;
        // console.log("Footer-HooksHOC – connectedChain: " + connectedChain)

        // This provider (and the below WS one) are passed into MemeWarsSite.jsx --> contractScripts.js
        // TODO: connect with above chain.ID value: https://wagmi.sh/react/hooks/useWebSocketProvider#configuration
        const provider = useProvider(DEFAULT_BLOCKCHAIN_ID)
        const wsProvider = useWebSocketProvider({
          chainId: DEFAULT_BLOCKCHAIN_ID,
        })
        // console.log("HOC provider: ")
        // console.log(provider)

        // establish public provider from wagmiClient
        // const networkUrl = wagmiClient.providers[0].value.chains[3].rpcUrls.public.http
        const currentNetwork = chain == undefined ? provider.chains[3]: chain;
        // console.log("networkUrl: ")
        // console.log(networkUrl) 
        // create ethers provider based on networkUrl
        // below is a provider for people who don't have metamask installed
        // const defaultNetwork = provider.chains[3]
        const networkUrl = currentNetwork.rpcUrls.default.http[0] 
        const defaultProvider = new ethers.providers.JsonRpcProvider(networkUrl)
        console.log("defaultProvider: ")
        console.log(defaultProvider)

        window.defaultProvider = defaultProvider;
        
        // console.log("HOC wsProvider: ")
        // console.log(wsProvider)
        // const WsProvider = new ethers.providers.WebSocketProvider('ws://localhost:8545') // TODO: this shouldn't be hardcoded
        // const WsProvider = new ethers.providers.WebSocketProvider(baseSepolia.rpcUrls.wss) // TODO: this shouldn't be hardcoded

        // RainbowKit
        const { openConnectModal } = useConnectModal();
        const { openAccountModal } = useAccountModal();
        const { openChainModal } = useChainModal();

        // Function Template
        const examplePropFunc = (args) => {
            console.log("Footer-HooksHOC – callback working: " + args)
            // useChainModal();
        };


        return <Component {...props} 
                  // wagmi (EVENTS) (TODO: should these be in contractScripts.js?)
                  useListenForNewMatch={useListenForNewMatch}

                  // wagmi
                  wagmiProvider={provider}
                  // wagmiWsProvider={WsProvider} // Websocket Provider (faster than HTTP one, WS events also used to trigger updates)
                  wagmiWsProvider={wsProvider} // Websocket Provider (faster than HTTP one, WS events also used to trigger updates)
                  wagmiNetwork={chain}
                  network={currentNetwork} // TODO: clarify this situation, make sure it works for Torus
                  wagmiChainOptions={chains}
                  wagmiAddress={address}
                  wagmiBalance={balance}
                  wagmiBlocknumber={blocknumber}

                  // RainbkowKit
                  openConnectModal={openConnectModal}
                  openAccountModal={openAccountModal}
                  openChainModal={openChainModal} 
                  examplePropFunc={examplePropFunc}
                  
                  // react-router
                  urlExtension={params}                
                />
      }
  }

export default WagmiHooksHOC;
