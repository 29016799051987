import React, { Component } from 'react';
import {
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  FormGroup,
  Label,
  Input,
  Form,
  Card,
  CardHeader,
  CardBody,
  FormText,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
  Alert,
  CustomInput
} from 'reactstrap';
import Select from 'react-select';

// Importing styles
import styles from './SurveyTool.module.scss';
import "../../assets/css/m_w.css";
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark, faCaretUp, faLock, faUnlock, faPlus, faCaretDown, faCheck, faTimes, faArrowLeft, faArrowRight, faQuestionCircle, faSpinner, faSearch, faExpand, faExternalLinkAlt, faFilter, faExclamationCircle, faClipboard, faCog } from '@fortawesome/free-solid-svg-icons';

// Crypto Functionality (encrypt/decrypt) and variables
import { ARWEAVE_ACTIVE } from '../../variables/CONTRACT_ADDRESSES';
import contractScripts from '../Buttons/contractScripts.js';
import { encrypt } from '@metamask/eth-sig-util';
import { ethers, utils } from 'ethers';
import sha256 from 'crypto-js/sha256';
import Slider from 'react-rangeslider';
import proposalScripts from 'components/UpcomingMatches/proposalScripts';

// SBTs
import SBTFilter from '../SBTs/SBTFilter.jsx';

class SurveyResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      responses: [],
      sbtFilteredResponses: [],
      csvData: '',
      filter: '',
      exportType: 'CSV',
      activeToggles: {},
      modalOpen: true,
      alertMessage: '',
      loading: true,
      surveyTitle: '',
      surveyId: '',
      activeQuestionToggles: {},
      questionResponses: {},
      sbtFilteredQuestionResponses: {},
      viewMode: this.props.viewMode || 'survey',
      filterLoading: false,
    };
  }

  componentDidMount() {
    window.addEventListener('popstate', this.handleUrlChange);
    this.handleUrlBasedView();
    this.fetchResponses();
  }
  
  componentWillUnmount() {
    window.removeEventListener('popstate', this.handleUrlChange);
  }
  
  handleUrlChange = () => {
    this.handleUrlBasedView();
  };
  
  handleUrlBasedView = () => {
    const path = window.location.pathname;
    let newViewMode = 'survey';
    if (path === '/questions' || path.includes('/questions')) {
      newViewMode = 'questions';
    }
    if (this.state.viewMode !== newViewMode) {
      this.setState({ viewMode: newViewMode }, this.fetchResponses);
    }
  };
  
  componentDidUpdate(prevProps, prevState) {
    if (window.location.pathname !== prevState.pathname) {
      this.handleUrlBasedView();
    }
  }

  fetchResponses = async () => {
    this.setState({ loading: true });
    try {
      // Get the latest block number from the blockchain
      const latestBlockNumber = await contractScripts.getLatestBlockNumber(this.props.provider);
      let cache = JSON.parse(localStorage.getItem('surveyCache')) || {};
  
      if (this.state.viewMode === 'survey') {
        // Get survey data for title
        const surveyData = cache.arweaveContent?.[this.props.surveyId];
        if (surveyData) {
          this.setState({
            surveyTitle: surveyData.title || 'Untitled Survey',
            surveyId: this.props.surveyId
          });
        }
  
        // Get cached responses and last fetched block for this survey
        let responses = cache.surveyResponses?.[this.props.surveyId] || [];
        let lastFetchedBlock = cache.surveyLastFetchedBlock?.[this.props.surveyId] || 0;
  
        // Fetch new responses if needed
        if (latestBlockNumber > lastFetchedBlock) {
          console.log(`Fetching new responses from block ${lastFetchedBlock + 1} to ${latestBlockNumber}`);
          const newResponses = await contractScripts.fetchAllSurveyResponses(
            this.props.provider,
            this.props.surveyId,
            lastFetchedBlock + 1,
            latestBlockNumber
          );
  
          // Merge new responses with cached ones
          responses = [...responses, ...newResponses];
  
          // Update cache with new responses and last fetched block
          cache.surveyResponses = {
            ...cache.surveyResponses,
            [this.props.surveyId]: responses
          };
          cache.surveyLastFetchedBlock = {
            ...cache.surveyLastFetchedBlock,
            [this.props.surveyId]: latestBlockNumber
          };
          localStorage.setItem('surveyCache', JSON.stringify(cache));
        }
  
        // Get the latest responses (per responder)
        const latestResponses = this.getLatestResponses(responses);
  
        // Set responses in state
        this.setState({
          responses: latestResponses,
          sbtFilteredResponses: latestResponses, // Initialize filtered responses
          loading: false
        }, this.generateCSV);
  
      } else {
        // For questions view mode
        const survey = cache.arweaveContent?.[this.props.surveyId];
        if (!survey || !survey.questionIDs) {
          throw new Error('Survey or question IDs not found');
        }
  
        // Initialize question responses and last fetched blocks
        let questionResponses = {};
        let questionLastFetchedBlocks = cache.questionLastFetchedBlock || {};
  
        for (const questionId of survey.questionIDs) {
          questionResponses[questionId] = cache.questionResponses?.[questionId] || [];
          questionLastFetchedBlocks[questionId] = questionLastFetchedBlocks[questionId] || 0;
        }
  
        // Fetch new responses for each question
        for (const questionId of survey.questionIDs) {
          const lastFetchedBlock = questionLastFetchedBlocks[questionId];
          if (latestBlockNumber > lastFetchedBlock) {
            const newResponses = await contractScripts.getResponsesByQuestionID(
              this.props.provider,
              questionId,
              lastFetchedBlock + 1,
              latestBlockNumber
            );
  
            // Merge new responses with cached ones
            questionResponses[questionId] = [
              ...(questionResponses[questionId] || []),
              ...newResponses
            ];
  
            // Update last fetched block for this question
            questionLastFetchedBlocks[questionId] = latestBlockNumber;
          }
        }
  
        // Also fetch survey responses and extract question responses
        let surveyResponses = cache.surveyResponses?.[this.props.surveyId] || [];
        let surveyLastFetchedBlock = cache.surveyLastFetchedBlock?.[this.props.surveyId] || 0;
  
        if (latestBlockNumber > surveyLastFetchedBlock) {
          const newSurveyResponses = await contractScripts.fetchAllSurveyResponses(
            this.props.provider,
            this.props.surveyId,
            surveyLastFetchedBlock + 1,
            latestBlockNumber
          );
  
          // Merge new responses with cached ones
          surveyResponses = [...surveyResponses, ...newSurveyResponses];
  
          // Update cache with new responses and last fetched block
          cache.surveyResponses = {
            ...cache.surveyResponses,
            [this.props.surveyId]: surveyResponses
          };
          cache.surveyLastFetchedBlock = {
            ...cache.surveyLastFetchedBlock,
            [this.props.surveyId]: latestBlockNumber
          };
        }
  
        // Extract question responses from survey responses
        surveyResponses.forEach(surveyResponse => {
          const parsedResponse = this.parseResponse(surveyResponse.response);
          if (parsedResponse && parsedResponse.responses) {
            parsedResponse.responses.forEach(answer => {
              const questionId = answer.questionID;
              if (survey.questionIDs.includes(questionId)) {
                const responseObj = {
                  responder: surveyResponse.responder,
                  response: JSON.stringify({
                    answer: answer.answer,
                    additional: answer.additional,
                    importance: answer.importance,
                    timestamp: parsedResponse.timeStamp
                  }),
                  timestamp: surveyResponse.timestamp
                };
                questionResponses[questionId] = questionResponses[questionId] || [];
                questionResponses[questionId].push(responseObj);
              }
            });
          }
        });
  
        // Deduplicate the question responses
        const uniqueQuestionResponses = this.getUniqueQuestionResponses(questionResponses);
  
        // Update cache with new question responses and last fetched blocks
        cache.questionResponses = {
          ...cache.questionResponses,
          ...uniqueQuestionResponses
        };
        cache.questionLastFetchedBlock = questionLastFetchedBlocks;
        localStorage.setItem('surveyCache', JSON.stringify(cache));
  
        // Set question responses in state
        this.setState({
          questionResponses: uniqueQuestionResponses,
          sbtFilteredQuestionResponses: uniqueQuestionResponses, // Initialize filtered responses
          loading: false
        });
      }
  
    } catch (error) {
      console.error('Error fetching responses:', error);
      this.setState({
        loading: false,
        alertMessage: 'Error fetching responses. Please try again.'
      });
    }
  };

  fetchQuestionResponses = async (fromBlock, toBlock) => {
    const { surveyId } = this.props;
    const survey = this.props.cache.arweaveContent[surveyId];
    if (!survey || !survey.questionIDs) {
      throw new Error('Survey or question IDs not found');
    }

    const questionResponses = {};
    for (const questionId of survey.questionIDs) {
      const responses = await contractScripts.getResponsesByQuestionID(this.props.provider, questionId, fromBlock, toBlock);
      questionResponses[questionId] = responses;
    }
    return questionResponses;
  };

  getLatestResponses(responses) {
    const addressMap = new Map();
    responses.forEach(response => {
      const existingResponse = addressMap.get(response.responder);
      if (!existingResponse || existingResponse.timestamp < response.timestamp) {
        addressMap.set(response.responder, response);
      }
    });
    return Array.from(addressMap.values());
  };

  parseResponse = (responseData) => {
    try {
      return typeof responseData === 'string' ? JSON.parse(responseData) : responseData;
    } catch (error) {
      console.error('Error parsing response data:', error);
      return null;
    }
  };

  renderAnswerByType = (type, value) => {
    if (!value) return <div>No answer provided.</div>;
    switch (type) {
      case 'multichoice':
        return (
          <FormGroup className={styles.multiChoice}>
            {value.map((option, oIndex) => (
              <Label check key={oIndex} className={styles.checkboxOptionText}>
                <Input
                  type="checkbox"
                  checked={true}
                  disabled
                />
                {option}
              </Label>
            ))}
          </FormGroup>
        );
      case 'rating':
        return (
          <div className={styles.importanceSlider}>
            <FormText className={styles.ratingLabelText}>
              {value}
            </FormText>
            <Slider
              min={0}
              max={10}
              step={1}
              value={value}
              tooltip={false}
              disabled={true}
              className={styles.ratingSlider}
              style={{ width: '200px' }}
            />
          </div>
        );
      case 'binary':
        return (
          <FormGroup className={styles.binaryChoice}>
            {['Agree', 'Unsure', 'Disagree'].map((option, oIndex) => (
              <Label check key={oIndex} className={`${styles.radioOptionText} ${styles[option.toLowerCase()]} ${value === option ? styles.selected : ''}`}>
                <Input
                  type="radio"
                  checked={value === option}
                  disabled
                />
                {option === 'Agree' && <FontAwesomeIcon icon={faCheck} className={styles.optionIcon} />}
                {option === 'Disagree' && <FontAwesomeIcon icon={faTimes} className={styles.optionIcon} />}
                {option}
              </Label>
            ))}
          </FormGroup>
        );
      case 'freeform':
      default:
        return <div className={styles.freeformAnswer}>{value}</div>;
    }
  };
  

  generateCSV = () => {
    const { responses } = this.state;
    if (!responses.length) {
      console.log('No responses to generate CSV');
      return;
    }

    const header = 'responderAddress,questionID,question,type,importance,answer,answerHash,additionalComments,answerEncrypted,additionalEncrypted,additionalHash\n';
    const csvRows = [];

    responses.forEach(response => {
      const parsedResponse = this.parseResponse(response.response);
      if (parsedResponse && parsedResponse.responses) {
        parsedResponse.responses.forEach(answer => {
          const row = [
            response.responder,
            answer.questionID || '',
            answer.question || '',
            answer.type || '',
            answer.importance || '',
            answer.answer ? answer.answer.value : '',
            answer.answer ? answer.answer.hash : '',
            answer.additional ? answer.additional.value : '',
            answer.answer ? answer.answer.encrypted : '',
            answer.additional ? answer.additional.encrypted : '',
            answer.additional ? answer.additional.hash : ''
          ]
            .map(value => `"${value !== undefined ? value : ''}"`)
            .join(',');
          csvRows.push(row);
        });
      }
    });

    const csvData = header + csvRows.join('\n');
    this.setState({ csvData });
  };

  downloadCSV = () => {
    const { exportType } = this.state;
    let dataToDownload;
  
    if (exportType === 'Polis Report') {
      dataToDownload = this.generatePolisReport();
      this.setState({
        alertMessage: 'Note: Only Binary (Agree / Disagree) question types are used in the Polis Report.',
      });
    } else if (exportType === 'CSV') {
      dataToDownload = this.state.csvData;
      this.setState({ alertMessage: '' });
    } else {
      // Handle other export types if any
      dataToDownload = this.state.csvData;
      this.setState({ alertMessage: '' });
    }
  
    const blob = new Blob([dataToDownload], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', `survey_responses_${this.props.surveyId}_${exportType}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  generatePolisReport = () => {
    const { questionResponses } = this.state;
    const questions = this.props.cache.arweaveContent;
    const authorId = this.props.account; // or however you get the author's address
  
    // Helper function to get vote counts for a question
    const getVoteCounts = (responses) => {
      return responses.reduce((acc, response) => {
        const answer = this.parseResponse(response.response)?.answer?.value;
        if (answer === 'Agree') acc.agrees++;
        else if (answer === 'Disagree') acc.disagrees++;
        return acc;
      }, { agrees: 0, disagrees: 0 });
    };
  
    // Helper to format datetime
    const formatDateTime = (timestamp) => {
      const date = new Date(timestamp);
      return date.toLocaleString('en-US', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short'
      });
    };
  
    // Header row
    const header = 'timestamp,datetime,comment-id,author-id,agrees,disagrees,moderated,comment-body\n';
  
    // Process each question's responses
    const rows = Object.entries(questionResponses)
      .filter(([questionId, responses]) => {
        const question = questions[questionId];
        return question?.type === 'binary';
      })
      .map(([questionId, responses]) => {
        const question = questions[questionId];
        const { agrees, disagrees } = getVoteCounts(responses);
  
        // Get the timestamp of the first response or now
        const timestamp = responses[0]?.timestamp || Date.now();
  
        return [
          timestamp,                    // timestamp
          formatDateTime(timestamp),    // datetime
          questionId,                   // comment-id
          authorId,                     // author-id
          agrees,                       // agrees
          disagrees,                    // disagrees
          1,                            // moderated (always 1)
          question.prompt               // comment-body
        ].join(',');
      })
      .join('\n');
  
    return header + rows;
  };
  

  generateTalkToTheCityReport = () => {
    // Placeholder for actual implementation
    return 'Talk To The City Report Data';
  };

  handleFilteredResponses = (filteredResponses) => {
    if (this.state.viewMode === 'survey') {
      this.setState({ sbtFilteredResponses: filteredResponses });
    } else {
      this.setState({ sbtFilteredQuestionResponses: filteredResponses });
    }
  };

  setFilterLoading = (loading) => {
    this.setState({ filterLoading: loading });
  };

  handleExportTypeChange = type => {
    this.setState({ exportType: type });
  };

  toggleResponse = index => {
    this.setState(prevState => ({
      activeToggles: {
        ...prevState.activeToggles,
        [index]: !prevState.activeToggles[index]
      }
    }));
  };

  closeModal = () => {
    this.setState({ modalOpen: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  toggleViewMode = () => {
    this.setState(
      prevState => ({
        viewMode: prevState.viewMode === 'survey' ? 'questions' : 'survey',
      }),
      this.fetchResponses
    );
  };

  toggleQuestionSummary = questionId => {
    this.setState(prevState => ({
      activeQuestionToggles: {
        ...prevState.activeQuestionToggles,
        [questionId]: !prevState.activeQuestionToggles[questionId],
      }
    }));
  };

  renderResponseContent = response => {
    const parsedResponse = this.parseResponse(response.response);
    if (!parsedResponse) {
      return <div>No response data available</div>;
    }

    const { timeStamp, responses } = parsedResponse;
    const formattedTimestamp = new Date(parseInt(timeStamp)).toLocaleString();

    return (
      <div className={styles.responseContent}>
        <p className={styles.timestamp}>
          <strong>Timestamp:</strong> {formattedTimestamp}
        </p>
        {responses &&
          responses.map((item, index) => (
            <Card key={index}>
              <CardBody className={styles.questionTitleBody}>
                <h4 className={styles.questionTitle}>{item.prompt}</h4>
                {item.answer.encrypted && item.answer.value === '*' ? (
                  <p>This answer is encrypted.</p>
                ) : (
                  this.renderAnswerByType(item.type, item.answer.value)
                )}
                {item.additional && item.additional.value && (
                  item.additional.encrypted && item.additional.value === '*' ? (
                    <p>Additional comments are encrypted.</p>
                  ) : (
                    <div className={styles.additionalCommentsSection}>
                      <strong className={styles.additionalCommentsLabel}>
                        Additional Comments:
                      </strong>
                      <p className={styles.additionalCommentsContent}>{item.additional.value}</p>
                    </div>
                  )
                )}
                {/* Importance Slider */}
                <div className={styles.importanceSlider}>
                  <h6 className={styles.importanceText}>Importance: {item.importance || 0} </h6>
                </div>
              </CardBody>
            </Card>
          ))}
      </div>
    );
  };

  getUniqueQuestionResponses(questionResponses) {
    const uniqueResponses = {};
  
    Object.keys(questionResponses).forEach(questionId => {
      const responses = questionResponses[questionId];
      const responderMap = new Map();
  
      responses.forEach(response => {
        const responder = response.responder;
        const existingResponse = responderMap.get(responder);
  
        // Compare timestamps
        const existingTimestamp = existingResponse ? parseInt(existingResponse.timestamp) : 0;
        const currentTimestamp = parseInt(response.timestamp);
  
        // Keep the latest response
        if (!existingResponse || existingTimestamp < currentTimestamp) {
          responderMap.set(responder, response);
        }
      });
  
      uniqueResponses[questionId] = Array.from(responderMap.values());
    });
  
    return uniqueResponses;
  }
  

  renderQuestionSummary = (questionId, responses) => {
    const question = this.props.cache.arweaveContent[questionId];
    if (!question) return null;
  
    const { prompt, type } = question;
    const totalResponses = responses.length;
    const uniqueResponders = [...new Set(responses.map(r => r.responder))];
    const numUniqueResponders = uniqueResponders.length;
  
    // Filter out responses where both 'additional' and 'answer' are empty
    const validResponses = responses.filter(r => {
      const response = this.parseResponse(r.response);
      if (!response) return false;
      return response.answer?.value || response.additional?.value;
    });
  
    const nonEncryptedResponses = validResponses.filter(r => {
      const response = this.parseResponse(r.response);
      return response && (!response.answer?.encrypted || response.answer.value !== '*');
    });
  
    const numEncryptedResponses = validResponses.length - nonEncryptedResponses.length;
  
    let summary;
    switch (type) {
      case 'freeform':
        summary = (
          <div>
            {nonEncryptedResponses.map((r, index) => (
              <div key={index} className={styles.responseItem}>
                <span className={styles.responderAddress}>
                  {proposalScripts.getShortenedAddress(r.responder)}
                  <a
                    href={`/question/${questionId}/${r.responder}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.externalLink}
                  >
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </a>
                </span>
                <p>{this.parseResponse(r.response)?.answer?.value}</p>
              </div>
            ))}
            {numEncryptedResponses > 0 && (
              <p className={styles.encryptedResponsesNote}>
                {numEncryptedResponses} response(s) are encrypted.
              </p>
            )}
          </div>
        );
        break;
      case 'multichoice':
        const options = question.options || [];
        const optionCounts = options.reduce((acc, option) => {
          acc[option] = nonEncryptedResponses.filter(r => {
            const answerValue = this.parseResponse(r.response)?.answer?.value;
            return Array.isArray(answerValue) && answerValue.includes(option);
          }).length;
          return acc;
        }, {});
        summary = (
          <div className={styles.multiChoiceSummary}>
            {options.map(option => (
              <div key={option} className={styles.multiChoiceOption}>
                <span>{option}:</span>
                <span>{optionCounts[option]} ({nonEncryptedResponses.length > 0 ? ((optionCounts[option] / nonEncryptedResponses.length) * 100).toFixed(2) : 0}%)</span>
              </div>
            ))}
            {numEncryptedResponses > 0 && (
              <p className={styles.encryptedResponsesNote}>
                {numEncryptedResponses} response(s) are encrypted.
              </p>
            )}
          </div>
        );
        break;
      case 'binary':
        const agreeCounts = {
          Agree: nonEncryptedResponses.filter(r => this.parseResponse(r.response)?.answer?.value === 'Agree').length,
          Disagree: nonEncryptedResponses.filter(r => this.parseResponse(r.response)?.answer?.value === 'Disagree').length,
          Unsure: nonEncryptedResponses.filter(r => this.parseResponse(r.response)?.answer?.value === 'Unsure').length,
        };
        const totalNonEncrypted = nonEncryptedResponses.length;
        summary = (
          <FormGroup className={styles.binaryOptionResults}>
            {['Agree', 'Unsure', 'Disagree'].map(option => (
              <Label key={option} className={styles.binaryOptionResponse}>
                <Input
                  type="radio"
                  checked={false}
                  disabled
                />
                {option} ({agreeCounts[option]} - {totalNonEncrypted > 0 ? ((agreeCounts[option] / totalNonEncrypted) * 100).toFixed(2) : 0}%)
              </Label>
            ))}
            {numEncryptedResponses > 0 && (
              <p className={styles.encryptedResponsesNote}>
                {numEncryptedResponses} response(s) are encrypted.
              </p>
            )}
          </FormGroup>
        );
        break;
      case 'rating':
        const ratings = nonEncryptedResponses.map(r => parseFloat(this.parseResponse(r.response)?.answer?.value)).filter(r => !isNaN(r));
        if (ratings.length > 0) {
          const sum = ratings.reduce((sum, r) => sum + r, 0);
          const mean = sum / ratings.length;
          summary = (
            <div className={styles.ratingSummary}>
              <p>Average Rating: {mean.toFixed(2)}</p>
              <Slider
                min={0}
                max={10}
                step={1}
                value={mean}
                tooltip={false}
                disabled={true}
                className={styles.ratingSlider}
              />
            </div>
          );
        } else {
          summary = <p>No non-encrypted responses available.</p>;
        }
        break;
      default:
        summary = <p>Unknown question type</p>;
    }
  
    const isActive = this.state.activeQuestionToggles[questionId];
  
    return (
      <Card key={questionId} className={styles.questionSummaryCard}>
        <CardHeader onClick={() => this.toggleQuestionSummary(questionId)} className={styles.questionSummaryHeader}>
          <span className={styles.questionTitle}>
            {prompt} ({validResponses.length} responses)
          </span>
          <FontAwesomeIcon
            icon={faBookmark}
            className={styles.bookmarkIcon}
            onClick={(e) => {
              e.stopPropagation();
              // handle bookmark click
            }}
          />
          <FontAwesomeIcon
            icon={isActive ? faCaretUp : faCaretDown}
            className={styles.caretIcon}
            style={{ marginLeft: '10px' }}
          />
        </CardHeader>
        <Collapse isOpen={isActive}>
          <CardBody className={styles.darkCardBody}>
            {summary}
          </CardBody>
        </Collapse>
      </Card>
    );
  };
  

  render() {
    const {
      responses, exportType, activeToggles, modalOpen, loading,
      viewMode, questionResponses, surveyTitle, surveyId,
      alertMessage, sbtFilteredResponses, sbtFilteredQuestionResponses,
      filterLoading,
    } = this.state;
  
    const modalTitle = `${viewMode === 'survey' ? 'Survey' : 'Question'} Results${surveyTitle ? ` - ${surveyTitle}` : ''}`;
    const modalSubtitle = surveyId ? `ID: ${surveyId}` : '';
  
    return (
      <Modal isOpen={modalOpen} toggle={this.closeModal} className={styles.resultsModal}>
        <ModalHeader toggle={this.closeModal} className={styles.modalHeader}>
          <h2 className={styles.modalTitle}>{modalTitle}</h2>
          {modalSubtitle && <div className={styles.modalSubtitle}>{modalSubtitle}</div>}
        </ModalHeader>
        <ModalBody className={styles.modalBody}>
          {loading || filterLoading ? (
            <div className={styles.loadingContainer}>
              <FontAwesomeIcon icon={faSpinner} spin size="3x" />
              <p>{loading ? 'Loading results...' : 'Applying filter...'}</p>
            </div>
          ) : (
            <>
            <SBTFilter
              items={viewMode === 'survey' ? responses : questionResponses}
              mode="creatorAndResponder"
              provider={this.props.provider}
              network={this.props.network}
              onFilter={this.handleFilteredResponses}
              setFilterLoading={this.setFilterLoading}
              autoExpand={false}
            />

              {alertMessage && (
                <Alert color="info" className={styles.alertMessage}>
                  {alertMessage}
                </Alert>
              )}
  
              {viewMode === 'survey' ? (
                <div className={styles.responseList}>
                  {sbtFilteredResponses.length === 0 && !filterLoading ? (
                    <p>No results yet.</p>
                  ) : (
                    sbtFilteredResponses.map((response, index) => (
                      <div key={index} className={styles.responseItem}>
                        <Card className={styles.questionTitleBody}>
                          <CardHeader
                            onClick={() => this.toggleResponse(index)}
                            className={styles.responseHeader}
                          >
                            <span className={styles.responderAddress}>
                              {proposalScripts.getShortenedAddress(response.responder)}
                              <a
                                href={`/survey/${this.props.surveyId}/${response.responder}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.externalLink}
                              >
                                <FontAwesomeIcon icon={faExternalLinkAlt} />
                              </a>
                              {response.response && response.response.encryptedPortion && (
                                <FontAwesomeIcon icon={faLock} className={styles.lockIcon} />
                              )}
                            </span>
                            <FontAwesomeIcon
                              icon={activeToggles[index] ? faCaretUp : faCaretDown}
                              className={styles.caretIcon}
                            />
                          </CardHeader>
                          <Collapse isOpen={!!activeToggles[index]}>
                            <CardBody className={styles.responseCard}>
                              {this.renderResponseContent(response)}
                            </CardBody>
                          </Collapse>
                        </Card>
                      </div>
                    ))
                  )}
                </div>
              ) : (
                <div className={styles.questionSummaries}>
                  {Object.entries(sbtFilteredQuestionResponses)
                    .filter(([questionId, responses]) => responses.length > 0 || !filterLoading)
                    .map(([questionId, responses]) =>
                      this.renderQuestionSummary(questionId, responses)
                    )}
                  {Object.keys(sbtFilteredQuestionResponses).length === 0 && !filterLoading && (
                    <p>No results yet.</p>
                  )}
                </div>
              )}

              <div className={styles.exportContainer}>
                <Label for="exportType" className={styles.exportLabel}>
                  EXPORT AS:
                </Label>
                <div className={styles.exportOptions}>
                  <UncontrolledDropdown direction="up">
                    <DropdownToggle caret className={styles.exportDropdown}>
                      {exportType}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => this.handleExportTypeChange('CSV')}>
                        CSV
                      </DropdownItem>
                      <DropdownItem onClick={() => this.handleExportTypeChange('Polis Report')}>
                        Polis Report
                      </DropdownItem>
                      <DropdownItem onClick={() => this.handleExportTypeChange('Talk To The City Report')}>
                        Talk To The City Report
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <Button onClick={this.downloadCSV} className={styles.downloadButton}>
                    Download
                  </Button>
                </div>
              </div>
            </>
          )}
        </ModalBody>
      </Modal>
    );
  }
}

export default SurveyResults;
