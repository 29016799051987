import React from 'react';
import { FormGroup, Label, Input, Button, UncontrolledTooltip } from 'reactstrap';
import styles from './SBTSelector.module.scss';
import SBTSelector from './SBTSelector.jsx';
import contractScripts from '../Buttons/contractScripts.js';
import proposalScripts from '../UpcomingMatches/proposalScripts';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faSpinner, faTimes, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

class SBTFilter extends React.Component {
  state = {
    selectedSBTGroupsCreator: [], // Array of { address, name }
    selectedSBTGroupsResponder: [],
    excludedSBTGroupsCreator: [],
    excludedSBTGroupsResponder: [],
    selectedSBTGroups: [], // For addresses mode
    excludedSBTGroups: [], // For addresses mode
    onlyVerifiedHumans: false,
    showFilterOptions: false,
    loading: false,
  };

  componentDidMount() {
    console.log('SBTFilter.jsx network:', this.props.network);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.items !== this.props.items ||
      prevProps.mode !== this.props.mode ||
      prevState.selectedSBTGroupsCreator !== this.state.selectedSBTGroupsCreator ||
      prevState.excludedSBTGroupsCreator !== this.state.excludedSBTGroupsCreator ||
      prevState.selectedSBTGroupsResponder !== this.state.selectedSBTGroupsResponder ||
      prevState.excludedSBTGroupsResponder !== this.state.excludedSBTGroupsResponder ||
      prevState.selectedSBTGroups !== this.state.selectedSBTGroups ||
      prevState.excludedSBTGroups !== this.state.excludedSBTGroups ||
      prevState.onlyVerifiedHumans !== this.state.onlyVerifiedHumans
    ) {
      this.applyFilter();
    }
  }

  applyFilter = async () => {
    const { items, mode, provider, network } = this.props;
    const {
      selectedSBTGroupsCreator,
      excludedSBTGroupsCreator,
      selectedSBTGroupsResponder,
      excludedSBTGroupsResponder,
      selectedSBTGroups,
      excludedSBTGroups,
      onlyVerifiedHumans,
    } = this.state;
  
    this.setState({ loading: true });
  
    const networkID = network?.id;
    console.log('Network ID used in SBTFilter:', networkID);
  
    if (!networkID) {
      console.error('Network ID is undefined in SBTFilter. Cannot proceed.');
      this.setState({ loading: false });
      this.props.onFilter([]);
      return;
    }
  
    // If no filters are applied, return original items
    if (
      selectedSBTGroupsCreator.length === 0 &&
      excludedSBTGroupsCreator.length === 0 &&
      selectedSBTGroupsResponder.length === 0 &&
      excludedSBTGroupsResponder.length === 0 &&
      selectedSBTGroups.length === 0 &&
      excludedSBTGroups.length === 0 &&
      !onlyVerifiedHumans
    ) {
      // Remove duplicates and normalize addresses
      const uniqueItems = [...new Set(items.map(item => {
        if (typeof item === 'string') {
          return item.toLowerCase();
        } else {
          return item;
        }
      }))];
      this.props.onFilter(uniqueItems);
      this.setState({ loading: false });
      return;
    }
  
    // Create a mapping of SBT address to set of holders
    const sbtHoldersMap = {};
  
    // Combine all unique SBT addresses to fetch holders only once per SBT
    const allSBTAddresses = new Set([
      ...selectedSBTGroupsCreator.map(sbt => sbt.address.toLowerCase()),
      ...excludedSBTGroupsCreator.map(sbt => sbt.address.toLowerCase()),
      ...selectedSBTGroupsResponder.map(sbt => sbt.address.toLowerCase()),
      ...excludedSBTGroupsResponder.map(sbt => sbt.address.toLowerCase()),
      ...selectedSBTGroups.map(sbt => sbt.address.toLowerCase()),
      ...excludedSBTGroups.map(sbt => sbt.address.toLowerCase()),
    ]);
  
    // Check cache first
    const sbtCache = JSON.parse(localStorage.getItem('sbtCache')) || {};
    console.log('sbtCache:', sbtCache);
  
    if (!sbtCache[networkID]) {
      console.log(`No cache found for network ID ${networkID}`);
      this.setState({ loading: false });
      this.props.onFilter([]);
      return;
    }
  
    for (const sbtAddress of allSBTAddresses) {
      const sbtCacheData = sbtCache[networkID].sbtList[sbtAddress.toLowerCase()];
      if (sbtCacheData && sbtCacheData.mintedAddresses && sbtCacheData.burnedAddresses) {
        // Use cached data
        const mintedAddresses = sbtCacheData.mintedAddresses.map(addr => addr.toLowerCase());
        const burnedAddresses = sbtCacheData.burnedAddresses.map(addr => addr.toLowerCase());
        const netMintedAddresses = mintedAddresses.filter(addr => !burnedAddresses.includes(addr));
        sbtHoldersMap[sbtAddress] = new Set(netMintedAddresses);
      } else {
        // Fetch data from blockchain
        try {
          let mintedAddresses = await contractScripts.getAddressesWhoMintedSBT(provider, sbtAddress);
          let burnedAddresses = await contractScripts.getAddressesWhoBurnedSBT(provider, sbtAddress);
  
          // Normalize addresses to lowercase
          mintedAddresses = mintedAddresses.map(addr => addr.toLowerCase());
          burnedAddresses = burnedAddresses.map(addr => addr.toLowerCase());
          const netMintedAddresses = mintedAddresses.filter(addr => !burnedAddresses.includes(addr));
  
          sbtHoldersMap[sbtAddress] = new Set(netMintedAddresses);
  
          // Update cache
          if (!sbtCache[networkID]) {
            sbtCache[networkID] = { sbtList: {} };
          }
          if (!sbtCache[networkID].sbtList[sbtAddress]) {
            sbtCache[networkID].sbtList[sbtAddress] = {};
          }
          sbtCache[networkID].sbtList[sbtAddress].mintedAddresses = mintedAddresses;
          sbtCache[networkID].sbtList[sbtAddress].burnedAddresses = burnedAddresses;
          localStorage.setItem('sbtCache', JSON.stringify(sbtCache));
        } catch (error) {
          console.error('Error fetching SBT holders:', error);
          sbtHoldersMap[sbtAddress] = new Set();
        }
      }
    }
  
    console.log('sbtHoldersMap:', sbtHoldersMap);
  
    // Map of address to SBTs held
    const addressToSBTsMap = {};
  
    // Build the reverse map
    for (const [sbtAddress, holdersSet] of Object.entries(sbtHoldersMap)) {
      for (const holderAddress of holdersSet) {
        if (!addressToSBTsMap[holderAddress]) {
          addressToSBTsMap[holderAddress] = new Set();
        }
        addressToSBTsMap[holderAddress].add(sbtAddress);
      }
    }
  
    console.log('addressToSBTsMap:', addressToSBTsMap);
  
    const filterItem = (item) => {
      if (mode === 'addresses') {
        if (typeof item !== 'string') {
          console.error('Expected item to be a string in addresses mode, but got:', item);
          return false;
        }
        const address = item.toLowerCase();
  
        // Exclude if holds any excluded SBTs
        for (const sbt of excludedSBTGroups) {
          const sbtAddress = sbt.address.toLowerCase();
          const holdersSet = sbtHoldersMap[sbtAddress];
          if (holdersSet && holdersSet.has(address)) {
            console.log(`Excluding address ${address} because it holds excluded SBT ${sbtAddress}`);
            return false;
          }
        }
  
        // If we have selected SBTs, check if address holds any
        if (selectedSBTGroups.length > 0) {
          let holdsIncludedSBT = false;
          for (const sbt of selectedSBTGroups) {
            const sbtAddress = sbt.address.toLowerCase();
            const holdersSet = sbtHoldersMap[sbtAddress];
            if (holdersSet && holdersSet.has(address)) {
              console.log(`Including address ${address} because it holds selected SBT ${sbtAddress}`);
              holdsIncludedSBT = true;
              break;
            }
          }
          if (!holdsIncludedSBT) {
            console.log(`Excluding address ${address} because it does not hold any selected SBT`);
            return false; // Exclude if doesn't hold any included SBT
          }
        }
  
        // Check for verified humans (assuming for addresses)
        if (onlyVerifiedHumans) {
          const humanSBTAddress = '...'; // Replace with the address of the verified human SBT
          const humanHoldersSet = sbtHoldersMap[humanSBTAddress];
          if (humanHoldersSet && !humanHoldersSet.has(address)) {
            console.log(`Excluding address ${address} because it is not a verified human`);
            return false; // Exclude if not a verified human
          }
        }
  
        return true;
      } else {
        let addressToCheckCreator = null;
        let addressToCheckResponder = null;
  
        if (mode === 'creator' || mode === 'creatorAndResponder') {
          addressToCheckCreator = item.creator.toLowerCase();
        }
        if (mode === 'responder' || mode === 'creatorAndResponder') {
          addressToCheckResponder = item.responder.toLowerCase();
        }
  
        // Check creator filters
        if (addressToCheckCreator) {
          // Exclude if holds any excluded SBTs
          for (const sbt of excludedSBTGroupsCreator) {
            const sbtAddress = sbt.address.toLowerCase();
            const holdersSet = sbtHoldersMap[sbtAddress];
            if (holdersSet && holdersSet.has(addressToCheckCreator)) {
              console.log(`Excluding creator ${addressToCheckCreator} because they hold excluded SBT ${sbtAddress}`);
              return false;
            }
          }
          // If we have selected SBTs, check if address holds any
          if (selectedSBTGroupsCreator.length > 0) {
            let holdsIncludedSBT = false;
            for (const sbt of selectedSBTGroupsCreator) {
              const sbtAddress = sbt.address.toLowerCase();
              const holdersSet = sbtHoldersMap[sbtAddress];
              if (holdersSet && holdersSet.has(addressToCheckCreator)) {
                console.log(`Including creator ${addressToCheckCreator} because they hold selected SBT ${sbtAddress}`);
                holdsIncludedSBT = true;
                break;
              }
            }
            if (!holdsIncludedSBT) {
              console.log(`Excluding creator ${addressToCheckCreator} because they do not hold any selected SBT`);
              return false; // Exclude if doesn't hold any included SBT
            }
          }
        }
  
        // Check responder filters
        if (addressToCheckResponder) {
          // Exclude if holds any excluded SBTs
          for (const sbt of excludedSBTGroupsResponder) {
            const sbtAddress = sbt.address.toLowerCase();
            const holdersSet = sbtHoldersMap[sbtAddress];
            if (holdersSet && holdersSet.has(addressToCheckResponder)) {
              console.log(`Excluding responder ${addressToCheckResponder} because they hold excluded SBT ${sbtAddress}`);
              return false;
            }
          }
          // If we have selected SBTs, check if address holds any
          if (selectedSBTGroupsResponder.length > 0) {
            let holdsIncludedSBT = false;
            for (const sbt of selectedSBTGroupsResponder) {
              const sbtAddress = sbt.address.toLowerCase();
              const holdersSet = sbtHoldersMap[sbtAddress];
              if (holdersSet && holdersSet.has(addressToCheckResponder)) {
                console.log(`Including responder ${addressToCheckResponder} because they hold selected SBT ${sbtAddress}`);
                holdsIncludedSBT = true;
                break;
              }
            }
            if (!holdsIncludedSBT) {
              console.log(`Excluding responder ${addressToCheckResponder} because they do not hold any selected SBT`);
              return false; // Exclude if doesn't hold any included SBT
            }
          }
        }
  
        // Check for verified humans (assuming for responders)
        if (onlyVerifiedHumans && addressToCheckResponder) {
          const humanSBTAddress = '...'; // Replace with the address of the verified human SBT
          const humanHoldersSet = sbtHoldersMap[humanSBTAddress];
          if (humanHoldersSet && !humanHoldersSet.has(addressToCheckResponder)) {
            console.log(`Excluding responder ${addressToCheckResponder} because they are not a verified human`);
            return false; // Exclude if not a verified human
          }
        }
  
        return true;
      }
    };
  
    let filteredItems = [];
  
    if (Array.isArray(items)) {
      filteredItems = items.filter(item => filterItem(item));
    } else if (typeof items === 'object') {
      filteredItems = {};
      Object.entries(items).forEach(([key, itemArray]) => {
        filteredItems[key] = itemArray.filter(item => filterItem(item));
      });
    }
  
    console.log('Filtered items:', filteredItems);
  
    this.setState({ loading: false }, () => {
      this.props.onFilter(filteredItems);
    });
  };
  
  handleAddSBTIncludeCreator = (sbtObject) => {
    // sbtObject should be { address, name }
    const { address } = sbtObject;
    if (!this.state.selectedSBTGroupsCreator.find(sbt => sbt.address === address)) {
      this.setState(prevState => ({
        selectedSBTGroupsCreator: [...prevState.selectedSBTGroupsCreator, sbtObject],
      }));
    }
  };

  handleRemoveSBTIncludeCreator = (address) => {
    this.setState(prevState => ({
      selectedSBTGroupsCreator: prevState.selectedSBTGroupsCreator.filter(sbt => sbt.address !== address),
    }));
  };

  handleAddSBTExcludeCreator = (sbtObject) => {
    const { address } = sbtObject;
    if (!this.state.excludedSBTGroupsCreator.find(sbt => sbt.address === address)) {
      this.setState(prevState => ({
        excludedSBTGroupsCreator: [...prevState.excludedSBTGroupsCreator, sbtObject],
      }));
    }
  };

  handleRemoveSBTExcludeCreator = (address) => {
    this.setState(prevState => ({
      excludedSBTGroupsCreator: prevState.excludedSBTGroupsCreator.filter(sbt => sbt.address !== address),
    }));
  };

  handleAddSBTIncludeResponder = (sbtObject) => {
    const { address } = sbtObject;
    if (!this.state.selectedSBTGroupsResponder.find(sbt => sbt.address === address)) {
      this.setState(prevState => ({
        selectedSBTGroupsResponder: [...prevState.selectedSBTGroupsResponder, sbtObject],
      }));
    }
  };

  handleRemoveSBTIncludeResponder = (address) => {
    this.setState(prevState => ({
      selectedSBTGroupsResponder: prevState.selectedSBTGroupsResponder.filter(sbt => sbt.address !== address),
    }));
  };

  handleAddSBTExcludeResponder = (sbtObject) => {
    const { address } = sbtObject;
    if (!this.state.excludedSBTGroupsResponder.find(sbt => sbt.address === address)) {
      this.setState(prevState => ({
        excludedSBTGroupsResponder: [...prevState.excludedSBTGroupsResponder, sbtObject],
      }));
    }
  };

  handleRemoveSBTExcludeResponder = (address) => {
    this.setState(prevState => ({
      excludedSBTGroupsResponder: prevState.excludedSBTGroupsResponder.filter(sbt => sbt.address !== address),
    }));
  };

  // For addresses mode
  handleAddSBTInclude = (sbtObject) => {
    const { address } = sbtObject;
    if (!this.state.selectedSBTGroups.find(sbt => sbt.address === address)) {
      this.setState(prevState => ({
        selectedSBTGroups: [...prevState.selectedSBTGroups, sbtObject],
      }));
    }
  };

  handleRemoveSBTInclude = (address) => {
    this.setState(prevState => ({
      selectedSBTGroups: prevState.selectedSBTGroups.filter(sbt => sbt.address !== address),
    }));
  };

  handleAddSBTExclude = (sbtObject) => {
    const { address } = sbtObject;
    if (!this.state.excludedSBTGroups.find(sbt => sbt.address === address)) {
      this.setState(prevState => ({
        excludedSBTGroups: [...prevState.excludedSBTGroups, sbtObject],
      }));
    }
  };

  handleRemoveSBTExclude = (address) => {
    this.setState(prevState => ({
      excludedSBTGroups: prevState.excludedSBTGroups.filter(sbt => sbt.address !== address),
    }));
  };

  toggleVerifiedHumans = () => {
    this.setState(prevState => ({
      onlyVerifiedHumans: !prevState.onlyVerifiedHumans,
    }));
  };

  toggleFilterOptions = () => {
    this.setState(prevState => ({
      showFilterOptions: !prevState.showFilterOptions,
    }));
  };

  render() {
    const {
      showFilterOptions,
      selectedSBTGroupsCreator,
      excludedSBTGroupsCreator,
      selectedSBTGroupsResponder,
      excludedSBTGroupsResponder,
      selectedSBTGroups, // For addresses mode
      excludedSBTGroups, // For addresses mode
      onlyVerifiedHumans,
      loading
    } = this.state;

    const { mode } = this.props;

    return (
      <div className={styles.sbtFilter}>
        {!this.props.autoExpand && (
          <Button
            onClick={this.toggleFilterOptions}
            className={styles.filterButton}
          >
            <FontAwesomeIcon icon={faCog} /> Filter
          </Button>
        )}

        {(this.props.autoExpand || showFilterOptions) && (
          <div className={styles.filterOptions}>
            {mode === 'addresses' && (
              <>
                <SBTSelector
                  id="includeAddresses"
                  network={this.props.network}
                  provider={this.props.provider}
                  onAddSBT={this.handleAddSBTInclude}
                  selectedSBTs={selectedSBTGroups}
                  label={"Include Addresses holding SBT"}
                />

                <div className={styles.selectedAddresses}>
                  {selectedSBTGroups.map(sbt => (
                    <div key={sbt.address} className={styles.addressTag}>
                      <span className={styles.sbtName}>{sbt.name || sbt.address}</span>
                      <FontAwesomeIcon
                        icon={faTimes}
                        className={styles.removeIcon}
                        size="lg"
                        onClick={() => this.handleRemoveSBTInclude(sbt.address)}
                      />
                      <FontAwesomeIcon
                      icon={faExternalLinkAlt}
                      className={styles.linkIcon}
                      size="lg"
                      onClick={() => window.open(`/sbt/${sbt.address}`, '_blank')}
                    />
                    </div>
                  ))}
                </div>

                <SBTSelector
                  id="excludeAddresses"
                  network={this.props.network}
                  provider={this.props.provider}
                  onAddSBT={this.handleAddSBTExclude}
                  selectedSBTs={excludedSBTGroups}
                  label={"Exclude Addresses holding SBT"}
                />

                <div className={styles.selectedAddresses}>
                  {excludedSBTGroups.map(sbt => (
                    <div key={sbt.address} className={styles.addressTag}>
                      <span className={styles.sbtName}>{sbt.name || sbt.address}</span>
                      <FontAwesomeIcon
                        icon={faTimes}
                        className={styles.removeIcon}
                        size="lg"
                        onClick={() => this.handleRemoveSBTExclude(sbt.address)}
                      />
                    <FontAwesomeIcon
                      icon={faExternalLinkAlt}
                      className={styles.linkIcon}
                      size="lg"
                      onClick={() => window.open(`/sbt/${sbt.address}`, '_blank')}
                    />
                    </div>
                  ))}
                </div>

                <FormGroup check id={styles.verifiedHumansLabel}>
                  <Label check className={styles.greyedOutLabel}>
                    <Input
                      type="checkbox"
                      checked={onlyVerifiedHumans}
                      onChange={this.toggleVerifiedHumans}
                      id="humanOnlyCheckbox"
                      disabled={true}
                    />
                    <span id="verifiedHumansLabel">
                      Only show verified human addresses<sup>soon</sup>
                    </span>
                  </Label>
                  <UncontrolledTooltip placement="right" target="verifiedHumansLabel">
                    We will use standards like OpenPassport soon.
                  </UncontrolledTooltip>
                </FormGroup>
              </>
            )}

            {(mode === 'creator' || mode === 'creatorAndResponder') && (
              <>
                <SBTSelector
                  id="includeCreator"
                  network={this.props.network}
                  provider={this.props.provider}
                  onAddSBT={this.handleAddSBTIncludeCreator}
                  selectedSBTs={selectedSBTGroupsCreator}
                  label={"Include Questions created by SBT Holders"}
                />

                <div className={styles.selectedAddresses}>
                  {selectedSBTGroupsCreator.map(sbt => (
                    <div key={sbt.address} className={styles.addressTag}>
                      <span className={styles.sbtName}>{sbt.name || sbt.address}</span>
                      <FontAwesomeIcon
                        icon={faTimes}
                        className={styles.removeIcon}
                        size="lg"
                        onClick={() => this.handleRemoveSBTIncludeCreator(sbt.address)}
                      />
                    </div>
                  ))}
                </div>

                <SBTSelector
                  id="excludeCreator"
                  network={this.props.network}
                  provider={this.props.provider}
                  onAddSBT={this.handleAddSBTExcludeCreator}
                  selectedSBTs={excludedSBTGroupsCreator}
                  label={"Exclude Questions created by SBT Holders"}
                />

                <div className={styles.selectedAddresses}>
                  {excludedSBTGroupsCreator.map(sbt => (
                    <div key={sbt.address} className={styles.addressTag}>
                      <span className={styles.sbtName}>{sbt.name || sbt.address}</span>
                      <FontAwesomeIcon
                        icon={faTimes}
                        className={styles.removeIcon}
                        size="lg"
                        onClick={() => this.handleRemoveSBTExcludeCreator(sbt.address)}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}

            {(mode === 'responder' || mode === 'creatorAndResponder') && (
              <>
                <h5>Filter by SBTs of Question Responders</h5>
                <SBTSelector
                  id="includeResponder"
                  network={this.props.network}
                  provider={this.props.provider}
                  onAddSBT={this.handleAddSBTIncludeResponder}
                  selectedSBTs={selectedSBTGroupsResponder}
                  label={"Include Responses from SBT Holders"}
                />

                <div className={styles.selectedAddresses}>
                  {selectedSBTGroupsResponder.map(sbt => (
                    <div key={sbt.address} className={styles.addressTag}>
                      <span className={styles.sbtName}>{sbt.name || sbt.address}</span>
                      <FontAwesomeIcon
                        icon={faTimes}
                        className={styles.removeIcon}
                        size="lg"
                        onClick={() => this.handleRemoveSBTIncludeResponder(sbt.address)}
                      />
                    </div>
                  ))}
                </div>

                <SBTSelector
                  id="excludeResponder"
                  network={this.props.network}
                  provider={this.props.provider}
                  onAddSBT={this.handleAddSBTExcludeResponder}
                  selectedSBTs={excludedSBTGroupsResponder}
                  label={"Exclude Responses from SBT Holders"}
                />

                <div className={styles.selectedAddresses}>
                  {excludedSBTGroupsResponder.map(sbt => (
                    <div key={sbt.address} className={styles.addressTag}>
                      <span className={styles.sbtName}>{sbt.name || sbt.address}</span>
                      <FontAwesomeIcon
                        icon={faTimes}
                        className={styles.removeIcon}
                        size="lg"
                        onClick={() => this.handleRemoveSBTExcludeResponder(sbt.address)}
                      />
                    </div>
                  ))}
                </div>

                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={onlyVerifiedHumans}
                      onChange={this.toggleVerifiedHumans}
                    />
                    Only see verified human responses
                  </Label>
                </FormGroup>
              </>
            )}
          </div>
        )}

        {loading && (
          <div className={styles.loadingContainer}>
            <FontAwesomeIcon icon={faSpinner} spin size="2x" />
            {mode === 'addresses' ? (
              <p>Filtering addresses...</p>
            ) : (
              <p>Filtering responses...</p>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default SBTFilter;
