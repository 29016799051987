import React from 'react';
import Iframe from 'react-iframe'; //TODO

// CSS
import 'assets/css/m_w.css';
import styles from "./MemeMatch.module.scss";

// Scripts
import memeMatchScripts from './memeMatchScripts.js';

// reactstrap components
import { Card, CardBody, CardFooter, CardHeader, Form, Modal } from 'reactstrap';

// Components
import VoteOnMatchButton from 'components/Buttons/VoteOnMatchButton.jsx';
import OverMemeCardBottom from "./OverMemeCardBottom.jsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' // TODO: fa library
import { faWindowClose, faPlayCircle } from '@fortawesome/free-solid-svg-icons'

// Default thumbnail image in case rendering fails TODO: present link?
// const defaultThumbnail = require('../../assets/img/etherum.png');

// import BN from 'bn.js';
// const BN = require('bn.js');

// TODO: lazy load the full-form

// var thumbnail0 = defaultThumbnail;
// var thumbnail1 = defaultThumbnail;
// var fullform = <div> <i class="fas fa-spinner fa-pulse"></i> </div>;

class ActiveMemeCard extends React.Component {
  state = {
    entryClicked: false,
    // thumbnailURL: '',
    thumbnailStatus: 'loading',
    fullFormStatus: 'loading'
  };

  componentDidMount() {
    this.loadThumbnail();
  }

  componentDidUpdate() {

    // this.loadFullForm();
    // console.log("ActiveMemeCardUpdate:")
    // console.log("this.props.entryNumber")
    // console.log(this.props.entryNumber)

    // console.log("this.props.content")
    // console.log(this.props.content)

    // this.loadThumbnail();
    // this.loadFullForm();

    // console.log("thumbnail0")
    // console.log(thumbnail0)
    // console.log("thumbnail1")
    // console.log(thumbnail1)
  }

  shouldComponentUpdate(nextProps, nextState) {

    // const votingStartTime = moment.unix(this.props.match.votingStartTime).utc();
    // const votingEndsTime = moment.utc(votingStartTime).add(this.props.match.proposal.allowedVotingTime, "seconds");
    // if (moment.utc().isAfter(votingEndsTime) && this.state.pendingSwitchHappened && !this.state.activeSwitchHappened) {
    //   this.setState({ activeSwitchHappened: true });
    //   return true
    // }

    if (this.props != nextProps || this.state.entryClicked != nextState.entryClicked) return true;
    else return false;
  }

  entryClicked = () => {
    console.log("entry clicked")
    console.log("state.entryClicked was:" + this.state.entryClicked)
    // keeps track of how long someone looks at meme entry for TODO: mixpanel
    // window.mixpanel.track("ENTRY_CLICKED", 
    // { 
    // "SELECTED_ENTRY_INDEX": this.props.entryNumber, 
    // "MATCH_ID": this.props.match.ID, 
    // "OPENING_ENTRY": !this.state.entryClicked 
    // }
    // );
    // window.mixpanel.time_event('ENTRY_CLICKED');
    const opposite = !this.state.entryClicked;
    this.setState({ entryClicked: opposite });
    // console.log("state.entryClicked changed to:" + this.state.entryClicked)
  }

  getMemeCard = () => {
    if (this.state.entryClicked) return this.getFullForm();
    else return this.getThumbnail();
  }


  loadFullForm = () => {
    const format = this.props.match.proposal.format; // TODO: Below is for testing only
    // const format = 'IMAGE'; 
    const submission = this.props.content;

    switch (format) {
      case "TIKTOK":
        var vidNumber = memeMatchScripts.getTikTokVideoNumber(submission)
        const iFrameURL = "https://www.tiktok.com/embed/" + vidNumber;
        // this.setState({ fullFormStatus: 'loaded'});
        // fullform = <TikTokPlayer link={submission} />;
        // ASYNC / AWAIT TODO: 
        return (
          // <div id={styles.iframeContainer}>
          // <Iframe id={styles.tiktokIframe}
          <Iframe id={styles.tiktokIframe}
            url={iFrameURL}
            width="100%"
            height="500px"
            // TODO ^ WEIRDNESS?
            // height="720px"
            // TODO ^ CAUSED SIDE SCROLLING WEIRDNESS?
            id={styles.tiktokEmbed}
            className="iframe-container"
            display="initial"
            position="relative"
          />
        );

      // return fullform;
      case "IMAGE":
        // handling is different depending format of imgur link: https://imgur.com/gallery/beUuEnq VS https://i.imgur.com/2wFcjHL.png
        // const imgurFullURL = "https://i.imgur.com/2wFcjHL.png";
        // const imgurFullURL = "https://imgur.com/gallery/beUuEnq";
        const imgurFullURL = submission;
        return (
          <a
            href={imgurFullURL} // TODO: hardcoded
            target='_blank'>
            <img
              // className='img-responsive center-block img-thumbnail'
              id={styles.fullFormImage}
              src={imgurFullURL} />
          </a>
        );

      case "TEXT":
        return (
          <div id={styles.fullFormText}>
            {submission}
          </div>
        );
    }
  };


  loadThumbnail = async () => {
    const format = this.props.match.proposal.format;
    const submission = this.props.content;
    var thumbnailURLReturn = await memeMatchScripts.makeThumbnail(format, submission);
    var thumbnailStyle;

    // apply appropriate CSS styles
    switch (format) {
      case 'TIKTOK':
        thumbnailStyle = styles.tiktokThumbnail;

      case 'IMAGE':
        thumbnailStyle = styles.imageThumbnail; // TODO: css

      case 'TEXT':
        thumbnailStyle = styles.textThumbnail; // TODO: css

      // case 'OTHER FORMATS' // TODO
    }


    // thumbnail0 = <div id={thumbnailStyle}> { "url(" + thumbnailURLReturn +")" } </div>;
    const thumbnailURL = "url(" + thumbnailURLReturn + ")";
    this.setState({ thumbnailURL: thumbnailURL, thumbnailStatus: 'loaded' });
    // return thumbnailURL;


    // console.log("THUMBNAIL " + this.props.entryNumber + " LOADED");
  };

  getThumbnail = () => {
    // if (this.state.thumbnailStatus == 'loading') return defaultThumbnail;
    // else return this.state.thumbnailURL;
    // return this.state.thumbnailURL;
    if (this.props.match.proposal.format == "TEXT") return this.props.content;

    else return "url(" + this.props.content + ")";
  };

  getThumbnailOverlay = () => {
    const format = this.props.match.proposal.format;
    // const format = "IMAGE" // TODO: hardcoded, for testing only
    switch (format) {
      case 'TIKTOK':
        // return play button overlay TODO: make icon
        // const tiktokOverlayURL = "http://wptf.com/wp-content/uploads/2014/05/play-button.png";
        // const tiktokOverlayURL = "https://cdn2.iconfinder.com/data/icons/music-media-player-outline-basic/32/1-icon-music-01-512.png"
        const tiktokOverlayURL = "https://cdn0.iconfinder.com/data/icons/snapchat-glyph-1/48/Sed-01-512.png";
        return tiktokOverlayURL;


      case 'IMAGE':
        // return expand overlay (on hover TODO)
        // const imageOverlayURL = <i class="fas fa-spinner fa-pulse"></i>;
        // const imageOverlayURL = <FontAwesomeIcon icon={faWindowClose} />
        const imageOverlayURL = "https://cdn0.iconfinder.com/data/icons/glyphpack/34/expand-512.png";
        return imageOverlayURL;
      // return "";


      // case 'OTHER FORMATS' // TODO
    }
  }

  render() {
    var match = this.props.match;
    var proposal = this.props.match.proposal;

    const leftRightID = this.props.entryNumber == 0 ? styles.activeMemeCardLeft : styles.activeMemeCardRight;
    const cardID = this.props.match.competitors == null ? styles.activeMemeCard : leftRightID;

    var cardColor = proposal.format !== undefined ? 'transparent' : memeMatchScripts.getMemeCardColor(proposal.format);

    // const voteButton = this.props.profile =! undefined ?
    const voteButton =
      <VoteOnMatchButton
        plaintext={true}
        loggedIn={this.props.loggedIn}
        voteFor={this.props.entryNumber}
        // account={this.props.profile.account} 
        account={this.props.account}
        provider={this.props.provider}
        lobby={this.props.lobby}
        paid={this.props.paid}
        voterEntryCost={match.proposal.voterEntryCost}

        // ********* TODO
        // changeFocusedTab={(newTabIndex) => this.props.changeFocusedTab(newTabIndex)} 
        updateXPBalance={(newXPBalance) => this.props.updateXPBalance(newXPBalance)} // TODO: have one function (here) which handles the newXP value (clients just call UpdateXPBalance())
        toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
        // updateLoginInfo={(newLoginStatus) => this.props.updateLoginInfo(newLoginStatus)}
        // updateCurrMatch={(latestMatchObj) => this.props.updateCurrMatch(latestMatchObj)}
        // updateMatches={(allMatchesObj) => this.props.updateMatches(allMatchesObj)}
        // ************************* 
        profile={this.props.profile}
        // lobbyInfo={this.props.lobbyInfo}
        // sessionState={this.props.sessionState}
        // ************************* 
        // account={this.props.account} 
        // provider={this.props.provider}
        joined={this.props.joined}
        XPBalance={this.props.XPBalance}
        ETHbalance={this.props.ETHBalance}
        availableETH={this.props.availableETH}
        // lobby={this.props.lobby}
        currMatch={this.props.currMatch}
        // matches={this.props.matches}
        // proposals={this.props.proposals}
        // focusedTab={this.props.focusedTab}
        focusedMatchID={this.props.focusedMatchID}
        focusedEntry={this.props.focusedEntry}
        loginComplete={this.props.loginComplete}
        loginInProgress={this.props.loginInProgress}


      />;

    const footerContent = this.props.matchOver ? <> </> : voteButton;

    const footerDisplay = this.props.matchOver && match.matchStatus == "OVER" ? <OverMemeCardBottom entryNumber={this.props.entryNumber} match={match} /> : footerContent;

    const thumbnailInsert = this.props.match.proposal.format == "TEXT" ? "" : this.getThumbnail();

    const fullform = this.loadFullForm();

    const textContent = this.props.match.proposal.format == "TEXT" ? this.props.content : "";
    // const thum
    // this.loadThumbnail();
    // console.log("fullform:");
    // console.log(fullform);

    const votePercentage = (match.votes[this.props.entryNumber] / match.numVoters) * 100;
    // const winningCard = this.props.matchOver && match.matchStatus == "OVER" && votePercentage >= 50 ? styles.winner : styles.nonWinner;
    const winningCard = this.props.matchOver && match.matchStatus == "OVER" && votePercentage >= 50 ? "card-blog card-background winner" : "card-blog card-background";

    // const thumbOverlayURL = this.getThumbnailOverlay();

    const interiorCard =
      <div
        // className="card-blog card-background"
        className={winningCard}
        id={leftRightID}
        data-animation={true}
        style={{
          // background: "rgba(255,255,255,1) url(" + thumbOverlayURL + ") no-repeat scroll center center / 100px 100px", // TODO: replace this with a better play button
          // background: "rgba(0,0,0,1) url(" + thumbOverlayURL + ") no-repeat scroll center center / 200px 200px", // TODO: replace this with a better play button
          // // backgroundImage: "linear-gradient(white, blue)",
          // // backgroundSize: "200px", // TODO
          // backgroundSize: "contain", // TODO
          // backgroundRepeat: "no-repeat, repeat",
          // zIndex: "10", // TODO make cursor 
          // opacity: "1",
        }}
      >
        <div
          onClick={this.entryClicked}
          id={winningCard}
          className="full-background"
          style={{
            backgroundImage: thumbnailInsert,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            paddingBotton: "0px !important",
          }}
        >


          <div id={styles.textEntryPreview}
            //  onClick={this.entryClicked}
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
              justifyContent: "center",
              // alignSelf: "center",
              // justifySelf: "center",
              fontSize: "3.5rem",
              fontWeight: "600",
              height: "100%",
              paddingBotton: "0px",
            }}>
            {textContent}
          </div>
        </div>
        <CardBody>

        </CardBody>
        <CardFooter
          id="activeCardFooter">
        </CardFooter>
      </div>

    return (
      <>
        {/* <div id={styles.activeCardContent}> */}
        {/* <Card id={cardID} className={winningCard}>       TODO: glow */}
        <Card id={cardID}>
          <CardBody id={styles.activeCardContent}>
            {interiorCard}
          </CardBody>
          <div id={styles.activeMemeCardFooter} >
            {footerDisplay}
          </div>
        </Card>
        {/* </div> */}


        {/* <div id={styles.entryModalDiv}>  */}
        {/* <Modal isOpen={this.state.entryClicked} toggle={this.toggleModalLogin}  id={styles.entryModal} modalClassName="modal-entry"> */}
        <Modal isOpen={this.state.entryClicked} modalClassName="modal-entry" id={styles.entryModal}>
          <Card id={styles.entryModalCard}>
            <CardHeader>
              <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={this.entryClicked}>
                <FontAwesomeIcon id={styles.closeModalIcon} icon={faWindowClose} />
              </button>
            </CardHeader>
            {fullform}
            <CardFooter className="text-center">
            </CardFooter>
          </Card>
        </Modal>
        {/* </div> */}
      </>
    );
  }
}

export default ActiveMemeCard;