import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCheckCircle, faExclamationCircle, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import contractScripts from './contractScripts.js';
import styles from './SendTestnetFundsButton.module.scss';

class SendTestnetFundsButton extends Component {
  state = {
    loading: false,
    success: false,
    error: false,
    transactionHash: null,
    errorMessage: "",
    autoSendEnabled: this.props.autoSendEnabled || false,
    autoSendTriggered: false
  };

  componentDidUpdate(prevProps) {
    if (this.props.autoSend && !this.state.autoSendTriggered) {
      this.setState({ autoSendTriggered: true }, () => {
        this.sendTestnetFunds();
      });
    }

    if (this.props.autoSendEnabled !== prevProps.autoSendEnabled) {
      this.setState({ autoSendEnabled: this.props.autoSendEnabled });
    }
  }

  sendTestnetFunds = async () => {
    this.setState({ loading: true, success: false, error: false, transactionHash: null, errorMessage: "" });
    try {
      const receipt = await contractScripts.sendTestnetFunds(this.props.account);
      this.setState({ loading: false, success: true, transactionHash: receipt.transactionHash });
      console.log("Transaction successful!");
    } catch (error) {
      const errorMessage = error.message.includes("threshold") ? "Wallet already has balance" : error.message;
      this.setState({ loading: false, error: true, errorMessage });
      console.error("Transaction failed:", error);
    }
  }

  getExplorerLink = (hash) => {
    const network = this.props.network;
    return network ? `${network.blockExplorers.default.url}/tx/${hash}` : 'https://sepolia.basescan.org/tx/' + hash;
  }

  render() {
    const { loading, success, error, transactionHash, errorMessage } = this.state;
    const buttonText = (
      loading ? "Sending Test Funds..." :
      success ? "Test Funds Sent" :
      error ? `${errorMessage}` :
      "Send Test Funds"
    );
    const relevantID = this.props.homepage ? styles.loadingScreenInitButton : styles.startMatchButton;

    return (
      <Button 
        onClick={this.sendTestnetFunds} 
        id={relevantID} 
        className={`${styles.button} ${loading ? styles.loading : ''} ${success ? styles.success : ''} ${error ? styles.error : ''}`} 
        disabled={loading || this.props.disabled}
      > 
        { loading && <FontAwesomeIcon icon={faSpinner} spin className={styles.loadingIcon} /> }
        { buttonText }
        { success && <FontAwesomeIcon icon={faCheckCircle} className={styles.successIcon} /> }
        { success && <a href={this.getExplorerLink(transactionHash)} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faExternalLinkAlt} className={styles.externalLinkIcon} />
        </a> }
        { error && <FontAwesomeIcon icon={faExclamationCircle} className={styles.errorIcon} /> }
      </Button>
    );
  }
}

export default SendTestnetFundsButton;
