import React, { Component } from 'react';
import Slider from 'react-rangeslider'
// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
// import { updateProposals } from '../../actions/lobbyActions.js';
// import { updateXPBalance, updateETHBalance } from '../../actions/accountActions.js'; // TODO: for ETH as well

// react plugin used to create DropdownMenu for selecting items
// import Select from "react-select";
// import SubmitProposalButton from '../Buttons/SubmitProposalButton.jsx';

// Scripts
import proposalScripts from '../UpcomingMatches/proposalScripts.js';
import contractScripts from '../Buttons/contractScripts.js'

// CSS
import "assets/css/m_w.css";
import styles from "./MemeMatch.module.scss";

// reactstrap components
import { Button, Card, CardHeader, Collapse, FormGroup, Input } from "reactstrap";

// Components
// import JoinAsCompetitorButton from 'components/Buttons/JoinAsCompetitorButton.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' // TODO: fa-library fa 
import { faSpinner, faExpandArrowsAlt, faCaretDown, faArrowUp, faStar, faUser } from '@fortawesome/free-solid-svg-icons'

// .env variables (TODO)
import { USER_STARTING_XP_QUANTITY } from '../../variables/CONTRACT_ADDRESSES.js';

const TikTokIcon = proposalScripts.getProposalCardIcon("TIKTOK")
const TikTokLabel = <> {TikTokIcon} {" TIKTOK"} </>;

const ImageIcon = proposalScripts.getProposalCardIcon("IMAGE")
const ImageLabel = <> {ImageIcon} {" IMAGE"} </>;

class CollapseBidPrompt extends Component {
  state = {
    visible: true,
    openedCollapses: [""],

    competitorBid: 0,               // not BN because it is used by slider
    competitorBidIsValid: false,
    bidValidityNeedsRefresh: true,

    submission: "",
    submissionIsValid: false,
    submissionValidityNeedsRefresh: true,

    loadingSliderValues: false,
    loadedSliderValues: false,
    minSliderValue: 0,              // not BN because it is used by slider
    maxSliderValue: 100,            // not BN because it is used by slider
  };

  componentDidMount() {
    // Enter a custom ID into DOM?
    // if (this.props.currMatch !== undefined) {
    //   this.setState({
    //     competitorBid: contractScripts.decimalEighteen(this.props.currMatch.proposal.minCompetitorBid)
    //   })
    // }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // return !isEqual(this.state, nextState);
    return true; // TODO: this might be dumb / causing issues
  }

  componentDidUpdate(prevProps, prevState) {

    console.log("CollapseBidPrompt - UPDATE")
    // console.log(this.state.competitorBid)
    // console.log("this.state.competitorBidIsValid Before Update:")
    // console.log(this.state.competitorBidIsValid)

    // const matchDefined = this.props.currMatch !== undefined;
    const matchDefined = this.props.currMatch.proposal !== undefined && this.props.currMatch != null; 
    // TODO: ^^^ this doesn't need update every time currMatch updates (maybe minBid could become lowest bid if two bidders?)

    // if user's XP balance changes, update the slider's max and min values 
    // currently: allow this to happen, by setting state field which triggers re-check
    // TODO: do same for ETH
    const relevantBalanceBN = this.props.paid ? this.props.ETHBalance : this.props.XPBalance;
    const relevantBalance = contractScripts.decimalEighteen(relevantBalanceBN, "CollapseBidPrompt")
    const prevRelevantBalanceBN = prevProps.paid ? prevProps.ETHBalance : prevProps.XPBalance;
    const prevRelevantBalance = contractScripts.decimalEighteen(prevRelevantBalanceBN, "CollapseBidPrompt")
    const relevantBalanceChange = relevantBalance != prevRelevantBalance;
    const initialLoadNeeded = !this.state.loadedSliderValues && !this.state.loadingSliderValues;
    
    const sliderReloadNeeded = initialLoadNeeded || relevantBalanceChange || !this.state.loadedSliderValues; 

    // const loginPropDefined = this.props.loginComplete !== undefined;
    // const loggedIn = this.props.loginComplete == true;
    // const loginPropChanged = this.props.loginComplete != prevProps.loginComplete;
    // const accountChange = loginPropDefined && loggedIn && loginPropChanged;

    // if slider (for entering bid) values have not been loaded yet 
    // if (matchDefined && (initialLoadNeeded || sliderReloadNeeded)) { // TODO HERE: ADDING THE SLIDERRELODED FUCKED IT
    if (matchDefined && (initialLoadNeeded)) { // TODO HERE: ADDING THE SLIDERRELODED FUCKED IT
      console.log("calling loadSliderValues() - sliderReloadNeeded");
      // console.log("relevantBalance")
      // console.log(relevantBalance.toString())
      // console.log("prevRelevantBalance")
      // console.log(prevRelevantBalance.toString())

      // this.setState({ 
      //   loadedSliderValues: false,
      //   // loadingSliderValues: false, 
      // });

      this.loadSliderValues();
    }

    // if slider (for entering bid) needs to be refreshed due to update (balances, etc) TODO 
    else if (matchDefined && sliderReloadNeeded) {
      console.log("calling loadSliderValues() - relevantBalanceChange || sliderReloadNeeded");
      console.log("relevantBalance: ")
      console.log(relevantBalance)
      console.log("prevRelevantBalance: ")
      console.log(prevRelevantBalance)

      console.log("sliderReloadNeeded:")
      console.log(sliderReloadNeeded)

      this.setState({ 
        loadedSliderValues: false,
        // loadingSliderValues: false, 
      });

      this.loadSliderValues(); // --> this is implied, because on next componentDidUpdate, first if-block will fire
    }

    // if new account logs in (or disconnects)
    // const loginPropDefined = prevProps.loginComplete !== undefined && prevProps.loginComplete == true;
    // const justLoggedIn = this.props.loginComplete !== undefined && this.props.loginComplete == true;
    // const justLoggedIn = prevProps.loginComplete == true;
    const loginPropChanged = prevProps.loginComplete != this.props.loginComplete;
    // const accountChange = loginPropDefined && justLoggedIn && loginPropChanged;
    // const accountChange = (justLoggedOut || justLoggedIn) && loginPropChanged;
    
    if (loginPropChanged) {
          console.log("componentDidUpdate - ACCOUNT CHANGE!")
          this.setState({ 
            loadedSliderValues: false,
            // loadingSliderValues: false, 
          }) 

          this.loadSliderValues(); // TODO: necessary?
      }

    

    if (matchDefined && this.state.bidValidityNeedsRefresh) {
      const wasBidValid = this.state.competitorBidIsValid
      // console.log("wasBidValid: ");
      // console.log(wasBidValid);
      const isBidValid = this.checkBidValid();
      // console.log("isBidValid: ");
      // console.log(isBidValid);
      if (wasBidValid != isBidValid) {
        this.setState({ 
          competitorBidIsValid: isBidValid,
          bidValidityNeedsRefresh: false,
        })
      }
    }

    if (matchDefined && this.state.submissionValidityNeedsRefresh) {       //  this.state.competitorBid != prevState.competitorBid

      const isSubmissionValid = this.checkSubmissionValid();
      this.setState({ 
        submissionIsValid: isSubmissionValid,
        submissionValidityNeedsRefresh: false
      })
    }
  }

  componentWillUnmount() {

  }

  sendMatchEntryTX = async () => {

    // Track click of "Submit" Button TODO: mixpanel
    // window.mixpanel.track("SUBMIT_MEME_COMPLETED",
    // { 
    //   "SELECTED_ENTRY_INDEX": this.props.entryNumber, 
    //   "MATCH_ID": this.props.currMatch.ID, 
    //   // "OPENING_ENTRY_DETAILS": !openedCollapses.includes(collapse)
    //   }
    // );
    // window.mixpanel.time_event('SUBMIT_MEME_OPTIONS_TOGGLED');

    const collapseClassname = "collapseOne";

    // // console.log("PMC sendMatchEntryTX");
    const asCompetitor = true;
    const competitorEntry = this.state.submission;

    await contractScripts.joinPendingMatch(
      this.props.account,
      this.props.provider,
      this.props.lobby, 
      this.props.paid, 
      asCompetitor, 
      competitorEntry, 
      contractScripts.toEighteenDecimals(this.state.competitorBid.toString())); // send value formatted as ethers.js BigNumber

      this.collapsesToggle(collapseClassname);

      // TODO: make sure this works + update XP balance at end of match if user played
      let XPBalance = await contractScripts.getXPBalance(this.props.account);
      // TODO
      let ETHBalance = await contractScripts.getETHBalance(this.props.account);

      const relevantUserBalance = this.props.paid ? ETHBalance : XPBalance;

      const relevantUpdateFunc = this.props.paid ? this.props.updateETHBalance : this.props.updateXPBalance;

      relevantUpdateFunc(relevantUserBalance);
      // this.props.updateXPBalance(XPBalance);
      // // TODO
      // this.props.updateETHBalance(ETHBalance);
  }

  onSubmissionChange = e => {
    this.setState({ 
      submission: e.target.value,
      submissionValidityNeedsRefresh: true
     });
  };

  onBidChange = value => {
    this.setState({
     competitorBid: value,
     bidValidityNeedsRefresh: true
    })
  };

  onSubmit = (e) => {
    e.preventDefault();
  }

  handleChangeStart = () => {
    // // console.log('Change event started')
  };

  handleChangeComplete = () => {
    // // console.log('Change event completed')
  };
    
  checkBidValid = () => {

    // console.log("checkBidValid - invoked")

    if (this.props.currMatch !== undefined && this.props.currMatch != null && this.state.bidValidityNeedsRefresh) {
      // console.log("checkBidValid - #1")
      var defaultStartingXP = contractScripts.toEighteenDecimals(USER_STARTING_XP_QUANTITY)
      // defaultStartingXP = defaultStartingXP
      // console.log("defaultStartingXP: ");
      // console.log(defaultStartingXP.toString());
      var relevantUserBalance = this.props.paid !== undefined && this.props.paid ? this.props.ETHBalance : this.props.XPBalance;

       // TODO: REALMONEYMODE
      relevantUserBalance = this.props.loginComplete && this.props.joined ? relevantUserBalance : defaultStartingXP 
      var correctUserBalance = relevantUserBalance;
      const minBid = this.props.currMatch.proposal.minCompetitorBid

      const userBid = this.state.competitorBid == 0 ? 0 : contractScripts.toEighteenDecimals(this.state.competitorBid.toString());
      // TODO HERE: UserBid should be BN
      // const userNotJoinedFirstBid = this.props.joined == false // TODO: allows to bid entire holdings

      const bncheck1 = contractScripts.objectIsBN(correctUserBalance)
      const bncheck2 = contractScripts.objectIsBN(userBid)
      const bncheck3 = contractScripts.objectIsBN(minBid)
      const allArgsAreBN = bncheck1 && bncheck2 && bncheck3

      if (allArgsAreBN) { 
        const isBidValid = correctUserBalance.gt(userBid) && userBid.gte(minBid); 
        return isBidValid;
      }
    }
  }

  checkSubmissionValid = () => {

    // console.log("checkSubmissionValid - invoked")

    // const relevantUserBalance = this.props.currMatch.proposal.paid ? this.props.ETHBalance : this.props.XPBalance;
    // const minBid = new BN(this.props.currMatch.proposal.minCompetitorBid);
    // const userBid = this.state.competitorBid == '' ? new BN("0") : new BN(contractScripts.toEighteenDecimals(this.state.competitorBid));

    // return relevantUserBalance.gte(userBid) && userBid.gte(minBid);
    // return true; // TODO

    if (this.state.submission == '') return false; 

    else if (this.state.submissionValidityNeedsRefresh) {

      const submission = this.state.submission;

      // TODO: this logic should be in memeMatchScripts.js
      switch(this.props.currMatch.proposal.format) {
        case 'TIKTOK':
          try {
              // // console.log(this.state.submission);
              new URL(submission);
            } catch (_) {
              return false;  
            }

          return true && submission.includes("tiktok") && submission.includes("/video/"); // TODO: improve

        case 'IMAGE':
          // MAKE SURE IMGUR IS IN LINK TODO
          const imgurLink = submission.includes("imgur");
          // MAKE SURE GALLERY IS NOT IN LINK TODO (FOR NOW)
          const notGallery = !submission.includes("gallery");

          return imgurLink && notGallery; // TODO: change when succesfully able to parse Gallery links

      }
      // try {
      //     // // console.log(this.state.submission);
      //     new URL(this.state.submission);
      //   } catch (_) {
      //     return false;  
      //   }
      
        return true;
    }

    else return this.state.submissionIsValid;
  }

  getWarningMessage = () => {

    if ( !this.props.loginComplete ) {
      return (
        <Button id={styles.bidPromptWarningButton} onClick={this.launchLoginModal} className="btn-link" color={"pendinglogin"}> 
        Log in to submit bid 
        </Button>
      )
    }

    else if ( this.state.competitorBid == 0 ) {
      return (
        <Button id={styles.bidPromptWarningButton}  className="btn-link" color={"pendinglogin"}> 
        Enter A Bid
        </Button>
      )
    }

    else if ( !this.state.submissionIsValid ) {
      switch(this.props.currMatch.proposal.format) {
        case 'TIKTOK':
          return (
            <Button id={styles.bidPromptWarningButton} className="btn-link" color={"pendinglogin"}> 
              Required format:  
            </Button>
          );

        case 'IMAGE': 
          return (
            <Button id={styles.bidPromptWarningButton} className="btn-link" color={"pendinglogin"}> 
              Required format: imgur.com/2wFcjHL.png 
            </Button>
          );

      // TODO: make more general than TikTok
          }
    }

    else if ( !this.state.competitorBidIsValid ) {
      return (
        <Button id={styles.bidPromptWarningButton} className="btn-link" color={"pendinglogin"}> 
          Invalid Bid
        </Button>
      )
    }
  }

  getInputPromptText = () => {
    switch(this.props.currMatch.proposal.format) {
      case 'TIKTOK': 
        const TikTokLinkPromptMessage = "TikTok Link"
        return TikTokLinkPromptMessage;

      case 'IMGUR': 
        const ImgurLinkPromptMessage = "Imgur Link"
        return ImgurLinkPromptMessage;
    }
  }

  launchLoginModal = () => {
    const modalOpen = true;
    this.props.toggleLoginModal(modalOpen)
  }

  // with this function we create an array with the opened collapses
  // it is like a toggle function for all collapses from this page (TODO: ?)
  collapsesToggle = collapse => {
    // Track click of "Submit Meme + Bid" Button TODO: mixpanel
    // window.mixpanel.track("SUBMIT_MEME_OPTIONS_TOGGLED", {"MATCH_STATE": "PENDING"});
    // window.mixpanel.time_event('SUBMIT_MEME_OPTIONS_TOGGLED');


    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: this.state.openedCollapses.filter(
          prop => prop !== collapse
        )
      });
    } else {
      this.setState({
        openedCollapses: [...this.state.openedCollapses, collapse]
      });
    }
  };


  // if the slider (for inputting bid) doesn't have up-to-date max and min values, 
  // this function can be called to update them (if player now has different amount of XP, etc.) 
  loadSliderValues = () => {
    console.log("loadSliderValues - invoked")

    // if (!this.state.loadedSliderValues && !this.state.loadingSliderValues ) {
    if (!this.state.loadingSliderValues && this.props.currMatch !== undefined) {
      console.log("loadSliderValues - #1")
      // this.setState({ loadedSliderValues: true }) // TODO
      this.setState({ loadingSliderValues: true }) // TODO

      const matchNotNull = this.props.match !== undefined && this.props.currMatch != null;
      // const joinedDefined = this.props.joined !== undefined;
      // const joinedDefined = true;
      const paidDefined = this.props.paid !== undefined;
      // const paidDefined = true; // TODO

      // if (!this.loadedSliderValues && !this.loadingSliderValues) {
      if (matchNotNull && paidDefined) {
        console.log("loadSliderValues - #1  #1")
        // this.setState({ loadingSliderValues: true })
        // this.setState({ loadedSliderValues: true })

        // TODO: REALMONEYMODE
        // TODO: check if these below Number() casts are working as expected... prob not
        // const relevantBalanceBN = this.props.paid ? this.props.ETHBalance : this.props.XPBalance; 
        // const relevantBalance = contractScripts.getBigNumber(contractScripts.decimalEighteen(relevantBalanceBN))
        const minMatchBid = Number(contractScripts.decimalEighteen(this.props.currMatch.proposal.minCompetitorBid, "CollapseBidPrompt"));  // TODO: make this the current second highest bid
        const defaultUserXP = Number(USER_STARTING_XP_QUANTITY);

        // console.log("relevantBalance: ")
        // console.log(relevantBalance)
        console.log("minMatchBid: ")
        console.log(minMatchBid)
        console.log("defaultUserXP: ")
        console.log(defaultUserXP)

        console.log("this.props.loginComplete: ")
        console.log(this.props.loginComplete)
        
            // - if user is not logged-in 
            if (!this.props.loginComplete) {

              console.log("loadSliderValues - #1  #1  #1 - setting slider state NOT LOGGED-IN")

              this.setState({ 
                minSliderValue: minMatchBid, 
                maxSliderValue: defaultUserXP, 
                loadedSliderValues: true,
                loadingSliderValues: false, 
              })
            }

            // - if user has loggedIn
            else if (this.props.loginComplete) {
              console.log("loadSliderValues - #1  #1  #2 - setting slider state LOGGED-IN")
              const relevantBalance = this.props.paid ? this.props.ETHBalance : this.props.XPBalance;

              // const minMatchBid = Number(this.props.currMatch.proposal.minCompetitorBid.toString());
              // const balanceBN = relevantBalance
              // const maxUserBalance = Number(contractScripts.decimalEighteen(relevantBalance));
              // const maxUserBalance = Number(contractScripts.decimalEighteen(balanceBN.toString()));
              var maxUserBalance = contractScripts.decimalEighteen(relevantBalance, "CollapseBidPrompt");
              if (maxUserBalance == "0.0") { maxUserBalance = defaultUserXP }
              // const maxUserBalanceString = maxUserBalance.toString()

              console.log("maxUserBalance: ")
              console.log(maxUserBalance)

              this.setState({ 
                minSliderValue: minMatchBid,
                maxSliderValue: maxUserBalance,
                loadedSliderValues: true,
                loadingSliderValues: false,
               })

            }

            else {
              this.setState({ 
                minSliderValue: minMatchBid,
                maxSliderValue: defaultUserXP,
                loadedSliderValues: false,
                loadingSliderValues: false,
               })
            }
      }

      else {
        console.log("loadSliderValues - #1 #1 ELSE-BLOCK")
        this.setState({ loadingSliderValues: false })
        // this.setState({ loadedSliderValues: false })
      }

      // this.setState({ loadingSliderValues: false })
    }

    else { 
      console.log("loadSliderValues - #1 ELSE-BLOCK")
      // console.log("this.state.loadingSliderValues:")
      // console.log(this.state.loadingSliderValues)
    }

  };

  render() {

    // console.log("CollapseBidPrompt.jsx: re-render")
    // console.log("this.state.minSliderValue:")
    // console.log(this.state.minSliderValue)
    // console.log("this.state.maxSliderValue:")
    // console.log(this.state.maxSliderValue)

    const { competitorBid, minSliderValue, maxSliderValue } = this.state;


    const collapseClassname = "collapseOne";
    // const className = this.props.entryNumber != 0 ? "card-collapse-left" :"card-collapse-right";
    // const headingNumber = this.props.entryNumber != 0 ? "headingZero" :"headingOne";
    const accordionID = this.props.entryNumber == 0 ? styles.accordionZero :styles.accordionOne;
    
    // TODO: Toggle/Convert ETH <---> USD
    var currencyIcon = this.props.currMatch.proposal.paid ? <div id="minBidIcon"><i class="fab fa-ethereum"></i></div>  :  <div id="minBidIcon"><i id="coinIcon" class="fas fa-up-down"></i></div>; // TODO: fa-library

    // var cardColor = this.state.format.value == "" ? "transparent" : proposalScripts.getProposalCardColor(this.state.format.value);
    // var warningMessageColor = this.state.format.value == "IMAGE" ? "image" : "tiktok"; // TODO: for more formats

    // checks for bid being a valid number above threshold and below balance / checks for valid link
    const submitWarningMessage = this.getWarningMessage();

    const inputPromptText = this.getInputPromptText();
    
    const submissionPrompt = 
    <FormGroup>
      <Input id={styles.linkInput}
      defaultValue="" 
      placeholder={inputPromptText}
      name="submission"
      onChange={this.onSubmissionChange}
      type="text" />
    </FormGroup>;

    // const submitButton = (this.props.loginComplete && this.checkBidValid() && this.checkSubmissionValid()) ? 
    const submitButton = (this.props.loginComplete && this.state.competitorBidIsValid && this.state.submissionIsValid) ? 
    // <JoinAsCompetitorButton 
    // onClick={ collapseFunc } // TODOL this not working
    // account={this.props.account} 
    // provider={this.props.provider} 
    // lobby={this.props.lobby} 
    // paid={this.props.paid}
    // submission={this.state.submission}
    // competitorBid={contractScripts.toEighteenDecimals(this.state.competitorBid.toString())} 
    // />
    <Button
      id={styles.submitBidButton}
      className="btn-simple"
      color="primary"
      type="button"
      onClick={this.sendMatchEntryTX}
    >
      SUBMIT
    </Button>
    :
    submitWarningMessage;

    const minBidMessage = <p> Minumum Bid: {contractScripts.decimalEighteen(this.props.currMatch.proposal.minCompetitorBid, "CollapseBidPrompt") } { currencyIcon } </p>;

    var releventMaxSliderBalance = this.props.currMatch.paid ? this.props.ETHBalance : this.props.XPBalance;
    // NOTE: below "corrects" from ethers.js BN to normal Number
    var correctedMaxSliderBalance = this.props.loginComplete ? Number(contractScripts.decimalEighteen(releventMaxSliderBalance, "CollapseBidPrompt")) : contractScripts.decimalEighteen(USER_STARTING_XP_QUANTITY);

    var minSliderBalance = this.props.currMatch != undefined ? Number(contractScripts.decimalEighteen(this.props.currMatch.proposal.minCompetitorBid, "CollapseBidPrompt")) : 50; // TODO: or current bid

    // console.log("releventMaxSliderBalance");
    // console.log(releventMaxSliderBalance);

    const bidPrompt = 
    <Slider
      // min={ minSliderBalance }
      min={ this.state.minSliderValue }
      // max={ correctedMaxSliderBalance }
      max={ this.state.maxSliderValue }
      step={ 1 }
      value={ this.state.competitorBid }
      tooltip={false}
      // labels={{ minSliderBalance: 'Minimum', correctedMaxSliderBalance: 'Your Balance'}} // TODO: 
      onChangeStart={this.handleChangeStart}
      onChange={this.onBidChange}
      onChangeComplete={this.handleChangeComplete}
    />;

    const bidDisplay = <div className='currentBidPendingPrompt'><h3 id={styles.yourPendingBidText}> Your Bid: {competitorBid} {" "} {currencyIcon} </h3> </div>;

    // const visibilityID = this.props.makeInvisible ? styles.invisibleCollapseBidPrompt : accordionID;
    const visibilityID = accordionID;

    return (
      <div
        aria-multiselectable={true}
        className="card-collapse"
        id={visibilityID}
        // id="accordion"
        role="tablist"
        >
        <Card id={styles.collapseBidCard}>
          <CardHeader id={styles.collapseBidCardHeader} role="tab">
            <a
              className="d-flex"
              href="#mw"
              data-toggle="collapse"
              aria-expanded={this.state.openedCollapses.includes(
                collapseClassname
              )}
              onClick={e => {
                e.preventDefault();
                this.collapsesToggle(collapseClassname);
              }}
            >
              {/* <div id={styles.pendingMemeCardBidPrompt}> Submit your meme + bid {" "} */}
              <div id={styles.pendingMemeCardBidPrompt}> Entry + bid {" "}
              <FontAwesomeIcon icon={faCaretDown} id={styles.collapseCompetitorIcon} /> </div>
            </a>
          </CardHeader>
          <Collapse
            role="tabpanel"
            isOpen={this.state.openedCollapses.includes(
              collapseClassname
            )}
          >
            {/* <CardBody> */}

              {/* { minBidMessage } */}

              {/* <BidInputSlider /> */}

              <div className='slider'>
                { bidPrompt }
              </div>

              { bidDisplay }

              { submissionPrompt }

            {/* </CardBody> */}
            <div id={styles.pendingMemeCardSubmitFooter}>
                <div id={styles.pendingMatchSubmitButton}>
                { submitButton }
                </div>
            </div>
          </Collapse>
        </Card>
      </div>
    );
  }
}

export default CollapseBidPrompt;


