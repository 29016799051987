import React, { Component } from 'react';
import moment from 'moment'; 

// CSS
import styles from './MemeMatch.module.scss';

// Components
import MemeMatchTopper from 'components/MemeMatch/MemeMatchTopper.jsx';
import MemeMatchCenter from './MemeMatchCenter.jsx';
import MemeCard from 'components/MemeMatch/MemeCard.jsx';
// import MemeCard1 from 'components/MemeMatch/MemeCard11.jsx';
import CollapseBidPrompt from './CollapseBidPrompt.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' // TODO: fa-library
import { faSpinner, faExpandArrowsAlt, faCaretDown, faArrowUp, faStar, faUser } from '@fortawesome/free-solid-svg-icons'


class MemeMatch extends Component {
  state = {
    matchLoading: false,           // bool
    matchLoaded: false,            // bool
    matchStage: "NONE",            // one of [ PENDING / ACTIVE / CANCELLED / OVER ] when: matchLoaded == true

    matchIsCancelled: false,       // bool
    isAfterJoiningPeriod: false,   // bool
    isAfterVotingPeriod: false,    // bool

    matchStartTime: null,          // moment.js timestamp object ( .unix().utc )
    joiningEndsTime: null,         // moment.js timestamp object ( .unix().utc )
    votingStartTime: null,         // moment.js timestamp object ( .unix().utc )
    votingEndsTime: null,          // moment.js timestamp object ( .unix().utc )

    activeSwitch: false,           // bool - true when [ ACTIVE --> OVER ] transition has occured
    pendingSwitch: false,          // bool - true when [ PENDING --> CANCELLED or PENDING --> ACTIVE ] transition has occured
  }

  componentDidMount() {}

  componentDidUpdate(prevState, prevProps) {
    // console.log("MemeMatch.jsx - UPDATE")

    const prevPropsDefined = prevProps.match !== undefined && prevProps.focusedMatchID !== undefined && prevProps.currMatch !== undefined;
    const currentPropsDefined = this.props.match !== undefined && this.props.focusedMatchID !== undefined && this.props.currMatch !== undefined;

    const focusedMatchIsLatestMatch = this.props.match.ID != undefined && this.props.focusedMatchID && this.props.match.ID.toString() == this.props.focusedMatchID.toString(); // this.props.focusedMatchID == this.props.currMatch.ID;
    var newMatchInfoInProps = false;

    const newCurrMatch = prevProps.currMatch != this.props.currMatch

    if (focusedMatchIsLatestMatch && this.props.currMatch != undefined) {
      newMatchInfoInProps = prevPropsDefined && (this.props.currMatch != prevProps.currMatch);
      // newMatchInfoInProps = (this.props.currMatch.matchStatus != prevProps.currMatch.matchStatus);
    }

    // const newMatchIDInProps = this.props.focusedMatchID != prevProps.focusedMatchID;
    const newMatchInProps = prevProps.match != this.props.match;

    const currMatchDefined = this.props.currMatch !== undefined;
    const prevCurrMatchDefined = prevProps.currMatch !== undefined;
    const currMatchPropsDefined = currMatchDefined && prevCurrMatchDefined;
    // const justLoggedIn = prevProps.loginComplete == true;
    const currMatchChanged = currMatchDefined && prevProps.currMatch != this.props.currMatch;
    // const accountChange = loginPropDefined && justLoggedIn && loginPropChanged;
    // const accountChange = matchDefined && loginPropChanged;
    const activeSwitchOccured = this.state.activeSwitch && this.state.activeSwitch != prevState.activeSwitch;
    const pendingSwitchOccured = this.state.pendingSwitch && this.state.pendingSwitch != prevState.pendingSwitch;

    // check if props are different and only update if they are
    const newProps = prevProps != this.props;
  
    // initial match load && updates ( after this.resetMatchState() is invoked)
    if (newProps && this.props !== undefined && !this.state.matchLoaded && !this.state.matchLoading) { 
      console.log("INITIAL MATCH LOAD - calling this.loadMatch();")
      this.loadMatch(); }

    // if currMatch has changed && is focused
    else if (currMatchPropsDefined && currMatchChanged && focusedMatchIsLatestMatch) {
      console.log("currMatch (focused) CHANGE!")
      this.resetMatchState(); 
    }
  
    // if focused match is currMatch & new match status appears, indicate this change to state
    else if ((currentPropsDefined && prevPropsDefined) && 
      ( newMatchInProps || (focusedMatchIsLatestMatch && newMatchInfoInProps))) {
      console.log("( newMatchIDInProps || (focusedMatchIsLatestMatch && newMatchInfoInProps))"); 
      this.resetMatchState(); // prompts total refresh of topper given new match info (in props)
    }

    else if (pendingSwitchOccured) {
      // this.loadMatch(); // prompts total refresh of topper given new match info (in props)
    }

    if (newCurrMatch) {
      // this.resetMatchState(); 
    }
  }

  // loads moment.js objects (into component state) which are associated with
  // determining the stage / status of focused-match (PENDING / ACTIVE / CANCELLED / OVER)
  loadMatch = () => {
    console.log("loadMatch - invoked");

    if (this.props.match !== undefined && this.props.match != null && this.props.match.ID != 0 && !this.state.matchLoading) { // TODO: 8/15/2020 added null=check
      console.log("loadMatch - #1");

      this.setState({ matchLoading: true }) 

      // Create moment.js objects (timestamps existing in smart contracts) and update relevant component state
      //
      var matchStartTime_notState_;
      var joiningEndsTime_notState_;
      var votingStartTime_notState_ = null; // default value for `votingEndsTime` in state
      var votingEndsTime_notState_ = null;  // default value for `votingEndsTime` in state (TO_CHECK: smart-contract values)
      //
      // *** FOR [ ALL ] MATCHES: set up relevant state fields and timers ***      
      matchStartTime_notState_ = moment.unix(this.props.match.matchStartTime.toString()).utc();
      joiningEndsTime_notState_ = moment.unix(this.props.match.matchStartTime.toString()).utc().add(Number(this.props.match.proposal.allowedJoiningTime.toString()), "seconds");
            // console.log("matchStartTime_notState_");
            // console.log(matchStartTime_notState_.format("LLL"));
            // console.log("match joining ends at:");
            // console.log(joiningEndsTime_notState_.format("LLL"));
      //
      // ***  FOR [ ACTIVE / OVER ] MATCHES: set up relevant state fields and timers ***
      if (this.props.match.votingStartTime.toString() != '0') {
        votingStartTime_notState_ = moment.unix(this.props.match.votingStartTime.toString()).utc()
        votingEndsTime_notState_ = moment.unix(this.props.match.votingStartTime.toString()).utc().add(Number(this.props.match.proposal.allowedVotingTime.toString()), "seconds");
            // console.log("votingStartTime_notState_:")
            // console.log(votingStartTime_notState_.format("LLL"))
            // console.log("votingEndsTime_notState_:")
            // console.log(votingEndsTime_notState_.format("LLL"))
      }       
      //
      ////////////////////////////////////////////////////////////////////////////////////////////////////////


      // Determine what stage match is in. One of [ PENDING / ACTIVE / CANCELLED / OVER ] 
      //
      var currentMatchStage_notState_ = "NONE";    // placeholder, will be updated if match is not PENDING
      var isAfterJoiningPeriod_notState_ = false;  // default
      var isAfterVotingPeriod_notState_  = false;  // default
      var matchIsCancelled_notState = false;       // default

      var pendingSwitch_notState_ = false;         // default - TRUE if match is "PENDING" and has transitioned
      var activeSwitch_notState_ = false;          // default - TRUE if match is "ACTIVE" and has transitioned
      // var shouldSendTransitionUpdate = false;      // TODO: if true, will send update to MemeMatch.jsx
      // var pendingTransitionUpdate = false;
      //
      // ***  FOR [ ACTIVE / OVER ] MATCHES: set relevant matchStage ***
      //
      // NOTE: votingEndTime and votingStartTime can only be set or used  
      //       if it's known when the voting period starts - 
      //       ie: when match goes PENDING --> ACTIVE 
      //         - triggered by first vote, in Smart Contracts (TODO TO_CHECK)
      if (this.props.match.votingStartTime.toString() != '0') {

        // const wasMatchPending = this.state.matchStage == "PENDING";
        const matchIsOver = this.props.match.matchStatus == "OVER" || moment.utc().isAfter(votingEndsTime_notState_);
        const matchIsActive = this.props.match.matchStatus == "ACTIVE" || moment.utc().isBefore(votingEndsTime_notState_)
        currentMatchStage_notState_ = matchIsOver ? "OVER" : "ACTIVE";


        if (matchIsOver) { 
          isAfterJoiningPeriod_notState_ = true; 
          isAfterVotingPeriod_notState_ = true;
          pendingSwitch_notState_ = true;
          activeSwitch_notState_ = true;

          // this.indicateActiveMatchOver() // TODO
          // shouldSendTransitionUpdate = true;
          // pendingTransitionUpdate = false; <-- it's already sent to this, and it can only be a transition between
        }

        else if (matchIsActive) {
          isAfterJoiningPeriod_notState_ = true; 
          isAfterVotingPeriod_notState_ = false;
          pendingSwitch_notState_ = true;
          activeSwitch_notState_ = false;
        };

          this.setState({
            isAfterJoiningPeriod: isAfterJoiningPeriod_notState_,
            isAfterVotingPeriod: isAfterVotingPeriod_notState_,
            matchIsCancelled: matchIsCancelled_notState,
            // indicate (to update loop) that a refresh has occured
            matchLoaded: true,
            matchLoading: false,
            pendingSwitch: pendingSwitch_notState_,
            activeSwitch: activeSwitch_notState_,
            matchStage: currentMatchStage_notState_
          });

      }
      //
      // ***  FOR [ PENDING / CANCELLED] MATCHES: set relevant matchStage ***
      //
      // NOTE: [ PENDING --> CANCELLED ]
      //
      else {
        // determine if focused-match is PENDING / CANCELLED 
        // currentMatchStage_notState_ = this.props.match.matchStatus.toString();

        const isItAfterJoiningTime = moment.utc().isAfter(joiningEndsTime_notState_);
        const notEnoughCompetitors = this.props.currMatch.competitors[1].address == "0x0000000000000000000000000000000000000000";
        const matchCancelled = isItAfterJoiningTime && notEnoughCompetitors;

        // match is CANCELLED
        if (matchCancelled) {
          currentMatchStage_notState_ = "CANCELLED";
          matchIsCancelled_notState = true;
        }
        // match is PENDING
        else {
          currentMatchStage_notState_ = "PENDING";
        }

        // if it's after joining time, match is either about to be ACTIVE (or is already) or CANCELLED
        if (isItAfterJoiningTime) {
          isAfterJoiningPeriod_notState_ = true;
          pendingSwitch_notState_ = true;
          // shouldSendTransitionUpdate = true;
          // pendingTransitionUpdate = true; // will trigger call to this.indicatePendingMatchCancelledOrActive();
          // this.indicatePendingMatchCancelledOrActive(); 
        }
      }
      ////////////////////////////////////////////////////////////////////////////////////////////////////////

      // if (shouldSendTransitionUpdate) { 
      //   // console.log("shouldSendTransitionUpdate")
      //   // this.resetMatchState(); // prompts total refresh of topper given new match info (in props)
      //   // if (pendingTransitionUpdate) this.indicatePendingMatchCancelledOrActive(); 
      //   // else { this.indicateActiveMatchOver() };    // TO_CHECK
      // } 

      // *** FOR [ ALL ] MATCHES: update component state (moment.js time objects) with most-recent values
      //     - NOTE: fields used to determine matchStage 
      //     - NOTE: ^ also: transitions between [ PENDING --> CANCELLED, PENDING --> ACTIVE, ACTIVE --> OVER ]
      this.setState({
        matchStartTime: matchStartTime_notState_,
        joiningEndsTime: joiningEndsTime_notState_,
        votingStartTime: votingStartTime_notState_,
        votingEndsTime: votingEndsTime_notState_,
        matchStage: currentMatchStage_notState_,
        isAfterJoiningPeriod: isAfterJoiningPeriod_notState_,
        isAfterVotingPeriod: isAfterVotingPeriod_notState_,
        matchIsCancelled: matchIsCancelled_notState,
        // indicate (to update loop) that a refresh has occured
        matchLoaded: true,
        matchLoading: false,
        pendingSwitch: pendingSwitch_notState_,
        activeSwitch: activeSwitch_notState_,
      });

      // if (shouldSendTransitionUpdate) { 
      //   console.log("shouldSendTransitionUpdate")
      //   this.props.resetMatchState(); // prompts total refresh of topper given new match info (in props)
      //   // if (pendingTransitionUpdate) this.indicatePendingMatchCancelledOrActive(); 
      //   // else { this.indicateActiveMatchOver() };    // TO_CHECK
      // } 
    }

    else { console.log("loadMatch - ELSE-BLOCK")}
  };

  resetMatchState = () => {
    this.setState({
      matchLoading: false,           // bool
      matchLoaded: false,            // bool
      matchStage: "NONE",            // one of [ PENDING / ACTIVE / CANCELLED / OVER ] when: matchLoaded == true

      matchIsCancelled: false,       // bool
      isAfterJoiningPeriod: false,   // bool
      isAfterVotingPeriod: false,    // bool

      matchStartTime: null,          // moment.js timestamp object ( .unix().utc )
      joiningEndsTime: null,         // moment.js timestamp object ( .unix().utc )
      votingStartTime: null,         // moment.js timestamp object ( .unix().utc )
      votingEndsTime: null,          // moment.js timestamp object ( .unix().utc )

      activeSwitch: false,           // bool - true when [ ACTIVE --> OVER ] transition has occured
      pendingSwitch: false,          // bool - true when [ PENDING --> CANCELLED or --> ACTIVE ] transition has occured
    });
  };

  render() {

    const relevantMatch = this.props.match // TODO: uncomment above if there's an issue
    // console.log("relevantMatch:")
    // console.log(relevantMatch)

    const topper =
    relevantMatch !== undefined 
      && 
      relevantMatch != null
      && 
      this.state.matchLoaded
      && 
      !this.state.matchLoading 
      ? 

        <MemeMatchTopper
        loggedIn={this.props.loggedIn}
        currMatch={this.props.currMatch} // Used for PENDING or ACTIVE (live updates) matches 
        match={relevantMatch}
        focusedMatchID={this.props.focusedMatchID}
        account={this.props.account}
        provider={this.props.provider}
        lobby={this.props.lobby}
        paid={this.props.paid}
        indicatePendingMatchCancelledOrActive={this.indicatePendingMatchCancelledOrActive}
        indicateActiveMatchOver={this.indicateActiveMatchOver}
        resetMatchState={this.resetMatchState}

        // pendingSwitch={this.state.pendingSwitch}
        // activeSwitch={this.state.activeSwitch}

        // now={this.state.now}
        timerRefreshRate={this.state.timerRefreshRate}
        matchLoading={this.state.matchLoading}           // bool
        matchLoaded={this.state.matchLoaded}            // bool
        matchStage={this.state.matchStage}                // one of [ PENDING / ACTIVE / CANCELLED / OVER ] when: matchLoaded == true
    
        // PENDING <--> CANCELLED 
        // ---------
        matchIsCancelled={this.state.matchIsCancelled}        // bool
    
        // PENDING <--> ACTIVE 
        // ---------
        matchStartTime={this.state.matchStartTime}           // moment object 
        joiningEndsTime={this.state.joiningEndsTime}           // moment object 
        isAfterJoiningPeriod={this.state.isAfterJoiningPeriod}    // bool
    
        // ACTIVE <--> OVER 
        // ---------
        votingStartTime={this.state.votingStartTime}           // moment object ( .unix().utc )
        votingEndsTime={this.state.votingEndsTime}           // moment object ( .unix().utc )
        isAfterVotingPeriod={this.state.isAfterVotingPeriod}     // bool
    
        // TRANSITIONS
        activeSwitch={this.state.activeSwitch}   // TODO this.props.pendingSwitch
        pendingSwitch={this.state.pendingSwitch}  
      />
      :
      <FontAwesomeIcon icon={faSpinner} pulse />;
      

      // const submitMemeInvisible = this.state.isAfterJoiningPeriod;
      const submitMemeInvisible = 
      this.props.match !== undefined && this.state.joiningEndsTime != null &&
      moment.utc().isAfter(this.state.joiningEndsTime)

      // console.log("SubmitMemeInvisible")
      // console.log(submitMemeInvisible)

      const submitBidAndMemeDisplay = 

        // !submitMemeInvisible

        // !this.state.isAfterJoiningPeriod

        // this.props.match != null && 
        // this.props.match !== undefined &&
        // // this.state.joiningEndsTime != null &&
        // // !(moment.utc().isAfter(this.state.joiningEndsTime)) &&
        // this.props.match.matchStatus == "PENDING"
      //   this.state.joiningEndsTime != null &
      // moment.utc().isAfter(this.state.joiningEndsTime) 
        // &&
        // this.state.joiningEndsTime != null
        // &&
        // !moment.utc().isAfter(this.state.joiningEndsTime)
        // && 
        // (this.state.pendingSwitch == false) // TODO: add any more conditions here for meme bid collapse to disappear
        // &&
        // (this.state.activeSwitch == false)
        // ?

      <div id={styles.submitBidAndMeme}>      
          <CollapseBidPrompt 
            // makeInvisible={submitMemeInvisible}
            // makeInvisible={false}
            makeInvisible={this.state.isAfterJoiningPeriod} // TODO
            // loggedIn={this.props.loggedIn}
            match={this.props.match}
            // entryNumber={this.props.entryNumber}
            entryNumber={"3"} // TODO: this doesn't make sense if Bid prompt is for both sides
            // ********* TODO
            // changeFocusedTab={(newTabIndex) => this.props.changeFocusedTab(newTabIndex)} 
            updateXPBalance={(newXPBalance) => this.props.updateXPBalance(newXPBalance)} // TODO: have one function (here) which handles the newXP value (clients just call UpdateXPBalance())
            toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
            // updateLoginInfo={(newLoginStatus) => this.props.updateLoginInfo(newLoginStatus)}
            // updateCurrMatch={(latestMatchObj) => this.props.updateCurrMatch(latestMatchObj)}
            // updateMatches={(allMatchesObj) => this.props.updateMatches(allMatchesObj)}
            // ************************* 
            // profile={this.props.profile}
            // lobbyInfo={this.props.lobbyInfo}
            // sessionState={this.props.sessionState}
            // ************************* 
            account={this.props.account} 
            provider={this.props.provider}
            joined={this.props.joined}
            XPBalance={this.props.XPBalance} 
            ETHbalance={this.props.ETHBalance} 
            availableETH={this.props.availableETH}
            lobby={this.props.lobby}
            paid={this.props.paid} 
            currMatch={this.props.currMatch}
            // matches={this.props.matches}
            proposals={this.props.proposals}
            // focusedTab={this.props.focusedTab}
            focusedMatchID={this.props.focusedMatchID}
            focusedEntry={this.props.focusedEntry}
            loginComplete={this.props.loginComplete}
            loginInProgress={this.props.loginInProgress}
          />   
        </div>;

      // console.log("this.state.isAfterJoiningPeriod")
      // console.log(this.state.isAfterJoiningPeriod)
      // console.log("this.state.isAfterVotingPeriod")
      // console.log(this.state.isAfterVotingPeriod)
      // console.log("this.state.matchIsCancelled")
      // console.log(this.state.matchIsCancelled)

    
    const submitMemeAndBidDisplay =     
    this.props.match !== undefined 
    && this.state.joiningEndsTime != null 

    // !moment.utc().isAfter(this.state.joiningEndsTime) 
    && !this.state.isAfterJoiningPeriod 
    && !this.state.pendingSwitch
    && !this.state.matchIsCancelled 
    ?
    submitBidAndMemeDisplay
    :
    <> </>;

    
    const contentLeftCard = this.props.match.competitors[0].submissionLink;
    const contentRightCard = this.props.match.competitors[1].submissionLink;
    // console.log("contentLeftCard")

    const noEntryFocusedDisplay = (
      <div id={styles.entriesAndStats}>
        <div id={styles.twoEntries} className={styles.leftEntry}>
          <MemeCard
            content={contentLeftCard}
            loggedIn={this.props.loggedIn}
            competitorNumber={0}
            // currMatch={this.props.currMatch}  // (globally, by lobby) most recent match
            match={this.props.match} // the currently-focused (in MemeMatchLoader.jsx) match (could be an old one)
            // ********* TODO
            // changeFocusedTab={(newTabIndex) => this.props.changeFocusedTab(newTabIndex)}
            updateXPBalance={newXPBalance => this.props.updateXPBalance(newXPBalance)} // TODO: have one function (here) which handles the newXP value (clients just call UpdateXPBalance())
            toggleLoginModal={loginModalIsOpen => this.props.toggleLoginModal(loginModalIsOpen)}
            // updateLoginInfo={(newLoginStatus) => this.props.updateLoginInfo(newLoginStatus)}
            // updateCurrMatch={(latestMatchObj) => this.props.updateCurrMatch(latestMatchObj)}
            // updateMatches={(allMatchesObj) => this.props.updateMatches(allMatchesObj)}
            // *************************
            // profile={this.props.profile}
            // lobbyInfo={this.props.lobbyInfo}
            // sessionState={this.props.sessionState}
            // *************************
            account={this.props.account}
            provider={this.props.provider}
            joined={this.props.joined}
            XPBalance={this.props.XPBalance}
            ETHbalance={this.props.ETHBalance}
            availableETH={this.props.availableETH}
            lobby={this.props.lobby}
            paid={this.props.paid}
            // matches={this.props.matches}
            // proposals={this.props.proposals}
            // focusedTab={this.props.focusedTab}
            focusedMatchID={this.props.focusedMatchID}
            focusedEntry={this.props.focusedEntry}
            loginComplete={this.props.loginComplete}
            loginInProgress={this.props.loginInProgress}
          />
        </div>

        <div id={styles.twoEntries} className={styles.rightEntry}>
          <MemeCard
            content={contentRightCard}
            loggedIn={this.props.loggedIn}
            competitorNumber={1}
            // currMatch={this.props.currMatch}  // (globally, by lobby) most recent match
            match={this.props.match} // the currently-focused (in MemeMatchLoader.jsx) match (could be an old one)
            
            // ********* TODO
            // changeFocusedTab={(newTabIndex) => this.props.changeFocusedTab(newTabIndex)}
            updateXPBalance={newXPBalance => this.props.updateXPBalance(newXPBalance)} // TODO: have one function (here) which handles the newXP value (clients just call UpdateXPBalance())
            toggleLoginModal={loginModalIsOpen => this.props.toggleLoginModal(loginModalIsOpen)}
            // updateLoginInfo={(newLoginStatus) => this.props.updateLoginInfo(newLoginStatus)}
            // updateCurrMatch={(latestMatchObj) => this.props.updateCurrMatch(latestMatchObj)}
            // updateMatches={(allMatchesObj) => this.props.updateMatches(allMatchesObj)}
            // *************************
            // profile={this.props.profile}
            // lobbyInfo={this.props.lobbyInfo}
            // sessionState={this.props.sessionState}
            // *************************
            account={this.props.account}
            provider={this.props.provider}
            joined={this.props.joined}
            XPBalance={this.props.XPBalance}
            ETHbalance={this.props.ETHBalance}
            availableETH={this.props.availableETH}
            lobby={this.props.lobby}
            paid={this.props.paid}
            // matches={this.props.matches}
            // proposals={this.props.proposals}
            // focusedTab={this.props.focusedTab}
            focusedMatchID={this.props.focusedMatchID}
            focusedEntry={this.props.focusedEntry}
            loginComplete={this.props.loginComplete}
            loginInProgress={this.props.loginInProgress}
          />
        </div>

        {/* // TODO: START OF SUBMIT BID + MEME PROMPT */}


        {/* <CollapseBidPrompt 
      loggedIn={this.props.loggedIn}
      match={this.props.match}
      entryNumber={this.props.entryNumber}
      // ********* TODO
      // changeFocusedTab={(newTabIndex) => this.props.changeFocusedTab(newTabIndex)} 
      updateXPBalance={(newXPBalance) => this.props.updateXPBalance(newXPBalance)} // TODO: have one function (here) which handles the newXP value (clients just call UpdateXPBalance())
      toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
      // updateLoginInfo={(newLoginStatus) => this.props.updateLoginInfo(newLoginStatus)}
      // updateCurrMatch={(latestMatchObj) => this.props.updateCurrMatch(latestMatchObj)}
      // updateMatches={(allMatchesObj) => this.props.updateMatches(allMatchesObj)}
      // ************************* 
      // profile={this.props.profile}
      lobbyInfo={this.props.lobbyInfo}
      sessionState={this.props.sessionState}
      // ************************* 
      account={this.props.account} 
      provider={this.props.provider}
      joined={this.props.joined}
      XPBalance={this.props.XPBalance} 
      ETHbalance={this.props.ETHBalance} 
      availableETH={this.props.availableETH}
      lobby={this.props.lobby}
      paid={this.props.paid} 
      currMatch={this.props.currMatch}
      // matches={this.props.matches}
      // proposals={this.props.proposals}
      // focusedTab={this.props.focusedTab}
      focusedMatchID={this.props.focusedMatchID}
      focusedEntry={this.props.focusedEntry}
      loginComplete={this.props.loginComplete}
      loginInProgress={this.props.loginInProgress}
    />; */}

         {/* // TODO: END OF SUBMIT BID + MEME PROMPT */}

         <div id={styles.matchStatsAndEntryPrompt}>

        <div className={styles.matchStats}> 
          <MemeMatchCenter
            loggedIn={this.props.loggedIn}
            match={this.props.match}
            currMatch={this.props.currMatch}
            account={this.props.account}
            provider={this.props.provider}
            lobby={this.props.lobby}
            paid={this.props.paid}
            // ********* TODO
            // changeFocusedTab={(newTabIndex) => this.props.changeFocusedTab(newTabIndex)}
            updateXPBalance={newXPBalance => this.props.updateXPBalance(newXPBalance)} // TODO: have one function (here) which handles the newXP value (clients just call UpdateXPBalance())
            toggleLoginModal={loginModalIsOpen => this.props.toggleLoginModal(loginModalIsOpen)}
            // updateLoginInfo={(newLoginStatus) => this.props.updateLoginInfo(newLoginStatus)}
            // updateCurrMatch={(latestMatchObj) => this.props.updateCurrMatch(latestMatchObj)}
            // updateMatches={(allMatchesObj) => this.props.updateMatches(allMatchesObj)}
            // *************************
            // profile={this.props.profile}
            // lobbyInfo={this.props.lobbyInfo}
            // sessionState={this.props.sessionState}
            // *************************
            // currMatch={this.props.currMatch}
            // account={this.props.account}
            // provider={this.props.provider}
            joined={this.props.joined}
            XPBalance={this.props.XPBalance}
            ETHbalance={this.props.ETHBalance}
            availableETH={this.props.availableETH}
            paid={this.props.paid}
            matches={this.props.matches}
            // proposals={this.props.proposals}
            // focusedTab={this.props.focusedTab}
            focusedMatchID={this.props.focusedMatchID}
            focusedEntry={this.props.focusedEntry}
            loginComplete={this.props.loginComplete}
            loginInProgress={this.props.loginInProgress}
          />
        </div>

        
            {/* { submitBidAndMemeDisplay } */}
            { submitMemeAndBidDisplay }
            </div>
      </div>
    );

    const entryFocusedDisplay = <> </>;


    // const memeMatchDisplay = this.props.focusedEntry ? entryFocusedDisplay : noEntryFocusedDisplay;
    const memeMatchDisplay = noEntryFocusedDisplay;

    return (
      <div className="block-gradient">
      {/* <div className="block-gradient-slow">  */}
        <div className="gradient-border">
          <div id={styles.memeMatchContainer}>
            {topper}
            {memeMatchDisplay}
          </div>
        </div>
      </div>
    );
  }
}

export default MemeMatch;

