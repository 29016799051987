import React, { Component } from "react";
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom'
// import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { changeFocusedTab, toggleLoginModal, toggleSettingsModal } from '../../actions/sessionStateActions.js';

// Footer-HooksHOC is a function component (allowed to use Hooks from wagmi and RainbowKit) 
// it passes props to this class-component so that this component can use React Hooks
import { WagmiHooksHOC } from '../HooksHOC/WagmiHooksHOC.jsx'

// CSS
import styles from "./Footer.module.scss";

// reactstrap components
import { NavLink } from "reactstrap";

class Footer extends React.Component {

  clickedSettingsLink = (e) => {
    // TODO mixpanel: incorporate more clicked links
    // window.mixpanel.track("CLICKED_FOOTER_LINK", {"LINK_TITLE": "Settings"}); 
    // this.props.toggleSettingsModal(true)
    // console.log("Footer.jsx – calling this.props.examplePropFunc()")
    // this.props.examplePropFunc("hello")
    // this.props.openChainModal()
    e.preventDefault(); // prevent the default navigation event
    this.props.toggleLoginModal(true)
  }

  clickedAboutLink = () => {
    // TODO mixpanel: incorporate more clicked links
    // window.mixpanel.track("CLICKED_FOOTER_LINK", {"LINK_TITLE": "About Memewars"}); 
    // this.props.toggleLoginModal(true);
  }

  clickedBetaLink = () => {
    // TODO: incorporate more clicked links
    // window.mixpanel.track("CLICKED_FOOTER_LINK", {"LINK_TITLE": "Beta"}); 
    const betaTab = 5;
    this.props.changeFocusedTab(betaTab);
  }

  clickedContributorsLink = () => {
    // console.log("Contributor Link Clicked")
  }

  clickedContractsLink = () => {
    // console.log("Contributor Link Clicked")
  }

  render() {
    return (
      <>
        <footer id={styles.footer} className="footer footer-simple">
            <nav>
              <ul>
                <li>
                  <NavLink
                    // href="https://docsend.com/view/28x54q8ez7pccsqq"
                    // href="https://trello.com/b/gLY2a5Zc/wwwmemewars"
                    // target="_blank"
                    href="/about"
                    target=""
                    onClick={this.clickedAboutLink}
                    id={styles.footerLink}
                  >
                    ABOUT
                      </NavLink>
                </li>
                {/* <li>
                  <NavLink
                    href="/contributors"
                    target=""
                    onClick={this.clickedContributorsLink}
                    id={styles.footerLink}     
                    >
                    CONTRIBUTORS
                  </NavLink>
                </li> */}
                <li>
                  <NavLink
                    href=""
                    target=""
                    onClick={this.clickedSettingsLink}
                    id={styles.footerLink}
                    >
                    SETTINGS
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink
                    href="#"
                    target="_blank"
                    className="ml-1"
                    id={styles.footerLink}
                  >
                    LOBBIES
                  </NavLink>
                </li> */}
                {/* <li>
                  <NavLink
                    href=""
                    target="_blank"
                    className="ml-1"
                    id={styles.footerLink}
                  >
                    CONTACT
                    </NavLink>
                </li> */}
                  <li>
                  <NavLink
                    href="/contracts"
                    target=""
                    className="ml-1"
                    onClick={this.clickedContractsLink}
                    id={styles.footerLink}
                  >
                    CONTRACTS
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink
                    href="/beta"
                    target=""
                    className="ml-1"
                    onClick={this.clickedBetaLink}
                    id={styles.footerLink}
                  >
                    BETA
                  </NavLink>
                </li> */}
              </ul>
            </nav>
            <div id={styles.copyright}>

              {/* © COPYRIGHT TODO*/}
               {/* {new Date().getFullYear()},  */}
               {/* <AccountDisplay account={'0xa8ee0babe72cd9a80ae45dd74cd3eae7a82fd5d1'} /> */}
               Made possible by {" "}
              <a
                className="copyright-link"
                // href="https://skale.network/"
                // href="https://www.dev.memewa.rs/"
                href="https://ethereum.org/what-is-ethereum/"
                target="_blank"
                rel="noopener noreferrer"
              >
               {/* {" "} SKALE {" "} */}
               {/* Ethereum */}
                  </a>
                  {/* {" "} and {" "} */}
                  <a
                className="copyright-link"
                href="https://ethereum.org/what-is-ethereum/"
                // href="https://www.dev.memewa.rs/"
                // href="https://skale.network/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Ethereum
              </a>  
              {/* .  */}
              {/* {" "} Created by {" "}
              <a
                className="copyright-link"
                href="https://pm1.narvii.com/6330/c543557733e5106dc4b643505aab5a8a82ae1d3d_00.jpg"
                target="_blank"
              >
                {"  meltware "}
              </a>
              . */}
                </div>
          </footer>
      </>
    );
  }
}

Footer.propTypes = {
  changeFocusedTab: PropTypes.func.isRequired,
  toggleLoginModal: PropTypes.func.isRequired,
  toggleSettingsModal: PropTypes.func.isRequired,
  focusedTab: PropTypes.number,
  loginModalToggled: PropTypes.bool,
};

const mapStateToProps = state => ({
  focusedTab: state.sessionState.focusedTab,
  loginModalToggled: state.sessionState.loginModalToggled,
  settingsModalToggled: state.sessionState.settingsModalToggled,
});

const FooterWithHooks = WagmiHooksHOC(Footer)

// export default FooterHooksHOC(connect(mapStateToProps, { changeFocusedTab, toggleLoginModal, toggleSettingsModal })(Footer));

export default connect(mapStateToProps, { changeFocusedTab, toggleLoginModal, toggleSettingsModal })(FooterWithHooks)
