import { Chain } from 'wagmi'
import { 
    ALCHEMY_BASE_SEPOLIA_URL_HTTP, 
    ALCHEMY_BASE_SEPOLIA_URL_WSS,
    ALCHEMY_BASE_URL_HTTP,
    ALCHEMY_BASE_URL_WSS
 } from './CONTRACT_ADDRESSES.js'


/** @type {Chain} */
export const baseSepolia = {
  id: 84532,
  name: 'Base Sepolia',
  network: 'base-sepolia',
  nativeCurrency: {
    decimals: 18,
    name: 'Ether',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://sepolia.base.org'] },
    default: { http: [ALCHEMY_BASE_SEPOLIA_URL_HTTP] },
    wss: [ALCHEMY_BASE_SEPOLIA_URL_WSS],
  },
  blockExplorers: {
    default: { name: 'Basescan', url: 'https://sepolia.basescan.org' },
  },
}

/** @type {Chain} */
export const base = {
  id: 8453, // Assign the appropriate network ID for base
  name: 'Base',
  network: 'base',
  nativeCurrency: {
    decimals: 18,
    name: 'Ether',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://mainnet.base.org'] }, // Replace with actual RPC URL if known
    default: { http: [ALCHEMY_BASE_URL_HTTP] }, 
    wss: [ALCHEMY_BASE_URL_WSS],
  },
  blockExplorers: {
    default: { name: 'Basescan', url: 'https://basescan.org' },
  },
}
