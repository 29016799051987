// web2DemoProposals.js

const web2DemoProposals = [
    {
      ID: "0x11115001c7b48d61e77d2e59209b71fdc5d1316858159dcfb9d7212ac9b5dffe",
      submitter: "0x0000000000000000000000000000000000000001",
      paid: false,
      format: "TEXT",
      prompt: "How would an AI 'kill switch' work?",
      voterEntryCost: {
        type: "BigNumber",
        hex: "0x4563918244f40000",
      },
      minCompetitorBid: {
        type: "BigNumber",
        hex: "0x8ac7230489e80000",
      },
      allowedJoiningTime: 300,
      allowedVotingTime: 120,
      timeSubmitted: 1693715996,
      upvotes: 12,
      chosen: false,
      defaultProposal: true,
    },
    {
      ID: "0x22225001c7b48d61e77d2e59209b71fdc5d1316858159dcfb9d7212ac9b5dffe",
      submitter: "0x0000000000000000000000000000000000000002",
      paid: false,
      format: "TEXT",
      prompt: "GPU tracking / compute governance",
      voterEntryCost: {
        type: "BigNumber",
        hex: "0x4563918244f40000",
      },
      minCompetitorBid: {
        type: "BigNumber",
        hex: "0x8ac7230489e80000",
      },
      allowedJoiningTime: 300,
      allowedVotingTime: 120,
      timeSubmitted: 1693715996,
      upvotes: 7,
      chosen: false,
      defaultProposal: false,
    },
    {
      ID: "0x33335001c7b48d61e77d2e59209b71fdc5d1316858159dcfb9d7212ac9b5dffe",
      submitter: "0x0000000000000000000000000000000000000003",
      paid: false,
      format: "TEXT",
      prompt: "Data as Labor",
      voterEntryCost: {
        type: "BigNumber",
        hex: "0x4563918244f40000",
      },
      minCompetitorBid: {
        type: "BigNumber",
        hex: "0x8ac7230489e80000",
      },
      allowedJoiningTime: 300,
      allowedVotingTime: 120,
      timeSubmitted: 1693715996,
      upvotes: 5,
      chosen: false,
      defaultProposal: false,
    },
    {
      ID: "0x44445001c7b48d61e77d2e59209b71fdc5d1316858159dcfb9d7212ac9b5dffe",
      submitter: "0x0000000000000000000000000000000000000004",
      paid: false,
      format: "TEXT",
      prompt: "Should AI labs be nationalized?",
      voterEntryCost: {
        type: "BigNumber",
        hex: "0x4563918244f40000",
      },
      minCompetitorBid: {
        type: "BigNumber",
        hex: "0x8ac7230489e80000",
      },
      allowedJoiningTime: 300,
      allowedVotingTime: 120,
      timeSubmitted: 1693715996,
      upvotes: 8,
      chosen: false,
      defaultProposal: false,
    },
    {
      ID: "0x44446001c7b48d61e77d2e59209b71fdc5d1316858159dcfb9d7212ac9b5dffe",
      submitter: "0x0000000000000000000000000000000000000005",
      paid: false,
      format: "TEXT",
      prompt: "ZK cryptography / AI Safety overlap",
      voterEntryCost: {
        type: "BigNumber",
        hex: "0x4563918244f40000",
      },
      minCompetitorBid: {
        type: "BigNumber",
        hex: "0x8ac7230489e80000",
      },
      allowedJoiningTime: 300,
      allowedVotingTime: 120,
      timeSubmitted: 1693715996,
      upvotes: 3,
      chosen: false,
      defaultProposal: false,
    },
    
    // Add more proposals here...
  ];
  
  export default web2DemoProposals;
  