import React, { Component } from 'react';

// CSS
import 'assets/css/m_w.css';
import styles from "./UpcomingMatches.module.scss";

// reactstrap components
import { Card, CardHeader, CardBody } from 'reactstrap';

// Components
import ProposalCard from './ProposalCard.jsx';
import CollapseProposalPrompt from './CollapseProposalPrompt.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faExpandArrowsAlt, faCaretDown, faArrowUp, faStar, faUser } from '@fortawesome/free-solid-svg-icons'

// var proposalCounter = 0; TODO: split new proposals into lists of 3 so they don't get too tall 

class ProposalsDisplay extends Component {

  componentDidMount() {}

  componentDidUpdate() {}

  render() {

    const restProposals = (this.props.proposals.length == 0 || this.props.proposals.filter(nextProposal => nextProposal.ID != "0x0000000000000000000000000000000000000000000000000000000000000000").length == 0) ? 
        <h3 id={styles.noProposalsText}>
          {/* No other proposals so far  */}
          None, so far... 
          <FontAwesomeIcon icon={faSpinner} id={styles.noProposalsIcon} pulse />
        </h3>
       : 
      (
        this.props.proposals.filter(nextProposal => nextProposal.ID != "0x0000000000000000000000000000000000000000000000000000000000000000")
        .map(nextProposal => 
        //  proposalCounter = proposalCounter == 3 ? 0 : proposalCounter += 1;
        <ProposalCard 
          updateProposals={(lobbyProposals) => this.props.updateProposals(lobbyProposals)}
          changeFocusedTab={(newTabIndex) => this.props.changeFocusedTab(newTabIndex)} 
          updateXPBalance={(newXPBalance) => this.props.updateXPBalance(newXPBalance)} 
          toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
          //
          // id={styles.proposalListProposalCard}
          key={nextProposal.ID} 
          joined={this.props.joined}
          loginComplete={this.props.loginComplete}
          lobby={this.props.lobby}
          account={this.props.account}
          paid={this.props.paid}
          provider={this.props.provider} 
          proposal={nextProposal} 
        />)
      );

    const proposalPrompt = (
      <CollapseProposalPrompt
        updateProposals={(lobbyProposals) => this.props.updateProposals(lobbyProposals)}
        changeFocusedTab={(newTabIndex) => this.props.changeFocusedTab(newTabIndex)} 
        updateXPBalance={(newXPBalance) => this.props.updateXPBalance(newXPBalance)} 
        toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
        //
        joined={this.props.joined}
        loginComplete={this.props.loginComplete}
        lobby={this.props.lobby}
        account={this.props.account}
        paid={this.props.paid}
        provider={this.props.provider}
        //
        proposalPromptOpen
      />
    );

    // const addProposalCssClass = 

  return (
      <Card id={styles.proposalsList}>
        <CardHeader id={styles.proposalsListHeader}>

        <h6 id={styles.otherProposalsText}>
          <div id={styles.otherProposalsIconAndText}>
            <i className="tim-icons icon-minimal-right" />  OTHER VOTE PROPOSALS
          </div>

            {proposalPrompt} 

        </h6>
        {/* {proposalPrompt} */}
        </CardHeader>
        <CardBody id={styles.proposalsListBody}>
        {/* <div id="restProposalsList"> TODO: split new proposals into lists of 3 so they don't get too tall  */}
          {restProposals}
        </CardBody>
      </Card>
    );
  }
}

export default ProposalsDisplay;
