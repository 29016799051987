import { FETCH_LOBBY, CHANGE_LOBBY, FETCH_PROPOSALS, NEW_PROPOSAL, LOAD_MATCHES, FETCH_MATCH, NEW_MATCH } from '../actions/types';

const initialState = {
    lobby: "main",              // indicates main lobby
    lobbyID: "0xb8e2054f8a912367e38a22ce773328ff8aabf8082c4120bad9ef085e1dbf29a7",            // keccak256(lobby)
    paid: false,            // indicates whether lobby is for real money or not
    currMatch: null,
    matches: [],            // TODO: changed from []
    proposals: [],          // TODO: changed from null --> []
    nsfwWarning: false,       // TODO
    jackpotAllowed: false,
    jackpotPool: 0,           // should be ethers.js BN
    jackpotThreshold: 0,      // should be ethers.js BN
};

export default function(state = initialState, action) {
    switch (action.type) {
      case FETCH_PROPOSALS:
        return {
          ...state,
          proposals: action.payload
        };
      case NEW_PROPOSAL: // TODO: unused ?
        return {
          ...state,
          newProposal: action.payload
        };
      case FETCH_LOBBY:
        return {
          ...state,
          lobby: action.payload.lobby,
          paid: action.payload.paid,
          currMatch: action.payload.currMatch,
          matches: action.payload.matches
        }
      case CHANGE_LOBBY:
        return {
          ...state,
          lobby: action.payload.lobby,
          paid: action.payload.paid,
          // currMatch: action.payload.currMatch,
          // matches: action.payload.matches
        }
      case NEW_MATCH:
        return {
          ...state,
          // currMatch: action.payload.currMatch,
          currMatch: action.payload
        }
      case LOAD_MATCHES:
          return {
            ...state,
            // currMatch: action.payload.currMatch,
            matches: action.payload
          }
      default:
        return state;
    }
  }


// TODO: MULTILOBBIESTODO
// make function for updating lobby / lobbyID