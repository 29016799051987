import React from 'react';

import contractScripts from '../Buttons/contractScripts.js'

// reactstrap components
import { Button } from 'reactstrap';

class VoteButton extends React.Component {

  voteForEntry = async () => {
    console.log("VoteForEntry");
    // console.log(this.props.account);
    // console.log(this.props.provider);
    // console.log("Lobby: " + this.props.lobby);
    // console.log("Paid Match: " + this.props.paid);
    // console.log("Vote: " + this.props.voteFor);

    if (this.props.loggedIn) {
  
    const vote = this.props.voteFor == 1 ? true : false; // false = vote for competitor 0 (left) / true = vote for competitor 1 (right) 
    // console.log("vote");
    // console.log(vote);
    await contractScripts.voteOnMatch(
      this.props.account, 
      this.props.provider, 
      this.props.lobby, 
      this.props.paid, 
      vote
      );

    // Get updated XPBalance
      // TODO: make sure this works + update XP balance at end of match if user played
      let XPBalance = await contractScripts.getXPBalance(this.props.account, this.props.provider);
      // TODO
      let ETHBalance = await contractScripts.getETHBalance(this.props.account, this.props.provider);
      this.props.updateXPBalance(XPBalance);
      // TODO
      // this.props.updateETHBalance(ETHBalance)
    }

    else {
      this.props.toggleLoginModal(true)
    }

  }

  launchLoginModal = () => {
    // TODO
    const openModal = true;
    this.props.toggleLoginModal(openModal);
  }

  render() {

    const currencyIcon = this.props.paid ? <div id="joinButtonIcon"><i class="fab fa-ethereum"></i></div> : <i id="coinIcon" class="fas fa-up-down"></i>;
    const costDisplay = <div id="voterEntryCost" > { contractScripts.decimalEighteen(this.props.voterEntryCost, "VoteOnMatchButton") } </div>;

    const currencyAmountAndIcon = <div id="joiningAmount"> {costDisplay} {currencyIcon} </div>;

    const onClickFunc = this.props.loggedIn ? this.voteForEntry : this.launchLoginModal;

    // const joinPrompt = this.props.plaintext ? <> vote {currencyAmountAndIcon} </> : <> vote {currencyAmountAndIcon} </>;

    const joinPrompt = this.props.plaintext ? <> vote </> : <> vote </>;

    const voteButton = this.props.plaintext ? 
    <Button onClick={onClickFunc} id="plaintextjoinAsVoterButton" className="btn-link ml-1" color="plainvoterjoin">
    { joinPrompt }
    </Button>
    :
    <Button onClick={onClickFunc} id="joinAsVoterButton" color="voterjoin" type="btn"> 
    { joinPrompt }
    </Button>;

    return voteButton;
  }
}

export default VoteButton;
