import React from 'react';
import moment from 'moment';

// CSS
import 'assets/css/m_w.css';
import styles from "./MemeMatch.module.scss";

// Scripts
import contractScripts from '../Buttons/contractScripts.js'

// reactstrap components
import { Badge } from 'reactstrap';

// Components
// import JoinAsVoterButton from 'components/Buttons/JoinAsVoterButton.jsx';
// import CollapseBidPrompt from './CollapseBidPrompt.jsx';
import EndMatchButton from 'components/Buttons/EndMatchButton.jsx';
import CancelMatchButton from 'components/Buttons/CancelMatchButton.jsx';

// GLOBALS (TODO)
// const BN = require('bn.js');

var numVotersGlowsUpdate = "numVotersGlowsUpdateLoad";
var voterStakesGlowsUpdate = "voterStakesGlowsUpdateLoad";
var competitorPrizeGlowsUpdate = "competitorPrizeGlowsUpdateLoad";

class MemeMatchCenter extends React.Component {
  state = {
    // matchEndedSwitch: false,
    now: null
  }

  componentDidMount() {
    // console.log("NEW MATCH TIME DISPLAY SPAWNED");
    this.interval = setInterval(() => {
      const now = moment.utc();
      this.setState({ now });
    }, 1000)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.voters.length != this.props.match.voters.length) {
      if (numVotersGlowsUpdate == "numVotersGlowsUpdateLoad") numVotersGlowsUpdate = "numVotersGlowsUpdateOne";
      else numVotersGlowsUpdate = numVotersGlowsUpdate == "numVotersGlowsUpdateOne" ? "numVotersGlowsUpdateTwo" : "numVotersGlowsUpdateOne";
    }

    if (prevProps.match.prizePool != this.props.match.prizePool) {
      if (voterStakesGlowsUpdate == "voterStakesGlowsUpdateLoad") voterStakesGlowsUpdate = "voterStakesGlowsUpdateOne";
      else voterStakesGlowsUpdate = voterStakesGlowsUpdate == "voterStakesGlowsUpdateOne" ? "voterStakesGlowsUpdateTwo" : "voterStakesGlowsUpdateOne";
    }

    if (prevProps.match.competitors[1].competitorBid != this.props.match.competitors[1].competitorBid) {
      if (competitorPrizeGlowsUpdate == "competitorPrizeGlowsUpdateLoad") competitorPrizeGlowsUpdate = "competitorPrizeGlowsUpdateOne";
      else competitorPrizeGlowsUpdate = competitorPrizeGlowsUpdate == "competitorPrizeGlowsUpdateOne" ? "competitorPrizeGlowsUpdateTwo" : "competitorPrizeGlowsUpdateOne";
    }

    // TODO: make it so that the values (ctrl+ f decimalEighteen()) don't get updated every second unless the props actually change
  }

  // shouldComponentUpdate(nextProps) {

  //   console.log("Should component update MemeMatchGraph2");

  //   if (nextProps != this.props) return true;

  //   const votingStartTime = moment.unix(this.props.match.votingStartTime);
  //   const votingEndsTime = moment.utc(votingStartTime).add(this.props.match.proposal.allowedVotingTime, "seconds");
  //   // const votingEndsPlusFive = moment.utc(votingEndsTime).add(1, "seconds");
  //   const isAfterVotingPeriod = moment.utc().isAfter(votingEndsTime);

  //   if (!this.state.matchEndedSwitch && isAfterVotingPeriod ) {
  //     this.setState({matchEndedSwitch: true});
  //     return true;
  //   }

  //   return true;

  // }

  // could be called from MemeMatch display, or in explorer -> change className in either case

  getTimeText = () => {

  };

  getVotersDisplay = () => {
    const prompt = this.props.match.proposal.prompt == "" ? " NONE" : this.props.match.proposal.prompt;

    // TODO: try to get rid of BN.js here, not sure why new BN(2) works on line 87 but not on 90
    // console.log("this.props.match.competitors[1].competitorBid:")
    // console.log(this.props.match.competitors[1].competitorBid)
    var currCompetitorPrize = this.props.match.competitors[1].competitorBid.mul(2);
    // if match becomes active, minimum competitor prize will be minCompetitorBid * 2
    var minCompetitorPrizeHalf = this.props.match.proposal.minCompetitorBid;
    var minCompetitorPrize = minCompetitorPrizeHalf.mul(2);
    var competitorPrizeVal = currCompetitorPrize.lte(minCompetitorPrize) ? minCompetitorPrize : currCompetitorPrize;

    const matchStartTime = moment.unix(this.props.match.matchStartTime).utc();
    const joiningEndsTime = moment.utc(matchStartTime).add(this.props.match.proposal.allowedJoiningTime, "seconds");
    const joinTimeOver = moment.utc().isAfter(joiningEndsTime);

    switch (this.props.match.matchStatus) {
      case "NONE":
      // return console.log("MemeMatchTopper: matchStatus is NONE");
      case "PENDING":

        const notEnoughCompetitors = joinTimeOver && this.props.match.competitors[1].address == "0x0000000000000000000000000000000000000000";

        // to account for roll-over period where first vote hasn't occured (match hasn't changed to ACTIVE)
        var timeBasedDisplay = joinTimeOver ?
          <>
            {/* <p> prompt: { prompt } </p> */}
            {/* <p> contestant_prize: <div className={competitorPrizeGlowsUpdate}  id="competitorPrizeValue"> {contractScripts.decimalEighteen(competitorPrizeVal)} </div> </p>
        <br /> */}
            {/* <p> min_entry_bid: 
          <div className={competitorPrizeGlowsUpdate}  id="competitorPrizeValue"> 
          {contractScripts.decimalEighteen(this.props.match.proposal.minCompetitorBid)} 
          </div> 
        </p>  */}
            <p className={styles.matchStat}>
              <div id={styles.matchStatTitle}>
                voters_so_far:
                <div className={numVotersGlowsUpdate} id="numberVotersValue">
                  {this.props.match.voters.length}
                </div>
              </div>
            </p>
            <p className={styles.matchStat}> cost_to_vote:
              <div id="numberVoterCost">
                {contractScripts.decimalEighteen(this.props.match.proposal.voterEntryCost, "MemeMatchCenter")}
              </div>
            </p>
            <p className={styles.matchStat}>
              <div id={styles.matchStatTitle}>
                voter_stakes:
                <div className={voterStakesGlowsUpdate} id="voterStakesValue">
                  {this.props.match.prizePool}
                </div>
              </div>
            </p>
          </>
          :
          <>
            {/* <p> prompt: { prompt } </p> */}
            {/* <p> min_entry_bid: 
          <div className={competitorPrizeGlowsUpdate}  id="competitorPrizeValue"> 
          {contractScripts.decimalEighteen(this.props.match.proposal.minCompetitorBid)} 
          </div> 
        </p> */}
            <div className={styles.matchStatRow}>
              <p className={styles.matchStat}> contestant_prize:
                <div className={competitorPrizeGlowsUpdate} id="competitorPrizeValue">
                  {contractScripts.decimalEighteen(competitorPrizeVal, "MemeMatchCenter")}
                </div>
              </p>

              <p className={styles.matchStat}> cost_to_vote:
                <div id="numberVoterCost">
                  {contractScripts.decimalEighteen(this.props.match.proposal.voterEntryCost, "MemeMatchCenter")}
                </div>
              </p>
            </div>

          </>;

        var pendingDisplay = notEnoughCompetitors ?
          <div id={styles.notEnoughCompetitors}>
            {/* <p> not_enough_competitors </p> */}
            {/* <p> not_enough_contestants </p> */}
            <p> not_enough_voters </p>
            <CancelMatchButton
              match={this.props.match}
              currMatch={this.props.match}
              matchContainer={true}
              text="FREE LOBBY FOR NEXT VOTE"
              // text="FREE LOBBY" 
              // text="TERMINATE MATCH" 

              // ********* TODO
              // changeFocusedTab={(newTabIndex) => this.props.changeFocusedTab(newTabIndex)}
              // updateXPBalance={newXPBalance => this.props.updateXPBalance(newXPBalance)} // TODO: have one function (here) which handles the newXP value (clients just call UpdateXPBalance())
              toggleLoginModal={loginModalIsOpen => this.props.toggleLoginModal(loginModalIsOpen)}
              // updateLoginInfo={(newLoginStatus) => this.props.updateLoginInfo(newLoginStatus)}
              // updateCurrMatch={(latestMatchObj) => this.props.updateCurrMatch(latestMatchObj)}
              // updateMatches={(allMatchesObj) => this.props.updateMatches(allMatchesObj)}
              // *************************
              // profile={this.props.profile}
              // lobbyInfo={this.props.lobbyInfo}
              // sessionState={this.props.sessionState}
              // *************************
              account={this.props.account}
              provider={this.props.provider}
              lobby={this.props.lobby}
              paid={this.props.paid}
              joined={this.props.joined}
              XPBalance={this.props.XPBalance}
              ETHbalance={this.props.ETHBalance}
              availableETH={this.props.availableETH}
              // currMatch={this.props.currMatch}
              matches={this.props.matches}
              // proposals={this.props.proposals}
              // focusedTab={this.props.focusedTab}
              // focusedMatchID={this.props.focusedMatchID}
              // focusedEntry={this.props.focusedEntry}
              loginComplete={this.props.loginComplete}
            // loginInProgress={this.props.loginInProgress}
            />

          </div>
          :
          timeBasedDisplay;

        return pendingDisplay;

      case "ACTIVE":

        const votingStartTime = moment.unix(this.props.match.votingStartTime);
        const votingEndsTime = moment.utc(votingStartTime).add(this.props.match.proposal.allowedVotingTime, "seconds");
        const isAfterVotingPeriod = moment.utc().isAfter(votingEndsTime);

        const activeDisplay = !isAfterVotingPeriod ?
          <>
            {/* <p> prompt: { prompt } </p> */}
            {/* <p> contestant_prize: <div className={competitorPrizeGlowsUpdate}  id="competitorPrizeValueFixed"> {contractScripts.decimalEighteen(competitorPrizeVal)} </div> </p> */}
            {/* <br />  */}
            <p> voters_so_far: <div className={numVotersGlowsUpdate} id="numberVotersValue"> {this.props.match.voters.length} </div> </p>
            {/* <p> cost_to_vote: <div id="numberVoterCost"> {contractScripts.decimalEighteen(this.props.match.proposal.voterEntryCost, "MemeMatchCenter")} </div>  </p> */}
            {/* <p> xp_to_vote: <div id="numberVoterCost"> {contractScripts.decimalEighteen(this.props.match.proposal.voterEntryCost, "MemeMatchCenter")} </div>  </p>
            <p> voter_stakes: <div className={voterStakesGlowsUpdate} id="voterStakesValue"> {this.props.match.prizePool} </div> </p> */}
          </>
          :
          <EndMatchButton
            matchContainer={true}
            // text="Reveal Winner" 
            text="Compute Result"
            // text="Calculate Winner" 
            toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
            loginComplete={this.props.loginComplete}
          />;

        return activeDisplay;

      case "OVER":

        return (<>
          <p className={styles.matchStat}> <div id={styles.matchStatTitle}> voters: </div> <div id="numberVotersValueFixed"> {this.props.match.voters.length} </div> </p>
          {/* <p className={styles.matchStat}> <div id={styles.matchStatTitle}> vote_cost: </div>  <div id="numberVoterCost"> {contractScripts.decimalEighteen(this.props.match.proposal.voterEntryCost, "MemeMatchCenter")} </div>  </p>
          <p className={styles.matchStat}> <div id={styles.matchStatTitle}> voter_prize: </div>  <div id="voterStakesValueFixed"> {this.props.match.prizeShare.substring(0, 5)} </div> </p>
          <p className={styles.matchStat}> <div id={styles.matchStatTitle}> contest_prize: </div> <div id="competitorPrizeValueFixed"> {contractScripts.decimalEighteen(competitorPrizeVal, "MemeMatchCenter")} </div> </p> */}

        </>);
      case "CANCELLED":
        // return <p> not_enough_contestants </p>;
        return <p> not_enough_voters </p>;
    }
  }

  getFundsDisplay = () => {
    switch (this.props.match.matchStatus) {
      case "NONE":
        return console.log("MemeMatchTopper: matchStatus is NONE");
      case "PENDING":
        // const stakesIcon = this.props.match.proposal.paid ? <i id="coinIcon" class="fas fa-up-down"></i>  : <i id="coinIcon" class="fas fa-up-down"></i> ;
        const stakesIcon = <i class="fas fa-money-bill-alt"></i>;
        const stakesIconSafe = <div id="stakesIcon"> {stakesIcon} </div>;
        return (
          <>
            <Badge color={"staked-funds"} className="stakedFundsDisplayBadge">
              <h3>
                {/* <div id="votersLoadingIcon"><i class="fas fa-spinner fa-pulse"></i></div> {"  "} */}
                <div id="prizePoolText">
                  {contractScripts.decimalEighteen(this.props.match.prizePool, "MemeMatchCenter")} {" "} {stakesIconSafe} {" "} At Stake </div>
              </h3>
            </Badge>
          </>
        );
      case "ACTIVE":
        return null;
      case "OVER":
        return null;
      case "CANCELLED":
        return null;
    }
  }


  render() {

    // console.log("MemeMatchCenter re-rendered");
    // console.log("MemeMatchCenter: this.props.match: ", this.props.match);

    const loadingIcon = <div><h1><i class="fas fa-spinner fa-pulse"></i></h1></div>; // TODO: fa-library

    const match = this.props.match;

    const compBid = this.props.match == undefined ? 0 : this.props.match.competitors[1].competitorBid;
    var comBidIsBN = contractScripts.objectIsBN(compBid);
    // var comBidIsBN = true;

    const textDisplay = match == undefined || !comBidIsBN ? loadingIcon : this.getVotersDisplay();

    var joinVoterButton = match.matchStatus != "PENDING" ?
      <></>
      :
      <></>;


    return (
      <>
        {/* <div id={styles.memeMatchSimpleStats}>  */}
        {joinVoterButton}
        {/* <div className={numVotersGlowsUpdate}>  */}
        {textDisplay}
        {/* </div> */}
        {/* </div> */}
      </>
    );
  }
}

export default MemeMatchCenter;
