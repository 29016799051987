import React from 'react';
import moment from 'moment';

// javascript
import memeCardScripts from './memeMatchScripts.js';

// Components
import PendingMemeCard from './PendingMemeCard.jsx';
import ActiveMemeCard from './ActiveMemeCard.jsx';
// import OverMemeMatchCard from './OverMemeMatchBottom.jsx';
import CancelledMemeCard from './CancelledMemeCard.jsx';

// CSS
import 'assets/css/m_w.css';
import styles from "./MemeMatch.module.scss";

class MemeCard extends React.Component {
  state = {
    pendingSwitchHappened: false, // PENDING ---> CANCELLED / ACTIVE
    activeSwitchHappened: false,
  };

  componentDidMount() {
    this.interval = setInterval(() => {
      const now = moment.utc();
      this.setState({ now });
    }, 1000); // TODO: have one time component in all app
  }

  componentDidUpdate(prevProps) {
    if (this.props.match !== undefined) {
      const matchStartTime = moment.unix(this.props.match.matchStartTime).utc();
      const joiningEndsTime = moment.utc(matchStartTime).add(Number(this.props.match.proposal.allowedJoiningTime.toString()), "seconds");

      if (moment.utc().isAfter(joiningEndsTime) && !this.state.pendingSwitchHappened) {
        this.setState({ pendingSwitchHappened: true });
      }
    }
  }

  shouldComponentUpdate(nextProps) {

    // const votingStartTime = moment.unix(this.props.match.votingStartTime).utc();
    // const votingEndsTime = moment.utc(votingStartTime).add(this.props.match.proposal.allowedVotingTime, "seconds");
    // if (moment.utc().isAfter(votingEndsTime) && this.state.pendingSwitchHappened && !this.state.activeSwitchHappened) {
    //   this.setState({ activeSwitchHappened: true });
    //   return true
    // }

    if (this.props != nextProps) return true;
    else return false;
  }

  getMemeCard = () => {

    const matchStartTime = moment.unix(this.props.match.matchStartTime).utc();
    const joiningEndsTime = moment.utc(matchStartTime).add(Number(this.props.match.proposal.allowedJoiningTime.toString()), "seconds");
    const joinTimeOver = moment.utc().isAfter(joiningEndsTime);

    const votingStartTime = moment.unix(this.props.match.votingStartTime).utc();
    const votingEndsTime = moment.utc(votingStartTime).add(Number(this.props.match.proposal.allowedVotingTime.toString()), "seconds");
    const isAfterVotingPeriod = moment.utc().isAfter(votingEndsTime);

    const notEnoughCompetitors = joinTimeOver && this.props.match.competitors[1].address == "0x0000000000000000000000000000000000000000";

    switch (this.props.match.matchStatus) {

      case "NONE":
        // return console.log("MemeMatchTopper: matchStatus is NONE");
        return null;

      case "PENDING":
        // to account for roll-over period where first vote hasn't occured (match hasn't changed to ACTIVE)
        var timeBasedDisplay = !joinTimeOver ?
          <PendingMemeCard
            loggedIn={this.props.loggedIn}
            entryNumber={this.props.competitorNumber}
            match={this.props.match}
            displayVoteOption={true} // TODO: what even is this?

            // ********* TODO
            // changeFocusedTab={(newTabIndex) => this.props.changeFocusedTab(newTabIndex)} 
            updateXPBalance={(newXPBalance) => this.props.updateXPBalance(newXPBalance)} // TODO: have one function (here) which handles the newXP value (clients just call UpdateXPBalance())
            toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
            // updateLoginInfo={(newLoginStatus) => this.props.updateLoginInfo(newLoginStatus)}
            // updateCurrMatch={(latestMatchObj) => this.props.updateCurrMatch(latestMatchObj)}
            // updateMatches={(allMatchesObj) => this.props.updateMatches(allMatchesObj)}
            // ************************* 
            // profile={this.props.profile}
            // lobbyInfo={this.props.lobbyInfo}
            // sessionState={this.props.sessionState}
            // ************************* 
            account={this.props.account}
            provider={this.props.provider}
            joined={this.props.joined}
            XPBalance={this.props.XPBalance}
            ETHbalance={this.props.ETHBalance}
            availableETH={this.props.availableETH}
            lobby={this.props.lobby}
            paid={this.props.paid}
            // currMatch={this.props.currMatch}
            // matches={this.props.matches}
            // proposals={this.props.proposals}
            // focusedTab={this.props.focusedTab}
            focusedMatchID={this.props.focusedMatchID}
            focusedEntry={this.props.focusedEntry}
            loginComplete={this.props.loginComplete}
            loginInProgress={this.props.loginInProgress}

          />
          :
          <ActiveMemeCard
            content={this.props.content}
            loggedIn={this.props.loggedIn}
            profile={this.props.profile}
            entryNumber={this.props.competitorNumber}
            match={this.props.match}

            // ********* TODO
            // changeFocusedTab={(newTabIndex) => this.props.changeFocusedTab(newTabIndex)} 
            updateXPBalance={(newXPBalance) => this.props.updateXPBalance(newXPBalance)} // TODO: have one function (here) which handles the newXP value (clients just call UpdateXPBalance())
            toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
            // updateLoginInfo={(newLoginStatus) => this.props.updateLoginInfo(newLoginStatus)}
            // updateCurrMatch={(latestMatchObj) => this.props.updateCurrMatch(latestMatchObj)}
            // updateMatches={(allMatchesObj) => this.props.updateMatches(allMatchesObj)}
            // ************************* 
            // lobbyInfo={this.props.lobbyInfo}
            // sessionState={this.props.sessionState}
            // ************************* 
            account={this.props.account}
            provider={this.props.provider}
            joined={this.props.joined}
            XPBalance={this.props.XPBalance}
            ETHbalance={this.props.ETHBalance}
            availableETH={this.props.availableETH}
            lobby={this.props.lobby}
            paid={this.props.paid}
            currMatch={this.props.currMatch}
            // matches={this.props.matches}
            // proposals={this.props.proposals}
            // focusedTab={this.props.focusedTab}
            focusedMatchID={this.props.focusedMatchID}
            focusedEntry={this.props.focusedEntry}
            loginComplete={this.props.loginComplete}
            loginInProgress={this.props.loginInProgress} />;

        var pendingDisplay = notEnoughCompetitors ?
          <CancelledMemeCard matchOver={isAfterVotingPeriod} loggedIn={this.props.loggedIn} profile={this.props.profile} entryNumber={this.props.competitorNumber} match={this.props.match} lobby={this.props.lobby} paid={this.props.paid} />
          :
          timeBasedDisplay;

        return pendingDisplay;

      case "ACTIVE":
        return <ActiveMemeCard
          updateXPBalance={(newXPBalance) => this.props.updateXPBalance(newXPBalance)} // TODO: have one function (here) which handles the newXP value (clients just call UpdateXPBalance())
          toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
          //        
          content={this.props.content}
          matchOver={isAfterVotingPeriod}
          loggedIn={this.props.loggedIn}
          profile={this.props.profile}
          entryNumber={this.props.competitorNumber}
          match={this.props.match}
          lobby={this.props.lobby}
          paid={this.props.paid}
          focusedMatchID={this.props.focusedMatchID}
        />;

      case "OVER":
        return <ActiveMemeCard
          updateXPBalance={(newXPBalance) => this.props.updateXPBalance(newXPBalance)} // TODO: have one function (here) which handles the newXP value (clients just call UpdateXPBalance())
          toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}

          //
          content={this.props.content}
          matchOver={isAfterVotingPeriod}
          loggedIn={this.props.loggedIn}
          profile={this.props.profile}
          entryNumber={this.props.competitorNumber}
          match={this.props.match}
          lobby={this.props.lobby}
          paid={this.props.paid}
          focusedMatchID={this.props.focusedMatchID}
        />;

      case "CANCELLED":
        return <CancelledMemeCard matchOver={isAfterVotingPeriod} loggedIn={this.props.loggedIn} profile={this.props.profile} entryNumber={this.props.competitorNumber} match={this.props.match} lobby={this.props.lobby} paid={this.props.paid} />;
    }
  };

  render() {

    // console.log("re-render in MemeCard10");

    const appropriateMemeCard = this.props.match == undefined ? <div><i class="fas fa-spinner fa-pulse"></i></div> : this.getMemeCard();

    return appropriateMemeCard;
  }
}

export default MemeCard;

// const stakesIcon = this.props.match.proposal.paid ? 
// <div id="stakesIcon"> <i id="coinIcon" class="fas fa-up-down"></i> </div>
// :
// <div id="stakesIcon"> <i id="coinIcon" class="fas fa-up-down"></i> </div>;
// // TODO ETH ICON ^^^ 
// return (
//   <>
//   <Badge color={"staked-funds"} className="stakedFundsDisplayBadge">
//   <h3> 
//   {/* <div id="votersLoadingIcon"><i class="fas fa-spinner fa-pulse"></i></div> {"  "} */}
//   <div id="prizePoolText">
//   {" "} Prize Pool:  {this.props.match.prizePool} {" "} {stakesIcon} {" "} </div>
//   </h3>
//   </Badge>
//   </>
