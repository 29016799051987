import React from 'react';

// CSS
import 'assets/css/m_w.css';
import styles from "./MemeMatch.module.scss";

// Scripts
import memeMatchScripts from './memeMatchScripts.js';

// reactstrap components
import { Progress } from 'reactstrap';

// Components
import OverMatchCollapseBottom from './OverMemeCardCollapse.jsx';

class OverMemeCardBottom extends React.Component {

  componentDidMount() {}

  componentDidUpdate() {}

  render() {
    var match = this.props.match;

    const votePercentage = (match.votes[this.props.entryNumber] / match.numVoters) * 100;

    const voteBarClassname = votePercentage >= 50 ? 'progress-container progress-success' : 'progress-container progress-info';

    return (
      <>
        <div id={styles.overMatchFooter}>
          <div id="voteCountProgressBar" className={voteBarClassname}>
            <h5 id={styles.voteCountRatioText}>
              {' '}
              {this.props.match.votes[this.props.entryNumber]} / {match.numVoters} VOTES
            </h5>
            {/* <Progress className={voteBarClassname} max="100" value={votePercentage}>
              <span className="progress-value"></span>
            </Progress> */}
          </div>
          <OverMatchCollapseBottom match={match} entryNumber={this.props.entryNumber} />
        </div>
      </>
    );
  }
}

export default OverMemeCardBottom;
