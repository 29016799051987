import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSpinner,
  faDownload,
  faQuestionCircle,
  faCheck,
  faTimes,
  faUpload,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import {
  UncontrolledTooltip,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';
import contractScripts from '../Buttons/contractScripts.js';
import styles from './DatabaseTool.module.scss';
// Import the AI scripts
import { callAnthropicAI, fetchContentFromURL } from '../../utilities/aiScripts.js';
import CreateSurvey from '../SurveyTool/CreateSurvey.jsx';
import sha256 from 'crypto-js/sha256';
// Import the prompt from a .txt file
import { seedGenPrompt } from '../../prompts/seedGenPrompt.js'; // Make sure to have this file

const DatabaseTool = ({
  provider,
  network,
  account,
  loginComplete,
  toggleLoginModal,
}) => {
  // State variables
  const [pastedText, setPastedText] = useState('');
  const [urlInput, setUrlInput] = useState('');
  const [ingressType, setIngressType] = useState('text');
  const [questionTypes, setQuestionTypes] = useState({
    binary: true,
    freeform: true,
    rating: true,
    multichoice: true,
  });
  const [count, setCount] = useState(5);
  const [loading, setLoading] = useState(false);
  const [seedStatements, setSeedStatements] = useState([]);
  const [error, setError] = useState('');
  const [selectedStatements, setSelectedStatements] = useState([]);
  const [answers, setAnswers] = useState({});
  const [statementsToUpload, setStatementsToUpload] = useState([]);
  const [prefilledAnswers, setPrefilledAnswers] = useState([]);

  const ingressOptions = [
    { value: 'text', label: 'Text' },
    { value: 'url', label: 'URL' },
    { value: 'video', label: 'Video (Soon)', disabled: true },
    { value: 'audio', label: 'Audio (Soon)', disabled: true },
  ];

  const questionTypeOptions = [
    { value: 'binary', label: 'Binary' },
    { value: 'freeform', label: 'Freeform' },
    { value: 'rating', label: 'Rating' },
    { value: 'multichoice', label: 'Multichoice' },
  ];

  useEffect(() => {
    setSeedStatements([]);
    setAnswers({});
  }, [questionTypes]);

  const initializeAnswerStates = (statements) => {
    const initialAnswers = {};
    statements.forEach((statement, index) => {
      switch (statement.questionType) {
        case 'binary':
          initialAnswers[index] = 'Unsure';
          break;
        case 'rating':
          initialAnswers[index] = 5;
          break;
        case 'freeform':
          initialAnswers[index] = '';
          break;
        case 'multichoice':
          initialAnswers[index] = [];
          break;
        default:
          break;
      }
    });
    setAnswers(initialAnswers);
  };

  const generateSeedStatements = async (content, questionTypes, count) => {
    try {
      // Prepare the variables for prompt template
      const variables = {
        sourceDocContent: content,
        numSeedStatements: count || 10,
        types: Object.keys(questionTypes)
          .filter((type) => questionTypes[type])
          .join(', ') || 'binary, multichoice, rating, freeform'
      };
  
      // Get the prompt template from the imported seedGenPrompt
      let processedPrompt = seedGenPrompt;
      
      // Replace placeholders with actual values
      processedPrompt = processedPrompt.replace('<SourceDocContent>', variables.sourceDocContent);
      processedPrompt = processedPrompt.replace('<NumSeedStatements>', variables.numSeedStatements);
      processedPrompt = processedPrompt.replace('<Types>', variables.types);
  
      console.log('Processed prompt being sent to AI:', processedPrompt);
  
      // Call the AI service with the processed prompt
      const aiResponse = await callAnthropicAI(processedPrompt);
  
      console.log('Raw AI response:', aiResponse);
  
      // Parse the response
      let responseData;
      try {
        responseData = JSON.parse(aiResponse);
      } catch (parseError) {
        console.error('Failed to parse AI response:', parseError);
        throw new Error('Invalid response format from AI');
      }
  
      // Validate the response
      if (!responseData.questions || !Array.isArray(responseData.questions)) {
        throw new Error('Invalid response format from AI');
      }
  
      // Filter questions by selected types
      const selectedTypes = Object.keys(questionTypes).filter(
        (type) => questionTypes[type]
      );
  
      const filteredQuestions = responseData.questions
        .filter((question) => selectedTypes.includes(question.questionType))
        .slice(0, count); // Ensure we only return the requested number of questions
  
      // Validate each question has the required structure
      filteredQuestions.forEach(question => {
        if (!question.answer) {
          question.answer = {
            value: "",
            encrypted: false,
            hash: ""
          };
        }
        if (!question.additional) {
          question.additional = {
            value: "",
            encrypted: false,
            hash: ""
          };
        }
      });
  
      return filteredQuestions;
    } catch (error) {
      console.error('Error generating seed statements:', error);
      throw error;
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSeedStatements([]);
  
    try {
      let content = '';
  
      if (ingressType === 'text') {
        if (!pastedText.trim()) {
          throw new Error('Please paste some text to process.');
        }
        content = pastedText;
      } else if (ingressType === 'url') {
        if (!urlInput.trim()) {
          throw new Error('Please enter a URL to process.');
        }
        try {
          // First fetch the URL content
          const urlContent = await fetchContentFromURL(urlInput.trim());
          if (!urlContent || urlContent.length < 100) {
            throw new Error('Not enough content could be extracted from the URL.');
          }
          content = urlContent;
          console.log('Successfully fetched URL content:', content.substring(0, 200) + '...');
        } catch (urlError) {
          console.error('URL fetch error:', urlError);
          throw new Error(`Failed to fetch URL content: ${urlError.message}`);
        }
      } else {
        throw new Error('Selected ingress type is not supported yet.');
      }
  
      // Validate content before sending to AI
      if (!content || content.trim().length < 50) {
        throw new Error('Insufficient content to generate questions from.');
      }
  
      // Generate seed statements using AI
      const statements = await generateSeedStatements(
        content.trim(),
        questionTypes,
        count
      );
  
      setSeedStatements(statements);
      initializeAnswerStates(statements);
    } catch (err) {
      console.error('Processing error:', err);
      setError('Error processing input: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleQuestionTypeChange = (type) => {
    setQuestionTypes((prevTypes) => ({
      ...prevTypes,
      [type]: !prevTypes[type],
    }));
  };

  const handleStatementSelection = (index) => {
    setSelectedStatements((prevSelected) => {
      if (prevSelected.includes(index)) {
        return prevSelected.filter((i) => i !== index);
      } else {
        return [...prevSelected, index];
      }
    });
  };

  const handleSelectAll = () => {
    const allIndexes = seedStatements.map((_, index) => index);
    setSelectedStatements(allIndexes);
  };

  const generateQuestionId = (type, prompt, options = []) => {
    let dataToHash = `${type}:${prompt.toLowerCase()}`;
    if (type === 'multichoice' && options.length > 0) {
      dataToHash += `:${options.join(',').toLowerCase()}`;
    }
    const hash = sha256(dataToHash).toString();
    return '0x' + hash;
  };

  const filterSimilarQuestions = async (questions) => {
    // Placeholder function that returns the questions as is
    // In the future, implement AI analysis to filter out similar questions
    return questions;
  };

  const handleUploadSelected = async () => {
    const selectedQuestions = seedStatements.filter((_, index) =>
      selectedStatements.includes(index)
    );

    // Filter out similar questions
    const filteredQuestions = await filterSimilarQuestions(selectedQuestions);

    const preformedQuestions = filteredQuestions.map((statement) => ({
      id: generateQuestionId(
        statement.questionType,
        statement.prompt,
        statement.options || []
      ),
      type: statement.questionType,
      prompt: statement.prompt,
      options:
        statement.questionType === 'multichoice' ? statement.options : undefined,
      associatedSurveyId: '',
      tags: statement.tags || [],
    }));

    // Prepare prefilled answers
    const prefilled = filteredQuestions.map((statement, idx) => answers[idx]);

    setStatementsToUpload(preformedQuestions);
    setPrefilledAnswers(prefilled);
  };

  const handleAnswerChange = (index, value) => {
    setAnswers((prev) => ({ ...prev, [index]: value }));
  };

  const handleMultichoiceChange = (index, option) => {
    setAnswers((prev) => {
      const currentSelection = prev[index] || [];
      if (currentSelection.includes(option)) {
        return {
          ...prev,
          [index]: currentSelection.filter((item) => item !== option),
        };
      } else {
        return { ...prev, [index]: [...currentSelection, option] };
      }
    });
  };

  const renderQuestionDisplay = (statement, index) => {
    return (
      <div key={index} className={styles.questionDisplay}>
        <div className={styles.questionText}>{statement.prompt}</div>
        <div className={styles.answerDisplay}>
          {statement.questionType === 'binary' && (
            <FormGroup id={styles.binaryChoice}>
              {['Agree', 'Unsure', 'Disagree'].map((option) => (
                <Label
                  check
                  key={option}
                  className={`${styles.radioOptionText} ${
                    styles[option.toLowerCase()]
                  } ${answers[index] === option ? styles.selected : ''}`}
                >
                  <Input
                    type="radio"
                    name={`question-${index}`}
                    value={option}
                    checked={answers[index] === option}
                    onChange={() => handleAnswerChange(index, option)}
                  />
                  {option === 'Agree' && (
                    <FontAwesomeIcon
                      icon={faCheck}
                      className={styles.optionIcon}
                    />
                  )}
                  {option === 'Disagree' && (
                    <FontAwesomeIcon
                      icon={faTimes}
                      className={styles.optionIcon}
                    />
                  )}
                  {option}
                </Label>
              ))}
            </FormGroup>
          )}
          {statement.questionType === 'freeform' && (
            <Input
              type="textarea"
              value={answers[index] || ''}
              onChange={(e) => handleAnswerChange(index, e.target.value)}
              placeholder="Your answer here..."
              className={styles.freeformAnswer}
            />
          )}
          {statement.questionType === 'rating' && (
            <div className={styles.ratingAnswer}>
              <input
                type="range"
                min="0"
                max="10"
                value={answers[index] || 5}
                onChange={(e) =>
                  handleAnswerChange(index, parseInt(e.target.value))
                }
                className={styles.ratingSlider}
              />
              <span className={styles.ratingValue}>
                {answers[index] || 5}/10
              </span>
            </div>
          )}
          {statement.questionType === 'multichoice' && (
            <div className={styles.multichoiceAnswer}>
              {statement.options.map((option, optionIndex) => (
                <Label
                  check
                  key={optionIndex}
                  className={styles.multichoiceOption}
                >
                  <Input
                    type="checkbox"
                    checked={(answers[index] || []).includes(option)}
                    onChange={() => handleMultichoiceChange(index, option)}
                  />
                  {option}
                </Label>
              ))}
            </div>
          )}
        </div>
        <input
          type="checkbox"
          checked={selectedStatements.includes(index)}
          onChange={() => handleStatementSelection(index)}
          className={styles.statementCheckbox}
        />
      </div>
    );
  };

  return (
    <div className={styles.databaseTool}>
      <div className={styles.header}>
        <h2>AI Database Tool</h2>
      </div>

      <form onSubmit={handleSubmit}>
        <div className={styles.formSection}>
          <h3 className={styles.sectionTitle}>
            Content to Ingest
            <div className={styles.ingressOptions}>
              {ingressOptions.map((option) => (
                <button
                  key={option.value}
                  type="button"
                  onClick={() =>
                    !option.disabled && setIngressType(option.value)
                  }
                  className={`${styles.ingressButton} ${
                    ingressType === option.value ? styles.active : ''
                  } ${option.disabled ? styles.disabled : ''}`}
                  disabled={option.disabled}
                >
                  {option.label}
                </button>
              ))}
            </div>
          </h3>
          {ingressType === 'text' && (
            <div className={styles.textInputGroup}>
              <textarea
                value={pastedText}
                onChange={(e) => setPastedText(e.target.value)}
                required
                placeholder="Paste your text content here"
                className={styles.pastedTextArea}
              />
            </div>
          )}
          {ingressType === 'url' && (
            <div className={styles.urlInputGroup}>
              <input
                type="url"
                value={urlInput}
                onChange={(e) => setUrlInput(e.target.value)}
                required
                placeholder="Enter the URL to process (must start with http:// or https://)"
                className={styles.urlInput}
                style={{
                  width: '100%',
                  padding: '10px',
                  fontSize: '16px',
                  borderRadius: '4px',
                  border: '1px solid #ccc',
                  marginBottom: '10px',
                  fontFamily: 'inherit'
                }}
              />
              {error && <div className={styles.error}>{error}</div>}
            </div>
          )}
          {ingressType !== 'text' && ingressType !== 'url' && (
            <div className={styles.comingSoon}>
              This feature is coming soon.
            </div>
          )}
        </div>

        <div className={styles.formSection}>
          <h3 className={styles.sectionTitle}>Question Types to Generate</h3>
          <div className={styles.questionTypeOptions}>
            {questionTypeOptions.map((option) => (
              <label key={option.value} className={styles.checkboxLabel}>
                <input
                  type="checkbox"
                  checked={questionTypes[option.value]}
                  onChange={() => handleQuestionTypeChange(option.value)}
                />
                {option.label}
              </label>
            ))}
          </div>
        </div>

        <div className={styles.formSection}>
          <h3 className={styles.sectionTitle}>Number of Questions</h3>
          <div className={styles.countInputGroup}>
            <input
              type="number"
              value={count}
              onChange={(e) => setCount(parseInt(e.target.value))}
              min="1"
              max="25"
              required
            />
          </div>
        </div>

        <button type="submit" className={styles.generateButton} disabled={loading}>
          {loading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            'Generate Questions'
          )}
        </button>
      </form>

      {error && <div className={styles.error}>{error}</div>}

      {seedStatements.length > 0 && (
        <div className={styles.results}>
          <div className={styles.resultsHeader}>
            <h3>Generated Seed Statements:</h3>
            <button onClick={handleSelectAll} className={styles.selectAllButton}>
              Select All
            </button>
          </div>
          <div className={styles.statementsContainer}>
            {seedStatements.map((statement, index) =>
              renderQuestionDisplay(statement, index)
            )}
          </div>
          <button onClick={handleUploadSelected} className={styles.addButton}>
            Upload {selectedStatements.length} Selected to Question Bank or Survey
          </button>
        </div>
      )}

      {statementsToUpload.length > 0 && (
        <CreateSurvey
          miniaturized={true}
          preformedQuestions={statementsToUpload}
          account={account}
          loginComplete={loginComplete}
          provider={provider}
          toggleLoginModal={toggleLoginModal}
          prefilledAnswers={prefilledAnswers}
          onUploadComplete={(surveyHash) => {
            // If you need to do something after upload is complete
            setStatementsToUpload([]);
            // Optional: Trigger upload and answer flow
          }}
        />
      )}
    </div>
  );
};

export default DatabaseTool;
