import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchSessionState } from '../../actions/sessionStateActions.js';

// CSS and Images
import "assets/css/m_w.css";
import styles from "./Modals.module.scss";
import modalImage from "assets/img/rules-modal.png";

// reactstrap components
import { Button, Card, CardHeader, CardBody, CardFooter, Form, FormGroup, Label, Modal, Input} from "reactstrap";

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' // TODO: fa library
import { faWindowClose, faCheck, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
// import { ReactTypeformEmbed } from 'react-typeform-embed'; Loaded via <script> tag in index.html

class GreetingModal extends Component {
  state = {
    rulesModalOpen: false,
    userOptsOutMetrics: false,
    emailInput: "",
  }
  
  componentDidMount() {
    // // starts timer that ends when user exits greeting/rules/beta modal(TODO: mixpanel)
    // window.mixpanel.time_event('EXIT_GREETING_MODAL');
    // // starts timer that ends when user clicks question mark to get more details on metrics (TODO: mixpanel) 
    // window.mixpanel.time_event('METRICS_DETAILS_CLICKED');
    // // starts timer that ends when user clicks LOGIN Button (TODO: mixpanel)
    // window.mixpanel.time_event('LOGIN_BUTTON_CLICKED');
    // // starts timer that ends when user navigates to new tab (TODO: mixpanel)
    // window.mixpanel.time_event('CHANGE_TAB');
    // // starts timer that ends when user clicks a footer link (TODO: mixpanel)
    // window.mixpanel.time_event('CLICKED_FOOTER_LINK');
    // // starts timer that ends when user hovers over top-right badge in "Latest Match" tab (TODO: mixpanel)
    // window.mixpanel.time_event('FORMAT_HOVER_START');
    // // starts timer that ends when user clicks an entry (left or right contestant in meme match) link (TODO: mixpanel)
    // window.mixpanel.time_event('ENTRY_CLICKED');
    // // starts timer that ends when user clicks an entry's details (left or right contestant in meme match) link (TODO: mixpanel)
    // window.mixpanel.time_event('ENTRY_DETAILS_CLICKED');
    // // starts timer that ends when user clicks "Submit Meme + Bid" (left or right PENDING memecard) (TODO: mixpanel)
    // window.mixpanel.time_event('SUBMIT_MEME_OPTIONS_TOGGLED');
    // // starts timer that ends when user submits an entry to a match (left or right PENDING memecard) (TODO: mixpanel)
    // window.mixpanel.time_event('SUBMIT_MEME_COMPLETED');
    // // starts timer that ends when user votes for an entry in an ACTIVE match (left or right PENDING memecard) (TODO: mixpanel)
    // window.mixpanel.time_event('ENTRY_VOTED_FOR');
    // // starts timer that ends when user submits a proposal for a future match (TODO: mixpanel)
    // window.mixpanel.time_event('ADD_PROPOSAL');
    // // starts timer that ends when user upvotes/downvotes an existing proposal  (TODO: mixpanel)
    // window.mixpanel.time_event('VOTE_ON_PROPOSAL');
    // // starts timer that ends when user starts a match (TODO: mixpanel)
    // window.mixpanel.time_event('START_MATCH');

    
    this.setState({ rulesModalOpen: this.props.visible }); // TODO: hardcoded open
  }

  componentWillUnmount() {
    // window.web3.currentProvider.disconnect();
  }

  componentDidUpdate(nextProps) {
    // console.log("GreetingModal.jsx - UPDATED")
    if (this.props.visible != nextProps.visible) {
      this.setState({ rulesModalOpen: this.props.visible }); // TODO: hardcoded open
    }
  
 
  }

  // toggleModalLogin = () => {
  //   this.setState({ rulesModalOpen: !this.state.rulesModalOpen });
  // };

  optOutChanged = () => {
    // console.log("optOut state changed")
    this.setState({ userOptsOutMetrics: !this.state.userOptsOutMetrics });
    // this.setState({ userOptsOutMetrics: true });

    // Opts user out of tracking for site-use-metrics (mixpanel TODO:)
    if (!this.state.userOptsOutMetrics) {
      // window.mixpanel.track("OPTED_OUT_METRICS", {"PROVIDED_EMAIL": this.state.emailInput}); 
      // window.mixpanel.opt_out_tracking({clear_persistence: false, delete_user: false});
    }

    else {
      // window.mixpanel.track("OPTED_IN_METRICS"); 
      // window.mixpanel.opt_in_tracking({track: false});
    }
  }

  emailInputChange = (e) => {
    // console.log(e.target.value);
    const latestEmail = e.target.value;
    this.setState({ emailInput: latestEmail });
  }

  closeRulesModal = () => {
    this.setState({ rulesModalOpen: false });

    // if user enters email, send it in mixpanel
    // console.log("EXIT_GREETING_MODAL");
    // TODO: mixpanel
    // window.mixpanel.track("EXIT_GREETING_MODAL", { "OPTED_OUT": this.state.userOptsOutMetrics }); 
    // window.mixpanel.track("EXIT_GREETING_MODAL", {"PROVIDED_EMAIL": this.state.emailInput}); 
    // TODO ^^ mixpanel in modal or in element?
    this.props.closeExplainerFunction(); 

  };
  
  render() {

    // console.log("OPTED OUT: " + this.state.userOptsOutMetrics)

    // Explanation Modal (only seen on first site-load);

    // const modalPicture = require("../../assets/img/frontliner-modal-3.png");
    // const modalPicture = require("../../assets/img/rules-modal.png");
    const modalPicture = modalImage

    const closeModalIcon = this.state.emailInput == "" ? faWindowClose : faCheckSquare;

    const formBottomVisibleID = this.props.optOutAndEmailBottom ? styles.betaInfo : styles.betaInfoInvisible;

    // TODO null; / undef
    var modalVisibleID = this.props.visible ? styles.rulesModal : styles.invisibleModal;
    // if (formBottomVisibleID) { modalVisibleID = styles.rulesModalNoActionBelow} // No email form in, metrics opt-out,e tc TODO

    



    const explainModal = 
    <>
      <Modal isOpen={this.state.rulesModalOpen} modalClassName="modal-rules transparentModal" 
      id={modalVisibleID}
      // style={{
      //                 // height: "600px",
      //                 backgroundImage: "url(" + modalPicture + ")", 
      //                 // backgroundSize: "cover",
      //                 backgroundSize: "contain",
      //                 backgroundRepeat: "no-repeat",
      //                         backgroundPosition: "center",
      //                 // marginTop: "50px"
      // }}
      >

      <Card id={styles.rulesModalCard}
                    // style={{
                    //   height: "600px",
                    //   backgroundImage: "url(" + modalPicture + ")", 
                    //   backgroundSize: "cover",
                    //   backgroundRepeat: "no-repeat",
                          //   backgroundPosition: "center",
                    //   // marginTop: "50px"
                    //   }}
                      >

        <CardBody id={styles.rulesModalCardBody}
                            style={{
                      // height: "600px",
                      backgroundImage: "url(" + modalPicture + ")", 
                      // backgroundSize: "cover",
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // opacity: "0",
                      // height: "500px"
                      // height: "100px"
                      // marginTop: "50px"
                      }}
                    >


        </CardBody>
        {/* <div id={styles.betaInfo}>  TODO */}
        <div id={formBottomVisibleID}> 
        <FormGroup check id={styles.optOutMetrics}>
            <Label check id={styles.optOutLabel}>
              <Input type="radio" name="radio1" checked={this.state.userOptsOutMetrics} id={styles.optOutSelect}
              // onClick={this.optOutChanged} 
              onChange={this.optOutChanged} 
              /> {' '}

              {/* <div id={styles.optOutMainText}> Opt out of site-use metrics gathering</div> <div id={styles.optOutDetailText}> (info not personal { "&" } only collected to improve site. <div id={styles.optOutWeb3Text}> WEB3 means control over your own data </div> ) </div>
                */}

  <div id={styles.optOutMainText}> Opt out metrics <div id={styles.optOutDetailText}> (tracks UI use) </div> </div> 
            </Label>
          </FormGroup>

          <CardFooter id={styles.rulesModalFooter}> 
                        {/* <ReactTypeformEmbed url="https://memewars.typeform.com/to/Jh9CwYtN" /> */}

                      <div id={styles.exitButton}>

          </div>

          

          <div id={styles.emailAndOptOut}>
            {/* TODO CHANGE NAME IF OPT OUT NOT IN SECTION ANYMORE */}




          {/* <Label id={styles.emailFormLabel}> Get updates — (no spam) </Label> */}
          <Label id={styles.emailFormLabel}> Get updates 
            {/* TODO: No Spam <div id={styles.noSpam}> (no spam) </div>  */}
          </Label>
          <div id={styles.emailSubjects}>

            <div className={styles.emailSubject}>
              — Beta Info
            </div>

            <div className={styles.emailSubject}>
              — How to own a % of site
            </div>

            <div className={styles.emailSubject}>
              — Launch
            </div>


          </div>
          {/* <Label id={styles.emailFormLabel}> Get email — beta / how to own a % / launch </Label> */}
          
          <FormGroup id={styles.enterEmail}>

          {/* <Input type="email" name="email" id={styles.emailInput} placeholder="beta / how to own a % / launch" /> */}
          <Input type="email" name="emailInput" onChange={this.emailInputChange} id={styles.emailInput} placeholder="lol@memewa.rs" />
          </FormGroup>


   
          {/* <FormGroup check>
            <Label check>
              <Input type="radio" name="radio1" />{' '}
              Option two can be something else and selecting it will deselect option one
            </Label>
          </FormGroup> */}
          </div>
          </CardFooter>
        </div>

        <button id={styles.closeExplainerModalButton} aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={this.closeRulesModal}>
                {/* <FontAwesomeIcon id={styles.closeModalIcon} icon={faWindowClose} /> */}
                {/* <FontAwesomeIcon id={styles.closeModalIcon} icon={faCheckSquare} /> */}
                <FontAwesomeIcon id={styles.closeModalIcon} icon={closeModalIcon} />
                
          </button>

      </Card>

      </Modal>



        </>
      

    return explainModal
  }
}

GreetingModal.propTypes = {
  fetchSessionState: PropTypes.func.isRequired,
  account: PropTypes.string,
  provider: PropTypes.string,
  joined: PropTypes.bool,
  lobby: PropTypes.string,
  paid: PropTypes.bool,
  currMatch: PropTypes.object,
  matches: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  account: state.profile.account,
  provider: state.profile.provider, 
  joined: state.profile.joined,
  lobby: state.lobbyInfo.lobby,
  paid: state.lobbyInfo.paid,
  currMatch: state.lobbyInfo.currMatch,
  matches: state.lobbyInfo.matches,
});


export default connect(mapStateToProps, { fetchSessionState })(GreetingModal);

