import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateXPBalance } from '../../actions/accountActions.js';

// reactstrap components
import { Button } from 'reactstrap';

// Scripts for Smart Contract Interactions
import contractScripts from '../Buttons/contractScripts.js';

class JoinButton extends React.Component {
  componentDidMount() {
    
  }

  setXPBal = () => {
    this.props.updateXPBalance("-1");
    this.sendJoinTX();
  }

  sendJoinTX = async () => {
    let XPBal = await contractScripts.joinMW(this.props.provider, this.props.account);
    this.props.updateXPBalance(XPBal);
  }


  render() {
    return (
      <Button onClick={this.sendJoinTX} id="joinMWButton" color="startMatch" type="btn"> 
        Click to Join + Claim 100XP
      </Button>
    );
  }
}

JoinButton.propTypes = {
    updateXPBalance: PropTypes.func.isRequired,
    account: PropTypes.string,
  };
  
  const mapStateToProps = state => ({
    account: state.profile.account,
    provider: state.profile.provider
  });
  
export default connect(mapStateToProps, { updateXPBalance })(JoinButton);

// 
