import React, { Component } from 'react';
import {
  Button,
  Label,
  Input,
  FormGroup,
  Card,
  CardBody,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSpinner,
  faClipboard,
  faPlus,
  faTimes, faBookmark, faCheck
} from '@fortawesome/free-solid-svg-icons';
import styles from './SurveyTool.module.scss';
import contractScripts from '../Buttons/contractScripts.js';
import { ethers } from 'ethers';
import sha256 from 'crypto-js/sha256';

class CreateSurvey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      questions: [],
      addingQuestionType: 'Question Type',
      surveySubmitted: false,
      surveyHash: '',
      isSubmitting: false,
      progress: 0,
      showJson: false,
      isStandaloneQuestion: false,
      associatedSurveyId: '',
      surveyAddedSuccessfully: false,
      questionsAddedSuccessfully: false,
      uploadedQuestions: [],
      submissionError: '',
      copySurveyIdSuccess: false,
      copySurveyLinkSuccess: false,
    };

    // Initialize state from preformedQuestions or preformedSurvey if provided
    if (props.preformedQuestions && props.preformedQuestions.length > 0) {
      this.state.questions = props.preformedQuestions;
      // If preformedSurvey is provided, set the title and isStandaloneQuestion
      if (props.preformedSurvey && props.preformedSurvey.title) {
        this.state.title = props.preformedSurvey.title;
        this.state.isStandaloneQuestion = false;
      } else {
        this.state.isStandaloneQuestion = true;
      }
    }
  }

  componentDidMount() {
    if (this.props.preformedQuestions && this.props.preformedQuestions.length > 0) {
      // Use preformed questions from props
      this.setState({
        questions: this.props.preformedQuestions,
        title: this.props.preformedSurvey?.title || '',
        isStandaloneQuestion: !this.props.preformedSurvey?.title,
      }, () => {
        this.updateSurveyHash();
      });
    } else {
      this.loadFromLocalStorage();
    }

    this.setState({
      isSubmitting: false,
      progress: 0,
      submissionError: '',
    });
  }

  loadFromLocalStorage = () => {
    const savedSurvey = localStorage.getItem('unfinishedSurvey');
    if (savedSurvey) {
      try {
        const parsedSurvey = JSON.parse(savedSurvey);
        // Ensure that questions have 'options' if they are multichoice
        if (parsedSurvey.questions && Array.isArray(parsedSurvey.questions)) {
          parsedSurvey.questions = parsedSurvey.questions.map(q => ({
            ...q,
            options: q.type === 'multichoice' && !Array.isArray(q.options) ? [] : q.options,
          }));
        }
        this.setState(parsedSurvey, this.updateSurveyHash);
      } catch (error) {
        console.error('Error parsing saved survey from localStorage:', error);
      }
    }
  };

  saveToLocalStorage = () => {
    localStorage.setItem('unfinishedSurvey', JSON.stringify(this.state));
  };

  generateQuestionId = (type, prompt, options = []) => {
    let dataToHash = `${type}:${prompt.toLowerCase()}`;
    if (type === 'multichoice' && options.length > 0) {
      dataToHash += `:${options.join(',').toLowerCase()}`;
    }
    const hash = sha256(dataToHash).toString();
    return '0x' + hash;
  };

  handleTitleChange = (event) => {
    this.setState({ title: event.target.value }, () => {
      this.updateSurveyHash();
      this.saveToLocalStorage();
    });
  };

  addQuestion = () => {
    const type = this.state.addingQuestionType;
    if (!type || type === 'Question Type') return;

    const newQuestionId = this.generateQuestionId(type, '', []); // Empty prompt and options initially
    const newQuestionData = {
      id: newQuestionId,
      type: type,
      prompt: '',
      options: type === "multichoice" ? [] : undefined,
      associatedSurveyId: '',
      tags: [], // Add tags field
    };

    this.setState(prevState => ({
      questions: [...prevState.questions, newQuestionData],
      addingQuestionType: 'Question Type'
    }), () => {
      this.updateSurveyHash();
      this.saveToLocalStorage();
    });
  };

  handleQuestionChange = (index, key, value) => {
    const { questions } = this.state;
    const updatedQuestions = [...questions];
    const question = { ...updatedQuestions[index], [key]: value };

    // If the prompt, type, or options change, regenerate the question ID
    const options = question.options || [];
    question.id = this.generateQuestionId(question.type, question.prompt, options);

    updatedQuestions[index] = question;

    this.setState({ questions: updatedQuestions }, () => {
      this.updateSurveyHash();
      this.saveToLocalStorage();
    });
  };

  handleOptionChange = (questionIndex, optionIndex, value) => {
    const { questions } = this.state;
    const question = { ...questions[questionIndex] };

    // Ensure options array exists
    if (!Array.isArray(question.options)) {
      question.options = [];
    }

    const updatedOptions = [...question.options];
    updatedOptions[optionIndex] = value;

    // Update question options and regenerate question ID
    const updatedQuestion = { ...question, options: updatedOptions };
    updatedQuestion.id = this.generateQuestionId(updatedQuestion.type, updatedQuestion.prompt, updatedOptions);

    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex] = updatedQuestion;

    this.setState({ questions: updatedQuestions }, () => {
      this.saveToLocalStorage();
    });
  };

  addOption = (questionIndex) => {
    const { questions } = this.state;
    const question = { ...questions[questionIndex] };

    // Ensure options array exists
    if (!Array.isArray(question.options)) {
      question.options = [];
    }

    const updatedOptions = [...question.options, ''];

    const updatedQuestion = { ...question, options: updatedOptions };
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex] = updatedQuestion;

    this.setState({ questions: updatedQuestions }, () => {
      this.saveToLocalStorage();
    });
  };

  removeOption = (questionIndex, optionIndex) => {
    const { questions } = this.state;
    const question = { ...questions[questionIndex] };

    // Ensure options array exists
    if (!Array.isArray(question.options)) {
      question.options = [];
    }

    const updatedOptions = question.options.filter((_, i) => i !== optionIndex);

    const updatedQuestion = { ...question, options: updatedOptions };
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex] = updatedQuestion;

    this.setState({ questions: updatedQuestions }, () => {
      this.saveToLocalStorage();
    });
  };

  removeQuestion = (index) => {
    const { questions } = this.state;

    const updatedQuestions = questions.filter((_, i) => i !== index);

    this.setState({ questions: updatedQuestions }, () => {
      this.updateSurveyHash();
      this.saveToLocalStorage();
    });
  };

  bookmarkQuestion = (questionId) => {
    let bookmarkedQuestions = JSON.parse(localStorage.getItem('bookmarkedQuestions')) || [];
    if (!bookmarkedQuestions.includes(questionId)) {
      bookmarkedQuestions.push(questionId);
      localStorage.setItem('bookmarkedQuestions', JSON.stringify(bookmarkedQuestions));
      console.log(`Question ${questionId} bookmarked successfully.`);
    } else {
      console.log(`Question ${questionId} is already bookmarked.`);
    }
  };

  updateSurveyHash = () => {
    const { title, isStandaloneQuestion } = this.state;
    if (isStandaloneQuestion) {
      this.setState({ surveyHash: '' });
    } else {
      const surveyData = { title };
      const newSurveyHash = "0x" + sha256(JSON.stringify(surveyData)).toString();
      this.setState({ surveyHash: newSurveyHash });
    }
  };

  toggleStandaloneQuestion = () => {
    this.setState(prevState => ({
      isStandaloneQuestion: !prevState.isStandaloneQuestion,
      title: '',
      surveyHash: '',
      surveyAddedSuccessfully: false,
      questionsAddedSuccessfully: false,
      submissionError: '',
      // Do not clear questions to retain them in both modes
    }), () => {
      this.updateSurveyHash();
      this.saveToLocalStorage();
    });
  };

  handleAssociatedSurveyIdChange = (index, value) => {
    const { questions } = this.state;
    const updatedQuestions = [...questions];
    updatedQuestions[index] = { ...updatedQuestions[index], associatedSurveyId: value };
    this.setState({ questions: updatedQuestions }, () => {
      this.saveToLocalStorage();
    });
  };

  handleTagsChange = (index, value) => {
    const { questions } = this.state;
    const updatedQuestions = [...questions];
    // Split tags by comma and trim spaces
    const tags = value.split(',').map(tag => tag.trim());
    updatedQuestions[index] = { ...updatedQuestions[index], tags: tags };
    this.setState({ questions: updatedQuestions }, () => {
      this.saveToLocalStorage();
    });
  };

  createSurvey = async () => {
    console.log("createSurvey function called");
  
    if (!this.props.loginComplete) {
      console.log("Login not complete. Please log in to create a survey");
      this.props.toggleLoginModal(true);
      return;
    }
  
    console.log("Login complete. Proceeding with survey creation.");
    this.setState({ isSubmitting: true, progress: 0 });
  
    const { title, questions, isStandaloneQuestion } = this.state;
    console.log("Survey State Values:", { title, questions });
  
    try {
      if (isStandaloneQuestion) {
        // Handle standalone questions
        if (questions.length === 0) {
          alert("Please add at least one question.");
          this.setState({ isSubmitting: false, progress: 0 });
          return;
        }
  
        this.setState({ progress: 10 });
  
        const surveyIds = [];
        const uploadedQuestions = []; // To store mapping of questionId to arweaveTxId
  
        // Remove duplicate questions
        const uniqueQuestions = this.removeDuplicateQuestions(questions);
  
        // Prepare questionDataArray
        const questionDataArray = [];
        for (const question of uniqueQuestions) {
          const questionData = {
            type: question.type,
            prompt: question.prompt,
            id: question.id,
            associatedSurveyId: question.associatedSurveyId || '',
            tags: question.tags || [],
            options: question.type === "multichoice" ? question.options : undefined,
            creator: this.props.account, // Added 'creator' field
          };
  
          questionDataArray.push(questionData);
  
          // For associatedSurveyId, use question.associatedSurveyId or ethers.constants.HashZero
          const surveyId = question.associatedSurveyId || ethers.constants.HashZero;
          surveyIds.push(surveyId);
        }
  
        this.setState({ progress: 50 });
  
        // Now, submit the questions via contractScripts.addQuestions
        const questionIds = uniqueQuestions.map(q => q.id);
  
        const { receipt, uploadedQuestions: uploadedQuestionsResult } = await contractScripts.addQuestions(
          this.props.provider,
          questionIds,
          questionDataArray,
          surveyIds
        );
  
        console.log("Questions submitted successfully:", receipt);
  
        this.setState({
          questionsAddedSuccessfully: true,
          isSubmitting: false,
          progress: 100,
          uploadedQuestions: uploadedQuestionsResult, // Store the uploaded questions in state
        });
  
        localStorage.removeItem('unfinishedSurvey');
      } else {
        if (!title.trim()) {
          alert("Please enter a survey title.");
          this.setState({ isSubmitting: false, progress: 0 });
          return;
        }
  
        // Step 1: Calculate surveyHash without questionIDs
        const surveyData = { title, creator: this.props.account }; // Added 'creator' field
        const newSurveyHash = "0x" + sha256(JSON.stringify(surveyData)).toString();
        this.setState({ surveyHash: newSurveyHash }, async () => {
          // Step 2: Assign surveyHash to each question's associatedSurveyId
          const questionDataArray = questions.map(q => {
            return {
              ...q,
              tags: q.tags || [],
              associatedSurveyId: newSurveyHash,
              creator: this.props.account, // Added 'creator' field
            };
          });
  
          // Step 3: Prepare surveyData with questionIDs
          const completeSurveyData = {
            surveyID: newSurveyHash,
            title: title,
            questionIDs: questions.map(q => q.id),
            creator: this.props.account, // Added 'creator' field
          };
  
          // Step 4: Submit survey and questions to the smart contract
          const { receipt } = await contractScripts.addSurveyWithQuestions(
            this.props.provider,
            newSurveyHash,
            completeSurveyData,
            questions.map(q => q.id),
            questionDataArray
          );
  
          console.log("Survey submitted successfully:", receipt);
          this.setState({
            surveyAddedSuccessfully: true,
            isSubmitting: false,
            progress: 100,
            surveyHash: newSurveyHash,
          });
  
          localStorage.removeItem('unfinishedSurvey');
        });
      }
    } catch (error) {
      console.error("Failed to create survey:", error);
      this.setState({
        isSubmitting: false,
        progress: 0,
        submissionError: error.message || 'An error occurred while creating the survey.',
      });
    }
  };
  

  removeDuplicateQuestions = (questions) => {
    const uniqueQuestions = [];
    const questionIds = new Set();
    for (const question of questions) {
      if (!questionIds.has(question.id)) {
        uniqueQuestions.push(question);
        questionIds.add(question.id);
      }
    }
    return uniqueQuestions;
  };

  toggleShowJson = () => {
    this.setState(prevState => ({ showJson: !prevState.showJson }));
  };

  copyQuestionIdToClipboard = (questionId) => {
    navigator.clipboard.writeText(questionId)
      .then(() => {
        console.log(`Successfully copied Question ID: ${questionId}`);
      })
      .catch((err) => {
        console.error('Failed to copy Question ID: ', err);
      });
  };

  copySurveyLinkToClipboard = (surveyID = null) => {
    // If no surveyID argument is provided, extract it from the URL
    if (!surveyID) {
      const urlParams = new URLSearchParams(window.location.search);
      surveyID = urlParams.get('surveyID') || this.state.surveyHash;
    }

    // Create the survey link using the surveyID
    const surveyLink = `${window.location.origin}/survey/${surveyID}`;

    // Copy the survey link to the clipboard
    navigator.clipboard.writeText(surveyLink)
      .then(() => {
        console.log(`Successfully copied ${surveyLink}`);
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  };

  render() {
    const {
      title, questions, isSubmitting, progress,
      showJson, isStandaloneQuestion, associatedSurveyId,
      surveyAddedSuccessfully, questionsAddedSuccessfully,
      uploadedQuestions, submissionError
    } = this.state;

    const surveyID = isStandaloneQuestion ? '' : this.state.surveyHash;

    const questionTypeTooltips = {
      freeform: "Allows respondents to enter any text as an answer.",
      rating: "Lets respondents rate on a scale, typically from 0 to 10.",
      multichoice: "Provides multiple options for respondents to choose from.",
      binary: "Presents an agree/disagree or yes/no type question."
    };

    // Prepare the JSON data for preview
    let jsonData = {};
    if (isStandaloneQuestion) {
      jsonData = {
        questions: questions.map(q => ({
          prompt: q.prompt,
          type: q.type,
          id: q.id,
          associatedSurveyId: q.associatedSurveyId || '',
          tags: q.tags || [],
          ...(q.type === "multichoice" && { options: q.options })
        }))
      };
    } else {
      jsonData = {
        surveyID: surveyID,
        title: title,
        questionIDs: questions.map(q => q.id),
        questions: questions.map(q => ({
          prompt: q.prompt,
          type: q.type,
          id: q.id,
          tags: q.tags || [],
          ...(q.type === "multichoice" && { options: q.options })
        }))
      };
    }

    return (
      <div className={`${styles.createSurveyContainer} ${this.props.miniaturized ? styles.miniaturized : ''}`}>
        {/* Mode Toggle */}
        <div className={styles.modeToggle}>
          <Label className={styles.toggleLabel}>Upload as Survey</Label>
          <div
            className={styles.toggleSwitch}
            onClick={this.toggleStandaloneQuestion}
            style={{
              display: 'inline-block',
              marginLeft: '10px',
              cursor: 'pointer',
              backgroundColor: '#ccc',
              borderRadius: '15px',
              position: 'relative',
              width: '60px',
              height: '30px',
              verticalAlign: 'middle',
            }}
          >
            <div
              style={{
                backgroundColor: isStandaloneQuestion ? '#4caf50' : '#fff',
                borderRadius: '50%',
                width: '28px',
                height: '28px',
                position: 'absolute',
                top: '1px',
                left: isStandaloneQuestion ? '31px' : '1px',
                transition: 'left 0.2s',
              }}
            />
          </div>
          <Label className={styles.toggleLabel} style={{ marginLeft: '10px' }}>Upload Questions</Label>
        </div>

        {/* Survey Title Input */}
        {!isStandaloneQuestion && (
          <Input
            className={styles.surveyTitleInput}
            placeholder="Survey Title (required)"
            value={title}
            onChange={this.handleTitleChange}
            required
          />
        )}

        {/* Questions List */}
        {questions.map((question, qIndex) => (
          <div key={qIndex} className={styles.questionContainer}>
            <div className={styles.questionHeader}>
              <strong className={styles.questionTypeText}>#{qIndex + 1}: {question.type.charAt(0).toUpperCase() + question.type.slice(1)} Question</strong>
              <Button className={styles.removeQuestionButton} onClick={() => this.removeQuestion(qIndex)}>
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </div>
            <Input
              className={styles.questionPromptInput}
              placeholder="Question prompt"
              value={question.prompt}
              onChange={e => this.handleQuestionChange(qIndex, 'prompt', e.target.value)}
            />
            {question.type === "multichoice" && (
              <div className={styles.optionsContainer}>
                {question.options.map((option, oIndex) => (
                  <div key={oIndex} className={styles.optionItem}>
                    <Input
                      placeholder="Option"
                      value={option}
                      onChange={e => this.handleOptionChange(qIndex, oIndex, e.target.value)}
                      className={styles.optionInput}
                    />
                    <Button className={styles.removeOptionButton} onClick={() => this.removeOption(qIndex, oIndex)}>
                      <FontAwesomeIcon icon={faTimes} />
                    </Button>
                  </div>
                ))}
                {question.options.length < 10 && (
                  <Button className={styles.addOptionButton} onClick={() => this.addOption(qIndex)}>
                    <FontAwesomeIcon icon={faPlus} /> Add Option
                  </Button>
                )}
              </div>
            )}
            <div className={styles.questionMetadata}>
              <div className={styles.questionId}>Question ID: {question.id || 'Generating...'}</div>
              {/* Tags Input */}
              <Input
                className={styles.tagsInput}
                placeholder="Tags (comma-separated)"
                value={question.tags ? question.tags.join(', ') : ''}
                onChange={e => this.handleTagsChange(qIndex, e.target.value)}
              />
              {isStandaloneQuestion && (
                <div className={styles.associatedSurveyContainer}>
                  <Label className={styles.associatedSurveyLabel}>
                    Associated Survey:
                    <span
                      className={styles.addCurrentLink}
                      onClick={() => this.addCurrentSurvey(qIndex)}
                      style={{ cursor: associatedSurveyId ? 'pointer' : 'not-allowed', opacity: associatedSurveyId ? 1 : 0.5 }}
                    >
                      (Add Current)
                    </span>
                  </Label>
                  <Input
                    className={styles.associatedSurveyInput}
                    placeholder="Optional"
                    value={question.associatedSurveyId}
                    onChange={e => this.handleAssociatedSurveyIdChange(qIndex, e.target.value)}
                  />
                </div>
              )}
            </div>
          </div>
        ))}

        {/* Add Question Section */}
        <div className={styles.addQuestionContainer}>
          <select
            className={styles.questionTypeSelect}
            value={this.state.addingQuestionType}
            onChange={e => this.setState({ addingQuestionType: e.target.value })}
          >
            <option value="Question Type">Question Type</option>
            {Object.entries(questionTypeTooltips).map(([type, tooltip]) => (
              <option key={type} value={type} title={tooltip}>
                {type.charAt(0).toUpperCase() + type.slice(1)}
              </option>
            ))}
          </select>
          <Button className={styles.addQuestionButton} onClick={this.addQuestion}>
            <FontAwesomeIcon icon={faPlus} /> Add Question(s)
          </Button>
        </div>

        {!isStandaloneQuestion && (
          <p className={styles.surveyIdPreview}>Survey ID: {surveyID || 'Generating...'}</p>
        )}

        {/* Create Survey Button */}
        <Button
          className={`${styles.createSurveyButton} ${submissionError ? styles.errorButton : ''}`}
          onClick={this.createSurvey}
          disabled={isSubmitting || (!this.state.isStandaloneQuestion && !this.state.title.trim()) || this.state.questions.length === 0}
        >
          {isSubmitting ? (
            <>
              <FontAwesomeIcon icon={faSpinner} spin /> Creating...
            </>
          ) : (
            this.state.isStandaloneQuestion ? "Add Questions" : "Create Survey"
          )}
        </Button>

        {submissionError && (
          <div className={styles.errorMessage}>
            {submissionError}
          </div>
        )}

        {questionsAddedSuccessfully && (
          <div className={styles.surveySubmissionConfirmation}>
            <h3>Questions Added Successfully!</h3>
            {/* Display uploaded questions with links and icons */}
            {uploadedQuestions && uploadedQuestions.length > 0 && (
              <div className={styles.uploadedQuestionsList}>
                <h4>Uploaded Questions:</h4>
                <ul>
                  {uploadedQuestions.map(({ questionId, arweaveTxId }, index) => (
                    <li key={index} className={styles.uploadedQuestionItem}>
                      <a href={`https://arweave.net/${arweaveTxId}`} target="_blank" rel="noopener noreferrer">
                        Question ID: {questionId}
                      </a>
                      <Button className={styles.copyQuestionIdButton} onClick={() => this.copyQuestionIdToClipboard(questionId)}>
                        <FontAwesomeIcon icon={faClipboard} />
                      </Button>
                      <Button className={styles.bookmarkQuestionButton} onClick={() => this.bookmarkQuestion(questionId)}>
                        <FontAwesomeIcon icon={faBookmark} />
                      </Button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}

        {surveyAddedSuccessfully && (
          <div className={styles.surveySubmissionConfirmation}>
            <h3>Survey Created Successfully!</h3>
            <p>
              Survey ID: {surveyID}
              <Button className={styles.copySurveyIdButton} onClick={() => this.copySurveyIdToClipboard(surveyID)}>
                <FontAwesomeIcon
                  icon={this.state.copySurveyIdSuccess ? faCheck : faClipboard}
                  id={styles.copySurveyIdIcon}
                  style={{ color: this.state.copySurveyIdSuccess ? 'green' : 'inherit' }}
                />
              </Button>
            </p>
            <div className={styles.surveyActions}>
              <Button className={styles.copySurveyLinkButton} onClick={this.copySurveyLinkToClipboard}>
                <FontAwesomeIcon icon={faCheck} className={styles.surveySubmittedIcon} />
                Copy Survey Link
              </Button>
              <a href={`/survey/${surveyID}`} target="_blank" rel="noopener noreferrer" className={styles.viewSurveyLink}>
                View Survey
              </a>
            </div>
          </div>
        )}

        <Button className={styles.toggleJsonButton} onClick={this.toggleShowJson}>
          {showJson ? "Hide JSON" : "Show JSON"}
        </Button>

        {showJson && (
          <pre className={styles.jsonDisplay}>
            {JSON.stringify(jsonData, null, 2)}
          </pre>
        )}
      </div>
    );
  }
}

export default CreateSurvey;
