import React, { Component } from "react";
import classNames from "classnames";

// CSS
import "assets/css/m_w.css";
import styles from "./RightSide.module.scss";

// react plugin used to create charts
// import { Line, /* Bar */ } from "react-chartjs-2";

// Chart Data
import { chartExample1 } from "variables/charts.js";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  // CardFooter,
  CardTitle,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
  // UncontrolledDropdown,
  // Label,
  // FormGroup,
  // Input,
  // Progress,
  // Table,
  // UncontrolledTooltip,
  Row,
  Col
} from "reactstrap";


class NetworkStats extends Component {
  state = {
      bigChartData: "data1"
  }

  setBgChartData = name => {
    this.setState({
      bigChartData: name
    });
  }

  render() {
    return (
      <div id={styles.networkStats}>
      <Card className={styles.rightSideCard}>
                <CardHeader>
                  <Row>
                    <Col sm="12" id="dataChartRightSide">
                      <div id={styles.chartOptionButtons}>
                      <ButtonGroup
                        className="btn-group-toggle"
                        data-toggle="buttons"
                      >
                        <Button
                          color="info"
                          id="0"
                          size="sm"
                          tag="label"
                          className={classNames("btn-simple", {
                            active: this.state.bigChartData === "data1"
                          })}
                          onClick={() => this.setBgChartData("data1")}
                        >
                          <input defaultChecked name="options" type="radio" />
                          <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            Players
                          </span>
                          <span className="d-block d-sm-none">
                            <i className="tim-icons icon-single-02" />
                          </span>
                        </Button>
                        <Button
                          color="info"
                          id="1"
                          size="sm"
                          tag="label"
                          className={classNames("btn-simple", {
                            active: this.state.bigChartData === "data2"
                          })}
                          onClick={() => this.setBgChartData("data2")}
                        >
                          <input name="options" type="radio" />
                          <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            Stakes
                          </span>
                          <span className="d-block d-sm-none">
                            <i className="tim-icons icon-gift-2" />
                          </span>
                        </Button>
                        <Button
                          color="info"
                          id="2"
                          size="sm"
                          tag="label"
                          className={classNames("btn-simple", {
                            active: this.state.bigChartData === "data3"
                          })}
                          onClick={() => this.setBgChartData("data3")}
                        >
                          <input name="options" type="radio" />
                          <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            Views
                          </span>
                          <span className="d-block d-sm-none">
                            <i className="tim-icons icon-tap-02" />
                          </span>
                        </Button>
                      </ButtonGroup>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart-area">
                    {/* <Line
                      data={chartExample1[this.state.bigChartData]}
                      options={chartExample1.options}
                    /> */}
                  </div>
                </CardBody>
              </Card>
      </div>
    );
  }
}

export default NetworkStats;
