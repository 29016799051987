// PlayersOnlineChart.jsx

import React from 'react';
import { Line } from 'react-chartjs-2';

// CSS
import styles from "./RightSide.module.scss";

import {
  CategoryScale,
  LinearScale,
  Chart,
  Title,
  Tooltip,
  PointElement,
  LineElement,
} from 'chart.js';

Chart.register(
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  PointElement,
  LineElement
);


const PlayersOnlineChart = ({ dataPoints }) => {
  const data = {
    labels: Array.from({ length: dataPoints.length }, (_, i) => i + 1),
    datasets: [
      {
        data: dataPoints,
        borderColor: 'rgba(45, 206, 137, 0.5)',
        fill: false,
        pointRadius: 0,
      },
    ],
  };

  const options = {
    scales: {
      x: { display: false },
      y: { display: false },
    },
    plugins: {
      legend: { display: false },
    },
  };

  return (
    <div className={styles.chartContainer}>
      <Line data={data} options={options} className={styles.chartLine}/>
    </div>
  );
};

export default PlayersOnlineChart;
