import React, { Component } from 'react';
import styles from './SimUserPage.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faExclamationTriangle, faFile, faComments } from '@fortawesome/free-solid-svg-icons';
import { Collapse, UncontrolledTooltip, Modal, ModalHeader, ModalBody, Input, Button } from 'reactstrap';
import historicalFigures from '../../variables/historical_figure_users.json';

class SimUserPage extends Component {
  state = {
    userInfo: null,
    collapseOpen: false,
    showFullProfileModal: false,
    influencingDocuments: [],
    newDocument: '',
    newProblem: '',
  };

  componentDidMount() {
    this.getUserInfoByUsername();
    this.initializeInfluencingDocuments();
  }

  getUserInfoByUsername = () => {
    const { simUsername } = this.props;
    const userInfo = historicalFigures.find(figure => figure.username === simUsername);
    this.setState({ userInfo });
  }

  initializeInfluencingDocuments = () => {
    const { simUsername } = this.props;
    const placeholderDoc = {
      name: `${simUsername}.doc`,
      content: 'Placeholder content for influencing document.',
      debates: [],
    };
    this.setState({ influencingDocuments: [placeholderDoc] });
  }

  toggleCollapse = () => {
    this.setState(prevState => ({
      collapseOpen: !prevState.collapseOpen
    }));
  }

  handleNewDocumentChange = (event) => {
    this.setState({ newDocument: event.target.value });
  }

  handleNewProblemChange = (event) => {
    this.setState({ newProblem: event.target.value });
  }

  addNewDocument = () => {
    const { newDocument, influencingDocuments } = this.state;
    if (newDocument.trim()) {
      const newDoc = {
        name: `${newDocument}.doc`,
        content: 'New document content.',
        debates: [],
      };
      this.setState({
        influencingDocuments: [...influencingDocuments, newDoc],
        newDocument: '',
      });
    }
  }

  addNewProblem = () => {
    const { newProblem, influencingDocuments } = this.state;
    if (newProblem.trim()) {
      const updatedDocs = influencingDocuments.map((doc, index) => {
        if (index === 0) {  // Add problem to the first document for simplicity
          return {
            ...doc,
            debates: [...doc.debates, newProblem],
          };
        }
        return doc;
      });
      this.setState({
        influencingDocuments: updatedDocs,
        newProblem: '',
      });
    }
  }

  renderQuestionAnswer = (question) => {
    switch (question.questionType) {
      case 'binary':
        return (
          <div className={styles.binaryAnswer}>
            {question.answer.value}
          </div>
        );
      case 'rating':
        return (
          <div className={styles.ratingAnswer}>
            <div className={styles.ratingBar} style={{ width: `${question.answer.value * 10}%` }}></div>
            <span>{question.answer.value}/10</span>
          </div>
        );
      case 'multichoice':
        return (
          <div className={styles.multichoiceAnswer}>
            {question.answer.value.map((choice, index) => (
              <span key={index} className={styles.choiceItem}>{choice}</span>
            ))}
          </div>
        );
      case 'freeform':
      default:
        return (
          <div className={styles.freeformAnswer}>
            "{question.answer.value}"
          </div>
        );
    }
  }

  render() {
    const { userInfo, collapseOpen, showFullProfileModal, influencingDocuments, newDocument, newProblem } = this.state;
    const { minimized } = this.props;

    if (!userInfo) {
      return <div>Loading...</div>;
    }

    return (
      <div className={`${styles.simUserPage} ${minimized ? styles.minimized : ''}`}>
        <div className={styles.header}>
          <div className={styles.userInfo}>
            <div className={styles.avatarContainer}>
              <img src={userInfo.avatar} alt={userInfo.name} className={styles.avatar} />
            </div>
            <h1 id={styles.userPageName}>
              {userInfo.name}
              <span className={styles.simulatedBadge} id="simulatedUserTooltip">
                <FontAwesomeIcon icon={faExclamationTriangle} />
              </span>
              <UncontrolledTooltip placement="right" target="simulatedUserTooltip">
                This is a simulated historical figure based on AI-generated responses.
              </UncontrolledTooltip>
            </h1>
          </div>
        </div>

        {!minimized && (
          <div className={styles.content}>
            <div className={styles.leftColumn}>
              <div className={styles.surveySection}>
                <h2>Answered Questions:</h2>
                {userInfo.questions.map((question, index) => (
                  <div key={index} className={styles.questionPreview}>
                    <div className={styles.questionTitle}>{question.question}</div>
                    {this.renderQuestionAnswer(question)}
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.rightColumn}>
              <div className={styles.adviceSection}>
                <h2>Summarized Advice:</h2>
                <div className={styles.adviceItem}>
                  <h3>Biggest Hope:</h3>
                  <p className={styles.highlightedText}>{userInfo.biggestHope}</p>
                </div>
                <div className={styles.adviceItem}>
                  <h3>Biggest Fear:</h3>
                  <p className={styles.highlightedText}>{userInfo.biggestFear}</p>
                </div>
                <div className={styles.adviceItem}>
                  <h3>Highlighted Advice:</h3>
                  <p className={styles.highlightedText}>{userInfo.highlightedAdvice}</p>
                </div>
              </div>
            </div>
          </div>
        )}

        {!minimized && (
          <>
            <div className={styles.actionButtons}>
              {/* <button onClick={this.toggleCollapse} className={styles.collapseButton}>
                Show More {collapseOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
              </button> */}
            </div>

            <Collapse isOpen={collapseOpen}>
              <div className={styles.extraInfo}>
                <h3>Additional Information:</h3>
                <p>Karma: {userInfo.Karma}</p>
                
                <h3>Influencing Documents:</h3>
                <div className={styles.influencingDocuments}>
                  {influencingDocuments.map((doc, index) => (
                    <div key={index} className={styles.document}>
                      <FontAwesomeIcon icon={faFile} /> {doc.name}
                      {doc.debates.length > 0 && (
                        <div className={styles.debates}>
                          <h4>Debates:</h4>
                          {doc.debates.map((debate, debateIndex) => (
                            <div key={debateIndex} className={styles.debate}>
                              <FontAwesomeIcon icon={faComments} /> {debate}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                
                <div className={styles.addDocument}>
                  <Input 
                    type="text" 
                    value={newDocument} 
                    onChange={this.handleNewDocumentChange} 
                    placeholder="Enter new document name" 
                  />
                  <Button onClick={this.addNewDocument}>Add Document</Button>
                </div>
                
                <div className={styles.addProblem}>
                  <Input 
                    type="textarea" 
                    value={newProblem} 
                    onChange={this.handleNewProblemChange} 
                    placeholder="Enter a problem or debate point" 
                  />
                  <Button onClick={this.addNewProblem}>Add Problem</Button>
                </div>
              </div>
            </Collapse>
          </>
        )}

        <Modal isOpen={showFullProfileModal} toggle={() => this.setState({ showFullProfileModal: false })} size="lg" className={styles.modalContent}>
          <ModalHeader toggle={() => this.setState({ showFullProfileModal: false })} className={styles.modalHeader}>Full User Profile</ModalHeader>
          <ModalBody className={styles.modalBody}>
            <div className={styles.modalSummary}>
              <h3>User Summary</h3>
              <p>{userInfo.highlightedAdvice}</p>
            </div>
            <div className={styles.modalQuestions}>
              <h3>Answered Questions</h3>
              {userInfo.questions.map((question, index) => (
                <div key={index} className={styles.questionPreview}>
                  <div className={styles.questionTitle}>{question.question}</div>
                  {this.renderQuestionAnswer(question)}
                </div>
              ))}
            </div>
            <div className={styles.modalAdvice}>
              <h3>Summarized Advice</h3>
              <div className={styles.adviceItem}>
                <h4>Biggest Hope:</h4>
                <p>{userInfo.biggestHope}</p>
              </div>
              <div className={styles.adviceItem}>
                <h4>Biggest Fear:</h4>
                <p>{userInfo.biggestFear}</p>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default SimUserPage;