import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toggleLoginModal } from '../../actions/sessionStateActions.js';
// import { fetchAccount } from '../../actions/accountActions.js';

// CSS & Icons
import styles from "./Navbar.module.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' // TODO: fa-library

// Components
// import LoginModal from '../LoginAndWeb3/LoginModal.jsx';
import LoginButton from 'components/Buttons/LoginButton.jsx';
import Web3Modal from '../LoginAndWeb3/Web3Modal.jsx'
import { AccountDisplay } from './AccountDisplay.jsx'; 
import { AccountDisplayTorus } from './AccountDisplayTorus.jsx';
// import ChangeNetwork from '../Buttons/ChangeNetwork.jsx';
// import JoinMWButton from '../Buttons/JoinMWButton.jsx';
import BalanceDisplay from './BalanceDisplay.jsx';
// import FreePaidToggle from './FreePaidToggle.jsx';

// reactstrap components
import { Button, Col } from "reactstrap";

// Smart Contract Interactions
import contractScripts from '../Buttons/contractScripts.js';
// import Web3 from 'web3';

class AccountSection extends Component {
  state = {
    // web3SettingsOpen: false,
  };

  componentDidMount() {}

  componentDidUpdate() {
    // console.log("AccountSection.jsx - UPDATED")
  }

  launchWeb3Settings = () => {
    const open = true;
    this.props.toggleLoginModal(open);
  }


  render() {

    const loggedIn = this.props.loginComplete;
    console.log("AccountSection.jsx – loggedIn: ", loggedIn)

    const balanceDisplay = loggedIn ?
    <BalanceDisplay 
      loginComplete={this.props.loginComplete}
      account={this.props.account} 
      paid={this.props.paid} 
      XPBalance={this.props.XPBalance} 
      ETHbalance={this.props.ETHBalance} 
      availableETH={this.props.availableETH}
    />
    :
    <></>;

    // only show settings button if logged in with wagmi wallet, otherwise
    // the torus account display acts as the settings button
    const relevantAccountDisplay = this.props.provider == "Torus" ? 
      <AccountDisplayTorus 
        account={this.props.account} 
        loginComplete={this.props.loginComplete}
        launchWeb3Settings={this.launchWeb3Settings}
        userImageURL={this.props.userImageURL}
      />
      :
      <>
        <AccountDisplay 
          account={this.props.account} 
          loginComplete={this.props.loginComplete}
        />

        <div id={styles.walletSettingsSection}>
          <Button className={styles.walletSettingsButton} id={styles.walletSettingsButton} onClick={this.launchWeb3Settings}>
            <div id={styles.walletSettingsIcon}> <i className="fas fa-cog"></i> </div> 
          </Button>
        </div> 
      </>


    const topRight = loggedIn ? (
      <>
        <div id={styles.AccountSectionLoggedIn}>
          <div id={styles.XPBalanceDisplay}>
            { balanceDisplay }
          </div>   
          <div id={styles.addressDisplay}>
            { relevantAccountDisplay }
          </div>
        </div >
      </>
    ) : (
      <>
        <LoginButton launchWeb3Modal={this.launchWeb3Settings} />
      </>
    );

    return (
      <>
        <Web3Modal 
        sendTestETH={(amountToSend) => this.props.sendTestETH(amountToSend)} 
        demoMode={this.props.demoMode}
        toggleDemoMode={(demoModeOn) => this.props.toggleDemoMode(demoModeOn)} 
        // network={this.props.network} // this comes through WAGMI HOC
        />
        { topRight }
      </> 
    );
  }
}

AccountSection.propTypes = {
  toggleLoginModal: PropTypes.func.isRequired,
  // updateLoginInfo: PropTypes.func.isRequired,
  loginModalToggled: PropTypes.bool,
  userImageURL: PropTypes.string,
};

const mapStateToProps = state => ({
  loginModalToggled: state.sessionState.loginModalToggled,
  userImageURL: state.profile.userImageURL,
});

export default connect(mapStateToProps, { toggleLoginModal })(AccountSection);

