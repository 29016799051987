import React, { Component } from 'react';

// CSS
import 'assets/css/m_w.css';
import styles from "./UpcomingMatches.module.scss";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  UncontrolledTooltip,
} from 'reactstrap';

// Components
import InitiateMatchButton from 'components/Buttons/InitiateMatchButton.jsx';
import ProposalCard from './ProposalCard.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' // TODO: fa-library
import { faSpinner, faExpandArrowsAlt, faCaretDown, faArrowUp, faStar, faUser } from '@fortawesome/free-solid-svg-icons'

class UpNextMatch extends Component {
  componentDidMount() {}

  componentDidUpdate() {}

  render() {

    // TODO HERE: WEIRD SHIT HAPPENING IN FOOTER OF PROPOSALCARD ON TOPPER
    //            OTHER THAN THAT TRYING TO MAKE PROPOSAL CARD cleaner and MAKE CARDS LOOK FINE

    const loadingIcon = <h1> <FontAwesomeIcon icon={faSpinner} pulse /> </h1>;

    var startNextMatchButton = this.props.canStartNew ? 
    <InitiateMatchButton 
      homepage={false} 
      changeFocusedTab={(newTabIndex) => this.props.changeFocusedTab(newTabIndex)} 
      toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
      focusedTab={this.props.focusedTab}
      lobby={this.props.lobby}
      account={this.props.account}
      paid={this.props.paid}
      provider={this.props.provider} 
      loginComplete={this.props.loginComplete}
      text="Start"
    />
    :
    <>
      <Button
        className="btn-tooltip ml-1"
        color="cantStartMatch"
        id="cannotStartMatchTooltip"
        size="sm"
        >
        Start
      </Button>
      <UncontrolledTooltip
        id="cantStartTooltip"
        delay={0}
        placement="top"
        target="cannotStartMatchTooltip">
        Can't start new vote when one is ongoing
      </UncontrolledTooltip>
    </>;

    var cardInput = this.props.proposal == undefined ? loadingIcon // TODO: better loading screen, implement in proposalCard.jsx
    :
    <ProposalCard
      // key={nextProposal.ID} // TODO: use?
      joined={this.props.joined}
      loggedIn={this.props.loggedIn}
      lobby={this.props.lobby}
      account={this.props.account}
      paid={this.props.paid}
      provider={this.props.provider}
      proposal={this.props.proposal}
      // forMemeMatchTopper={true} // TODO: forUpNextArea etc. 
    />;

    return (
      <div id={styles.nextUp} className="animLine">
        <Card id={styles.upNextCard}>
          <CardBody id={styles.upNextCardBody}>
            <h6 className={styles.upNextCardTopRow}>
              <div id={styles.upNextHeaderIconAndText}>
                <i id={styles.nextMatchIcon} className="tim-icons icon-double-right fa-blink" />
                {" "} Current Next Vote
              </div>
              { startNextMatchButton }
            </h6>
            {cardInput}
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default UpNextMatch;
