import { FETCH_LOBBY, CHANGE_LOBBY, FETCH_PROPOSALS, NEW_PROPOSAL, LOAD_MATCHES, FETCH_MATCH, NEW_MATCH } from './types';
import store from '../store.js';

export const fetchLobby = () => dispatch => {
  // Check redux store for state (TODO: safe?)
  var currLobby = store.getState().lobbyInfo.lobby;
  var currPaid = store.getState().lobbyInfo.paid;
  var currMatch = store.getState().lobbyInfo.currMatch;
  var currMatches = store.getState().lobbyInfo.matches;

  const lobbyInfo = {
    lobby: currLobby,
    paid: currPaid,
    currMatch: currMatch,
    matches: currMatches
  }; 

  dispatch({
    type: FETCH_LOBBY,
    payload: lobbyInfo,
  });
};


export const changeLobby = (lobbyInfo) => dispatch => {
  dispatch({
    type: CHANGE_LOBBY,
    payload: lobbyInfo
  })
};


export const updateCurrMatch = (newCurrMatch) => dispatch => {
  dispatch({
    type: NEW_MATCH,
    payload: newCurrMatch
  })
};


export const updateMatches = (lobbyMatches) => dispatch => {

  dispatch({
    type: LOAD_MATCHES,
    payload: lobbyMatches
  })

};

// TODO: update or fetch proposals? Fetching from contract, updating in Redux
export const updateProposals = (LobbyProposals) => dispatch => {
      dispatch({
        type: FETCH_PROPOSALS,
        payload: LobbyProposals
      })
};


// export const fetchProposals = (jsonLobbyProposals) => dispatch => {
//   fetch('https://jsonplaceholder.typicode.com/posts')
//     .then(res => res.json())
//     .then(proposals =>
//       dispatch({
//         type: FETCH_PROPOSALS,
//         payload: proposals
//       })
//     );

//       // dispatch({
//       //   type: FETCH_PROPOSALS,
//       //   payload: JSONLobbyProposals
//       // })
// };


// export const createProposal = (proposalData) => dispatch => {

//   // this.updateProposals

//   // TODO: append to proposals object rather than just calling again
//   // fetch('https://jsonplaceholder.typicode.com/posts', {
//   //   method: 'POST',
//   //   headers: {
//   //     'content-type': 'application/json'
//   //   },
//   //   body: JSON.stringify(proposalData)
//   // })
//   //   .then(res => res.json())
//   //   .then(proposal =>
//   //     dispatch({
//   //       type: NEW_PROPOSAL,
//   //       payload: proposal
//   //     })
//   //   );
// };

