// TODO: move to /Buttons/ ?

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { changeFocusedMatchID } from '../../actions/sessionStateActions.js';

// CSS
import 'assets/css/m_w.css';
import styles from "./MemeMatch.module.scss";

// reactstrap components
import { Pagination, PaginationItem, PaginationLink, Row } from 'reactstrap';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'

var PaginateStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: '10px',
  paddingBottom: '10px',
  paddingRight: '5px'
};

var nextMatchID = 0;
var prevMatchID = 0;

class Paginate extends React.Component {

  componentDidMount() {
    this.changeFocusedMatch(this.props.currMatch.ID); // TODO: this breaks live
    // this.setState({focusedMatch: this.props.currMatch.ID})
  }

  componentDidUpdate() { }

  changeFocusedMatch = async (newMatchID) => {
    // console.log("changeFocusedMatch");
    this.props.changeFocusedMatchID(newMatchID);
  }

  getPrevMatch = () => {
    //   this.setState({ focusedMatchID : prevMatchID })
    this.changeFocusedMatch(prevMatchID);
  }

  getNextMatch = () => {
    // this.setState({ focusedMatchID : nextMatchID })
    this.changeFocusedMatch(nextMatchID);
  }

  setNextAndPreviousMatches = () => {
  
        const matches = this.props.matches;
    
        var i, len = matches.length;
        
        for (i = 0; i < len; i++) {
            if (matches[i].hasOwnProperty('ID') && matches[i].ID == this.props.focusedMatchID) {
            //   console.log(" i is:");
            //   console.log(i);
              if (i < len-1) nextMatchID = matches[i+1].ID
              else (nextMatchID = 0);
              if (i > 0) prevMatchID = matches[i-1].ID
              else (prevMatchID = 0);
            //   console.log("nextMatchID");
            //   console.log(nextMatchID);
            //   console.log("prevMatchID");
            //   console.log(prevMatchID);

            }
        }
  }

  // updateDisplayedMatches = () => {
  //   const defaultDisplayedMatches = {
  //     first: null,
  //     second: null,
  //     third: null,
  //   };

  //   switch(this.props.matches.length) {
  //     case 0:
  //       return defaultDisplayedMatches;

  //     case 1:
  //       const updatedDisplayMatches = defaultDisplayedMatches;
  //       updatedDisplayMatches.first = this.props.matches[0].ID;
  //       return updatedDisplayMatches;
  //   }
  // }
  

  render() {

    // console.log("PAGINATE RE-RENDER");

    const matchIDToDisplay = this.props.focusedMatchID == null ? this.props.currMatch.ID : this.props.focusedMatchID;

    var displayedMatches = {
      first: matchIDToDisplay,
    };

    // const matchNum1 = this.props.matches == null && !this.props.matches.length > 0 ? "" : this.props.matches[this.props.matches.length - 1];
    const className1 = "active";

    // const lobbyMatchIDs = this.props.matches.filter(function(match) {
    //     return match.ID != 0
    // });

    // console.log("lobbyMatchIDs:");
    // console.log(lobbyMatchIDs);

    this.setNextAndPreviousMatches();

    // const nextMatch = this.state.focusedMatchID == this.props.currMatchID ?
    // const prevMatch = this.props.matches.length > 1 ? 


    const leftArrowDisplay = !(this.props.matches.length > 1) || prevMatchID == 0 ? 
    <PaginationItem>
      <PaginationLink aria-label="Previous" href="">
        {/* <span aria-hidden={true}> */}
          <FontAwesomeIcon icon={faAngleDoubleLeft} className={styles.disabledArrow} />
        {/* </span> */}
      </PaginationLink>
    </PaginationItem>
    :
    <PaginationItem>
      <PaginationLink aria-label="Previous" href="" onClick={ this.getPrevMatch }>
        <span aria-hidden={true}>
          <FontAwesomeIcon icon={faAngleDoubleLeft} id="paginateLeftArrow" />
        </span>
      </PaginationLink>
    </PaginationItem>;

    const rightArrowDisplay = this.props.currMatch == null || this.props.focusedMatchID == null || this.props.focusedMatchID == this.props.currMatch.ID || nextMatchID == 0 ? 
    <PaginationItem>
      <PaginationLink aria-label="Next" href="">
        <FontAwesomeIcon icon={faAngleDoubleRight} className={styles.disabledArrow} />
      </PaginationLink>
    </PaginationItem>
    :
    <PaginationItem>
      <PaginationLink aria-label="Next" href="" onClick={ this.getNextMatch }>
        <FontAwesomeIcon icon={faAngleDoubleRight} id="paginateRightArrow" />
      </PaginationLink>
    </PaginationItem>;

    return (
        <div style={PaginateStyle} className="section section-pagination">
          <Row>
            <div className="pagination-container justify-content-center">
              <Pagination className="pagination pagination-primary" listClassName="pagination-primary">

                { leftArrowDisplay }

                <PaginationItem className={className1}>
                  <PaginationLink id="focusedMatchNumber" href="#mw" onClick={e => e.preventDefault()}>
                    { displayedMatches.first }
                  </PaginationLink>
                </PaginationItem>

                { rightArrowDisplay }

              </Pagination>
            </div>
          </Row>
        </div>
    );
  }
}

Paginate.propTypes = {
  changeFocusedMatchID: PropTypes.func.isRequired,
  currMatch: PropTypes.object.isRequired,
  matches: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  focusedMatchID: state.sessionState.focusedMatchID,
  currMatch: state.lobbyInfo.currMatch,
  matches: state.lobbyInfo.matches,
});

export default connect(mapStateToProps, { changeFocusedMatchID })(Paginate);

