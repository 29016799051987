import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
// import { updateCurrMatch } from '../../actions/lobbyActions.js';

// reactstrap components
import { Button } from 'reactstrap';

// Web3 Script for Smart Contract Interactions
import contractScripts from './contractScripts.js';

class CancelMatchButton extends Component {
  componentDidMount() {}

  componentDidUpdate() { }

  sendCancelTX = async () => {
    await contractScripts.cancelMatch(this.props.account, this.props.provider, this.props.lobby, this.props.paid)
  }

  launchLoginModal = () => {
    const modalOpen = true;
    this.props.toggleLoginModal(modalOpen)
  }

  getButtonFunction = () => {
    
    if (this.props.loginComplete) {
      return this.sendCancelTX
    }

    else {
      return this.launchLoginModal
    }
  }

  render() {


    
    const relevantID = this.props.matchContainer ? "matchContainerCancelButton" : "startMatchButton";
    const buttonText = this.props.text;

    const onClickFunction = this.getButtonFunction();

    const matchCancelled = this.props.currMatch !== undefined && this.props.currMatch.matchStatus == "CANCELLED";

    const cancelButtonDisplay = matchCancelled ? 
    <></>
    :
    <Button onClick={onClickFunction} id={relevantID} color="startMatch" type="btn"> 
      { buttonText }
    </Button>

    // console.log("CancelMatchButton - match is cancelled:")
    // console.log(matchCancelled)

    return (
      cancelButtonDisplay
    );
  }
}

// CancelMatchButton.propTypes = {
//     updateCurrMatch: PropTypes.func.isRequired,
//     account: PropTypes.string,
//     provider: PropTypes.string,
//     lobby: PropTypes.string,
//     paid: PropTypes.bool,
//     currMatch: PropTypes.object,
//     matches: PropTypes.array.isRequired,
//   };
  
//   const mapStateToProps = state => ({
//     currMatch: state.lobbyInfo.currMatch,
//   });
  
// export default connect(mapStateToProps, { updateCurrMatch })(CancelMatchButton);

export default CancelMatchButton

