import React, { Component } from 'react';
import {
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  FormGroup,
  Label,
  Input,
  Form,
  Card,
  CardHeader,
  CardBody,
  FormText,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
  Alert,
  CustomInput
} from 'reactstrap';
import Select from 'react-select';

// Importing styles
import styles from './SurveyTool.module.scss';
import "../../assets/css/m_w.css";
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark, faCaretUp, faLock, faUnlock, faPlus, faCaretDown, faCheck, faTimes, faArrowLeft, faArrowRight, faQuestionCircle, faSpinner, faSearch, faExpand, faExternalLinkAlt, faFilter, faExclamationCircle, faClipboard, faCog } from '@fortawesome/free-solid-svg-icons';
// Components
import AudioInput from './AudioInput.jsx';
import CreateSurvey from './CreateSurvey';
import SurveyResults from './SurveyResults';
import QuestionFilter from './QuestionFilter';

// Crypto Functionality (encrypt/decrypt) and variables
import { ARWEAVE_ACTIVE } from '../../variables/CONTRACT_ADDRESSES';
import contractScripts from '../Buttons/contractScripts.js';
import { encrypt } from '@metamask/eth-sig-util';
import { ethers, utils } from 'ethers';
import sha256 from 'crypto-js/sha256';
import Slider from 'react-rangeslider';
import proposalScripts from 'components/UpcomingMatches/proposalScripts';

class SurveyTool extends Component {
  constructor(props) {
    super(props);
    // Initialize cache from localStorage or set default structure
    let savedCache;
    const savedCacheStr = localStorage.getItem('surveyCache');
    if (savedCacheStr) {
      try {
        savedCache = JSON.parse(savedCacheStr);
      } catch (e) {
        console.error('Error parsing saved cache from localStorage:', e);
        savedCache = {
          surveyIDs: [],
          questionIDs: [],
          questionResponses: {},
          arweaveContent: {},
        };
      }
    } else {
      savedCache = {
        surveyIDs: [],
        questionIDs: [],
        questionResponses: {},
        arweaveContent: {},
      };
    }

    this.state = {
      cache: savedCache,
      latestBlockNumber: 0,
      events: [],
    };
  }

  updateCache = (updater, callback) => {
    if (typeof updater !== 'function') {
      console.error('updateCache expects a function as its argument, but received:', updater);
      return;
    }
    this.setState((prevState) => {
      const newCache = updater(prevState.cache);
      // Save updated cache to localStorage
      localStorage.setItem('surveyCache', JSON.stringify(newCache));
      return { cache: newCache };
    }, callback);
  };

  componentDidMount() {
    this.initializeEventListener();
    this.loadInitialData();
    this.fetchAllQuestionIDs(); // New function to fetch question IDs
  }

  fetchAllQuestionIDs = async () => {
    try {
      const questionIDs = await contractScripts.fetchAllQuestionIDs(this.props.provider);
      if (!questionIDs || questionIDs.length === 0) {
        console.warn('No question IDs fetched.');
        return;
      }
      console.log('Fetched question IDs:', questionIDs);

      this.updateCache((prevCache) => ({
        ...prevCache,
        questionIDs,
      }));

      // Fetch question details and update cache
      for (let questionId of questionIDs) {
        let questionData = this.state.cache.arweaveContent[questionId];
        if (!questionData) {
          questionData = await contractScripts.getQuestionData(this.props.provider, questionId);
          if (questionData) {
            this.updateCache((prevCache) => ({
              ...prevCache,
              arweaveContent: {
                ...prevCache.arweaveContent,
                [questionId]: questionData,
              },
            }));
          } else {
            console.warn(`Question data not found for ID: ${questionId}`);
          }
        }
      }

      // After updating cache, fetch responses for all questions
      this.fetchAllQuestionResponses();
    } catch (error) {
      console.error('Error fetching all question IDs:', error);
    }
  };


  // Function to fetch responses for all questions
  fetchAllQuestionResponses = async () => {
    const { cache } = this.state;
    const { questionIDs } = cache;

    for (let questionId of questionIDs) {
      const responses = await contractScripts.getResponsesByQuestionID(this.props.provider, questionId);
      this.updateCache((prevCache) => ({
        ...prevCache,
        questionResponses: {
          ...prevCache.questionResponses,
          [questionId]: responses,
        },
      }));
    }
  };


  componentDidUpdate(prevProps, prevState) {
    // Check if cache has changed in a meaningful way
    const cacheChanged =
      this.state.cache.surveyIDs.length !== prevState.cache.surveyIDs.length ||
      Object.keys(this.state.cache.arweaveContent).length !== Object.keys(prevState.cache.arweaveContent).length;

    if (cacheChanged) {
      this.fetchSurveys();
    }
  }

  // Initialize event listeners for surveys (if applicable)
  initializeEventListener = () => {
    // Example: Listen for new surveys added via smart contract events
    contractScripts.listenForSurveyEvents(this.props.provider, this.handleNewEvent);
  };

  // Handle new events and update cache accordingly
  handleNewEvent = (event) => {
    console.log('New event detected:', event);
    this.updateCacheFromEvent(event);
  };

  // Update cache based on the received event
  updateCacheFromEvent = async (event) => {
    // Implement logic based on the event type
    // Example:
    /*
    if (event.event === 'SurveyAdded') {
      const surveyID = event.args.surveyId;
      const surveyData = await contractScripts.getSurveyDataById(this.props.provider, surveyID);
      // Fetch question data
      surveyData.questions = [];
      for (let questionID of surveyData.questionIDs) {
        let questionData = await contractScripts.getQuestionData(this.props.provider, questionID);
        questionData.id = questionID;
        surveyData.questions.push(questionData);
      }
      // Update cache
      this.updateCache(prevCache => ({
        ...prevCache,
        surveyIDs: [...prevCache.surveyIDs, surveyID],
        arweaveContent: {
          ...prevCache.arweaveContent,
          [surveyID]: surveyData
        }
      }));
    }
    */
    // Implement other event types as needed
  };

  // Fetch survey data by survey ID
  getSurveyData = async (surveyID) => {
    if (this.props.singleQuestionMode) {
      // Do not fetch survey data in single question mode
      return null;
    }
  
    console.log('Getting survey data for ID:', surveyID);
    let surveyData = this.state.cache.arweaveContent[surveyID];
  
    if (!surveyData) {
      surveyData = await contractScripts.getSurveyDataById(this.props.provider, surveyID);
      if (!surveyData) {
        console.warn(`No survey data found for ID ${surveyID}`);
        return null;
      }
      // Fetch question data and include in surveyData
      surveyData.questions = [];
      for (let questionID of surveyData.questionIDs) {
        let questionData = await contractScripts.getQuestionData(this.props.provider, questionID);
        questionData.id = questionID; // Ensure each question has its ID
        surveyData.questions.push(questionData);
      }
  
      // Update cache
      this.updateCache(prevCache => ({
        ...prevCache,
        arweaveContent: {
          ...prevCache.arweaveContent,
          [surveyID]: surveyData
        }
      }));
    }
  
    return surveyData;
  };
  
  // Load initial data: fetch survey IDs and their data
  loadInitialData = async () => {
    console.log('Loading initial data');
    try {
      // Fetch survey IDs submitted by the user
      const surveyIDs = await contractScripts.fetchUserSubmittedSurveyIDs(this.props.provider);
      console.log('Fetched survey IDs:', surveyIDs);

      // Update cache with survey IDs
      this.updateCache(prevCache => ({
        ...prevCache,
        surveyIDs,
      }));

      // Fetch survey data and related questions
      for (let surveyID of surveyIDs) {
        const surveyData = await this.getSurveyData(surveyID);
        console.log(`Fetched survey data for ID ${surveyID}:`, surveyData);
      }

      // Fetch latest block number (if needed)
      const latestBlockNumber = await contractScripts.getLatestBlockNumber(this.props.provider);
      this.setState({ latestBlockNumber });
      console.log('Latest block number:', latestBlockNumber);
    } catch (error) {
      console.error("Error loading initial data:", error);
    }
  };


  // Fetch surveys based on cache
  fetchSurveys = async () => {
    console.log('Fetching surveys');
    try {
      const surveyIDs = this.state.cache.surveyIDs;
      console.log('Using cached survey IDs:', surveyIDs);
      const userSubmittedSurveys = [];
      const surveySet = new Set();

      for (let surveyID of surveyIDs) {
        if (!surveyID) continue; // Skip undefined IDs
        const surveyData = await this.getSurveyData(surveyID);
        if (!surveyData || !surveyData.title || !surveyData.questionIDs) {
          console.warn(`Survey data for ID ${surveyID} is invalid.`);
          continue;
        }
        surveyData.id = surveyID;

        if (!surveySet.has(surveyID)) {
          surveySet.add(surveyID);
          userSubmittedSurveys.push(surveyData);
          console.log(`Added survey to list: ID=${surveyID}, Data=`, surveyData);
        }
      }

      // Optionally, update state with fetched surveys if needed
      // this.setState({ surveys: userSubmittedSurveys, loading: false });
      console.log('Fetched surveys:', userSubmittedSurveys);
    } catch (error) {
      console.error("Error fetching surveys:", error);
    }
  };

  clearCache = () => {
    console.log('Clearing cache');
    localStorage.removeItem('surveyCache');
    this.updateCache(() => ({
      surveyIDs: [],
      questionIDs: {},
      arweaveContent: {},
    }));
    console.log('Cache cleared. Reloading initial data...');
    this.loadInitialData();
  };

  render() {
    return (
      <div id={styles.surveySelectorRow}>
        <SurveySelector
          surveyID={this.props.surveyID}
          displayAnswerMode={this.props.displayAnswerMode}
          viewAddress={this.props.viewAddress}
          account={this.props.account}
          provider={this.props.provider}
          network={this.props.network}
          toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
          loginComplete={this.props.loginComplete}
          cache={this.state.cache}
          updateCache={this.updateCache}
          // Pass new props to SurveySelector
          questionID={this.props.questionID}
          responderAddress={this.props.responderAddress}
          singleQuestionMode={this.props.singleQuestionMode}
        />
        {/* <Button onClick={this.clearCache} style={{ marginLeft: '10px' }}>
          Clear Cache
        </Button> */}
      </div>
    );
  }
}

class SurveySelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surveys: [],
      selectedSurveyIndex: null,
      pubKey: '',
      createSurveyMode: false,
      demoMode: false,
      showResults: false,
      loading: true,
      filterModalOpen: false,
      viewMode: 'questions',
      selectedTypes: [],
      filteredQuestionCount: 0, // Added this line
      currentPath: window.location.pathname,
    };
  }

  componentDidMount() {
    if (!this.props.singleQuestionMode) {
      // window.history.pushState({}, '', `/questions`); // Want to default to <base_url>/questions, but this interferes with SurveyResults mode loading
      this.handleUrlBasedView();
      this.fetchSurveys();
      this.computeFilteredQuestionCount();
      window.addEventListener('popstate', this.handleUrlChange);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handleUrlChange);
  }


  componentDidUpdate(prevProps, prevState) {

    if (prevProps.cache.questionIDs !== this.props.cache.questionIDs) {
      this.computeFilteredQuestionCount();
    }

    if (!this.props.singleQuestionMode) {
      if (window.location.pathname !== prevState.currentPath) {
        this.handleUrlBasedView();
        this.setState({ currentPath: window.location.pathname });
      }
    }

    if (!this.props.singleQuestionMode) {
      if (this.props.surveyID !== prevProps.surveyID) {
        this.updateSelectedSurvey();
      }
      // Only fetch surveys if cache changes significantly
      const cacheChanged =
        this.props.cache.surveyIDs.length !== prevProps.cache.surveyIDs.length ||
        Object.keys(this.props.cache.arweaveContent).length !== Object.keys(prevProps.cache.arweaveContent).length;

      if (cacheChanged) {
        this.fetchSurveys();
      }
    }
  }  

  computeFilteredQuestionCount = () => {
    const { cache } = this.props;
    const totalQuestions = cache.questionIDs ? cache.questionIDs.length : 0;
    this.setState({ filteredQuestionCount: totalQuestions });
  };

  handleUrlChange = () => {
    this.handleUrlBasedView();
    // Optionally force update or call setState if needed
  };

  
  handleUrlBasedView = () => {
    const path = window.location.pathname;
    if (path === '/surveys' || path.includes('/survey/')) {
      this.setState({ viewMode: 'surveys' });
    } else if (path === '/questions') {
      this.setState({ viewMode: 'questions' });
    }
  };

  selectOption = (option) => {
    if (option === 'questions') {
      this.setState({ viewMode: 'questions' }, () => {
        window.history.pushState({}, '', '/questions');
      });
    } else {
      this.setState({ viewMode: 'surveys' }, () => {
        this.selectSurvey(option);
        const newSurveyId = this.state.surveys[option]?.id;
        if (newSurveyId) {
          window.history.pushState({}, '', `/survey/${newSurveyId}`);
        } else {
          window.history.pushState({}, '', '/surveys');
        }
      });
    }
  };

  updateFilteredQuestionCount = (count) => {
    this.setState({ filteredQuestionCount: count });
  };
  

  handleUpdateSelectedTypes = (selectedTypes) => {
    this.setState({ selectedTypes });
  };
  

  updatePubKey = (newPubkey) => {
    this.setState({ pubKey: newPubkey });
  };

  fetchSurveys = async () => {
    console.log('Fetching surveys');
    if (!this.state.demoMode) {
      try {
        const surveyIDs = this.props.cache.surveyIDs;
        console.log('Using cached survey IDs:', surveyIDs);
        const userSubmittedSurveys = [];
        const surveySet = new Set();

        for (let surveyID of surveyIDs) {
          if (!surveyID) continue; // Skip undefined IDs
          const surveyData = await this.getSurveyData(surveyID);
          if (!surveyData || !surveyData.title || !surveyData.questionIDs) {
            console.warn(`Survey data for ID ${surveyID} is invalid.`);
            continue;
          }
          surveyData.id = surveyID;

          if (!surveySet.has(surveyID)) {
            surveySet.add(surveyID);
            userSubmittedSurveys.push(surveyData);
            console.log(`Added survey to list: ID=${surveyID}, Data=`, surveyData);
          }
        }

        this.setState({ surveys: userSubmittedSurveys, loading: false }, this.updateSelectedSurvey);
        console.log('Fetched surveys:', userSubmittedSurveys);
      } catch (error) {
        console.error("Error fetching surveys:", error);
        this.setState({ loading: false });
      }
    } else {
      this.setState({ surveys: [], loading: false }, this.updateSelectedSurvey);
    }
  }

  getSurveyData = async (surveyID) => {
    console.log('Getting survey data for ID:', surveyID);
    let surveyData = this.props.cache.arweaveContent[surveyID];

    if (!surveyData) {
      surveyData = await contractScripts.getSurveyDataById(this.props.provider, surveyID);
      if (!surveyData) {
        console.warn(`No survey data found for ID ${surveyID}`);
        return null;
      }
      // Fetch question data and include in surveyData
      surveyData.questions = [];
      for (let questionID of surveyData.questionIDs) {
        let questionData = await contractScripts.getQuestionData(this.props.provider, questionID);
        questionData.id = questionID; // Ensure each question has its ID
        surveyData.questions.push(questionData);
      }

      // Update cache
      this.props.updateCache(prevCache => ({
        ...prevCache,
        arweaveContent: {
          ...prevCache.arweaveContent,
          [surveyID]: surveyData
        }
      }));
    }

    return surveyData;
  };

  updateSelectedSurvey = () => {
    const { surveyID } = this.props;
    const { surveys } = this.state;
  
    const path = window.location.pathname;
    if (path === '/surveys') {
      this.setState({ selectedSurveyIndex: null });
      return;
    }
  
    const selectedIndex = surveys.findIndex(survey => survey.id === surveyID);
  
    if (selectedIndex !== -1) {
      this.setState({ selectedSurveyIndex: selectedIndex });
      console.log(`Selected survey index updated to ${selectedIndex}`);
    } else if (surveys.length > 0 && path == '/surveys') {
      this.setState({ selectedSurveyIndex: 0 }, () => {
        console.log('No matching survey ID found. Defaulting to first survey.');
        this.updateURL(surveys[0].id);
      });
    } else {
      this.setState({ selectedSurveyIndex: null });
    }
  }  
  
  selectSurvey = (index) => {
    this.setState({ selectedSurveyIndex: index }, () => {
      const newSurveyId = this.state.surveys[index].id;
      console.log(`Survey selected: Index=${index}, ID=${newSurveyId}`);
      window.history.pushState({}, '', `/survey/${newSurveyId}`);
      if (this.props.onSurveyChange) {
        this.props.onSurveyChange(newSurveyId);
      }
    });
  };

  updateURL = (surveyId) => {
    if (this.props.displayAnswerMode && this.props.viewAddress) {
      window.history.pushState({}, '', `/survey/${surveyId}/${this.props.viewAddress}`);
    } else {
      window.history.pushState({}, '', `/survey/${surveyId}`);
    }
    if (this.props.onSurveyChange) {
      this.props.onSurveyChange(surveyId);
    }
    console.log(`URL updated to /survey/${surveyId}${this.props.viewAddress ? `/${this.props.viewAddress}` : ''}`);
  }

  updatePubKey = (newPubkey) => {
    this.setState({ pubKey: newPubkey });
  };

  toggleFilterModal = () => {
    this.setState(prevState => ({ filterModalOpen: !prevState.filterModalOpen }));
  };  

  toggleCreateMode = () => {
    this.setState({ createSurveyMode: !this.state.createSurveyMode }, () => {
      console.log(`Create survey mode toggled to ${this.state.createSurveyMode}`);
    });
  };

  toggleShowResults = () => {
    this.setState({ showResults: !this.state.showResults }, () => {
      console.log(`Show results toggled to ${this.state.showResults}`);
    });
  };

  toggleShowQuestions = () => {
    this.setState(prevState => ({ showQuestions: !prevState.showQuestions }));
  };

  copySurveyIdToClipboard = (surveyID = null) => {
    const { surveys, selectedSurveyIndex } = this.state;
    let idToCopy = surveyID;
  
    // If surveyID is not passed in, check if it's available in the URL
    if (!idToCopy) {
      const urlParams = new URLSearchParams(window.location.search);
      idToCopy = urlParams.get('surveyID');
    }
  
    // If still no ID, use the selected survey ID from the state
    if (!idToCopy && selectedSurveyIndex !== null && surveys[selectedSurveyIndex]) {
      idToCopy = surveys[selectedSurveyIndex].id;
    }
  
    if (idToCopy) {
      navigator.clipboard.writeText(idToCopy)
        .then(() => {
          console.log('Survey ID copied to clipboard:', idToCopy);
          // Update state to reflect successful copy
          this.setState({ copySurveyIdSuccess: true });
          setTimeout(() => {
            this.setState({ copySurveyIdSuccess: false });
          }, 2000);
        })
        .catch(err => {
          console.error('Could not copy survey ID:', err);
        });
    } else {
      console.error('No survey ID available to copy.');
    }
  };  

  handleUpdateSelectedTypes = (selectedTypes) => {
    this.setState({ selectedTypes });
  };
  

  render() {
    const { surveys, selectedSurveyIndex, loading, viewMode, createSurveyMode, filterModalOpen, selectedTypes, filteredQuestionCount } = this.state;
    const selectedSurvey = selectedSurveyIndex !== null && surveys[selectedSurveyIndex] ? surveys[selectedSurveyIndex] : null;  
    const { surveyAddedSuccessfully, surveyHash, title, questions, isSubmitting, progress, showJson, isStandaloneQuestion, submissionError } = this.state;
    const surveyID = isStandaloneQuestion ? '' : surveyHash;

    // // Calculate filtered question count based on selected types
    // const filteredQuestionCount = this.props.cache.questionIDs ? 
    //   this.props.cache.questionIDs.filter(id => {
    //     const question = this.props.cache.arweaveContent[id];
    //     if (!question) return false;
    //     if (selectedTypes && selectedTypes.length > 0) {
    //       return selectedTypes.includes(question.type);
    //     }
    //     return true;
    //   }).length : 0;

      const dropdownTitle = loading 
      ? 'Loading...'
      : viewMode === 'questions'
        ? `Questions (${this.state.filteredQuestionCount})`
        : selectedSurvey
          ? selectedSurvey.title
          : 'Select survey';    

    const createSurveyComponent = (
      <CreateSurvey
        account={this.props.account}
        loginComplete={this.props.loginComplete}
        provider={this.props.provider}
        toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
        expanded={this.state.createSurveyMode}
        surveys={surveys}
        surveyIndex={selectedSurveyIndex}
        cache={this.props.cache}
        updateCache={this.updateCache}
      />
    );

    // Adjust the rendering based on singleQuestionMode
    if (this.props.singleQuestionMode) {
      return (
        <SurveyQuestions
          account={this.props.account}
          provider={this.props.provider}
          toggleLoginModal={this.props.toggleLoginModal}
          loginComplete={this.props.loginComplete}
          cache={this.props.cache}
          updateCache={this.props.updateCache}
          singleQuestionMode={this.props.singleQuestionMode}
          questionID={this.props.questionID}
          responderAddress={this.props.responderAddress}
          pubKey={this.state.pubKey}
          updatePubKey={(newPubkey) => this.updatePubKey(newPubkey)}
        />
      );
    }

    return (
      <div>
        <div id={styles.surveysRow}>
          <UncontrolledDropdown id={styles.surveysDropdown}>
            <DropdownToggle id={styles.dropdownToggle}>
              {loading ? (
                <>
                  <FontAwesomeIcon icon={faSpinner} spin /> {'Loading...'}
                </>
              ) : (
                <>
                  {dropdownTitle}
                  <FontAwesomeIcon icon={faCaretDown} id={styles.dropdownToggleCaret} />
                  {selectedSurvey && viewMode !== 'questions' && (
                    <Button className={styles.copySurveyIdButton} onClick={() => this.copySurveyIdToClipboard(selectedSurvey.id)}>
                      <FontAwesomeIcon icon={faClipboard} />
                    </Button>
                  )}
                  {viewMode === 'questions' && (
                    <Button id={styles.filterButton} onClick={this.toggleFilterModal}>
                      <FontAwesomeIcon icon={faFilter} />
                    </Button>
                  )}
                </>
              )}
            </DropdownToggle>
            {!loading && (
              <DropdownMenu id={styles.dropdownMenu}>
                <DropdownItem 
                  className={`${styles.dropdownItem} ${styles.questionsItem}`}
                  onClick={() => this.selectOption('questions')}
                  active={viewMode === 'questions'}
                >
                  Questions ({filteredQuestionCount})
                </DropdownItem>
                {surveys.map((survey, index) => (
                  <DropdownItem 
                    className={`${styles.dropdownItem} ${styles.surveyItem}`}
                    key={index} 
                    onClick={() => this.selectOption(index)}
                    active={viewMode !== 'questions' && selectedSurveyIndex === index}
                  >
                    {survey.title}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            )}
          </UncontrolledDropdown>
  
          <button id={styles.createSurveyButton} onClick={this.toggleCreateMode}>
            {this.state.createSurveyMode ? 'Exit Survey Creation' : 'Add Question / Survey'}
          </button>

          <Button onClick={this.toggleShowResults} id={styles.showResultsButton}>
            Results
          </Button>

        </div>
        
        {this.state.createSurveyMode ? createSurveyComponent : null}
  
        {selectedSurvey && viewMode !== 'questions' ? (
          <SurveyQuestions
            displayAnswerMode={this.props.displayAnswerMode}
            viewAddress={this.props.viewAddress}
            account={this.props.account}
            provider={this.props.provider}
            toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
            surveys={surveys}
            loginComplete={this.props.loginComplete}
            pubKey={this.state.pubKey}
            updatePubKey={(newPubkey) => this.updatePubKey(newPubkey)}
            surveyIndex={this.state.selectedSurveyIndex}
            surveyID={selectedSurvey.id}
            cache={this.props.cache}
            updateCache={this.props.updateCache}
          />
        ) : null}
  
        {this.state.showResults ? (
          <SurveyResults
            provider={this.props.provider}
            surveyId={selectedSurvey?.id}
            onClose={this.toggleShowResults}
            cache={this.props.cache}
            updateCache={this.props.updateCache}
            viewMode={viewMode}
          />
        ) : null}
  
          {viewMode === 'questions' && (
          <QuestionsDashboard
            account={this.props.account}
            provider={this.props.provider}
            toggleLoginModal={this.props.toggleLoginModal}
            loginComplete={this.props.loginComplete}
            cache={this.props.cache}
            updateCache={this.props.updateCache}
            network={this.props.network}
            onFilteredQuestionCountUpdate={this.updateFilteredQuestionCount}
            filterModalOpen={this.state.filterModalOpen}
            toggleFilterModal={this.toggleFilterModal}
          />
        )}
      </div>
    );
  }
}

class QuestionsDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: [],
      filteredQuestions: [],
      filterLoading: false,
    };
  }

  componentDidMount() {
    this.loadQuestions();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.cache !== this.props.cache) {
      this.loadQuestions();
    }
  }

  loadQuestions = () => {
    const { cache } = this.props;
    let questions = cache.questionIDs.map(questionId => {
      const questionData = cache.arweaveContent[questionId];
      if (!questionData) {
        return null;
      }
      return {
        id: questionId,
        ...questionData,
        creator: questionData.creator || '',
      };
    }).filter(q => q !== null);

    this.setState({
      questions,
      filteredQuestions: questions, // Initialize filteredQuestions with all questions
    }, () => {
      // Update the filtered question count
      if (this.props.onFilteredQuestionCountUpdate) {
        this.props.onFilteredQuestionCountUpdate(questions.length);
      }
    });
  };

  handleFilteredQuestions = (filteredQuestions) => {
    this.setState({ filteredQuestions }, () => {
      if (this.props.onFilteredQuestionCountUpdate) {
        this.props.onFilteredQuestionCountUpdate(filteredQuestions.length);
      }
    });
  };

  setFilterLoading = (loading) => {
    this.setState({ filterLoading: loading });
  };

  render() {
    const { filteredQuestions, filterLoading } = this.state;

    return (
      <div className={styles.questionsDashboard}>
        <div className={styles.questionsHeader}>
          {/* You can uncomment this if you want a filter button outside the modal */}
          {/* <Button id={styles.filterButton} onClick={this.props.toggleFilterModal}>
            FILTER QUESTIONS <FontAwesomeIcon icon={faFilter} />
          </Button> */}
        </div>

        <QuestionFilter
          filterModalOpen={this.props.filterModalOpen}
          toggleFilterModal={this.props.toggleFilterModal}
          questions={this.state.questions}
          questionResponses={this.props.cache.questionResponses}
          provider={this.props.provider}
          network={this.props.network}
          onFilter={this.handleFilteredQuestions}
          setFilterLoading={this.setFilterLoading}
        />

        {filterLoading ? (
          <div className={styles.loadingContainer}>
            <FontAwesomeIcon icon={faSpinner} spin size="2x" />
            <p>Applying filter...</p>
          </div>
        ) : (
          <SurveyQuestions
            account={this.props.account}
            provider={this.props.provider}
            toggleLoginModal={this.props.toggleLoginModal}
            loginComplete={this.props.loginComplete}
            cache={this.props.cache}
            updateCache={this.props.updateCache}
            questionPool={filteredQuestions}
            isStandalone={true}
          />
        )}
      </div>
    );
  }
}

class SurveyQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surveysResponseState: [],
      displayAnswerMode: this.props.displayAnswerMode,
      viewAddressAnswers: '',
      noResponse: false,
      userHasResponse: false,
      userResponseEncrypted: false,
      startFresh: false,
      userAnswers: null,
      isDecrypting: false,
      jsonPreview: '',
      isEditing: false,
      isSubmitting: false,
      submitProgress: 0,
      submissionComplete: false,
      responseUrl: '',
      submissionError: '',
      currentStep: 0,
      questionPool: this.props.isStandalone || this.props.singleQuestionMode ? this.props.questionPool || [] : [],
      showJson: false,
      showQuestionsJson: false,
      showResponseJson: false,
      copiedQuestionsJson: false,
      copiedResponseJson: false,
      isLoadingResponse: false,
      parsedViewAddressAnswers: null,
    };
  this.bottomRef = React.createRef();
  this.topRef = React.createRef();
  }

  initializeSurveyResponseState = () => {
    if (this.props.singleQuestionMode) {
      // Initialize state for single question mode
      const questionId = this.props.questionID;
      let initialAnswers = {};
      let initialAdditionalThoughts = {};
  
      if (this.props.questionPool && this.props.questionPool.length > 0) {
        // Use questionPool to initialize
        this.props.questionPool.forEach((question) => {
          initialAnswers[question.id] = { value: '', encrypted: false, encryptedPortion: '', hash: '' };
          initialAdditionalThoughts[question.id] = { value: '', encrypted: false, encryptedPortion: '', hash: '' };
        });
      } else if (questionId) {
        initialAnswers[questionId] = { value: '', encrypted: false, encryptedPortion: '', hash: '' };
        initialAdditionalThoughts[questionId] = { value: '', encrypted: false, encryptedPortion: '', hash: '' };
      }
  
      return [
        {
          answers: initialAnswers,
          importance: {},
          additionalComments: initialAdditionalThoughts,
        },
      ];
    } else if (this.props.isStandalone) {
      // Existing logic for standalone mode
      let initialAnswers = {};
      let initialAdditionalThoughts = {};
  
      if (this.props.questionPool && Array.isArray(this.props.questionPool)) {
        this.props.questionPool.forEach((question) => {
          initialAnswers[question.id] = { value: '', encrypted: false, encryptedPortion: '', hash: '' };
          initialAdditionalThoughts[question.id] = { value: '', encrypted: false, encryptedPortion: '', hash: '' };
        });
      }
  
      return [
        {
          answers: initialAnswers,
          importance: {},
          additionalComments: initialAdditionalThoughts,
        },
      ];
    } else {
      // Original logic for surveys with multiple questions
      const surveyIndex = this.props.surveyIndex;
      let initialAnswers = {};
      let initialAdditionalThoughts = {};
  
      if (this.state.questionPool && Array.isArray(this.state.questionPool)) {
        this.state.questionPool.forEach((question) => {
          initialAnswers[question.id] = { value: '', encrypted: false, encryptedPortion: '', hash: '' };
          initialAdditionalThoughts[question.id] = { value: '', encrypted: false, encryptedPortion: '', hash: '' };
        });
      }
  
      const newSurveysResponseState = [...this.state.surveysResponseState || []];
  
      // Ensure the array is long enough
      while (newSurveysResponseState.length <= surveyIndex) {
        newSurveysResponseState.push(null);
      }
  
      newSurveysResponseState[surveyIndex] = {
        answers: initialAnswers,
        importance: {},
        additionalComments: initialAdditionalThoughts,
      };
  
      return newSurveysResponseState;
    }
  };
  

  async componentDidMount() {
    if (this.props.singleQuestionMode) {
      await this.fetchSingleQuestionData();
      if (this.props.responderAddress) {
        this.setState({ displayAnswerMode: true });
      }
      this.updateJsonPreview();
    } else if (!this.props.isStandalone) {
      await this.fetchQuestionPool();
      // Initialize surveysResponseState after questionPool is fetched
      this.setState(
        {
          surveysResponseState: this.initializeSurveyResponseState(),
        },
        async () => {
          await this.fetchSurveyResponse();
          this.checkAndHandleStartFresh();
        }
      );
    } else {
      this.setState({ jsonPreview: this.prepareJsonAndHash(0) });
    }
  }
  
  
  async componentDidUpdate(prevProps, prevState) {
    if (this.props.singleQuestionMode) {
      if (
        prevProps.questionID !== this.props.questionID ||
        prevProps.responderAddress !== this.props.responderAddress
      ) {
        await this.fetchSingleQuestionData();
        this.updateJsonPreview();
      }
      if (this.props.account !== prevProps.account) {
        await this.fetchSingleQuestionData();
        this.updateJsonPreview();
  
        if (this.state.userHasResponse) {
          // Automatically switch to editing mode
          this.setState({
            displayAnswerMode: false,
            isEditing: true,
          });
        }
      }
      if (prevState.questionPool !== this.state.questionPool) {
        this.setState({
          surveysResponseState: this.initializeSurveyResponseState(),
        });
      }
    } else if (this.props.isStandalone) {
      if (prevProps.questionPool !== this.props.questionPool) {
        this.setState(
          (prevState) => ({
            questionPool: this.props.questionPool || [],
            surveysResponseState: this.mergeSurveyResponseState(
              prevState.surveysResponseState,
              this.props.questionPool
            ),
          }),
          () => {
            this.setState({ jsonPreview: this.prepareJsonAndHash(0) });
          }
        );
      }
    } else {
      if (this.props.surveyID !== prevProps.surveyID) {
        await this.fetchQuestionPool();
        // Initialize surveysResponseState after questionPool is fetched
        this.setState(
          {
            surveysResponseState: this.initializeSurveyResponseState(),
          },
          async () => {
            await this.fetchSurveyResponse();
            this.checkAndHandleStartFresh();
          }
        );
      }
      if (
        this.props.account !== prevProps.account ||
        this.props.viewAddress !== prevProps.viewAddress
      ) {
        await this.fetchSurveyResponse();
      }
    }
  }

  
  checkAndHandleStartFresh = () => {
    if (!this.props.viewAddress && !this.state.userHasResponse) {
      this.handleStartFresh();
    }
  };

  async fetchQuestionPool() {
    console.log('Fetching question pool');
    if (this.props.isStandalone) {
      this.setState({ questionPool: this.props.questionPool || [] });
    } else {
      const { surveyID, cache } = this.props;
      const survey = cache.arweaveContent[surveyID];
  
      if (!survey) {
        console.error('Survey not found in cache');
        return;
      }
  
      const questionPool = await Promise.all(
        survey.questionIDs.map(async (questionId) => {
          let questionData = cache.arweaveContent[questionId];
  
          if (!questionData) {
            questionData = await contractScripts.getQuestionData(this.props.provider, questionId);
            // Update cache
            this.props.updateCache((prevCache) => ({
              ...prevCache,
              arweaveContent: {
                ...prevCache.arweaveContent,
                [questionId]: questionData,
              },
            }));
          }
  
          return { ...questionData, id: questionId };
        })
      );
  
      this.setState(
        { questionPool },
        () => {
          // Initialize surveysResponseState after questionPool is set
          this.setState({
            surveysResponseState: this.initializeSurveyResponseState(),
          });
        }
      );
    }
  }  

  fetchSingleQuestionData = async () => {
    this.setState({ isLoadingResponse: true });
    const questionId = this.props.questionID;
    const responderAddress = this.props.responderAddress;
    const { cache } = this.props;
  
    // Fetch question data if not in cache
    let questionData = cache.arweaveContent[questionId];
  
    if (!questionData) {
      questionData = await contractScripts.getQuestionData(this.props.provider, questionId);
      if (questionData) {
        this.props.updateCache((prevCache) => ({
          ...prevCache,
          arweaveContent: {
            ...prevCache.arweaveContent,
            [questionId]: questionData,
          },
        }));
      } else {
        console.warn(`Question data not found for ID: ${questionId}`);
      }
    }
  
    this.setState(
      { questionPool: [{ ...questionData, id: questionId }] },
      async () => {
        // Initialize surveysResponseState after questionPool is set
        this.setState(
          {
            surveysResponseState: this.initializeSurveyResponseState(),
          },
          async () => {
            // Fetch responses for the question
            if (responderAddress) {
              // Fetch the responder's answer
              const response = await this.getQuestionResponse(responderAddress, questionId);
              if (response) {
                this.setState({
                  viewAddressAnswers: JSON.stringify(response),
                  parsedViewAddressAnswers: response,
                  noResponse: false,
                });
              } else {
                this.setState({
                  viewAddressAnswers: '',
                  parsedViewAddressAnswers: null,
                  noResponse: true,
                });
              }
            } else {
              // If no responderAddress, reset viewing state
              this.setState({
                viewAddressAnswers: '',
                parsedViewAddressAnswers: null,
                noResponse: false,
                displayAnswerMode: false,
              });
            }
  
            // Fetch current user's answer
            if (this.props.account) {
              const userAnswer = await this.getQuestionResponse(this.props.account, questionId);
              if (userAnswer) {
                const hasEncryptedPortion =
                  userAnswer.answer.encryptedPortion && userAnswer.answer.encryptedPortion !== '';
                this.setState({
                  userHasResponse: true,
                  userResponseEncrypted: hasEncryptedPortion,
                  startFresh: false,
                  userAnswers: userAnswer,
                });
                this.prefillSingleQuestionResponse(userAnswer);
              } else {
                this.setState({
                  userHasResponse: false,
                  userResponseEncrypted: false,
                  userAnswers: null,
                });
              }
            }
  
            this.updateJsonPreview();
            this.setState({ isLoadingResponse: false });
          }
        );
      }
    );
  };

  mergeSurveyResponseState = (currentState, newQuestionPool) => {
    const surveyIndex = 0;
    const currentSurveyResponse = currentState[surveyIndex] || {
      answers: {},
      importance: {},
      additionalComments: {}
    };
  
    const newAnswers = { ...currentSurveyResponse.answers };
    const newAdditionalComments = { ...currentSurveyResponse.additionalComments };
    const newImportance = { ...currentSurveyResponse.importance };
  
    newQuestionPool.forEach((question) => {
      const questionId = question.id;
      if (!(questionId in newAnswers)) {
        newAnswers[questionId] = { value: '', encrypted: false, encryptedPortion: '', hash: '' };
      }
      if (!(questionId in newAdditionalComments)) {
        newAdditionalComments[questionId] = { value: '', encrypted: false, encryptedPortion: '', hash: '' };
      }
    });
  
    return [{
      answers: newAnswers,
      importance: newImportance,
      additionalComments: newAdditionalComments
    }];
  };
  
  async fetchSurveyResponse() {
    this.setState({ isLoadingResponse: true });

    if (this.props.displayAnswerMode && this.props.viewAddress) {
      try {
        const viewAnswers = await this.getSurveyResponse(
          this.props.viewAddress,
          this.props.surveyID
        );
        if (viewAnswers) {
          this.setState({
            viewAddressAnswers: JSON.stringify(viewAnswers),
            parsedViewAddressAnswers: viewAnswers, // store parsed data
            noResponse: false,
          });
        } else {
          this.setState({
            viewAddressAnswers: '',
            parsedViewAddressAnswers: null,
            noResponse: true,
          });
        }
      } catch (error) {
        console.error('Error fetching survey response:', error);
        this.setState({
          viewAddressAnswers: '',
          parsedViewAddressAnswers: null,
          noResponse: true,
        });
      }
    } else {
      this.setState({
        viewAddressAnswers: '',
        parsedViewAddressAnswers: null,
        noResponse: false,
      });
    }

    if (this.props.account) {
      try {
        const userAnswers = await this.getSurveyResponse(this.props.account, this.props.surveyID);
        if (userAnswers) {
          const hasEncryptedPortion = userAnswers.responses.some(
            (r) => r.answer.encryptedPortion && r.answer.encryptedPortion !== ''
          );
          this.setState({
            userHasResponse: true,
            userResponseEncrypted: hasEncryptedPortion,
            startFresh: false,
            userAnswers: userAnswers,
          });
          this.prefillSurveyResponses(userAnswers);
        } else {
          this.setState({
            userHasResponse: false,
            userResponseEncrypted: false,
            userAnswers: null,
          });
        }
      } catch (error) {
        console.error("Error fetching user's survey response:", error);
        this.setState({
          userHasResponse: false,
          userResponseEncrypted: false,
          userAnswers: null,
        });
      }
    }

    this.setState({ isLoadingResponse: false });
  } 
  
  getQuestionResponse = async (responderAddress, questionId) => {
    console.log("getQuestionResponse() - invoked with questionId:", questionId);

    const questionAnswer = await contractScripts.getResponse(this.props.provider, responderAddress, questionId);

    console.log("questionAnswer: ", questionAnswer);

    return questionAnswer;
  };
  
  prefillSingleQuestionResponse = (userAnswer) => {
    const surveyIndex = 0;
    const newSurveysResponseState = [...this.state.surveysResponseState];
    const currentSurvey = newSurveysResponseState[surveyIndex];
  
    const questionId = this.props.questionID;
    currentSurvey.answers[questionId] = {
      value: this.parseAnswerValue(userAnswer.answer.value),
      encrypted: userAnswer.answer.encrypted,
      hash: userAnswer.answer.hash || '',
      encryptedPortion: userAnswer.answer.encryptedPortion || ''
    };
    currentSurvey.importance[questionId] = userAnswer.importance;
    currentSurvey.additionalComments[questionId] = {
      value: this.parseAnswerValue(userAnswer.additional.value),
      encrypted: userAnswer.additional.encrypted,
      hash: userAnswer.additional.hash || '',
      encryptedPortion: userAnswer.additional.encryptedPortion || ''
    };
  
    this.setState({
      surveysResponseState: newSurveysResponseState,
    }, () => {
      this.updateJsonPreview();
    });
  };

  // Updated prefillSurveyResponses to include encryptedPortion
  prefillSurveyResponses = (userAnswers) => {
    const newSurveysResponseState = [...this.state.surveysResponseState];
    const currentSurvey = newSurveysResponseState[this.props.surveyIndex];

    userAnswers.responses.forEach((response) => {
      const questionId = response.questionID;
      currentSurvey.answers[questionId] = {
        value: this.parseAnswerValue(response.answer.value),
        encrypted: response.answer.encrypted,
        hash: response.answer.hash || '',
        encryptedPortion: response.answer.encryptedPortion || ''
      };
      currentSurvey.importance[questionId] = response.importance;
      currentSurvey.additionalComments[questionId] = {
        value: this.parseAnswerValue(response.additional.value),
        encrypted: response.additional.encrypted,
        hash: response.additional.hash || '',
        encryptedPortion: response.additional.encryptedPortion || ''
      };
    });

    this.setState({
      surveysResponseState: newSurveysResponseState,
      jsonPreview: this.prepareJsonAndHash(this.props.surveyIndex)
    });
  };

  parseAnswerValue = (value) => {
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  };

  handleStartFresh = () => {
    const freshState = this.initializeSurveyResponseState();
    this.setState({
      startFresh: true,
      surveysResponseState: freshState,
      displayAnswerMode: false,
      userHasResponse: false,
      userResponseEncrypted: false,
      isEditing: false,
    }, () => {
            this.updateJsonPreview();
    });
  };

  handleDecryptEdit = async () => {
    this.setState({ isDecrypting: true });
    try {
      await this.decryptMultipleAnswers();
      this.setState({
        startFresh: false,
        displayAnswerMode: false,
        isEditing: true,
        isDecrypting: false,
      }, () => {
        // Update JSON preview after decryption
        const jsonPreview = this.prepareJsonAndHash(this.props.surveyIndex);
        this.setState({ jsonPreview });
      });
    } catch (error) {
      console.error("Error decrypting answers:", error);
      this.setState({ isDecrypting: false });
    }
  };
  
  handleAnswer = (surveyIndex, questionId, answer) => {
    surveyIndex = this.props.isStandalone || this.props.singleQuestionMode ? 0 : surveyIndex;
    const newSurveysResponseState = [...this.state.surveysResponseState];
    let answerStr;
  
    if (Array.isArray(answer) || typeof answer === "number") {
      answerStr = JSON.stringify(answer);
    } else {
      answerStr = answer;
    }
  
    newSurveysResponseState[surveyIndex] = newSurveysResponseState[surveyIndex] || {
      answers: {},
      importance: {},
      additionalComments: {}
    };
  
    newSurveysResponseState[surveyIndex].answers[questionId] = {
      ...newSurveysResponseState[surveyIndex].answers[questionId],
      value: answer
    };
  
    const question = this.state.questionPool.find(q => q.id === questionId);
    const isBinaryQuestion = question && question.type === 'binary';
    if (!(Array.isArray(answer)) && typeof answer !== "number" && !isBinaryQuestion) {
      const newAnswerHash = utils.keccak256(utils.toUtf8Bytes(answerStr));
      newSurveysResponseState[surveyIndex].answers[questionId].hash = newAnswerHash;
    }
  
    this.setState({
      surveysResponseState: newSurveysResponseState,
      isEditing: true
    }, () => {
      this.updateJsonPreview();
    });
  };    
  
  handleAdditional = (surveyIndex, questionId, additionalComments) => {
    surveyIndex = this.props.isStandalone || this.props.singleQuestionMode ? 0 : surveyIndex;
    const newSurveysResponseState = [...this.state.surveysResponseState];
    newSurveysResponseState[surveyIndex].additionalComments[questionId] = {
      ...newSurveysResponseState[surveyIndex].additionalComments[questionId],
      value: additionalComments
    };
  
    const newAnswer = newSurveysResponseState[surveyIndex].additionalComments[questionId].value;
    const newAnswerHash = utils.keccak256(utils.toUtf8Bytes(newAnswer));
    newSurveysResponseState[surveyIndex].additionalComments[questionId].hash = newAnswerHash;
    this.setState({
      surveysResponseState: newSurveysResponseState,
      isEditing: true
    });
  };
  
  handleImportance = (surveyIndex, questionId, importance) => {
    surveyIndex = this.props.isStandalone || this.props.singleQuestionMode ? 0 : surveyIndex;
    const newSurveysResponseState = [...this.state.surveysResponseState];
    newSurveysResponseState[surveyIndex].importance[questionId] = importance;
    this.setState({
      surveysResponseState: newSurveysResponseState,
      isEditing: true
    });
  }; 
  

  toggleShowJson = () => {
    this.setState(prevState => ({ showJson: !prevState.showJson }));
  };

  toggleDisplayAnswerMode = () => {
    this.setState(
      prevState => ({
        displayAnswerMode: !prevState.displayAnswerMode,
        isEditing: !prevState.displayAnswerMode, // Set isEditing to true when switching to fill out mode
      }),
      async () => {
        if (this.state.displayAnswerMode) {
          // Now in viewingAnswers mode
          if (this.props.singleQuestionMode && this.props.responderAddress) {
            await this.fetchSingleQuestionData();
          } else if (this.props.viewAddress) {
            await this.fetchSurveyResponse();
          }
        } else {
          // Exiting viewingAnswers mode
          this.setState({ parsedViewAddressAnswers: null });
        }
        this.updateJsonPreview();
      }
    );
  };
  
  handleUpdateResponse = async () => {
    if (this.state.isEditing) {
      await this.encryptAndUpload();
      this.setState({ isEditing: false, userHasResponse: true, userResponseEncrypted: true });
    } else {
      this.handleDecryptEdit();
    }
  };

  handleShowJsonAtBottom = () => {
    if (!this.state.showJson) {
      this.setState({ showJson: true }, () => {
        if (this.bottomRef.current) {
          this.bottomRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      });
    } else {
      if (this.bottomRef.current) {
        this.bottomRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  handleScrollToTop = () => {
    if (!this.state.showJson) {
      this.setState({ showJson: true }, () => {
        if (this.topRef.current) {
          this.topRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      });
    } else {
      if (this.topRef.current) {
        this.topRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  toggleAnswerEncryption = (surveyIndex, questionId, newEncryptedState) => {
    surveyIndex = this.props.isStandalone || this.props.singleQuestionMode ? 0 : surveyIndex;
    const newSurveysResponseState = [...this.state.surveysResponseState];
    if (newSurveysResponseState[surveyIndex].answers[questionId]) {
      newSurveysResponseState[surveyIndex].answers[questionId].encrypted = newEncryptedState;
      this.setState({ surveysResponseState: newSurveysResponseState });
    }
  };   
   

  toggleAdditionalCommentsEncryption = (surveyIndex, questionId, newEncryptedState) => {
    surveyIndex = this.props.isStandalone || this.props.singleQuestionMode ? 0 : surveyIndex;
    const newSurveysResponseState = [...this.state.surveysResponseState];
    if (newSurveysResponseState[surveyIndex].additionalComments[questionId]) {
      newSurveysResponseState[surveyIndex].additionalComments[questionId].encrypted = newEncryptedState;
      this.setState({ surveysResponseState: newSurveysResponseState });
    }
  };
  

  getSurveyResponse = async (responderAddress, surveyID) => {
    console.log("getSurveyResponse() - invoked with surveyID:", surveyID);

    const surveyAnswers = await contractScripts.getSurveyResponse(this.props.provider, responderAddress, surveyID);

    console.log("surveyAnswers: ", surveyAnswers);

    return surveyAnswers;
  };

  prepareJsonAndHash = (surveyIndex, responderAddress) => {
    surveyIndex = this.props.isStandalone || this.props.singleQuestionMode ? 0 : surveyIndex;
  
    const surveyHash = this.props.isStandalone
      ? undefined
      : this.props.singleQuestionMode
      ? undefined
      : this.props.surveyID;
    const surveyResponseState = this.state.surveysResponseState[surveyIndex];
    const surveyQuestions = this.state.questionPool;
  
    if (!surveyQuestions || !surveyResponseState) {
      return null;
    }
  
    const answeredQuestions =
      this.props.isStandalone || this.props.singleQuestionMode
        ? surveyQuestions.filter((question) => {
            const questionId = question.id;
            const answer = surveyResponseState.answers[questionId];
            return answer && answer.value !== '';
          })
        : surveyQuestions;
  
    const responses = answeredQuestions.map((question) => {
      const questionId = question.id;
      const answer = surveyResponseState.answers[questionId] || {};
      const additional = surveyResponseState.additionalComments[questionId] || {};
      const importance = surveyResponseState.importance[questionId];
  
      let responseItem = {
        questionID: questionId,
        prompt: question.prompt,
        type: question.type,
        responder: responderAddress || this.props.account,
        importance: importance !== undefined ? importance : null,
        answer: {
          value: answer.value,
          encrypted: answer.encrypted,
          hash: answer.hash || '',
          encryptedPortion: answer.encryptedPortion || '',
        },
        additional: {
          value: additional.value,
          encrypted: additional.encrypted,
          hash: additional.hash || '',
          encryptedPortion: additional.encryptedPortion || '',
        },
      };
  
      return responseItem;
    });
  
    if (this.props.singleQuestionMode) {
      // For single question mode, include timeStamp in the response
      if (responses.length > 0) {
        const singleResponse = {
          timeStamp: Date.now(),
          ...responses[0],
        };
        return singleResponse;
      } else {
        return {};
      }
    }
  
    const response = {
      ...(surveyHash !== undefined && { surveyID: surveyHash }),
      responder: responderAddress || this.props.account,
      timeStamp: Date.now(),
      responses: responses,
    };
  
    return response;
  };  
  
  
  updateJsonPreview = () => {
    this.setState({
      jsonPreview: this.prepareJsonAndHash(this.props.surveyIndex)
    });
  };

  processJsonToTree = (json, level = 0) => {
    let output = [];

    if (json === null || json === undefined) {
      return output;
    }

    if (Array.isArray(json)) {
      json.forEach((item, index) => {
        if (item !== null && typeof item === 'object') {
          output.push({ type: 'arrayItem', key: index, level });
          output = [...output, ...this.processJsonToTree(item, level + 1)];
        } else {
          output.push({ type: 'arrayItemValue', key: index, value: item, level });
        }
      });
    } else if (typeof json === 'object') {
      Object.keys(json).forEach((key) => {
        if (json[key] !== null && typeof json[key] === 'object') {
          output.push({ type: 'objectKey', key, level });
          output = [...output, ...this.processJsonToTree(json[key], level + 1)];
        } else {
          output.push({ type: 'objectKeyValue', key, value: json[key], level });
        }
      });
    }
    return output;
  };

  jsonTreeDisplay = (jsonInput) => {
    let jsonObject;
    if (!jsonInput) {
      console.error("Invalid input for jsonTreeDisplay: Input is null or undefined");
      return null;
    }
    if (typeof jsonInput === 'string') {
      try {
        jsonObject = JSON.parse(jsonInput);
      } catch (e) {
        console.error("Invalid JSON string:", e);
        return null;
      }
    } else if (typeof jsonInput === 'object') {
      jsonObject = jsonInput;
    } else {
      console.error("Invalid input for jsonTreeDisplay: Expected string or object, got", typeof jsonInput);
      return null;
    }
    if (!jsonObject) {
      return null;
    }
    const treeData = this.processJsonToTree(jsonObject);
  
    return (
      <ul className={styles.tree}>
        {treeData.map((node, index) => (
          <li
            key={index}
            className={styles.treeItem}
            style={{ marginLeft: `${node.level * 20}px` }}
          >
            <span className={styles.keyValueContainer}>
              {node.type === 'arrayItemValue' && (
                <span>[{node.key}]: {String(node.value)}</span>
              )}
              {node.type === 'objectKeyValue' && (
                <span>{node.key}: {String(node.value)}</span>
              )}
              {node.type === 'arrayItem' && <span>[{node.key}]</span>}
              {node.type === 'objectKey' && <span>{node.key}:</span>}
              {node.type === 'value' && <span>{String(node.value)}</span>}
            </span>
          </li>
        ))}
      </ul>
    );
  };
   

  getQuestionsJson = () => {
    const questionsJson = this.state.questionPool;
    const questions = questionsJson.filter(q => q.type !== undefined);
    return questions;
  };
  
  getResponseJson = () => {
    if (this.props.singleQuestionMode) {
      // For single question mode
      if (this.props.viewAddress || this.props.responderAddress) {
        // If viewing someone else's response
        return this.state.parsedViewAddressAnswers || {};
      } else {
        // Get the current response for the single question
        const surveyIndex = 0;
        const currentSurveyResponseState = this.state.surveysResponseState[surveyIndex];
        const question = this.state.questionPool[0];
        
        if (!question || !currentSurveyResponseState) {
          return {};
        }
  
        const questionId = question.id;
        const answer = currentSurveyResponseState.answers[questionId] || {};
        const additional = currentSurveyResponseState.additionalComments[questionId] || {};
        const importance = currentSurveyResponseState.importance[questionId];
  
        return {
          questionID: questionId,
          prompt: question.prompt,
          type: question.type,
          responder: this.props.account,
          timeStamp: Date.now(),
          importance: importance !== undefined ? importance : null,
          answer: {
            value: answer.value || '',
            encrypted: answer.encrypted || false,
            hash: answer.hash || '',
            encryptedPortion: answer.encryptedPortion || '',
          },
          additional: {
            value: additional.value || '',
            encrypted: additional.encrypted || false,
            hash: additional.hash || '',
            encryptedPortion: additional.encryptedPortion || '',
          },
        };
      }
    } else if (this.props.viewAddress || this.props.responderAddress) {
      // If viewing someone else's response
      return this.state.parsedViewAddressAnswers || {};
    } else {
      // Return own response
      return this.prepareJsonAndHash(this.props.surveyIndex);
    }
  };
  
  copyJsonToClipboard = (json, type) => {
    let jsonToUse = json;
    
    // Special handling for empty or undefined json
    if (!jsonToUse || (typeof jsonToUse === 'object' && Object.keys(jsonToUse).length === 0)) {
      if (this.props.singleQuestionMode) {
        // Get fresh data for single question mode
        jsonToUse = this.getResponseJson();
      }
    }
  
    // Don't copy if we still have no valid JSON
    if (!jsonToUse || Object.keys(jsonToUse).length === 0) {
      console.warn('No valid JSON data to copy');
      return;
    }
  
    const jsonString = JSON.stringify(jsonToUse, null, 2);
    navigator.clipboard.writeText(jsonString).then(() => {
      if (type === 'questions') {
        this.setState({ copiedQuestionsJson: true });
        setTimeout(() => {
          this.setState({ copiedQuestionsJson: false });
        }, 2000);
      } else if (type === 'response') {
        this.setState({ copiedResponseJson: true });
        setTimeout(() => {
          this.setState({ copiedResponseJson: false });
        }, 2000);
      }
    }).catch(error => {
      console.error('Failed to copy JSON to clipboard:', error);
    });
  };
  
  
  toggleShowQuestionsJson = () => {
    this.setState((prevState) => ({ showQuestionsJson: !prevState.showQuestionsJson }));
  };
  
  toggleShowResponseJson = () => {
    this.setState((prevState) => ({ showResponseJson: !prevState.showResponseJson }));
  };

  // Encryption and decryption methods

  getPublicKey = async () => {
    var provider;
    const address = this.props.account;

    if (this.props.provider == "Torus") {
      provider = window.torusProvider
    }
    else { // NOTE: Specify Metamask
      provider = window.ethereum;
    }

    // const signer = provider.getSigner();

    const pubkey = await provider.request({
      "method": "eth_getEncryptionPublicKey",
      "params": [
        address
      ]
    });

    console.log("pubkey: " + pubkey)
    return pubkey;
  };

  encryptDataForPublicKey = async (publicKey, data) => { // 
    // console.log("publicKey: " + publicKey)

    // hardcoded argument: https://github.com/MetaMask/eth-sig-util/blob/67a95bab3f414ed8a5718fcb4beccc6088c0db0e/src/encryption.ts#L40C10-L40C36
    const encrypted = await encrypt({ publicKey: publicKey, data: data, version: 'x25519-xsalsa20-poly1305' });

    // console.log("encrypted: ")
    // console.log(encrypted)

    // formatting is particular for the eth_decrypt functionality: 
    // https://github.com/ethers-io/ethers.js/issues/4139#issuecomment-1615971987
    // https://metamask.github.io/test-dapp/#getEncryptionKeyButton --> https://github.com/MetaMask/test-dapp/blob/261be4fe4b9f7b774064c090faa617d7b756c3d5/src/index.js#L1320
    const encryptedFormatted = `0x${Buffer.from(JSON.stringify(encrypted), 'utf8').toString('hex')}`


    return encryptedFormatted;
  };

  decryptData = async (encryptedData) => {
    // Note: Works for Metamask / Wagmi, would need change if integrating other providers
    const provider = this.props.provider == "Torus" ? window.torusProvider : window.ethereum;

    const hexCypher = encryptedData;

    try {
      const unencryptedData = await provider.request({
        "method": "eth_decrypt",
        "params": [
          hexCypher,
          this.props.account, // address (whose public key data is encrypted for)
        ]
      });

      console.log("unencrypted: " + unencryptedData)
      return unencryptedData;
    } catch (error) {
      console.log("Error - Data Decryption: ")
      console.log(error);
    }
  };

  encryptMultipleAnswers = async (pubKey) => {
    return new Promise(async (resolve, reject) => {
      var surveyIndex =
        this.props.isStandalone || this.props.singleQuestionMode ? 0 : this.props.surveyIndex;
      var currentSurvey = this.state.surveysResponseState[surveyIndex];
  
      for (let questionId in currentSurvey.answers) {
        const answer = currentSurvey.answers[questionId];
        const additional = currentSurvey.additionalComments[questionId];
  
        if (answer.encrypted && !answer.encryptedPortion && answer.value !== '*') {
          let dataToEncrypt =
            typeof answer.value === 'string' ? answer.value : JSON.stringify(answer.value);
          const encryptedData = await this.encryptDataForPublicKey(pubKey, dataToEncrypt);
          answer.encryptedPortion = encryptedData;
          answer.value = '*';
        }
  
        if (
          additional &&
          additional.encrypted &&
          !additional.encryptedPortion &&
          additional.value !== '*'
        ) {
          let dataToEncrypt =
            typeof additional.value === 'string'
              ? additional.value
              : JSON.stringify(additional.value);
          const encryptedData = await this.encryptDataForPublicKey(pubKey, dataToEncrypt);
          additional.encryptedPortion = encryptedData;
          additional.value = '*';
        }
      }
  
      const newsurveysResponseState = [...this.state.surveysResponseState];
      newsurveysResponseState[surveyIndex] = currentSurvey;
  
      this.setState({ surveysResponseState: newsurveysResponseState }, () => {
        resolve();
      });
    });
  };  

  decryptMultipleAnswers = async () => {
    var surveyIndex = this.props.isStandalone || this.props.singleQuestionMode ? 0 : this.props.surveyIndex;
    var currentSurvey = this.state.surveysResponseState[surveyIndex];
  
    for (let questionId in currentSurvey.answers) {
      const answer = currentSurvey.answers[questionId];
      const additional = currentSurvey.additionalComments[questionId];
  
      if (answer.encryptedPortion) {
        try {
          const decryptedData = await this.decryptData(answer.encryptedPortion);
          let data;
          try {
            data = JSON.parse(decryptedData);
          } catch (e) {
            data = decryptedData;
          }
          answer.value = data;
          answer.encryptedPortion = '';
  
          const answerStr = Array.isArray(data) || typeof data === "number" ? JSON.stringify(data) : data;
          const question = this.state.questionPool.find(q => q.id === questionId);
          const isBinaryQuestion = question && question.type === 'binary';
          if (!(Array.isArray(data)) && typeof data !== "number" && !isBinaryQuestion) {
            const newAnswerHash = utils.keccak256(utils.toUtf8Bytes(answerStr));
            answer.hash = newAnswerHash;
          }
        } catch (error) {
          console.error("Error decrypting data for question", questionId, error);
        }
      }
  
      if (additional && additional.encryptedPortion) {
        try {
          const decryptedData = await this.decryptData(additional.encryptedPortion);
          let data;
          try {
            data = JSON.parse(decryptedData);
          } catch (e) {
            data = decryptedData;
          }
          additional.value = data;
          additional.encryptedPortion = '';
  
          const additionalStr = data;
          const newAdditionalHash = utils.keccak256(utils.toUtf8Bytes(additionalStr));
          additional.hash = newAdditionalHash;
        } catch (error) {
          console.error("Error decrypting additional data for question", questionId, error);
        }
      }
    }
  
    const updatedsurveysResponseState = [...this.state.surveysResponseState];
    updatedsurveysResponseState[surveyIndex] = currentSurvey;
  
    this.setState(
      { surveysResponseState: updatedsurveysResponseState },
      () => {
        console.log("Successfully decrypted all answers.");
      }
    );
  };  
  
  handleDecryptQuestionAnswer = async (questionId, fieldToDecrypt = 'both') => {
    const surveyIndex =
      this.props.isStandalone || this.props.singleQuestionMode ? 0 : this.props.surveyIndex;
    const currentSurvey = this.state.surveysResponseState[surveyIndex];
  
    if (!currentSurvey) {
      console.error('Current survey response state is undefined.');
      return;
    }
  
    const answer = currentSurvey.answers[questionId];
    const additional = currentSurvey.additionalComments[questionId];
  
    if (!answer && !additional) {
      console.error(`No answer or additional comments found for question ID: ${questionId}`);
      return;
    }
  
    try {
      if (fieldToDecrypt === 'answer' || fieldToDecrypt === 'both') {
        if (answer && answer.encryptedPortion) {
          const decryptedAnswer = await this.decryptData(answer.encryptedPortion);
          let data;
          try {
            data = JSON.parse(decryptedAnswer);
          } catch (e) {
            data = decryptedAnswer;
          }
          answer.value = data;
          answer.encryptedPortion = '';
  
          const answerStr = Array.isArray(data) || typeof data === 'number' ? JSON.stringify(data) : data;
          const question = this.state.questionPool.find((q) => q.id === questionId);
          const isBinaryQuestion = question && question.type === 'binary';
          if (!(Array.isArray(data)) && typeof data !== 'number' && !isBinaryQuestion) {
            const newAnswerHash = utils.keccak256(utils.toUtf8Bytes(answerStr));
            answer.hash = newAnswerHash;
          }
        }
      }
  
      if (fieldToDecrypt === 'additional' || fieldToDecrypt === 'both') {
        if (additional && additional.encryptedPortion) {
          const decryptedAdditional = await this.decryptData(additional.encryptedPortion);
          let data;
          try {
            data = JSON.parse(decryptedAdditional);
          } catch (e) {
            data = decryptedAdditional;
          }
          additional.value = data;
          additional.encryptedPortion = '';
  
          const additionalStr = data;
          const newAdditionalHash = utils.keccak256(utils.toUtf8Bytes(additionalStr));
          additional.hash = newAdditionalHash;
        }
      }
  
      const updatedSurveysResponseState = [...this.state.surveysResponseState];
      updatedSurveysResponseState[surveyIndex] = currentSurvey;
  
      this.setState({ surveysResponseState: updatedSurveysResponseState }, () => {
        console.log(`Successfully decrypted ${fieldToDecrypt} for question ID: ${questionId}`);
        const jsonPreview = this.prepareJsonAndHash(surveyIndex);
        this.setState({ jsonPreview });
      });
    } catch (error) {
      console.error(
        `Error decrypting ${fieldToDecrypt} for question ID: ${questionId}`,
        error
      );
      this.setState({
        submissionError: `Failed to decrypt ${fieldToDecrypt} for question ID: ${questionId}`,
      });
    }
  };
  
  
  encryptData = async (pubKey) => {
    console.log("encryptData() - invoked");
    if (!this.props.loginComplete) {
      const open = true;
      this.props.toggleLoginModal(open);
      return;
    }
  
    try {
      await this.encryptMultipleAnswers(pubKey);
  
      const jsonData = this.prepareJsonAndHash(this.props.surveyIndex);
      console.log("encrypted jsonData:", jsonData);
  
      this.setState({ jsonPreview: JSON.stringify(jsonData, null, 2) });
    } catch (error) {
      console.error("Encryption error:", error);
      this.setState({ submissionError: error.message || 'Encryption failed.' });
    }
  };
  
  encryptAndUpload = async () => {
    if (!this.props.loginComplete) {
      const open = true;
      this.props.toggleLoginModal(open);
      return;
    }
  
    this.setState({ isSubmitting: true, submitProgress: 0, currentStep: 1 });
  
    try {
      const hasEncrypted = this.hasEncryptedAnswers();
  
      if (hasEncrypted) {
        let pubKey = this.props.pubKey;
        if (this.props.pubKey === '') {
          pubKey = await this.getPublicKey();
          this.props.updatePubKey(pubKey);
        }
        await this.encryptData(pubKey);
        await this.verifyEncryption();
      }
  
      this.setState({ currentStep: 2 });
  
      await this.submitSurveyResponse();
  
      this.setState({
        isSubmitting: false,
        submitProgress: 100,
        submissionComplete: true,
        currentStep: 3,
      });
  
      const responseUrl = `${window.location.origin}/survey/${this.props.surveyID || ''}/${this.props.account}`;
      this.setState({ responseUrl });
  
    } catch (error) {
      console.error("Failed to submit survey:", error);
      this.setState({ isSubmitting: false, submitProgress: 0, submissionError: error.message });
    }
  };  
   
  handleExitEditing = () => {
    this.setState({
      isEditing: false,
    });
  };

  verifyEncryption = async () => {
    console.log("Verifying encryption...");
    return true;
  };

  submitSurveyResponse = async () => {
    if (!this.props.loginComplete) {
      const open = true;
      this.props.toggleLoginModal(open);
      return;
    }
  
    const surveyIndex = this.props.isStandalone || this.props.singleQuestionMode ? 0 : this.props.surveyIndex;
    const surveyAnswersData = this.prepareJsonAndHash(surveyIndex);
    console.log("surveyAnswersData:", surveyAnswersData);
  
    let questionIds, questionResponses, surveyID, surveyResponse;
  
    if (this.props.singleQuestionMode) {
      // Single question mode
      if (!surveyAnswersData || !surveyAnswersData.questionID) {
        console.log("No responses to submit.");
        this.setState({
          isSubmitting: false,
          submitProgress: 0,
          submissionError: "No responses to submit.",
        });
        return;
      }
      questionIds = [surveyAnswersData.questionID];
      questionResponses = [surveyAnswersData];
      surveyID = ethers.constants.HashZero; // For single question, surveyID is HashZero
      surveyResponse = null; // No surveyResponse for single question
    } else {
      if (
        !surveyAnswersData ||
        !surveyAnswersData.responses ||
        surveyAnswersData.responses.length === 0
      ) {
        console.log("No responses to submit.");
        this.setState({
          isSubmitting: false,
          submitProgress: 0,
          submissionError: "No responses to submit.",
        });
        return;
      }
      questionIds = surveyAnswersData.responses.map((r) => r.questionID);
      questionResponses = surveyAnswersData.responses;
      surveyID = this.props.isStandalone ? ethers.constants.HashZero : this.props.surveyID;
      surveyResponse = this.props.isStandalone ? null : surveyAnswersData;
    }
  
    try {
      const response = await contractScripts.submitResponses(
        this.props.provider,
        questionIds,
        questionResponses,
        surveyID,
        surveyResponse
      );
  
      console.log("response:", response);
      if (response.status === 1) {
        console.log("Survey response submitted successfully!");
        this.setState({
          isSubmitting: false,
          submitProgress: 100,
          submissionComplete: true,
          currentStep: 3,
        });
      } else {
        console.log("Error submitting survey response.");
        this.setState({
          isSubmitting: false,
          submitProgress: 0,
          submissionError: "Submission failed.",
        });
      }
    } catch (error) {
      console.error("Failed to submit survey:", error);
      this.setState({
        isSubmitting: false,
        submitProgress: 0,
        submissionError: error.message,
      });
    }
  };
   

  renderQuestion = (question, qIndex, currentSurveyResponseState) => {
    if (!question || !question.id || !question.type) {
      console.error('Invalid question data at index:', qIndex);
      console.error("question:");
      console.error(question);
      return null;
    }
  
    if (!currentSurveyResponseState) {
      console.warn('currentSurveyResponseState is undefined in renderQuestion');
      return null;
    }
  
    const surveyIndex = this.props.isStandalone || this.props.singleQuestionMode ? 0 : this.props.surveyIndex;
    const answer = currentSurveyResponseState.answers[question.id] || { value: '', encrypted: false };
    
    // Check if the answer is encrypted and needs decryption
    if (answer.encrypted && answer.value === '*') {
      return (
        <Card key={question.id}>
          <CardBody id={styles.questionTitleBody}>
            <h4 id={styles.questionTitle}>
              {question.prompt}
            </h4>
            <p>This answer is encrypted. Please decrypt to view and edit.</p>
            <Button onClick={() => this.handleDecryptQuestionAnswer(question.id)} id={styles.decryptQuestionButton}>
              Decrypt Answer
            </Button>
          </CardBody>
        </Card>
      );
    } else {
      let questionComponent;

      switch (question.type) {
        case 'multichoice':
          questionComponent = (
            <FormGroup id={styles.multiChoice}>
              {question.options.map((option, oIndex) => (
                <Label check key={oIndex} className={styles.checkboxOptionText}>
                  <Input
                    type="checkbox"
                    name={`question-${question.id}`}
                    value={option}
                    onChange={(e) => {
                      const currentAnswer = currentSurveyResponseState.answers[question.id]?.value || [];
                      const newAnswer = [...currentAnswer];
                      if (e.target.checked) {
                        newAnswer.push(option);
                      } else {
                        const index = newAnswer.indexOf(option);
                        if (index > -1) {
                          newAnswer.splice(index, 1);
                        }
                      }
                      this.handleAnswer(surveyIndex, question.id, newAnswer);
                    }}
                    checked={currentSurveyResponseState.answers[question.id]?.value?.includes(option) || false}
                  />
                  {option}
                </Label>
              ))}
            </FormGroup>
          );
          break;
        case 'rating':
          questionComponent = (
            <>
              <div className={styles.importanceSlider}>
                <Slider
                  min={0}
                  max={10}
                  step={1}
                  value={currentSurveyResponseState.answers[question.id]?.value || 0}
                  tooltip={false}
                  onChange={(ratingAnswer) => this.handleAnswer(surveyIndex, question.id, ratingAnswer)}
                  id={styles.ratingSlider}
                  style={{ width: '200px' }}
                />
              </div>
              <FormText id={styles.ratingLabelText}>
                {currentSurveyResponseState.answers[question.id]?.value || 0}
              </FormText>
            </>
          );
          break;
        case 'binary':
          questionComponent = (
            <FormGroup id={styles.binaryChoice}>
              {['Agree', 'Unsure', 'Disagree'].map((option, oIndex) => (
                <Label check key={oIndex} className={`${styles.radioOptionText} ${styles[option.toLowerCase()]} ${currentSurveyResponseState.answers[question.id]?.value === option ? styles.selected : ''}`}>
                  <Input
                    type="radio"
                    name={`question-${question.id}`}
                    value={option}
                    checked={currentSurveyResponseState.answers[question.id]?.value === option || false}
                    onChange={() => this.handleAnswer(surveyIndex, question.id, option)}
                  />
                  {option === 'Agree' && <FontAwesomeIcon icon={faCheck} className={styles.optionIcon} />}
                  {option === 'Disagree' && <FontAwesomeIcon icon={faTimes} className={styles.optionIcon} />}
                  {option}
                </Label>
              ))}
            </FormGroup>
          );
          break;
        default: // 'freeform'
          questionComponent = (
            <AudioInput
              qIndex={qIndex}
              placeholder={'response (optional)'}
              updateFunction={(answer) => this.handleAnswer(surveyIndex, question.id, answer)}
              toggleEncryption={(newEncryptedState) => this.toggleAnswerEncryption(surveyIndex, question.id, newEncryptedState)}
              value={currentSurveyResponseState.answers[question.id]?.value || ''}
              encrypted={currentSurveyResponseState.answers[question.id]?.encrypted || false}
            />
          );
          break;
      }

      return (
        <Card key={question.id}>
          <CardBody id={styles.questionTitleBody}>
            <h4 id={styles.questionTitle}>
              {question.prompt}
            </h4>
            <InputGroup>
              {questionComponent}
              {question.type !== 'freeform' && (
                <InputGroupAddon addonType="append" id={styles.encryptAdditionalThoughts}>
                  <div id={styles.encryptOptionButton} onClick={() => this.toggleAnswerEncryption(surveyIndex, question.id, !currentSurveyResponseState.answers[question.id]?.encrypted)}>
                    <FontAwesomeIcon id={styles.encryptIcon} icon={currentSurveyResponseState.answers[question.id]?.encrypted ? faLock : faUnlock} />
                    <InputGroupText id={styles.inputGroupTextBox}>
                      <Input
                        addon
                        type="checkbox"
                        aria-label="encrypt"
                        checked={currentSurveyResponseState.answers[question.id]?.encrypted || false}
                        onChange={() => this.toggleAnswerEncryption(surveyIndex, question.id, !currentSurveyResponseState.answers[question.id]?.encrypted)}
                        id={styles.encryptCheckbox}
                      />
                      Encrypt
                    </InputGroupText>
                  </div>
                </InputGroupAddon>
              )}
            </InputGroup>
            <div className={styles.importanceSlider}>
              <h6 id={styles.importanceText}>Importance: {currentSurveyResponseState.importance[question.id] || 0} </h6>
              <Slider
                min={0}
                max={10}
                step={1}
                value={currentSurveyResponseState.importance[question.id] || 0}
                tooltip={false}
                onChange={(importance) => this.handleImportance(surveyIndex, question.id, importance)}
              />
            </div>
            <AudioInput
              qIndex={qIndex}
              updateFunction={(additionalComments) => this.handleAdditional(surveyIndex, question.id, additionalComments)}
              toggleEncryption={(newEncryptedState) => this.toggleAdditionalCommentsEncryption(surveyIndex, question.id, newEncryptedState)}
              placeholder={'related thoughts or URLs (optional)'}
              value={currentSurveyResponseState.additionalComments[question.id]?.value || ''}
              encrypted={currentSurveyResponseState.additionalComments[question.id]?.encrypted || false}
            />
          </CardBody>
        </Card>
      );
    }
  };

  renderQuestionAnswer = (question, response, index, isOwnResponse) => {
    if (!question || !response) {
      console.warn('renderQuestionAnswer: question or response is undefined');
      return null;
    }
  
    const { prompt, type } = question;
  
    const surveyIndex =
      this.props.isStandalone || this.props.singleQuestionMode ? 0 : this.props.surveyIndex;
    const currentSurveyResponseState = this.state.surveysResponseState
      ? this.state.surveysResponseState[surveyIndex]
      : null;
  
    if (isOwnResponse && !currentSurveyResponseState) {
      console.warn('currentSurveyResponseState is undefined');
      return null;
    }
  
    // Get the answer from the current survey response state if it's the user's own response
    let answer, additional, importance;
    if (isOwnResponse) {
      const currentAnswer = currentSurveyResponseState.answers[question.id];
      const currentAdditional = currentSurveyResponseState.additionalComments[question.id];
      const currentImportance = currentSurveyResponseState.importance[question.id];
  
      answer = currentAnswer || response.answer || {};
      additional = currentAdditional || response.additional || {};
      importance =
        currentImportance !== undefined ? currentImportance : response.importance || 0;
    } else {
      answer = response.answer || {};
      additional = response.additional || {};
      importance = response.importance || 0;
    }
  
    // Parse answer.value and additional.value
    let answerValue = answer.value;
    if (typeof answerValue === 'string') {
      answerValue = this.parseAnswerValue(answerValue);
    }
    let additionalValue = additional.value;
    if (typeof additionalValue === 'string') {
      additionalValue = this.parseAnswerValue(additionalValue);
    }
  
    // Handle encrypted answers
    const isAnswerEncrypted = answer.encrypted && answer.value === '*';
    const isAdditionalEncrypted = additional.encrypted && additional.value === '*';
  
    // If isOwnResponse and answer is decrypted, render the question as editable
    if (isOwnResponse && !isAnswerEncrypted) {
      return this.renderQuestion(question, index, currentSurveyResponseState);
    }
  
    // Otherwise, render the answer as usual
    return (
      <Card key={index}>
        <CardBody id={styles.questionTitleBody}>
          <h4 id={styles.questionTitle}>{prompt}</h4>
          {isAnswerEncrypted ? (
            isOwnResponse ? (
              <div>
                <p>This answer is encrypted.</p>
                <Button
                  onClick={() => this.handleDecryptQuestionAnswer(question.id, 'answer')}
                  id={styles.decryptQuestionButton}
                >
                  Decrypt Answer
                </Button>
              </div>
            ) : (
              <p>This answer is encrypted.</p>
            )
          ) : (
            this.renderAnswerByType(type, answerValue)
          )}
          {additional && additionalValue && (
            isAdditionalEncrypted ? (
              isOwnResponse ? (
                <div>
                  <p>Additional comments are encrypted.</p>
                  <Button
                    onClick={() =>
                      this.handleDecryptQuestionAnswer(question.id, 'additional')
                    }
                    id={styles.decryptQuestionButton}
                  >
                    Decrypt Additional Comments
                  </Button>
                </div>
              ) : (
                <p>Additional comments are encrypted.</p>
              )
            ) : (
              <div className={styles.additionalCommentsSection}>
                <strong className={styles.additionalCommentsLabel}>
                  Additional Comments:
                </strong>
                <p className={styles.additionalCommentsContent}>{additionalValue}</p>
              </div>
            )
          )}
          {/* Importance Slider */}
          <div className={styles.importanceSlider}>
            <h6 id={styles.importanceText}>Importance: {importance} </h6>
          </div>
        </CardBody>
      </Card>
    );
  };  
  
  renderAnswerByType = (type, value) => {
    switch (type) {
      case 'multichoice':
        if (!Array.isArray(value) || value.length === 0) {
          return <div>No answer provided.</div>;
        } else {
          return (
            <FormGroup id={styles.multiChoice}>
              {value.map((option, oIndex) => (
                <Label check key={oIndex} className={styles.checkboxOptionText}>
                  <Input
                    type="checkbox"
                    checked={true}
                    disabled
                  />
                  {option}
                </Label>
              ))}
            </FormGroup>
          );
        }
      case 'rating':
        return (
          <div className={styles.importanceSlider}>
            <FormText id={styles.ratingLabelText}>
              {value}
            </FormText>
            <Slider
              min={0}
              max={10}
              step={1}
              value={value}
              tooltip={false}
              disabled={true}
              id={styles.ratingSlider}
              style={{ width: '200px' }}
            />
          </div>
        );
      case 'binary':
        return (
          <FormGroup id={styles.binaryChoice}>
            {['Agree', 'Unsure', 'Disagree'].map((option, oIndex) => (
              <Label check key={oIndex} className={`${styles.radioOptionText} ${styles[option.toLowerCase()]} ${value === option ? styles.selected : ''}`}>
                <Input
                  type="radio"
                  checked={value === option}
                  disabled
                />
                {option === 'Agree' && <FontAwesomeIcon icon={faCheck} className={styles.optionIcon} />}
                {option === 'Disagree' && <FontAwesomeIcon icon={faTimes} className={styles.optionIcon} />}
                {option}
              </Label>
            ))}
          </FormGroup>
        );
      case 'freeform':
      default:
        return <div className={styles.freeformAnswer}>{value}</div>;
    }
  };  

  renderSingleQuestionAnswer = (question, response, isOwnResponse) => {
    if (!question || !response) {
      console.warn('renderSingleQuestionAnswer: question or response is undefined');
      return null;
    }
  
    const answer = response.answer || {};
    const additional = response.additional || {};
    const importance = response.importance !== undefined ? response.importance : 0;
  
    // Handle encrypted answers
    const isAnswerEncrypted = answer.encrypted && answer.value === '*';
    const isAdditionalEncrypted = additional.encrypted && additional.value === '*';
  
    // If isOwnResponse and answer is decrypted, render the question as editable
    if (isOwnResponse && !isAnswerEncrypted) {
      const surveyIndex = this.props.isStandalone || this.props.singleQuestionMode ? 0 : this.props.surveyIndex;
      const currentSurveyResponseState = this.state.surveysResponseState[surveyIndex];
      return this.renderQuestion(question, 0, currentSurveyResponseState);
    }
  
    // Otherwise, render the answer as usual
    return (
      <Card key={question.id}>
        <CardBody id={styles.questionTitleBody}>
          <h4 id={styles.questionTitle}>{question.prompt || 'Question'}</h4>
          {isAnswerEncrypted ? (
            isOwnResponse ? (
              <div>
                <p>This answer is encrypted.</p>
                <Button
                  onClick={() => this.handleDecryptQuestionAnswer(question.id, 'answer')}
                  id={styles.decryptQuestionButton}
                >
                  Decrypt Answer
                </Button>
              </div>
            ) : (
              <p>This answer is encrypted.</p>
            )
          ) : (
            this.renderAnswerByType(question.type, answer.value)
          )}
          {additional && additional.value && (
            isAdditionalEncrypted ? (
              isOwnResponse ? (
                <div>
                  <p>Additional comments are encrypted.</p>
                  <Button
                    onClick={() =>
                      this.handleDecryptQuestionAnswer(question.id, 'additional')
                    }
                    id={styles.decryptQuestionButton}
                  >
                    Decrypt Additional Comments
                  </Button>
                </div>
              ) : (
                <p>Additional comments are encrypted.</p>
              )
            ) : (
              <div className={styles.additionalCommentsSection}>
                <strong className={styles.additionalCommentsLabel}>Additional Comments:</strong>
                <p className={styles.additionalCommentsContent}>{additional.value}</p>
              </div>
            )
          )}
          {/* Importance Slider */}
          <div className={styles.importanceSlider}>
            <h6 id={styles.importanceText}>Importance: {importance} </h6>
          </div>
        </CardBody>
      </Card>
    );
  };
    
  
  hasEncryptedAnswers = () => {
    const surveyIndex = this.props.isStandalone || this.props.singleQuestionMode ? 0 : this.props.surveyIndex;
    const currentSurvey = this.state.surveysResponseState[surveyIndex];
  
    return (
      Object.values(currentSurvey.answers).some((answer) => answer.encrypted) ||
      Object.values(currentSurvey.additionalComments).some((additional) => additional.encrypted)
    );
  };
  

  renderSurveyAnswers = (responses, isOwnResponse) => {
    if (this.props.singleQuestionMode) {
      const question = this.state.questionPool[0];
      const response = responses;
      if (question && response) {
        return this.renderQuestionAnswer(question, response, 0, isOwnResponse);
      } else {
        return null;
      }
    } else {
      const questionMap = {};
      this.state.questionPool.forEach(q => {
        questionMap[q.id] = q;
      });
  
      return (
        <>
          {responses.map((response, index) => {
            const question = questionMap[response.questionID];
            if (question) {
              return this.renderQuestionAnswer(question, response, index, isOwnResponse);
            } else {
              return null;
            }
          })}
        </>
      );
    }
  };

  render() {
    const surveyIndex =
      this.props.isStandalone || this.props.singleQuestionMode ? 0 : this.props.surveyIndex;
    const currentSurveyResponseState =
      this.state.surveysResponseState && this.state.surveysResponseState.length > 0
        ? this.state.surveysResponseState[surveyIndex]
        : null;
  
    if (this.state.isLoadingResponse) {
      return (
        <div className={styles.loadingContainer}>
          <FontAwesomeIcon icon={faSpinner} spin /> Checking for user response...
        </div>
      );
    }
  
    if (!currentSurveyResponseState) {
      console.log('currentSurveyResponseState is undefined');
      console.log('surveysResponseState:', this.state.surveysResponseState);
      console.log('surveyIndex:', surveyIndex);
      const newSurveysResponseState = [...this.state.surveysResponseState];
      const currentSurvey = newSurveysResponseState[surveyIndex];
      console.log('currentSurvey:', currentSurvey);
      return <div>Loading...</div>;
    }
  
    const viewingAnswers = this.state.displayAnswerMode;
    const jsonPreview = this.prepareJsonAndHash(surveyIndex);
  
    let jsonDisplay = null;
    const notClickable = false;
    const shortenedViewAddress =
      this.props.viewAddress || this.props.responderAddress
        ? proposalScripts.getShortenedAddress(
            this.props.viewAddress || this.props.responderAddress,
            notClickable
          )
        : '';
  
    const isOwnResponse =
      (this.props.viewAddress &&
        this.props.account &&
        this.props.viewAddress.toLowerCase() === this.props.account.toLowerCase()) ||
      (this.props.responderAddress &&
        this.props.account &&
        this.props.responderAddress.toLowerCase() === this.props.account.toLowerCase()) ||
      (!this.props.viewAddress && this.props.account && this.state.userHasResponse);
  
    if (viewingAnswers) {
      if (this.state.noResponse) {
        jsonDisplay = this.jsonTreeDisplay({
          message:
            'No response for this ' +
            (this.props.singleQuestionMode ? 'question' : 'survey') +
            ' from address: ' +
            (this.props.viewAddress || this.props.responderAddress),
        });
      } else {
        if (isOwnResponse && jsonPreview) {
          jsonDisplay = this.jsonTreeDisplay(jsonPreview);
        } else if (this.state.parsedViewAddressAnswers) {
          jsonDisplay = this.jsonTreeDisplay(this.state.parsedViewAddressAnswers);
        } else {
          jsonDisplay = null;
        }
      }
    } else {
      if (jsonPreview) {
        jsonDisplay = this.jsonTreeDisplay(jsonPreview);
      } else {
        jsonDisplay = null;
      }
    }
  
    const exitEditingButton = this.state.isEditing ? (
      <Button onClick={this.handleExitEditing} id={styles.exitEditingButton}>
        Exit Editing
      </Button>
    ) : null;
  
    const viewAnswersButton =
      (this.props.viewAddress || this.props.responderAddress) && (!isOwnResponse || !this.state.isEditing) ? (
        <Button onClick={this.toggleDisplayAnswerMode} id={styles.answerSurveyButton}>
          <FontAwesomeIcon icon={viewingAnswers ? faPlus : faArrowLeft} id={styles.encryptIcon} />
          <div id={styles.surveyButtonText}>
            {viewingAnswers
              ? ` Fill out ${this.props.singleQuestionMode ? 'question' : 'survey'}`
              : ` View ${shortenedViewAddress} ${
                  this.props.singleQuestionMode ? 'answer' : 'answers'
                }`}
          </div>
        </Button>
      ) : null;
  
    let anyEncryptedAnswers = false;
    if (currentSurveyResponseState) {
      anyEncryptedAnswers =
        Object.values(currentSurveyResponseState.answers || {}).some((answer) => answer.encrypted) ||
        Object.values(currentSurveyResponseState.additionalComments || {}).some(
          (comment) => comment.encrypted
        );
    }
  
    const submitButtonText =
      this.state.isEditing && isOwnResponse
        ? `Update ${this.props.singleQuestionMode ? 'Answer' : 'Answers'}`
        : anyEncryptedAnswers
        ? 'Encrypt / Upload Responses'
        : 'Upload Responses';
  
    const userResponseNotice =
      this.state.userHasResponse && isOwnResponse ? (
        <div className={styles.userResponseNotice}>
          <p>
            Pre-existing {this.props.singleQuestionMode ? 'response' : 'survey response'} detected
          </p>
          <Button onClick={this.handleStartFresh} id={styles.startFreshButton}>
            Start Fresh
          </Button>
          <Button
            onClick={this.handleDecryptEdit}
            id={styles.decryptEditButton}
            disabled={this.state.isDecrypting}
          >
            {this.state.isDecrypting ? 'Decrypting...' : 'Decrypt / Edit All'}
          </Button>
          <Button
            onClick={this.encryptAndUpload}
            id={styles.updateResponseButton}
            disabled={!this.state.isEditing || this.state.isSubmitting}
          >
            {this.state.isSubmitting
              ? 'Updating...'
              : `Update ${this.props.singleQuestionMode ? 'Answer' : 'Answers'}`}
          </Button>
          {exitEditingButton}
        </div>
      ) : null;

      const submitResponseButton = (
        <div className={styles.footer} id={styles.surveyFooter}>
        <Button
          id={styles.submitSurveyButton}
          onClick={this.encryptAndUpload}
          disabled={this.state.isSubmitting}
        >
          {this.state.isSubmitting ? (
            <div
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <FontAwesomeIcon icon={faSpinner} spin style={{ marginRight: '10px' }} />
              {this.state.currentStep === 1 && this.hasEncryptedAnswers()
                ? 'Encrypting...'
                : 'Uploading...'}
            </div>
          ) : this.state.submissionComplete ? (
            <div
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />
              Response Submitted
            </div>
          ) : this.state.submissionError ? (
            <div
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <FontAwesomeIcon
                icon={faExclamationCircle}
                style={{ marginRight: '10px' }}
              />
              {this.state.submissionError}
            </div>
          ) : (
            submitButtonText
          )}
        </Button>
      </div>  
      );
  
    return (
      <div>
      <div ref={this.topRef}>
        {viewAnswersButton}
        {userResponseNotice}
        {viewingAnswers ? (
          this.state.noResponse ? (
            <div>
              No response for this{' '}
              {this.props.singleQuestionMode ? 'question' : 'survey'} from address:{' '}
              {this.props.viewAddress || this.props.responderAddress}
            </div>
          ) : (
            <div>
              {(this.props.viewAddress || this.props.responderAddress) && (
                <h2 className={styles.viewAddressHeading}>{shortenedViewAddress} Response:</h2>
              )}
              {this.props.singleQuestionMode ? (
                this.state.questionPool[0] &&
                (isOwnResponse || this.state.parsedViewAddressAnswers) ? (
                  this.renderQuestionAnswer(
                    this.state.questionPool[0],
                    isOwnResponse ? jsonPreview : this.state.parsedViewAddressAnswers,
                    0,
                    isOwnResponse
                  )
                ) : (
                  <div>No response to this question from this address.</div>
                )
              ) : (
                this.renderSurveyAnswers(
                  isOwnResponse
                    ? jsonPreview.responses || []
                    : this.state.parsedViewAddressAnswers
                    ? this.state.parsedViewAddressAnswers.responses || []
                    : [],
                  isOwnResponse
                )
              )}
            </div>
          )
        ) : (
          <>
            {(!this.state.userHasResponse || this.state.startFresh || this.state.isEditing) && (
              <>

              {/* duplicate so that people can see it at top / bottom */}
                {submitResponseButton}

                <Button className={styles.toggleJsonButton} onClick={this.handleShowJsonAtBottom}>
                  { 'View JSON' }
                </Button>

                {this.state.questionPool.map((question, qIndex) =>
                  this.renderQuestion(question, qIndex, currentSurveyResponseState)
                )}

                {submitResponseButton}

                <Button className={styles.toggleJsonButton} onClick={this.handleScrollToTop}>
                  { 'Back To Top' }
                </Button>

              </>
            )}
            {this.state.userHasResponse && !this.state.startFresh && !this.state.isEditing && (
              <div>
                {this.props.singleQuestionMode ? (
                  this.state.questionPool[0] ? (
                    this.renderQuestionAnswer(
                      this.state.questionPool[0],
                      this.state.userAnswers,
                      0,
                      isOwnResponse
                    )
                  ) : (
                    <div>Unable to load question.</div>
                  )
                ) : (
                  this.renderSurveyAnswers(this.state.userAnswers.responses, isOwnResponse)
                )}
              </div>
            )}
          </>
        )}
      </div>

      <div ref={this.bottomRef}>
        {this.props.isStandalone ? (
          <div className={styles.jsonButtonsContainer}>
            <Button className={styles.toggleJsonButton} onClick={this.toggleShowQuestionsJson}>
              {this.state.showQuestionsJson ? 'Hide Questions .json' : 'Show Questions .json'}
            </Button>
            <Button className={styles.toggleJsonButton} onClick={this.toggleShowResponseJson}>
              {this.state.showResponseJson ? 'Hide Response .json' : 'Show Response .json'}
            </Button>
          </div>
        ) : (
          <Button className={styles.toggleJsonButton} onClick={this.toggleShowJson}>
            {this.state.showJson ? 'Hide JSON' : 'Show JSON'}
            {/* { 'View JSON' } */}
          </Button>
        )}
  
        {this.props.isStandalone && this.state.showQuestionsJson && (
          <div className={styles.jsonContainer}>
            <Button
              className={styles.copyJsonButton}
              onClick={() => this.copyJsonToClipboard(this.getQuestionsJson(), 'questions')}
            >
              {this.state.copiedQuestionsJson ? (
                <FontAwesomeIcon icon={faCheck} color="green" />
              ) : (
                <FontAwesomeIcon icon={faClipboard} />
              )}
            </Button>
            <pre id={styles.responsePreview}>{this.jsonTreeDisplay(this.getQuestionsJson())}</pre>
          </div>
        )}
        {this.props.isStandalone && this.state.showResponseJson && (
          <div className={styles.jsonContainer}>
            <Button
              className={styles.copyJsonButton}
              onClick={() => this.copyJsonToClipboard(this.getResponseJson(), 'response')}
            >
              {this.state.copiedResponseJson ? (
                <FontAwesomeIcon icon={faCheck} color="green" />
              ) : (
                <FontAwesomeIcon icon={faClipboard} />
              )}
            </Button>
            <pre id={styles.responsePreview}>{this.jsonTreeDisplay(this.getResponseJson())}</pre>
          </div>
        )}
        {!this.props.isStandalone && this.state.showJson && (
          <div className={styles.jsonContainer}>
            <Button
              className={styles.copyJsonButton}
              onClick={() => this.copyJsonToClipboard(jsonPreview, 'response')}
            >
              {this.state.copiedResponseJson ? (
                <FontAwesomeIcon icon={faCheck} color="green" />
              ) : (
                <FontAwesomeIcon icon={faClipboard} />
              )}
            </Button>
            <pre id={styles.responsePreview}>
              {viewingAnswers && !isOwnResponse && this.state.parsedViewAddressAnswers
                ? this.jsonTreeDisplay(this.state.parsedViewAddressAnswers)
                : this.jsonTreeDisplay(jsonPreview)}
            </pre>
          </div>
        )}
      </div>
      </div>
    );
  }
  
}

export default SurveyTool;