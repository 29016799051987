import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import testData from './riskMatrixTestData.json';
import styles from './RiskMatrix.module.scss';

const categories = [
  {
    name: 'AI',
    subcategories: ['Machine Learning', 'Neural Networks', 'Robotics', 'Natural Language Processing', 'Computer Vision']
  },
  {
    name: 'Ethereum',
    subcategories: ['Smart Contracts', 'DeFi', 'NFTs', 'Layer 2 Solutions']
  },
  {
    name: 'Quantum Computers',
    subcategories: ['Quantum Algorithms', 'Quantum Cryptography', 'Quantum Sensors']
  },
  {
    name: 'Biotechnology',
    subcategories: ['Gene Editing', 'Synthetic Biology', 'Bioinformatics', 'Regenerative Medicine']
  },
  {
    name: 'BCI',
    subcategories: ['Invasive BCI', 'Non-invasive BCI', 'Neurofeedback']
  },
  {
    name: 'Robotics',
    subcategories: ['Industrial Robots', 'Service Robots', 'Autonomous Vehicles', 'Drones']
  },
  {
    name: 'Renewable Energy',
    subcategories: ['Solar', 'Wind', 'Hydroelectric', 'Geothermal', 'Biomass']
  },
  {
    name: 'Nuclear Weapons',
    subcategories: ['Fission Weapons', 'Fusion Weapons', 'Delivery Systems']
  },
  {
    name: 'Government',
    subcategories: ['Legislation', 'Regulation', 'Policy', 'International Relations']
  },
];

const MAX_HISTORY = 10;

const TechGrid = () => {
  const [modal, setModal] = useState(false);
  const [selectedCell, setSelectedCell] = useState('');
  const [existingComments, setExistingComments] = useState([]);
  const [valence, setValence] = useState('opportunity');
  const [comment, setComment] = useState('');
  const [intensity, setIntensity] = useState(5);
  const [url, setUrl] = useState('');
  const [heatmap, setHeatmap] = useState({});
  const [primaryGrid, setPrimaryGrid] = useState({ rows: categories, cols: categories });
  const [secondaryGrid, setSecondaryGrid] = useState({ rows: null, cols: null });
  const [history, setHistory] = useState([{ primary: { rows: categories, cols: categories }, secondary: { rows: null, cols: null }, selectedPrimaryRow: null, selectedPrimaryCol: null }]);
  const [historyIndex, setHistoryIndex] = useState(0);
  const [selectedPrimaryRow, setSelectedPrimaryRow] = useState(null);
  const [selectedPrimaryCol, setSelectedPrimaryCol] = useState(null);

  useEffect(() => {
    setHeatmap(loadCommentsFromTestData());
  }, []);

  const toggle = () => setModal(!modal);

  const handleCellClick = (rowCategory, rowSubcategory, colCategory, colSubcategory, isSecondary = false) => {
    const cellCoordinate = getCellCoordinate(
      isSecondary ? selectedPrimaryRow : rowCategory,
      rowSubcategory,
      isSecondary ? selectedPrimaryCol : colCategory,
      colSubcategory
    );
    const existingComments = getCommentsForCell(cellCoordinate);
    setSelectedCell(cellCoordinate);
    setExistingComments(existingComments);
    setModal(true);
  };

  const getCellCoordinate = (cat1, sub1, cat2, sub2) => {
    const sanitize = (str) => (str ? str.replace(/\s+/g, '') : 'Unknown');
    const parts = [
      { category: sanitize(cat1), subcategory: sanitize(sub1) },
      { category: sanitize(cat2), subcategory: sanitize(sub2) }
    ].sort((a, b) => a.category.localeCompare(b.category));

    return `${parts[0].category}:${parts[0].subcategory},${parts[1].category}:${parts[1].subcategory}`;
  };

  const getCommentsForCell = (cellCoordinate) => {
    return testData.filter((commentData) => {
      const dataCoordinate = getCellCoordinate(
        commentData.category1,
        commentData.subcategory1,
        commentData.category2,
        commentData.subcategory2
      );
      return dataCoordinate === cellCoordinate;
    });
  };

  const handleValenceChange = (event) => setValence(event.target.value);
  const handleCommentChange = (event) => setComment(event.target.value);
  const handleIntensityChange = (event) => setIntensity(parseInt(event.target.value));
  const handleUrlChange = (event) => setUrl(event.target.value);

  const saveCommentToSmartContract = (comment, valence, intensity, url) => {
    console.log('Saving comment:', comment, 'Valence:', valence, 'Intensity:', intensity, 'Cell:', selectedCell, 'URL:', url);
    // Here you would implement the logic to save the comment to your smart contract
    const newComment = { comment, valence, intensity, url };
    setExistingComments([...existingComments, newComment]);
    // Update heatmap
    setHeatmap(prevHeatmap => {
      const newHeatmap = { ...prevHeatmap };
      const valenceValue = valence === 'risk' ? -1 : 1;
      newHeatmap[selectedCell] = (newHeatmap[selectedCell] || 0) + valenceValue * intensity;
      return newHeatmap;
    });
  };

  function loadCommentsFromTestData() {
    const heatmap = {};

    testData.forEach((commentData) => {
      const cellCoordinate = getCellCoordinate(
        commentData.category1,
        commentData.subcategory1,
        commentData.category2,
        commentData.subcategory2
      );
      const valence = commentData.valence === 'risk' ? -1 : 1;
      heatmap[cellCoordinate] = (heatmap[cellCoordinate] || 0) + valence * commentData.intensity;
    });

    return heatmap;
  }

  const getColorByValence = (rowCategory, rowSubcategory, colCategory, colSubcategory) => {
    const cellCoordinate = getCellCoordinate(rowCategory, rowSubcategory, colCategory, colSubcategory);
    const valence = heatmap[cellCoordinate] || 0;
    if (valence === 0) return '#fff';
    return valence > 0
      ? `rgba(0, 255, 0, ${Math.min(Math.abs(valence) / 20, 1)})`
      : `rgba(255, 0, 0, ${Math.min(Math.abs(valence) / 20, 1)})`;
  };

  const getPrimaryCellColor = (category) => {
    const subcategoryValences = category.subcategories.map(sub => {
      const cellCoordinate = getCellCoordinate(category.name, sub, category.name, sub);
      return heatmap[cellCoordinate] || 0;
    });
    const avgValence = subcategoryValences.reduce((a, b) => a + b, 0) / subcategoryValences.length;
    if (avgValence === 0) return '#fff';
    return avgValence > 0
      ? `rgba(0, 255, 0, ${Math.min(Math.abs(avgValence) / 20, 1)})`
      : `rgba(255, 0, 0, ${Math.min(Math.abs(avgValence) / 20, 1)})`;
  };

  const handleCategoryClick = (index, type, isPrimary = true) => {
    const grid = isPrimary ? primaryGrid : secondaryGrid;
    const clickedCategory = type === 'row' ? grid.rows[index] : grid.cols[index];
    
    let newSecondaryGrid = { ...secondaryGrid };
    let newSelectedPrimaryRow = selectedPrimaryRow;
    let newSelectedPrimaryCol = selectedPrimaryCol;

    if (isPrimary) {
      if (type === 'row') {
        if (selectedPrimaryRow === clickedCategory.name) {
          newSelectedPrimaryRow = null;
          newSecondaryGrid.rows = null;
        } else {
          newSelectedPrimaryRow = clickedCategory.name;
          newSecondaryGrid.rows = clickedCategory.subcategories.map(sub => ({ name: sub }));
        }
      } else {
        if (selectedPrimaryCol === clickedCategory.name) {
          newSelectedPrimaryCol = null;
          newSecondaryGrid.cols = null;
        } else {
          newSelectedPrimaryCol = clickedCategory.name;
          newSecondaryGrid.cols = clickedCategory.subcategories.map(sub => ({ name: sub }));
        }
      }
    }

    setSecondaryGrid(newSecondaryGrid);
    setSelectedPrimaryRow(newSelectedPrimaryRow);
    setSelectedPrimaryCol(newSelectedPrimaryCol);
    addToHistory({ primary: primaryGrid, secondary: newSecondaryGrid, selectedPrimaryRow: newSelectedPrimaryRow, selectedPrimaryCol: newSelectedPrimaryCol });
  };

  const addToHistory = (state) => {
    setHistory(prev => {
      const newHistory = [...prev.slice(0, historyIndex + 1), state];
      if (newHistory.length > MAX_HISTORY) {
        newHistory.shift();
      }
      return newHistory;
    });
    setHistoryIndex(prev => Math.min(prev + 1, MAX_HISTORY - 1));
  };

  const navigateHistory = (direction) => {
    const newIndex = historyIndex + direction;
    if (newIndex >= 0 && newIndex < history.length) {
      setHistoryIndex(newIndex);
      setPrimaryGrid(history[newIndex].primary);
      setSecondaryGrid(history[newIndex].secondary);
      setSelectedPrimaryRow(history[newIndex].selectedPrimaryRow);
      setSelectedPrimaryCol(history[newIndex].selectedPrimaryCol);
    }
  };

  const renderGrid = (grid, isPrimary = true) => {
    if (!grid || (!grid.rows && !grid.cols)) {
      return null;
    }

    const rows = grid.rows || [];
    const cols = grid.cols || [];

    return (
      <div className={styles.gridContainer} style={{
        gridTemplateColumns: `auto repeat(${cols.length}, 1fr)`,
        gridTemplateRows: `auto repeat(${rows.length}, 1fr)`,
      }}>
        <div className={`${styles.gridItem} ${styles.gridItemDisabled}`} />
        {cols.map((category, colIndex) => (
          <div
            key={`col-${colIndex}`}
            className={`${styles.gridItem} ${styles.gridItemHeader} ${isPrimary && selectedPrimaryCol === category.name ? styles.highlighted : ''}`}
            onClick={() => handleCategoryClick(colIndex, 'col', isPrimary)}
            style={{ backgroundColor: isPrimary ? '#f0f0f0' : undefined }}
          >
            {category.name}
          </div>
        ))}
        {rows.map((rowCategory, rowIndex) => (
          <React.Fragment key={`row-${rowIndex}`}>
            <div
              className={`${styles.gridItem} ${styles.gridItemHeader} ${isPrimary && selectedPrimaryRow === rowCategory.name ? styles.highlighted : ''}`}
              onClick={() => handleCategoryClick(rowIndex, 'row', isPrimary)}
              style={{ backgroundColor: isPrimary ? '#f0f0f0' : undefined }}
            >
              {rowCategory.name}
            </div>
            {cols.map((colCategory, colIndex) => (
              <div
                key={`cell-${rowIndex}-${colIndex}`}
                className={`${styles.gridItem} ${(isPrimary && selectedPrimaryRow === rowCategory.name && selectedPrimaryCol === colCategory.name) ? styles.highlightedCell : ''}`}
                onClick={() => handleCellClick(
                  isPrimary ? rowCategory.name : selectedPrimaryRow,
                  rowCategory.name,
                  isPrimary ? colCategory.name : selectedPrimaryCol,
                  colCategory.name,
                  !isPrimary
                )}
                style={{
                  backgroundColor: isPrimary
                    ? getPrimaryCellColor(rowCategory)
                    : getColorByValence(
                        selectedPrimaryRow,
                        rowCategory.name,
                        selectedPrimaryCol,
                        colCategory.name
                      ),
                }}
              />
            ))}
          </React.Fragment>
        ))}
      </div>
    );
  };

  return (
    <Container className={styles.container}>
      <Row className={styles.navigationRow}>
        <Col>
          <Button color="secondary" onClick={() => navigateHistory(-1)} disabled={historyIndex === 0}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
          <Button color="secondary" onClick={() => navigateHistory(1)} disabled={historyIndex === history.length - 1}>
            <FontAwesomeIcon icon={faArrowRight} />
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          {renderGrid(primaryGrid)}
        </Col>
      </Row>
      {secondaryGrid.rows && secondaryGrid.cols && (
        <Row>
          <Col>
            {renderGrid(secondaryGrid, false)}
          </Col>
        </Row>
      )}

      <Modal isOpen={modal} toggle={toggle} className={styles.riskMatrixCommentModal}>
        <ModalHeader toggle={toggle} className={styles.modalHeader}>
          Add Comment for Cell {selectedCell}
        </ModalHeader>
        <ModalBody className={styles.modalBody}>
          {existingComments.length > 0 && (
            <>
              <h5 className={styles.modalTitle}>Existing comments:</h5>
              <ul className={styles.commentList}>
                {existingComments.map((comment, index) => (
                  <li key={index} className={styles.commentItem}>
                    <strong>{comment.comment}</strong> - {comment.valence} (Intensity: {comment.intensity})
                    {comment.url && <a href={comment.url} target="_blank" rel="noopener noreferrer" className={styles.commentLink}> Link</a>}
                  </li>
                ))}
              </ul>
              <hr />
            </>
          )}
<h5 className={styles.modalTitle}>Add a new comment:</h5>
          <Input
            type="textarea"
            placeholder="Enter your comment here..."
            value={comment}
            onChange={handleCommentChange}
            className={styles.commentInput}
          />
          <FormGroup tag="fieldset" className={styles.valenceGroup}>
            <FormGroup check>
              <Label check className={styles.radioLabel}>
                <Input
                  type="radio"
                  name="valence"
                  value="opportunity"
                  checked={valence === 'opportunity'}
                  onChange={handleValenceChange}
                />
                Opportunity
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check className={styles.radioLabel}>
                <Input
                  type="radio"
                  name="valence"
                  value="risk"
                  checked={valence === 'risk'}
                  onChange={handleValenceChange}
                />
                Risk
              </Label>
            </FormGroup>
          </FormGroup>
          <FormGroup>
            <Label for="intensity" className={styles.rangeLabel}>Intensity: {intensity}</Label>
            <Input
              type="range"
              name="intensity"
              min="1"
              max="10"
              value={intensity}
              onChange={handleIntensityChange}
              className={styles.rangeInput}
            />
          </FormGroup>
          <FormGroup>
            <Label for="url" className={styles.urlLabel}>Relevant URL:</Label>
            <Input
              type="url"
              name="url"
              placeholder="https://example.com"
              value={url}
              onChange={handleUrlChange}
              className={styles.urlInput}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter className={styles.modalFooter}>
          <Button
            color="primary"
            onClick={() => {
              saveCommentToSmartContract(comment, valence, intensity, url);
              setComment('');
              setValence('opportunity');
              setIntensity(5);
              setUrl('');
              toggle();
            }}
            className={styles.saveButton}
          >
            Save Comment
          </Button>{' '}
          <Button color="secondary" onClick={toggle} className={styles.cancelButton}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default TechGrid;