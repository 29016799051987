import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
// import { updateProposals } from '../../actions/lobbyActions.js';

// CSS
import 'assets/css/m_w.css';
import styles from "./UpcomingMatches.module.scss";

// for Smart Contract Interactions
import contractScripts from '../Buttons/contractScripts.js';

// reactstrap components
import { Col } from 'reactstrap';

// Components
import ProposalsList from "./ProposalsList.jsx";
import UpNextMatch from "./UpNextMatch.jsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' // TODO fa-library
import { faSpinner, faExpandArrowsAlt, faCaretDown, faArrowUp, faStar, faUser, faPlayCircle } from '@fortawesome/free-solid-svg-icons'

class FutureMatchesTab extends Component {
  state = {
    newMatchPossible: false,
    needReloadOfMatchPossibility: true,
    demoMode: true, // Controls whether demo mode is enabled
    showDemo: true, // Controls visibility of demo screen
  }

  componentDidMount() {
    // this.getLobbyProposals();
    // this.listenForProposalUpdates();
  }

  // componentWillUpdate(nextProps, nextState) {
    // if change in currMatch detected, refresh this.state.newMatchPossible

    // // const loginPropDefined = nextProps.loginComplete !== undefined && nextProps.loginComplete == true;
    // const matchDefined = nextProps.currMatch !== undefined && nextProps.currMatch != null;
    // // const justLoggedIn = nextProps.loginComplete == true;
    // const currMatchChanged = matchDefined && nextProps.currMatch != this.props.currMatch;
    // // const accountChange = loginPropDefined && justLoggedIn && loginPropChanged;
    // // const accountChange = matchDefined && loginPropChanged;

    // if (currMatchChanged) {
    //   console.log("componentWillUpdate - currMatch CHANGE!")
    //   this.setState({ 
    //     needReloadOfMatchPossibility: true,
    //     // loadingSliderValues: false, 
    //   }) 
    // }
  // }

  componentDidUpdate(prevProps) { 
    // console.log("FutureMatchesTab - UPDATE")

    // // if different match (by ID #) becomes focused (and gets passed in) --> reset pendingMatchNowCancelledOrActive
    // const lastPropsNotUndefined = prevProps.currMatch !== undefined;
    // const currentPropsNotUndefined = this.props.currMatch !== undefined;
    // const noPropsUndefined = lastPropsNotUndefined && currentPropsNotUndefined;
    // console.log(lastPropsNotUndefined)
    // console.log(currentPropsNotUndefined)
    // console.log(noPropsUndefined)

    // console.log("this.props.currMatch: ")
    // console.log(this.props.currMatch)

    // console.log("prevProps.currMatch: ")
    // console.log(prevProps.currMatch)

    // // const propsJustChanged = 
    // const newMatchStatusInProps = 
    // ( prevProps.currMatch === null && prevProps.currMatch != this.props.currMatch ) 
    // || 
    // ( prevProps.currMatch !== null && prevProps.currMatch.matchStatus != this.props.currMatch.matchStatus)

    // console.log(newMatchStatusInProps)
    // // upon update, check if new match can be started
    // if (noPropsUndefined && newMatchStatusInProps) {
    //   console.log("CHECKING AGAIN IF NEW MATCH CAN BE STARTED")
    //   this.checkNewMatchPossible();
    // }

    // reload "possibility of new match" if relevant
    if (this.props.currMatch !== undefined && this.state.needReloadOfMatchPossibility ) { // && this.props.currMatch != prevProps.currMatch
      this.checkNewMatchPossible();
    }

    // const loginPropDefined = prevProps.loginComplete !== undefined && prevProps.loginComplete == true;
    const currMatchDefined = this.props.currMatch !== undefined;
    // const justLoggedIn = prevProps.loginComplete == true;
    const currMatchChanged = currMatchDefined && prevProps.currMatch != this.props.currMatch;
    // const accountChange = loginPropDefined && justLoggedIn && loginPropChanged;
    // const accountChange = matchDefined && loginPropChanged;
    
    if (currMatchChanged) {
      console.log("componentWillUpdate - currMatch CHANGE!")
      this.setState({ 
        needReloadOfMatchPossibility: true,
        // loadingSliderValues: false, 
      }) 
    }
  }

  checkNewMatchPossible = () => {
    // console.log("checkNewMatchPossible - invoked")

    if (this.props.currMatch !== undefined && this.state.needReloadOfMatchPossibility) {
      this.setState({ needReloadOfMatchPossibility: false })
      // console.log("checkNewMatchPossible - #1")

    // When 0 (none) matches exist in a given lobby, a new match is possible
      if (this.props.currMatch == null) {
        // console.log("checkNewMatchPossible - #1 #1")
        this.setState({ 
          newMatchPossible: true,
          needReloadOfMatchPossibility: false 
        })
      }

      else if (this.props.currMatch.matchStatus != undefined) {
        console.log(this.props.currMatch)
        console.log("checkNewMatchPossible - #1 #2")
        const currMatchStatusString = this.props.currMatch.matchStatus.toString();

          // console.log('currMatchStatusString:')
          // console.log(currMatchStatusString)
      
          // When the latest (current) match is PENDING or ACTIVE, no new match can start
          if (currMatchStatusString == 'PENDING' || currMatchStatusString == 'ACTIVE') {
            this.setState({ newMatchPossible: false })
            // return true
          }

          // When the latest (current) match is OVER or CANCELLED, a new match can start
          else if (currMatchStatusString == 'OVER' || currMatchStatusString == 'CANCELLED') {
            this.setState({ newMatchPossible: true })
            // return true
          }
      }
    }

    else { 
      // console.log("checkNewMatchPossible - ELSE-BLOCK");
      // this.setState({ needReloadOfMatchPossibility: true })
    }
  }

  getSortedProposals = (proposals) => {
    // this.props.proposals.sort((a, b) => b.upvotes - a.upvotes == 0 ? a.timeSubmitted - b.timeSubmitted : (b.upvotes - a.upvotes)); // TIE-BREAKING BY OLDEST
    return proposals.sort((a, b) => b.upvotes - a.upvotes == 0 ? a.timeSubmitted - b.timeSubmitted : (b.upvotes - a.upvotes)); // TIE-BREAKING BY OLDEST
  }

  // New method to handle starting the demo
  startDemo = () => {
    this.setState({ showDemo: false });
  }

  render() {
    const loadingIcon = <h1> <FontAwesomeIcon icon={faSpinner} pulse /> </h1> // TODO: fa-library
    // const isLoggedIn = this.props.provider != "none";
    // const isLoggedIn = this.props.loginComplete;

    var proposalsSorted = this.props.proposals == null ? loadingIcon : this.getSortedProposals(this.props.proposals);

    const upNextMatch = this.props.proposals == null ? loadingIcon : proposalsSorted[0];

    const restProposalsDisplay = this.props.proposals == null ? loadingIcon
    : 
    <ProposalsList 
      updateProposals={(lobbyProposals) => this.props.updateProposals(lobbyProposals)}
      changeFocusedTab={(newTabIndex) => this.props.changeFocusedTab(newTabIndex)} 
      updateXPBalance={(newXPBalance) => this.props.updateXPBalance(newXPBalance)} 
      toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
      //
      joined={this.props.joined} 
      proposals={proposalsSorted.slice(1)} 
      loginComplete={this.props.loginComplete}
      lobby={this.props.lobby} 
      account={this.props.account} 
      paid={this.props.paid} 
      provider={this.props.provider} 
    />;

    // console.log("FutureMatchesTab - this.state.newMatchPossible: ");
    // console.log(this.state.newMatchPossible)

    const upNextMatchDisplay = this.props.proposals == undefined ? loadingIcon 
    : 
    <UpNextMatch 
      changeFocusedTab={(newTabIndex) => this.props.changeFocusedTab(newTabIndex)} 
      toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
      focusedTab={this.props.focusedTab}
      // ** ORIGINAL (before refactor)
      joined={this.props.joined}
      proposal={upNextMatch} 
      // loggedIn={isLoggedIn} 
      loginComplete={this.props.loginComplete}
      lobby={this.props.lobby}
      account={this.props.account}
      paid={this.props.paid}
      provider={this.props.provider} 
      canStartNew={this.state.newMatchPossible} 
      currMatch={this.props.currMatch} 
    />;

    // Demo mode display
    if (this.state.demoMode && this.state.showDemo) {
      return (
        <div id={styles.upcomingMatchesTab} className={styles.demoContainer}>
          <div className={styles.demoContent}>
            <h2 className={styles.demoDescription}>
              The most-upvoted items from the Debate Tree will appear here, and then initiate focused group-prompting to imagine positive and negative visions associated with each entry
            </h2>
            <button className={styles.demoButton} onClick={this.startDemo}>
              <FontAwesomeIcon icon={faPlayCircle} /> View Demo
            </button>
          </div>
        </div>
      );
    }

    // Regular tab display (unchanged)
    return (
      <div id={styles.upcomingMatchesTab}>
        <div id={styles.upNextMatchContainer}>
          {upNextMatchDisplay}
        </div>
        <div id={styles.proposalsListContainer}>
          {restProposalsDisplay} 
        </div>
      </div>
    );
  }
}

export default FutureMatchesTab

// TODO: POPULAR IN THIS LOBBY / POPULAR IN OTHER LOBBIES