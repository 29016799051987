import React, { Component } from 'react';
import styles from "./Navbar.module.scss";
import contractScripts from '../Buttons/contractScripts.js';
import { Container, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

class BalanceDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    };
  }

  toggleModal = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
    });
  };

  componentDidMount() {}

  componentDidUpdate() {
    // console.log("BalanceDisplay - UPDATED")
    // if (this.props.loginComplete) {
    //   console.log("BalanceDisplay LOGGED IN - XPBalance:")
    //   console.log(this.props.XPBalance.toString())
    // }
  }

  render() {
    // ************** XP 
    const XPBalanceAvailable = this.props.XPBalance != {}; 
    const loggedIn = this.props.loginComplete;
    const XPBalance = XPBalanceAvailable && loggedIn ? 
      this.props.XPBalance : 
      contractScripts.getBigNumber(0);

    console.log("XPBalance: ")
    console.log(XPBalance)

    const XPBalanceNumber = this.props.XPBalance === undefined ? 
      0 : 
      contractScripts.decimalEighteen(XPBalance, "BalanceDisplay");

    console.log("XPBalance: ")
    console.log(XPBalance)

    const XPBalMessage = XPBalance === undefined || XPBalanceNumber === 0 ? 
      "100" : 
      XPBalanceNumber.toString();

    // Keep original voting display structure and styling
    const XPBalDisplay = (
      <>
        <div
          onClick={this.toggleModal}
          style={{
            cursor: 'pointer',
            textDecoration: 'underline',
            textDecorationColor: 'currentColor',
            textDecorationThickness: '2px',
            textUnderlineOffset: '2px',
            textDecorationOpacity: '0.5',
            color: 'black !important'
          }}
        >
          <div
            id={styles.balanceText}
            style={{
              display: 'inline',
              color: 'black !important'
            }}
          >
            {"Votes:"}
          </div>
          <div
            id={styles.balanceNumber}
            style={{
              display: 'inline',
              color: 'black !important'
            }}
          >
             {"‎ ‎ " + XPBalMessage}
          </div>
          <div
            id={styles.XPBalanceIcon}
            style={{
              display: 'inline',
              color: 'black !important'
            }}
          >
            <i className="fas fa-comment-dots"></i>
          </div>
        </div>
      </>
    );

    // ************** ETH
    const ETHBalance = this.props.ETHBalance;
    const ETHBalMessage = ETHBalance === undefined || ETHBalance === 0 ? 
      "0" : 
      contractScripts.decimalEighteen(this.props.availableETH, "BalanceDisplay").substring(0, 5);
    const ETHBalDisplay = <> {"ETH Balance: "} {ETHBalMessage} </>;

    // ************* GLOWING
    const correctGlowBalances = this.props.paid ? (
      <h2 className={styles.glow} id={styles.ETHBalanceText}>{ETHBalDisplay}</h2>
    ) : (
      <h2 className={styles.glow} id={styles.XPBalanceText}>{XPBalDisplay}</h2>
    );

    const modalStyles = {
      content: {
        backgroundColor: 'white !important',
        marginTop: '-100px',
      },
      header: {
        fontSize: '1.5em',
        fontWeight: 600,
        backgroundColor: 'white !important',
        borderBottom: '1px solid #dee2e6',
        padding: '1rem'
      },
      body: {
        backgroundColor: 'white !important',
        fontSize: '1.5em',
        fontWeight: 600,
        padding: '1.5rem'
      },
      footer: {
        backgroundColor: 'white !important',
        borderTop: '1px solid #dee2e6',
        padding: '1rem'
      },
      link: {
        color: '#0066cc',
        textDecoration: 'none',
        fontWeight: 600
      },
      image: {
        height: '250px',
        objectFit: 'contain',
        margin: '1.5rem 0'
      },
      list: {
        listStyleType: 'none',
        paddingLeft: 0
      },
      listItem: {
        padding: '0.75rem 0',
        borderBottom: '1px solid #eee'
      }
    };

    return (
      <>
        <Container>
          <Col>
            <div>{correctGlowBalances}</div>
          </Col>
        </Container>

        <Modal 
          isOpen={this.state.modalOpen} 
          toggle={this.toggleModal}
          contentClassName={styles.modalContent}
          className={styles.modalContent}
        >
          <ModalHeader 
            toggle={this.toggleModal}
            style={modalStyles.header}
          >
            Votes Information
          </ModalHeader>
          <ModalBody style={modalStyles.body}>
            <p>
              For more information on Quadratic Voting, visit{' '}
              <a 
                href="https://www.radicalxchange.org/wiki/quadratic-voting/" 
                target="_blank" 
                rel="noopener noreferrer"
                style={modalStyles.link}
              >
                RadicalxChange
              </a>
              .
            </p>
            
            <div style={{ textAlign: 'center' }}>
              <img 
                src="https://www.radicalxchange.org/images/wiki/qv-diagram.png" 
                alt="Quadratic Voting Diagram" 
                style={modalStyles.image}
              />
            </div>

            {/* <h5 style={{ fontSize: '1.25em', fontWeight: 600, margin: '1.5rem 0 1rem', color: 'black !important' }}>
              Ways to use your votes (Soon):
            </h5> */}
          <h5 id={styles.voteUseStyle}>
              Ways to use your votes (Soon):
            </h5>
            <ul style={modalStyles.list}>
              <li style={modalStyles.listItem}>
                Vote up/down questions (weekly refresh) - Available Votes: {XPBalMessage}
              </li>
              <li style={modalStyles.listItem}>
                Vote for/against historical figures (weekly refresh) - Available Votes: {XPBalMessage}
              </li>
              <li style={modalStyles.listItem}>
                Give kudos/karma to other users for good contributions - Available Votes: {XPBalMessage}
              </li>
            </ul>
          </ModalBody>
          <ModalFooter style={modalStyles.footer}>
            <Button color="secondary" onClick={this.toggleModal}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default BalanceDisplay;