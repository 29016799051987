import React, { Component } from 'react';

// CSS
import styles from "./MemeMatch.module.scss";

// Smart-Contract (temporary – dealing with cache issue)
// import contractScripts from '../Buttons/contractScripts.js'

// Components
import MemeMatchLoadingScreen from './MemeMatchLoadingScreen.jsx';
import MemeMatch from './MemeMatch.jsx';
import MemeMatchPaginate from './MemeMatchPaginate.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' // TODO: fa-library fa library
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

class MemeMatchLoader extends Component {
  
  state = {
  // State passed-down from top-level component (MemeWarsSite.jsx)
  }

  componentDidMount() { }

  componentDidUpdate() { } 

  componentWillUnmount() { }

  render() {
    // console.log("this.props.currMatch");
    // console.log(this.props.currMatch)
    // console.log("this.props.match");
    // console.log(this.props.match)

  const demoMode = this.props.demoMode;

  const loaderContent = 
  this.props.currMatch == null || this.props.currMatch == undefined || this.props.currMatch.ID == 0 || !demoMode
  ? 
    <MemeMatchLoadingScreen 
      changeFocusedTab={(newTabIndex) => this.props.changeFocusedTab(newTabIndex)} 
      toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
      focusedTab={this.props.focusedTab}
      // proposals={this.props.proposals} 
      matches={this.props.matches} 
      loginComplete={this.props.loginComplete}
      joined={this.props.joined} 
      match={this.props.match}
      currMatch={this.props.currMatch}
      //
      demoMode={this.props.demoMode}
    />
    : 
    <MemeMatch 
      // currMatch={this.props.currMatch} // (globally, by lobby) most recent match
      match={this.props.match}
      currMatch={this.props.currMatch}
      proposals={this.props.proposals}
      // match={this.props.currMatch} // the currently-focused match (could be an old one) TODO

      updateXPBalance={(newXPBalance) => this.props.updateXPBalance(newXPBalance)} // TODO: have one function (here) which handles the newXP value (clients just call UpdateXPBalance())
      toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
      focusedMatchID={this.props.focusedMatchID}
      focusedEntry={this.props.focusedEntry}
      loginComplete={this.props.loginComplete}
      loginInProgress={this.props.loginInProgress}
     
      joined={this.props.joined}
      XPBalance={this.props.XPBalance} 
      ETHbalance={this.props.ETHBalance} 
      availableETH={this.props.availableETH}
      // ***
      profile={this.props.profile} // TODO: can get rid of this
      loggedIn={this.props.loginComplete}

      account={this.props.account}    // TODO: redundant (included in profile)
      provider={this.props.provider}  // TODO: redundant (included in profile)
      lobby={this.props.lobby}      
      paid={this.props.paid} 
      //
      demoMode={this.props.demoMode}
    />; 
  

  const paginationView =  this.props.currMatch == null || !demoMode ? 
  <></> 
  : 
  <MemeMatchPaginate 
  
    // have to thread-through changeFocusedMatchID
    // focusedMatchID={this.props.focusedMatchID}
    // currMatch={this.props.currMatch}
    // matches={this.props.matches}

  
  />;

  return (
    <>
    <div id={styles.MemeMatchLoader}>
      { loaderContent }
    </div>
    <div id={styles.paginationContainer}>
      { paginationView }
    </div>
    </>
  );
  }
}

export default MemeMatchLoader;