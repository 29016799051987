import React from 'react';
import styles from './DemoModeSelect.module.scss';

class DemoModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: {
        chat: false,
        playersOnline: false,
        issueRanking: false,
        communitySection: false,
        activeVote: false,
        overVote: false,
      },
      dropdownOpen: false,
    };
  }

  componentDidMount() {
    // Initialize state from props
    if (this.props.demoMode) {
      this.setState({
        settings: Object.keys(this.state.settings).reduce((acc, key) => {
          acc[key] = true;
          return acc;
        }, {}),
      });
    }
  }

  handleClick = (demo) => {
    this.setState(prevState => ({
      settings: {
        ...prevState.settings,
        [demo]: !prevState.settings[demo]
      }
    }));
  }

  handleArrowClick = () => {
    // Implement the redirection logic here
  }

  toggleDropdown = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  toggleDemoMode = (DemoModeOn) => {
    this.props.toggleDemoMode(DemoModeOn);
    // Set all sub-settings
    // TODO: change this when demo mode becomes more granular 
    // const newSettings = Object.keys(this.state.settings).reduce((acc, key) => {
    //   acc[key] = DemoModeOn;
    //   return acc;
    // }, {});
    // this.setState({ settings: newSettings });
  }

  render() {
    const { settings, dropdownOpen } = this.state;

    if (this.props.textMode) {
      return (
        <div
          className={styles.demoModeText}
          onClick={() => this.toggleDemoMode(!this.props.demoMode)}
        >
          Demo Mode: {this.props.demoMode ? 'ON' : 'OFF'}
        </div>
      );
    }

    return (
      <div className={styles.dropdown}>
        <button onClick={this.toggleDropdown} className={styles.dropdownButton}>
          <input
            type="checkbox"
            style={{ marginLeft: '8px', scale: '1.5', alignSelf: 'center' }}
            checked={this.props.demoMode}
            onChange={() => this.toggleDemoMode(!this.props.demoMode)}
          />
          Demo Mode
        </button>
        {dropdownOpen && (
          <div className={styles.demoModal}>
            <h2 className={styles.title}>
              When selected, smart contract reads/writes are disabled and placeholder data appears in:
            </h2>
            {/* <p></p>
            {Object.keys(settings).map((demo, index) => (
              <div className={styles.demoItem} key={index}>
                <label>
                  <input
                    type="checkbox"
                    checked={settings[demo]}
                    onChange={() => this.handleClick(demo)}
                  />
                  {demo}
                </label>
              </div>
            ))}
            <button className={styles.arrowButton} onClick={this.handleArrowClick}>
              →
            </button> */}
          </div>
        )}
      </div>
    );
  }
}

export default DemoModal;