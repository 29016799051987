import { FETCH_SESSION_STATE, UPDATE_SESSION_STATE, CHANGE_METRICS_CHOICE, CHANGE_FOCUSED_TAB, CHANGE_FOCUSED_MATCH, CHANGE_FOCUSED_ENTRY, TOGGLE_LOGIN_MODAL, TOGGLE_SETTINGS_MODAL, LOGIN_IN_PROGRESS, TOGGLE_DEMO_MODE } from '../actions/types';
import store from '../store.js';

export const fetchSessionState = () => dispatch => {
    // Check redux store for state (TODO: safe?)
    var currFocusedTab = store.getState().sessionState.focusedTab;
    var currFocusedMatch = store.getState().sessionState.focusedMatchID;
    var currLoginModalToggle = store.getState().sessionState.loginModalToggled;
    var currExplorerHistory = store.getState().sessionState.explorerHistory
  
    const sessionInfo = {
      focusedTab: currFocusedTab,
      focusedMatch: currFocusedMatch,
      loginModalToggled: currLoginModalToggle,
      explorerHistory: currExplorerHistory
    }; 
  
    dispatch({
      type: FETCH_SESSION_STATE,
      payload: sessionInfo,
    });
};

export const changeFocusedTab = (newTabIndex) => dispatch => {
  dispatch({
    type: CHANGE_FOCUSED_TAB,
    payload: newTabIndex,
  });
}

export const changeFocusedMatchID = (newFocusedMatchID) => dispatch => {
  dispatch({
    type: CHANGE_FOCUSED_MATCH,
    payload: newFocusedMatchID,
  });
}

export const changeFocusedEntry = (newEntry) => dispatch => {
  dispatch({
    type: CHANGE_FOCUSED_ENTRY,
    payload: newEntry, // TODO: should this be bool or URL?
  });
}

export const toggleLoginModal = (modalIsOpen) => dispatch => {
  // console.log("toggleLoginModal: modalIsOpen=" + modalIsOpen)
  dispatch({
    type: TOGGLE_LOGIN_MODAL,
    payload: modalIsOpen,
  });
}

export const toggleSettingsModal = (modalIsOpen) => dispatch => {
  // console.log("toggleSettingsModal: modalIsOpen=" + modalIsOpen)
  dispatch({
    type: TOGGLE_SETTINGS_MODAL,
    payload: modalIsOpen,
  });
}

export const updateLoginInfo = (pendingLogin) => dispatch => {
  dispatch({
    type: LOGIN_IN_PROGRESS,
    payload: pendingLogin,
  });
}

export const changeMetricsChoice = (newMetricsChoice) => dispatch => {
  dispatch({
    type: CHANGE_METRICS_CHOICE,
    payload: newMetricsChoice,
  });
}

export const toggleDemoMode = (newDemoMode) => dispatch => {
  dispatch({
    type: TOGGLE_DEMO_MODE,
    payload: newDemoMode,
  });
}