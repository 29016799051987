import React, { useState } from 'react';
import contractScripts from 'components/Buttons/contractScripts'; // smart contract info: addresses & content
import {
  ACCOUNTS_BASE_ADDRESS,
  ACCOUNTS_BASE_SEPOLIA_ADDRESS,
  SURVEYS_BASE_ADDRESS,
  SURVEYS_BASE_SEPOLIA_ADDRESS,
  SBT_FACTORY_BASE_ADDRESS,
  SBT_FACTORY_BASE_SEPOLIA_ADDRESS
} from '../../variables/CONTRACT_ADDRESSES.js';
import worldVoteImage from '../../assets/img/worldvote.jpg';
import coinbaseIcon from '../../assets/img/coinbase_icon.png'; // add this import
// Smart Contract JSON
import ACCOUNTS from '../../contractsABI/ACCOUNTS_ABI.json';
import SURVEYS from '../../contractsABI/SURVEYS_ABI.json';
// CSS
import styles from './ContractPage.module.scss';
//
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand } from '@fortawesome/free-solid-svg-icons';
// Utilities
import proposalScripts from '../UpcomingMatches/proposalScripts.js';
// Components

const ContractPage = (props) => {
  const contracts = [
    {
      name: 'Accounts',
      addresses: [
        // { id: 8543, address: ACCOUNTS_BASE_ADDRESS },
        // { id: 84532, address: ACCOUNTS_BASE_SEPOLIA_ADDRESS, testnet: true },
      ],
      explainer: 'Keeps track of usernames, referals, and votes. ',
      button: <></>
    },
    {
      name: 'Questions and Surveys',
      addresses: [
        // { id: 8543, address: SURVEYS_BASE_ADDRESS },
        { id: 84532, address: SURVEYS_BASE_SEPOLIA_ADDRESS, testnet: true },
      ],
      explainer: 'Keeps track of questions / surveys (+ responses) storage hashes on Arweave.',
      button: <></>
    },
    {
      name: 'SBT Factory',
      addresses: [
        // { id: 8543, address: SBT_FACTORY_BASE_ADDRESS },
        { id: 84532, address: SBT_FACTORY_BASE_SEPOLIA_ADDRESS, testnet: true },
      ],
      explainer: 'Allows anyone to easily create Soulbound Tokens (Non-transferrable NFTs) to signify event participation, group membership, or public belief / association.',
      button:        
        <button onClick={() => window.location.href = "/sbts"} className={styles.backButton}>
          <FontAwesomeIcon icon={faExpand} /> SBTs list
        </button>
    },
    // Add more contracts here
  ];

  const [bytes32Input, setBytes32Input] = useState('');
  const [base64urlInput, setBase64urlInput] = useState('');
  const [base64urlOutput, setBase64urlOutput] = useState('');
  const [bytes32Output, setBytes32Output] = useState('');
  const [base64Input, setBase64Input] = useState('');
  const [base64Output, setBase64Output] = useState('');

  const handleBytes32ToBase64url = () => {
    setBase64urlOutput(contractScripts.hexToBase64url(bytes32Input));
  };

  const handleBase64urlToBytes32 = () => {
    setBytes32Output(contractScripts.base64urlToHex(base64urlInput));
  };

  const handleBase64urlToBase64 = () => {
    setBase64Output(contractScripts.base64urlToBase64(base64urlInput));
  };

  const getLink = (address, chainId) => {
    if (chainId === 8543) {
      return `https://basescan.org/address/${address}`;
    } else if (chainId === 84532) {
      return `https://sepolia.basescan.org/address/${address}`;
    }
    return `https://sepolia.basescan.org/address/${address}`;
  };

  return (
    <div className={styles.contractPage}>
      <div className={styles.contractList}>
        {contracts.map((contract, index) => (
          <div key={index} className={styles.contractCard}>
            <div className={styles.contractDetails}>
              <pre>{contract.name}</pre>
            </div>
            <div className={styles.contractHeader}>
              {contract.addresses.map((addr, addrIndex) => (
                <div key={addrIndex} className={styles.contractAddress}>
                  {proposalScripts.getShortenedAddress(
                    addr.address,
                    true,
                    getLink(addr.address, addr.id)
                  )}
                  {addr.testnet && <span className={styles.testnetLabel}> (Testnet)</span>}
                </div>
              ))}
              <img src={coinbaseIcon} alt="Coinbase Icon" className={styles.coinbaseIcon} />
            </div>
            <div className={styles.contractDetails}>
              <ul>
                <li>{contract.explainer}</li>
              </ul>

              <div id={styles.sbtButton}> {contract.button} </div>

            </div>
          </div>
        ))}
      </div>
      <div className={styles.converterSection}>
        <div className={styles.converterSectionTitle}>Utils</div>
        <div className={styles.converterSectionUtil}>
          <div className={styles.converterRow}>
            <input
              type="text"
              value={base64urlInput}
              onChange={(e) => setBase64urlInput(e.target.value)}
              placeholder="Enter Base64url"
            />
            <button onClick={handleBase64urlToBytes32}>Convert to Bytes32</button>
          </div>
          <div>Output: {bytes32Output}</div>
        </div>
        <div className={styles.converterSectionUtil}>
          <div className={styles.converterRow}>
            <input
              type="text"
              value={bytes32Input}
              onChange={(e) => setBytes32Input(e.target.value)}
              placeholder="Enter bytes32"
            />
            <button onClick={handleBytes32ToBase64url}>Convert to Base64url</button>
          </div>
          <div>Output: {base64urlOutput}</div>
        </div>
        <div className={styles.converterSectionUtil}>
          <div className={styles.converterRow}>
            <input
              type="text"
              value={base64urlInput}
              onChange={(e) => setBase64urlInput(e.target.value)}
              placeholder="Enter Base64url"
            />
            <button onClick={handleBase64urlToBase64}>Convert to Base64</button>
          </div>
          <div>Output: {base64Output}</div>
        </div>
      </div>
    </div>
  );
};

export default ContractPage;
