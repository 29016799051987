import React from 'react';
import { Container, Jumbotron, Row, Col } from 'reactstrap';

// CSS
import 'assets/css/m_w.css';
// CSS
import styles from "./MainContent.module.scss";

// Components
import MemeMatchLoader from 'components/MemeMatch/MemeMatchLoader.jsx';

class LatestMatchTab extends React.Component {
  componentDidMount() {}

  componentDidUpdate() {}

  render() {
    return (
      // <div className="block-gradient">
        // <div className="gradient-border"> 
            <MemeMatchLoader
              changeFocusedTab={(newTabIndex) => this.props.changeFocusedTab(newTabIndex)} 
              updateXPBalance={(newXPBalance) => this.props.updateXPBalance(newXPBalance)} // TODO: have one function (here) which handles the newXP value (clients just call UpdateXPBalance())
              toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
              // updateLoginInfo={(newLoginStatus) => this.props.updateLoginInfo(newLoginStatus)}
              updateCurrMatch={(latestMatchObj) => this.props.updateCurrMatch(latestMatchObj)}
              updateMatches={(allMatchesObj) => this.props.updateMatches(allMatchesObj)}
              // ************************* 
              profile={this.props.profile} // TODO: can get rid of this
              // lobbyInfo={this.props.lobbyInfo}
              // sessionState={this.props.sessionState}
              // ************************* 
              account={this.props.account} 
              provider={this.props.provider}
              joined={this.props.joined}
              XPBalance={this.props.XPBalance} 
              ETHbalance={this.props.ETHBalance} 
              availableETH={this.props.availableETH}
              lobby={this.props.lobby}
              paid={this.props.paid} 
              match={this.props.match}
              currMatch={this.props.currMatch}
              matches={this.props.matches}
              proposals={this.props.proposals}
              focusedTab={this.props.focusedTab}
              focusedMatchID={this.props.focusedMatchID}
              focusedEntry={this.props.focusedEntry}
              loginComplete={this.props.loginComplete}
              loginInProgress={this.props.loginInProgress}
              //
              demoMode={this.props.demoMode}
            />
      // </div>
      // </div>
    );
  }
}

export default LatestMatchTab;