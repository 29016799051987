import React, { Component } from "react";
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchAccount, updateXPBalance, updateETHBalance } from '../actions/accountActions.js';
import { updateCurrMatch, updateMatches, updateProposals } from '../actions/lobbyActions.js';
import { fetchSessionState, changeFocusedTab, toggleLoginModal, updateLoginInfo, changeFocusedMatchID, toggleDemoMode  } from '../actions/sessionStateActions.js';

// CSS
import "assets/css/m_w.css";
import styles from "./Main.module.scss";

// DemoMode Data
import web2DemoData from '../variables/web2DemoVotes.js';
import web2DemoProposals from '../variables/web2DemoProposals.js';

// Smart Contract Events / Interactions
import contractScripts from './Buttons/contractScripts.js';
import { ethers } from 'ethers';

// Footer-HooksHOC is a function component (allowed to use Hooks from wagmi and RainbowKit) 
// it passes props to this class-component so that this component can use React Hooks
import { WagmiHooksHOC } from './HooksHOC/WagmiHooksHOC.jsx'

// Components
import Navbar from "./Navbar/Navbar.jsx";
import MainAreaTabs from "./MainContent/MainAreaTabsAlt.jsx"; // To make early demo less overwhelming
import Footer from "./Footer/Footer.jsx";
import RightsideAlt from "./RightSidebar/RightSideAlt.jsx"; // To make early demo less overwhelming
// About Page:
import AboutPage from "./AboutPage/AboutPage.jsx"
// User Page
import UserPage from "./UserPage/UserPage.jsx";
import SimulatedUserPage from "./UserPage/SimUserPage.jsx";
// Risk Matrix Demo
import RiskMatrixDemo from "./DemoPages/RiskMatrixDemo.jsx";
// Contract Information / Display Page
import ContractPage from "./ContractPage/ContractPage.jsx";
import CompareAddresses from "./UserPage/CompareAddresses.jsx";
// Survey Tool
import SurveyPage from "./SurveyTool/SurveyPage.jsx";
import SurveyTool from "./SurveyTool/SurveyTool.jsx";
// SBT Tools
import SBTPage from "./SBTs/SBTPage.jsx";
import SBTsPage from "./SBTs/SBTsList.jsx"; // TODO: replace with SBTsPage

// NOTE: GLOBAL STATE managed with redux
class MainSite extends Component {
  state = {
    // when new match starts, change focused match to latest
    autoSnapToNewMatch: true,  // TODO: should be a setting for user
    // TODO: having an issue, when below is true: can't switch to another match, app glitches
    searchForCachedMatches: true, // TODO: should be a setting in another file
    latestMatchNumber: null,  // Updated / Used in: extendMatchesArrayForNewMatch() // NOTE: equivalent to latestMatchID

    // LATEST MATCHES TAB
    loadedLatestMatch: false,
    loadingLatestMatch: false,

    loadedAllMatches: false, 
    loadingAllMatches: false,

    // FUTURE MATCHES TAB
    loadedProposals: false,
    loadingProposals: false,

    // BLOCKCHAIN-RELATED
    latestBlockNumber: null,  // Used in: listenForMatchUpdates() and listenForProposalUpdates()

    // Demo functionality (for showing idea before users exist)
    demoModeOverrideToFalse: false,  // Setting can be toggled by user in Web3 / Settings modal, turns off fetching of onchain data and replaces with example data for web2 demo 
  }
  
  async componentDidMount() {
    // get global settings for website from redux store
    this.props.fetchSessionState();

    console.log("this.props.urlExtension:" + this.props.urlExtension)

    if (this.state.demoModeOverrideToFalse) {
      this.props.toggleDemoMode(false)
    }

    if (this.props.web2DemoMode) {
    
        // window.provider = window.defaultProvider;
        // console.log("window.provider:")
        // console.log(window.provider)

      console.log("web2DemoMode - skipping smart-contract interactions and WS setup")
    //   this.loadDemoMatches();
    //   this.getLobbyProposals(); // Demo switch inside

     // Initialize SBT cache and start event listener
    //  await this.updateLatestBlockNumber();
      this.initializeSbtCache();
    //   this.startSbtEventListener();
    }

    // if in web2DemoMode: avoid all smart-contract interactions and WS setup 
    else if (!this.props.web2DemoMode) {

      // establish Web3 connection (WSS) to be used wherever possible (contractScripts.js, lower components, etc.)
    //   window.provider = window.defaultProvider;
    //   console.log("window.provider:")
    //   console.log(window.provider)

    //   this.updateLatestBlockNumber();
      this.startEventListener();
      this.loadMatchesFromLocalStorage();

      // Initialize SBT cache and start event listener
      this.initializeSbtCache();
      this.startSbtEventListener();
    }
  }

  componentWillUnmount() {
    if (this.props.socket !== undefined) {
      // SERVERTODO  
      // this.props.socket.disconnect() // server connection (established in App.jsx)
    }

    // disconnect Torus web3 connection if relevant
    if (this.props.provider == "Torus") {
      window.torus.cleanup()
    }

    // Remove SBT event listener
    contractScripts.removeSBTEventListener(this.props.provider);
  }

  componentDidUpdate(prevProps) {
    console.log("this.props.matches:")
    console.log(this.props.matches)
    console.log("this.props.currMatch")
    console.log(this.props.currMatch)
    console.log("this.props.focusedMatchID:")
    console.log(this.props.focusedMatchID)

    // LATEST MATCHES TAB
    const needCurrMatchReload = !this.state.loadedLatestMatch && !this.state.loadingLatestMatch && this.props.currMatch !== undefined;
    const needAllMatchesReload = !this.state.loadedAllMatches && !this.state.loadingAllMatches;

    // FUTURE MATCHES TAB
    const needProposalsReload = !this.state.loadedProposals && !this.state.loadingProposals;

    if (!this.props.web2DemoMode) {
      // load latest match (if not loaded) or refresh (if need for refresh indicated)
      if (needCurrMatchReload) {
        this.loadLatestMatch();
      }
    
      // load rest of matches (upon start, in lazy way TODO) or refresh / add-to `loadedMatches` (if need indicated)
      if (needAllMatchesReload) {
        this.loadAllMatches();
      }

      // load match proposals relevant to lobby (for FUTURE MATCHES)
      if (needProposalsReload) {
        this.getLobbyProposals();
      }
    }
  }

  // SERVER COMMUNICATION START TODO -------------------------------------------------------

  sendMessageToServer = () => {
    // this.props.socket.send("MESSAGE from MainSite.jsx");        // TODO: Use 'ack" parameter?
  }

  // SERVER COMMUNICATION END  -------------------------------------------------------------

  // SBTs START *****************************************************************************

initializeSbtCache = async () => {
    // const provider = window.provider; // Assuming provider is set
    const network = this.props.network;
    const networkID = network?.id;
  
    if (!networkID) {
      console.error('Network ID is undefined in MainSite. Cannot proceed.');
      return;
    }
  
    const BATCH_SIZE = 5;
    const cache = JSON.parse(localStorage.getItem('sbtCache')) || {};
  
    let latestBlock;
    try {
      const cachedNetworkData = cache[networkID];
      const cachedSbtList = cachedNetworkData ? cachedNetworkData.sbtList : {};
      const cacheLatestBlock = cachedNetworkData ? cachedNetworkData.lastBlock : 0;
      latestBlock = await contractScripts.getLatestBlockNumber(this.props.provider);
  
      // Fetch new SBTs if there are new blocks
      let newSbts = [];
      if (cacheLatestBlock < latestBlock) {
        newSbts = await contractScripts.getSbtsCreated(this.props.provider, cacheLatestBlock + 1, latestBlock);
      }
  
      // Combine existing and new SBT addresses
      const existingSbtAddresses = Object.keys(cachedSbtList).map(addr => addr.toLowerCase());
      const newSbtAddresses = newSbts.map((sbt) => sbt.sbtAddress.toLowerCase());
      const allSbtAddresses = [...new Set([...existingSbtAddresses, ...newSbtAddresses])];
  
      // Process SBTs, consulting cache first
      const processedSbts = {};
      for (let i = 0; i < allSbtAddresses.length; i += BATCH_SIZE) {
        const batch = allSbtAddresses.slice(i, i + BATCH_SIZE);
        const batchPromises = batch.map(async (sbtAddressLower) => {
          try {
            const cachedSBT = cachedSbtList[sbtAddressLower];
  
            if (cachedSBT && cachedSBT.sbtInfo && cachedSBT.mintedAddresses && cachedSBT.burnedAddresses) {
              // Use cached data
              return cachedSBT;
            } else {
              // Fetch data for new or incomplete SBTs
              const sbtInfo = await contractScripts.getSbtMetadata(this.props.provider, sbtAddressLower);
              const mintedAddresses = await contractScripts.getAddressesWhoMintedSBT(this.props.provider, sbtAddressLower);
              const burnedAddresses = await contractScripts.getAddressesWhoBurnedSBT(this.props.provider, sbtAddressLower);
  
              // Normalize addresses to lowercase
              const normalizedMintedAddresses = mintedAddresses.map((addr) => addr.toLowerCase());
              const normalizedBurnedAddresses = burnedAddresses.map((addr) => addr.toLowerCase());
  
              const result = {
                sbtAddress: sbtAddressLower,
                sbtInfo,
                mintedAddresses: normalizedMintedAddresses,
                burnedAddresses: normalizedBurnedAddresses,
                blockNumber: latestBlock,
              };
  
              return result;
            }
          } catch (error) {
            console.error(`Error processing SBT ${sbtAddressLower}:`, error);
            return null;
          }
        });
  
        const batchResults = await Promise.all(batchPromises);
  
        // Update processed results
        batchResults.forEach((result) => {
          if (result) {
            const sbtAddressLower = result.sbtAddress.toLowerCase();
            processedSbts[sbtAddressLower] = result;
          }
        });
      }
  
      const newCache = {
        ...cache,
        [networkID]: {
          ...cache[networkID],
          lastBlock: latestBlock,
          sbtList: {
            ...cachedSbtList,
            ...processedSbts,
          },
        },
      };
  
      localStorage.setItem('sbtCache', JSON.stringify(newCache));
      console.log('Updated sbtCache in MainSite:', newCache); // Added console log for debugging
    } catch (error) {
      console.error('Error initializing SBT cache:', error);
    }
  };  

  startSbtEventListener = () => {
    const provider = window.provider; // Assuming provider is set
    contractScripts.listenForSBTEvents(this.props.provider, this.onNewSbtEventDetected);
  };

  onNewSbtEventDetected = async (event) => {
    console.log("onNewSbtEventDetected() – invoked");
    console.log("event: ");
    console.log(event);

    const newEvent = event.blockNumber > this.state.latestBlockNumber;
    console.log("newEvent:", newEvent);
    console.log("event.blockNumber:", event.blockNumber);
    console.log("this.state.latestBlockNumber:", this.state.latestBlockNumber);

    // ------------------------------ SBT Events ------------------------------

    if (newEvent && event.eventSignature === "SBTCreated(address)") {
      await this.onSbtCreatedDetected(event);
    } else if (newEvent && event.eventSignature === "Issued(address,uint256)") {
      await this.onSbtIssuedDetected(event);
    } else if (newEvent && event.eventSignature === "Transfer(address,address,uint256)") {
      await this.onSbtTransferDetected(event);
    } else {
      console.log("Unhandled SBT event detected");
    }

    // Update the latestBlockNumber to the current block number
    if (newEvent) {
      this.setState({ latestBlockNumber: event.blockNumber });
    }
  };

  onSbtCreatedDetected = async (event) => {
    console.log("onSbtCreatedDetected() – invoked");
    // const provider = window.provider; // Assuming provider is set
    const network = this.props.network;
    const networkID = network?.id;
    const cache = JSON.parse(localStorage.getItem('sbtCache')) || {};

    try {
      const sbtAddress = event.args.sbtAddress.toLowerCase(); // event.args.sbtAddress
      const sbtInfo = await contractScripts.getSbtMetadata(this.props.provider, sbtAddress);
      let mintedAddresses = []; // No minted addresses yet
      let burnedAddresses = [];

      const newCache = {
        ...cache,
        [networkID]: {
          ...cache[networkID],
          sbtList: {
            ...cache[networkID]?.sbtList,
            [sbtAddress]: {
              sbtAddress,
              sbtInfo,
              mintedAddresses,
              burnedAddresses,
            },
          },
        },
      };

      localStorage.setItem('sbtCache', JSON.stringify(newCache));
      console.log("SBT cache updated with new SBT:", sbtAddress);
    } catch (error) {
      console.error('Error handling SBTCreated event:', error);
    }
  };

  onSbtIssuedDetected = async (event) => {
    console.log("onSbtIssuedDetected() – invoked");
    // const provider = window.provider; // Assuming provider is set
    const network = this.props.network;
    const networkID = network?.id;
    const cache = JSON.parse(localStorage.getItem('sbtCache')) || {};

    try {
      const toAddress = event.args.to.toLowerCase(); // event.args.to
      const tokenId = event.args.tokenId;
      const sbtAddress = event.address.toLowerCase();

      // Update cache
      if (cache[networkID] && cache[networkID].sbtList[sbtAddress]) {
        let mintedAddresses = cache[networkID].sbtList[sbtAddress].mintedAddresses || [];
        if (!mintedAddresses.includes(toAddress)) {
          mintedAddresses.push(toAddress);
        }

        cache[networkID].sbtList[sbtAddress].mintedAddresses = mintedAddresses;
        localStorage.setItem('sbtCache', JSON.stringify(cache));
        console.log(`Minted addresses updated for SBT ${sbtAddress}:`, mintedAddresses);
      } else {
        // If the SBT is not in cache, fetch metadata and initialize
        const sbtInfo = await contractScripts.getSbtMetadata(this.props.provider, sbtAddress);
        let mintedAddresses = [toAddress];
        let burnedAddresses = [];

        const newCache = {
          ...cache,
          [networkID]: {
            ...cache[networkID],
            sbtList: {
              ...cache[networkID]?.sbtList,
              [sbtAddress]: {
                sbtAddress,
                sbtInfo,
                mintedAddresses,
                burnedAddresses,
              },
            },
          },
        };

        localStorage.setItem('sbtCache', JSON.stringify(newCache));
        console.log("SBT cache initialized with new SBT and minted address:", sbtAddress);
      }
    } catch (error) {
      console.error('Error handling Issued event:', error);
    }
  };

  onSbtTransferDetected = async (event) => {
    console.log("onSbtTransferDetected() – invoked");
    // const provider = window.provider; // Assuming provider is set
    const network = this.props.network;
    const networkID = network?.id;
    const cache = JSON.parse(localStorage.getItem('sbtCache')) || {};

    try {
      const fromAddress = event.args.from.toLowerCase(); // event.args.from
      const toAddress = event.args.to.toLowerCase();   // event.args.to
      const tokenId = event.args.tokenId;
      const sbtAddress = event.address.toLowerCase();

      if (toAddress === '0x0000000000000000000000000000000000000000') {
        // Token burned
        if (cache[networkID] && cache[networkID].sbtList[sbtAddress]) {
          let burnedAddresses = cache[networkID].sbtList[sbtAddress].burnedAddresses || [];
          if (!burnedAddresses.includes(fromAddress)) {
            burnedAddresses.push(fromAddress);
          }

          cache[networkID].sbtList[sbtAddress].burnedAddresses = burnedAddresses;
          localStorage.setItem('sbtCache', JSON.stringify(cache));
          console.log(`Burned addresses updated for SBT ${sbtAddress}:`, burnedAddresses);
        }
      }
    } catch (error) {
      console.error('Error handling Transfer event:', error);
    }
  };

  // SBTs END *******************************************************************************

  // LATEST MATCH TAB START ****************************************************************

  loadDemoMatches = async () => {
    console.log("loadDemoMatches() - invoked");

    if (this.props.web2DemoMode) {

      this.setState({ latestMatchNumber: web2DemoData.length - 1})

      const web2DemoDataFixedBN = await this.traverseBignumberFix(web2DemoData)
      const web2DemoDataFixedBNAndTimestamps = this.updateDemoMatchTimestamps(web2DemoDataFixedBN)

      this.props.updateMatches(web2DemoDataFixedBNAndTimestamps);

      const latestMatchObj = web2DemoDataFixedBNAndTimestamps[web2DemoData.length - 1];
     
      this.props.updateCurrMatch(latestMatchObj);

      this.props.changeFocusedMatchID(web2DemoData.length)
    }
  }

  updateDemoMatchTimestamps = (dataArray) => {
    return dataArray.map(data => {
      if (data.matchStatus === 'ACTIVE') {
        // Get current Unix timestamp
        const currentTimestamp = moment().unix();
        
        // Update the votingStartTime to be 60 seconds before the current time
        data.votingStartTime = currentTimestamp - 30;
      }

      else if (data.matchStatus === 'PENDING') {
        // Get current Unix timestamp
        const currentTimestamp = moment().unix();
        
        // Update the matchStartTime to be 60 seconds after the current time
        data.matchStartTime = currentTimestamp + 60;
      }
  
      return data;
    });
  }

  loadLatestMatch = async () => {
    // retrieve match if it's not already being loaded + needs to be loaded
    if (!this.state.loadingLatestMatch && !this.state.loadedLatestMatch) {
      if (this.state.latestMatchNumber != null && this.state.latestMatchNumber > 0) {
        this.setState({ loadingLatestMatch: true })

        let latestMatchObj = await contractScripts.getMatchInfoByID(this.state.latestMatchNumber);  // #NORELOAD

        this.setState({ 
          loadedLatestMatch: true,         
          loadingLatestMatch: false,
         })

        this.props.updateCurrMatch(latestMatchObj);

        // update local cache's most-recent entry as well
        // if there have been any changes
        const lastRecordedMatch = this.props.matches[this.state.latestMatchNumber - 1]
        console.log("lastRecordedMatch")
        console.log(lastRecordedMatch)
        console.log("latestMatchObj")
        console.log(latestMatchObj)

        if (JSON.stringify(lastRecordedMatch) !== JSON.stringify(latestMatchObj)) {
          this.updateCurrMatchInLocalStorage(latestMatchObj)
        }
      }

      else { 
        console.log("loadLatestMatch - ELSE-BLOCK #1 #1 - this.state.latestMatchNumber == null");
      }
    }
  }

  loadLatestMatchNumber = async () => {
    console.log(" loadLatestMatchNumber() - invoked")
    let latestMatchNumber = await contractScripts.getLatestMatchByLobby(this.props.lobby, this.props.paid);
    console.log("latestMatchNumber:")
    console.log(latestMatchNumber);
    if (latestMatchNumber > 0) {
        this.setState({ latestMatchNumber: latestMatchNumber })
    }
  }

  loadAllMatches = async () => {
    console.log("loadAllMatches - invoked")
    let numLobbyMatches = await contractScripts.getNumberOfMatchesByLobby(this.props.lobby, this.props.paid);
    console.log("numLobbyMatches")
    console.log(numLobbyMatches)
    if (numLobbyMatches > 0 && !this.state.loadedAllMatches && !this.state.loadingAllMatches) {
      this.setState({ loadingAllMatches: true })
      let allMatchesObj = await contractScripts.getMatchesByLobby(this.props.lobby, this.props.paid);

      if (allMatchesObj.length != 0) {
        console.log("ALL MATCHES UPDATED")
        this.setState({ loadedAllMatches: true } );
        this.props.updateMatches(allMatchesObj);
        this.saveMatchesToLocalStorage(allMatchesObj)
        console.log("allmatchesObj:")
        console.log(allMatchesObj)
      }
    }
  }

  saveMatchesToLocalStorage = (matchesObj) => {
    console.log("saveMatchesToLocalStorage() - invoked");

    // convert array to string
    const matchesArrayString = JSON.stringify(matchesObj);

    // set key-value in localStorage
    localStorage.setItem('matchesCache', matchesArrayString);
  }

  loadMatchesFromLocalStorage = async () => {
    console.log("loadMatchesFromLocalStorage() - invoked");
    this.setState({ loadingAllMatches: true }); // blocks this.loadAllMatches() from being called before cache is searched
    const retrievedMatchesArrayString = localStorage.getItem('matchesCache');
    const matchesCache = JSON.parse(retrievedMatchesArrayString);

    if (this.state.latestMatchNumber == null) { await this.loadLatestMatchNumber() }

    console.log("matchesCache:");
    console.log(matchesCache)
    console.log("this.state.latestMatchNumber:")
    console.log(this.state.latestMatchNumber)

    if (matchesCache != null && matchesCache.length <= this.state.latestMatchNumber && this.state.searchForCachedMatches) {
      console.log("loadMatchesFromLocalStorage() - #1 - matchesCache != null");
      // check if latest match in matchesCache is the same as latest match in this component (this.state.latestMatchNumber)
      const latestMatchInCache = matchesCache[matchesCache.length - 1];
      console.log("latestMatchInCache:");
      console.log(latestMatchInCache);
      if (latestMatchInCache.ID == this.state.latestMatchNumber) {
        console.log("loadMatchesFromLocalStorage() - #1 #1 - latestMatchInCache.matchID == this.state.latestMatchNumber");
        // populates this.props.currMatch
        this.loadLatestMatch()
        this.setState({ loadedAllMatches: true, loadingAllMatches: false }); // TODO: should this be above?
        // if so, simply update global state:
        const matchesCacheWithProperBignumbers = await this.traverseBignumberFix(matchesCache);
        this.props.updateMatches(matchesCacheWithProperBignumbers);

      } else {
        // else, load missing matches from this.loadMissingMatches()
        this.loadMissingMatches(matchesCache);
      }
    }
    else { 
      this.setState({ loadingAllMatches: false }); // allows this.loadAllMatches() to work if cache empty
      this.loadAllMatches(); 
    }
  }

  traverseBignumberFix = async (data) => {
    if (Array.isArray(data)) {
      data.forEach(item => this.traverseBignumberFix(item));
    } else if (typeof data === 'object') {
      Object.entries(data).forEach(([key, value]) => {
        if (value && value.type === 'BigNumber') {
          data[key] = contractScripts.getBigNumber(value, "MemewarsSite.jsx");
        }
        this.traverseBignumberFix(value);
      });
    }
    return data;
  }

  getFocusedMatchObject = () => {
    const matches = this.props.matches;
    const focusedMatchID = this.props.focusedMatchID;

    if (matches[focusedMatchID - 1] != null) { 
      console.log("matches[focusedMatchID - 1]:")
      console.log(matches[focusedMatchID - 1])
      return matches[focusedMatchID - 1] 
    }
    else {
      console.log("getFocusedMatchObject() - focusedMatchID not found in matches[] array - loading from smart contracts")
      this.setState({ loadingFocusedMatch: true });

      const focusedMatchObj = this.loadMatchByID(focusedMatchID);

      this.setState({ 
        loadedFocusedMatch: true,
        loadingFocusedMatch: false 
       });

      return focusedMatchObj;
    } 
  }

  extendMatchesArrayForNewMatch = async () => {
    const matchesArrayCopy = [...this.props.matches];

    console.log("matchesArrayCopy:")
    console.log(matchesArrayCopy)

    // get latest match by ID
    const latestMatchObj = await contractScripts.getMatchInfoByID(this.state.latestMatchNumber)
    console.log("latestMatchObj:")
    console.log(latestMatchObj)

    const matchesArrayCopyWithLatestMatchAdded = matchesArrayCopy.concat(latestMatchObj);

    console.log("matchesArrayCopyWithLatestMatchAdded: ")
    console.log(matchesArrayCopyWithLatestMatchAdded)

    this.props.updateMatches(matchesArrayCopyWithLatestMatchAdded);
  }

  // LATEST MATCH TAB END ******************************************************************

  // SMART-CONTRACT EVENT HANDLERS START ***************************************************

  startEventListener = async () => {
    contractScripts.listenForNewEvents(this.onNewEventDetected)
    this.setState({ listeningForEvents: true })
  }

  onNewEventDetected = async (event) => {
    console.log("onNewEventDetected() – invoked")
    console.log("event: ")
    console.log(event)

    const newEvent = event.blockNumber > this.state.latestBlockNumber
    console.log("newEvent:")
    console.log(newEvent)
    console.log("event.blockNumber:")
    console.log(event.blockNumber)
    console.log("this.state.latestBlockNumber:")
    console.log(this.state.latestBlockNumber)

    // ------------------------------ Matches Events ------------------------------
    // NEW MATCH DETECTED
    if (newEvent && event.eventSignature == "NewMatch(uint256,bytes32,bool)") {
      this.onNewMatchDetected(event);
    }

    // MATCH INFO UPDATE
    else if (newEvent && event.eventSignature == "MatchInfoUpdate(uint256,bytes32,bool)") { 
      this.onMatchInfoUpdateDetected(event);
    }

    // MATCH CANCELLED
    else if (newEvent && event.eventSignature == "MatchCancelled(uint256,bytes32,bool)") {
      this.onMatchCancelledDetected(event);
    }

    // ----------------------------- Proposals Events -----------------------------
    // NEW PROPOSAL SUBMITTED
    if (newEvent && event.eventSignature == "NewProposalSubmitted(bytes32,bytes32,bool,address)") {
      this.onNewProposalSubmittedDetected(event);
    }

    else if (newEvent && event.eventSignature == "ProposalVoteChange(bytes32,int256)") {
      this.onProposalVoteChangeDetected(event);
    }

    else if (newEvent && event.eventSignature == "ProposalChosen(bytes32,bytes32,bool)") {
      this.onProposalChosenDetected(event);
    }

    else if (event.eventSignature == "DefaultProposalUpdated(bytes32,bytes32,bool)") {}

    else if (event.eventSignature == "NewLobbyCreated(string,bytes32)") {}
  }

  onNewMatchDetected = async (event) => {  
    const newMatchID = event.args[0].toNumber();
    var currMatchID = this.props.currMatch != null ? this.props.currMatch.ID : 0;
    
    if (newMatchID > currMatchID) {
        console.log("setting this.state.loadedLatestMatch = FALSE")

        // update this.state.latestMatchNumber
        this.setState({ latestMatchNumber: newMatchID })

        // adds +1 slot (onto array of all matches) with NEW MATCH
        // and updates cached matches array
        await this.extendMatchesArrayForNewMatch(); 
  
        // indicate need for reload of component state, so that loadLatestMatch() works
        this.setState({ loadedLatestMatch: false, loadingLatestMatch: false })

        // updates this.props.currMatch
        await this.loadLatestMatch();

        if (this.state.autoSnapToNewMatch) { this.props.changeFocusedMatchID(newMatchID) }
    }
  }

  onMatchInfoUpdateDetected = async (event) => {
    console.log("onMatchInfoUpdateDetected() – invoked")

    // NOTE: loadLatestMatch won't work otherwise
    this.setState({ loadedLatestMatch: false, loadingLatestMatch: false })
    await this.loadLatestMatch();                             
  }

  onMatchCancelledDetected = async (event) => {
    console.log("onMatchCancelledDetected() – invoked")
    
    // NOTE: loadLatestMatch won't work otherwise
    this.setState({ loadedLatestMatch: false, loadingLatestMatch: false })
    await this.loadLatestMatch();          

    if (this.props.loginComplete && this.props.currMatch != null) { await this.getLatestBalances(); }
  }

  // Proposals Events

  onNewProposalSubmittedDetected = async (event) => {
    console.log("onNewProposalSubmittedDetected() – invoked")
    this.setState({ loadedProposals: false, loadingProposals: false });
    this.getLobbyProposals();   
  }

  onProposalVoteChangeDetected = async (event) => {
    console.log("onProposalVoteChangeDetected() – invoked")
    this.setState({ loadedProposals: false, loadingProposals: false });
    this.getLobbyProposals();
  }

  onProposalChosenDetected = async (event) => {
    console.log("onProposalChosenDetected() – invoked")
    this.setState({ loadedProposals: false, loadingProposals: false });
    this.getLobbyProposals();
  }

  // SMART-CONTRACT EVENT HANDLERS END ***************************************************

  // FUTURE MATCHES / PROPOSALS START ------------------------------------------------------------

  getLobbyProposals = async () => {
    // load demo proposals if web2DemoMode enabled
    if (this.props.web2DemoMode) {
      console.log("getLobbyProposals() - loading demo proposals:");
      console.log(web2DemoProposals);
      this.props.updateProposals(web2DemoProposals);
      this.setState({ loadedProposals: true })
    }

    else if (!this.state.loadingProposals && !this.state.loadedProposals) {
      this.setState({ loadingProposals: true })
      let lobbyProposals = await contractScripts.getProposalsByLobby(this.props.lobby, this.props.paid);

      // getProposalsByLobby() returns [] if smart-contract call unsuccessful
      const emptyProposalsResponse = [];
      if (lobbyProposals != emptyProposalsResponse) {
        this.props.updateProposals(lobbyProposals);  // #NORELOAD
        this.setState({ loadedProposals: true })
      }

      this.setState({ loadingProposals: false });

      }
    }

  // FUTURE MATCHES / PROPOSALS END --------------------------------------------------------------

  // update latest block # in component state
  // TODO: handle error if it occurs (https://wagmi.sh/docs/hooks/useBlockNumber#return-value)
  updateLatestBlockNumber = async () => {
      const lastBlockNumber = await contractScripts.getLatestBlockNumber(this.props.provider); // "none" uses the window.default provider
      this.setState({  latestBlockNumber: lastBlockNumber  }); 
  }

  // React-Router Paths START ------------------------------------------------------------

  getMainView = (relevantMatch) => {
    // MAIN SITE VIEW
    if (this.props.path == "/" || this.props.path == "" ) {
      return ( 
        <div id={styles.main}>
          <MainAreaTabs 
            updateProposals={(lobbyProposals) => this.props.updateProposals(lobbyProposals)}
            changeFocusedTab={(newTabIndex) => this.props.changeFocusedTab(newTabIndex)} 
            updateXPBalance={(newXPBalance) => this.props.updateXPBalance(newXPBalance)} // TODO: have one function (here) which handles the newXP value (clients just call UpdateXPBalance())
            toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
            // updateLoginInfo={(newLoginStatus) => this.props.updateLoginInfo(newLoginStatus)}
            updateCurrMatch={(latestMatchObj) => this.props.updateCurrMatch(latestMatchObj)}
            updateMatches={(allMatchesObj) => this.props.updateMatches(allMatchesObj)}
            //
            toggleDemoMode={(demoModeOn) => this.props.toggleDemoMode(demoModeOn)} 
            // ************************* 
            // profile={this.props.profile}
            // lobbyInfo={this.props.lobbyInfo}
            // sessionState={this.props.sessionState}
            // ************************* 
            account={this.props.account} 
            provider={this.props.provider}
            joined={this.props.joined}
            XPBalance={this.props.XPBalance} 
            ETHbalance={this.props.ETHBalance} 
            availableETH={this.props.availableETH}
            lobby={this.props.lobby}
            paid={this.props.paid} 
            match={relevantMatch} // #CURRLOGIC 
            currMatch={this.props.currMatch} // #CURRLOGIC 
            matches={this.props.matches}
            proposals={this.props.proposals}
            focusedTab={this.props.focusedTab}
            focusedMatchID={this.props.focusedMatchID}
            focusedEntry={this.props.focusedEntry}
            loginComplete={this.props.loginComplete}
            loginInProgress={this.props.loginInProgress}
            // DEMO MODE
            demoMode={this.props.web2DemoMode}
            // NETWORK
            network={this.props.network} // this comes through WAGMI HOC
          />

          <RightsideAlt
            numPlayers={this.props.numPlayers} 
            account={this.props.account} 
            // web2DemoMode={this.props.web2DemoMode}
            demoMode={this.props.web2DemoMode}
            // network={this.props.network}
          />

        </div>
      );
    }

    else if (this.props.path == "/polis") {

        //
  
      }
  
  
  
      // check for address comparison before individual address page
  
      else if (this.props.path.includes("/compare/")) {
  
        // pull first address out of URL, it starts with 0x and finishes with & 
  
        // (where the second address begins)
  
        const firstAddress = this.props.path.slice(4, 46);
  
        // console.log("firstAddress:")
  
        // console.log(firstAddress)
  
        return (
  
          <CompareAddresses firstAddress={firstAddress}/>
  
        );
  
      }
  
  
  
      // check for survey comparison before individual address page
  
      else if (this.props.path.includes("/surveys") || this.props.path.includes("/survey/") || this.props.path.includes("/questions")) {
  
        // pull first address out of URL, it starts with 0x and finishes with & 
  
        const surveyID = this.props.path.slice(8, 74);
  
        // Check if there is a second slash after "/survey/" and an Ethereum address
  
        const pathParts = this.props.path.split('/');
  
        const displayAnswerMode = pathParts.length > 3 && pathParts[3].startsWith('0x') && pathParts[3].length === 42;
  
  
  
        const viewResponseAddress = displayAnswerMode ? pathParts[3] : null;
  
  
  
        console.log("surveyID:")
  
        console.log(surveyID)
  
        console.log("displayAnswerMode:")
  
        console.log(displayAnswerMode)
  
  
  
        return (
  
          <SurveyPage
  
            surveyID={surveyID}
  
            displayAnswerMode={displayAnswerMode} // Added prop
  
            viewAddress={viewResponseAddress} // Added prop
  
            //
  
            toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
  
            account={this.props.account} 
  
            provider={this.props.provider}
  
            joined={this.props.joined}
  
            XPBalance={this.props.XPBalance} 
  
            ETHbalance={this.props.ETHBalance} 
  
            availableETH={this.props.availableETH}
  
            lobby={this.props.lobby}
  
            paid={this.props.paid} 
  
            loginComplete={this.props.loginComplete}
  
            loginInProgress={this.props.loginInProgress}
  
            //
  
            network={this.props.network}
  
          /> 
  
        );
  
      }
  
  
  
      // MainSite.jsx (add this conditional before the address comparison)
  
      else if (this.props.path.includes("/question/")) {
  
        const pathParts = this.props.path.split('/');
  
        const questionIndex = pathParts.indexOf('question');
  
        const questionID = pathParts[questionIndex + 1];
  
        const responderAddress = pathParts[questionIndex + 2] || null;
  
  
  
        // console.log("questionID:", questionID);
  
        // console.log("responderAddress:", responderAddress);
  
  
  
        return (
  
          <SurveyTool
  
            questionID={questionID}
  
            responderAddress={responderAddress}
  
            singleQuestionMode={true}
  
            //
  
            toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
  
            account={this.props.account}
  
            provider={this.props.provider}
  
            joined={this.props.joined}
  
            XPBalance={this.props.XPBalance}
  
            // lobby={this.props.lobby}
  
            // paid={this.props.paid}
  
            loginComplete={this.props.loginComplete}
  
            loginInProgress={this.props.loginInProgress}
  
            //
  
            network={this.props.network}
  
          />
  
        );
  
      }
  
  
  
      else if (this.props.path.includes("/sbts")) {
  
        return (
  
          <SBTsPage
  
          provider={this.props.provider}
  
          account={this.props.account}
  
          network={this.props.network}
  
          modalView={true} // only true from CommunityTab.jsx
  
          //
  
          loginComplete={this.props.loginComplete}
  
          toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
  
          expanded={true}
  
          //
  
          miniaturized={false}
  
          />
  
        );
  
      }
  
  
  
      // check for SBT page before individual address page
  
      // the SBT url may be url/sbt/0xAddress/ClaimPassword, 
  
      // so we store the sbtAddress in a prop called sbtAddress
  
      // and the password in a prop called sbtPassword
  
      else if (this.props.path.includes("/sbt/")) {
  
          const path = this.props.path;
  
          // Extract the SBT address and optional claim password from the URL
  
          const pathParts = path.split('/');
  
          const sbtAddress = pathParts[2];
  
          const sbtPassword = pathParts.length > 3 ? pathParts[3] : null;
  
          // console.log("SBT Address:", sbtAddress);
  
          // console.log("SBT Password:", sbtPassword);
  
    
  
          return (
  
            <SBTPage
  
              SBTAddress={sbtAddress}
  
              sbtMintPassword={sbtPassword}
  
              toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
  
              account={this.props.account}
  
              provider={this.props.provider}
  
              joined={this.props.joined}
  
              XPBalance={this.props.XPBalance}
  
              // ETHBalance={this.props.ETHBalance}
  
              // availableETH={this.props.availableETH}
  
              // lobby={this.props.lobby}
  
              // paid={this.props.paid}
  
              loginComplete={this.props.loginComplete}
  
              loginInProgress={this.props.loginInProgress}
  
              //
  
              network={this.props.network}
  
              chains={this.props.wagmiChainOptions}
  
              blockNumber={this.props.wagmiBlocknumber}
  
            />
  
          );
  
      }
  
  
  
      // check for simulated user page, determined by whether 
  
      // the path includes /su/<simUsername>
  
      else if (this.props.path.includes("/su/")) {
  
        const simUsername = this.props.path.slice(4);
  
        // console.log("simUsername:")
  
        // console.log(simUsername)
  
        return (
  
          <SimulatedUserPage
  
            simUsername={simUsername}
  
            provider={this.props.provider}
  
            network={this.props.network}
  
          />
  
        );
  
      }
  
  
  
      // Check if viewAddress is defined and contains "0x"
  
      else if (this.props.path.includes("0x")) {
  
        // to distinguish from normal use of "address" in props, which is the 
  
        // signed-in address of the user (not necessarily the same as address they are viewing)
  
        const viewAddress = this.props.path.slice(1).replace("u/", "");
  
        // console.log("viewAddress:")
  
        // console.log(viewAddress)
  
        
  
        // if provider is undefined, pass in wagmiProvider
  
        // TODO: sort out provider logic
  
        // const provider = this.props.provider == undefined ? this.props.wagmiProvider : this.props.provider;
  
  
  
        // console.log("this.props.wagmiProvider")
  
        // console.log(this.props.wagmiProvider)
  
  
  
        return (
  
            <UserPage 
  
              viewAddress={viewAddress} 
  
              address={this.props.address} 
  
              provider={this.props.provider}
  
              network={this.props.network}
  
            />
  
        );
  
      }
  
  
  
      else if (this.props.path == "/about") {
  
        return (
  
          <AboutPage />
  
        );
  
      }
  
  
  
      else if (this.props.path == "/matrix") {
  
        return (
  
          <RiskMatrixDemo />
  
        );
  
      }
  
  
  
      // TODO: not sure why trailing slash shows up on this link and not others
  
      else if (this.props.path == "/contracts" || this.props.path == "/contracts/") {
  
        return (
  
          <ContractPage />
  
        );
  
      }
  }

  render() {

    const currMatchDefined = this.props.currMatch != null;
    const noMatches = this.props.focusedMatchID == null || this.props.matches.length == 0;
    const currMatchIsFocused = noMatches || currMatchDefined && this.props.focusedMatchID.toString() == this.props.currMatch.ID.toString();
    
    const relevantMatch = currMatchIsFocused ? this.props.currMatch : this.getFocusedMatchObject();

    console.log("this.props.loginComplete:" + this.props.loginComplete)

    const mainViewDisplay = this.getMainView(relevantMatch); 

    return (
        <>
          {/* <GreetingModal /> */}
          <Navbar 
              updateXPBalance={(newXPBalance) => this.newXPBalance(newXPBalance)} 
              toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
              updateLoginInfo={(newLoginStatus) => this.props.updateLoginInfo(newLoginStatus)}
              toggleDemoMode={(demoModeOn) => this.props.toggleDemoMode(demoModeOn)}
              demoMode={this.props.web2DemoMode}
              account={this.props.account} 
              provider={this.props.provider}
              joined={this.props.joined}
              XPBalance={this.props.XPBalance} 
              ETHbalance={this.props.ETHBalance} 
              availableETH={this.props.availableETH}
              lobby={this.props.lobby}
              paid={this.props.paid} 
              focusedTab={this.props.focusedTab}
              loginComplete={this.props.loginComplete}
              loginInProgress={this.props.loginInProgress}
              sendTestETH={(amountToSend) => this.getUserTestETH(amountToSend)} 
          />

          { mainViewDisplay }

          <Footer
            toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
          />
        </>
    );
  }
}

MainSite.propTypes = {
  // *** functions (get + set) ***
  updateProposals: PropTypes.func.isRequired, // Used in UpNextMatch.jsx TODO
  fetchSessionState: PropTypes.func.isRequired,
  fetchAccount: PropTypes.func.isRequired, 
  // TODO: fetchLobby?
  changeFocusedTab: PropTypes.func.isRequired,
  updateXPBalance: PropTypes.func.isRequired,
  updateETHBalance: PropTypes.func.isRequired,
  toggleLoginModal: PropTypes.func.isRequired,
  updateLoginInfo: PropTypes.func.isRequired,
  updateCurrMatch: PropTypes.func.isRequired,
  updateMatches: PropTypes.func.isRequired,
  toggleDemoMode: PropTypes.func.isRequired,
  //
  changeFocusedMatchID: PropTypes.func.isRequired,
  // *** profile state ***
  profile: PropTypes.object,
  account: PropTypes.string,
  provider: PropTypes.string,
  joined: PropTypes.bool,
  XPBalance: PropTypes.object,    // ethers.js BigNumber object 
  ETHBalance: PropTypes.object,   // ethers.js BigNumber object 
  availableETH: PropTypes.object,
  // *** lobby state ***
  lobbyInfo: PropTypes.object,
  lobby: PropTypes.string,
  paid: PropTypes.bool,
  currMatch: PropTypes.object,
  matches: PropTypes.array.isRequired,
  // proposals: state.lobbyInfo.proposals,
  // *** session state ***
  sessionState: PropTypes.object,
  focusedTab: PropTypes.number,       // TODO: change to `focusedTabIndex`
  loginComplete: PropTypes.bool,
  loginInProgress: PropTypes.bool,
  focusedMatchID: PropTypes.number,   // TODO: string?
  web2DemoMode: PropTypes.bool,
  //focusedEntry: PropTypes.bool,     // TODO: string (URL)?
};

// TODO: export profile (state.profile) / lobbyInfo / sessionState etc.
// TODO: make sure .isRequired is being used intelligently
const mapStateToProps = state => ({
  // profile state
  profile: state.profile,
  account: state.profile.account,
  provider: state.profile.provider, 
  network: state.profile.network, // TODO: this should be moved to 'session state'
  joined: state.profile.joined,
  XPBalance: state.profile.XPBalance,
  ETHBalance: state.profile.ETHBalance,
  availableETH: state.profile.availableETH,
  // lobby state
  lobbyInfo: state.lobbyInfo,
  lobby: state.lobbyInfo.lobby,
  paid: state.lobbyInfo.paid,
  currMatch: state.lobbyInfo.currMatch,
  matches: state.lobbyInfo.matches,
  proposals: state.lobbyInfo.proposals,
  // session state
  sessionState: state.sessionState,
  focusedTab: state.sessionState.focusedTab,
  focusedMatchID: state.sessionState.focusedMatchID,
  focusedEntry: state.sessionState.focusedEntry,
  loginComplete: state.sessionState.loginComplete,
  loginInProgress: state.sessionState.loginInProgress,
  web2DemoMode: state.sessionState.web2DemoMode,
});

const MainSiteWithWagmiHooks = WagmiHooksHOC(MainSite)

export default connect(mapStateToProps, {
    fetchAccount, 
    fetchSessionState, 
    changeFocusedTab, 
    toggleLoginModal, 
    updateLoginInfo,
    updateCurrMatch,
    updateMatches,
    updateXPBalance,
    updateETHBalance,
    updateProposals,
    changeFocusedMatchID,
    toggleDemoMode
                          })(MainSiteWithWagmiHooks);




// POSSIBLE PROPS FOR OTHER COMPONENTS:
// 
// updateProposals={(lobbyProposals) => this.props.updateProposals(lobbyProposals)}
// changeFocusedTab={(newTabIndex) => this.props.changeFocusedTab(newTabIndex)} 
// updateXPBalance={(newXPBalance) => this.props.updateXPBalance(newXPBalance)} // TODO: have one function (here) which handles the newXP value (clients just call UpdateXPBalance())
// toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
// updateLoginInfo={(newLoginStatus) => this.props.updateLoginInfo(newLoginStatus)}
// updateCurrMatch={(latestMatchObj) => this.props.updateCurrMatch(latestMatchObj)}
// updateMatches={(allMatchesObj) => this.props.updateMatches(allMatchesObj)}
// // ************************* 
// profile={this.props.profile}
// lobbyInfo={this.props.lobbyInfo}
// sessionState={this.props.sessionState}
// // ************************* 
// account={this.props.account} 
// provider={this.props.provider}
// joined={this.props.joined}
// XPBalance={this.props.XPBalance} 
// ETHbalance={this.props.ETHBalance} 
// availableETH={this.props.availableETH}
// lobby={this.props.lobby}
// paid={this.props.paid} 
// currMatch={this.props.currMatch}
// matches={this.props.matches}
// proposals={this.props.proposals}
// focusedTab={this.props.focusedTab}
// focusedMatchID={this.props.focusedMatchID}
// focusedEntry={this.props.focusedEntry}
// loginComplete={this.props.loginComplete}
// loginInProgress={this.props.loginInProgress}