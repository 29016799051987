import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
// import { updateCurrMatch } from '../../actions/lobbyActions.js';

// CSS
// import styles from "./Buttons.module.scss";

// reactstrap components
import { Button } from 'reactstrap';

// Scripts for Smart Contract Interactions
import contractScripts from './contractScripts.js';

// TODO .env: make tab names / indexes an .env variable
// 2 is current index of "Upcoming Matches" tab
var upcomingMatchesTabIndex = 2;
var onClickFunction;

class InitiateMatchButton extends Component {
  componentDidMount() { }

  componentDidUpdate() {
    // console.log("InitiateMatchButton - UPDATED")
    // console.log(actionFunction)
    // console.log(onClickFunction)
    // console.log("this.props.focusedTab:")
    // console.log(this.props.focusedTab)
    // console.log(upcomingMatchesTabIndex)
    // console.log(this.props.focusedTab == upcomingMatchesTabIndex)
  }

  // **** BUTTON POSSIBLE ACTIONS START TODO

  sendInitTX = async () => {
    await contractScripts.initiateMatch(
      this.props.account,
      this.props.provider,
      this.props.lobby, 
      this.props.paid
    );
  }

  launchLoginModal = () => {
    const modalOpen = true;
    this.props.toggleLoginModal(modalOpen)
  }

  goToUpcomingMatches = () => {
    this.props.changeFocusedTab(upcomingMatchesTabIndex);
  }

  // **** BUTTON POSSIBLE ACTIONS END TODO


  getButtonFunction = () => {
    if (this.props.focusedTab != upcomingMatchesTabIndex) {
      return this.goToUpcomingMatches
    }

    else if (this.props.loginComplete) {
      return this.sendInitTX
    }

    else {
      return this.launchLoginModal
    }
  }




  render() {

    const buttonText = this.props.text;
    // const focusedTab = this.props.focusedTab;

    onClickFunction = this.getButtonFunction();
    // console.log("onClickFunction")
    // console.log(onClickFunction)

    // onClickFunction = this.props.loginComplete ? this.sendInitTX : this.launchLoginModal;

    // actionFunction = this.props.loginComplete ? 
    //   this.sendInitTX 
    //   : 
    //   this.launchLoginModal;

    // // onClickFunction = focusedTab !== undefined && focusedTab == upcomingMatchesTabIndex ? 
    // onClickFunction = this.props.focusedTab == upcomingMatchesTabIndex ? 
    //   actionFunction
    //   :
    //   this.goToUpcomingMatches;



    const relevantID = this.props.homepage ? "loadingScreenInitButton" : "startMatchButton";

    return (
      //  <Button onClick={onClickFunction} id={relevantID} color="startMatch" type="btn"> 
      <Button onClick={onClickFunction} id={relevantID} color="startMatch" type="btn"> 
          { buttonText }
      </Button>
    );
  }
}

// InitiateMatchButton.propTypes = {
//     updateCurrMatch: PropTypes.func.isRequired,
//     account: PropTypes.string,
//     provider: PropTypes.string,
//     lobby: PropTypes.string,
//     paid: PropTypes.bool,
//     currMatch: PropTypes.object,
//     matches: PropTypes.array.isRequired,
//   };
  
//   const mapStateToProps = state => ({
//     account: state.profile.account,
//     provider: state.profile.provider, 
//     lobby: state.lobbyInfo.lobby,
//     paid: state.lobbyInfo.paid,
//     currMatch: state.lobbyInfo.currMatch,
//     matches: state.lobbyInfo.matches,
//   });
  
// export default connect(mapStateToProps, { updateCurrMatch })(InitiateMatchButton);

export default InitiateMatchButton

