import { BrowserRouter, useLocation, useNavigate, useParams } from "react-router-dom";
  
  function withRouter(Component) {
    function ComponentWithRouterProp(props) {

      // const useGetLocation = () => {
      //   let result = useLocation();
      //   console.log("result: " + result)
      //   return result;

      // }
  
      // const useGetNavigate = () => {
      //   let result = useNavigate();
      //   console.log("result: " + result)
      //   return result;
      // }
  
      // const useGetParams = () => {
      //   let result = useParams();
      //   console.log("result: " + result)
      //   return result;
      // }

      const params = useParams()
      const navigate = useNavigate()
      const location = useLocation()
      // const navigate = useNavigate()
      // const location = useLocation()

      return (
        // <BrowserRouter>
          <Component
            {...props}
            params={params}
            location={location}
            navigate={navigate}
          />
        // </BrowserRouter> 
      );
    }
  
    return ComponentWithRouterProp;
  }
  
  export default withRouter;