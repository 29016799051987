import React, { useEffect, useState, useRef } from 'react';
import contractScripts from '../Buttons/contractScripts.js';
import proposalScripts from '../UpcomingMatches/proposalScripts.js';
import styles from './SBTsList.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faExpand, faSync, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'reactstrap';
import SBTPage from './SBTPage';
import { featured_SBTs_LIST } from '../../variables/CONTRACT_ADDRESSES.js';

const SBTsList = ({ provider, modalView, network, account, loginComplete, miniaturized }) => {
  const [sbtList, setSbtList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cache, setCache] = useState(JSON.parse(localStorage.getItem('sbtCache')) || {});
  const [refreshing, setRefreshing] = useState(false);
  const [lastFetchBlock, setLastFetchBlock] = useState(0);
  
  const isMounted = useRef(true);
  const BATCH_SIZE = 5;

  const fetchSBTs = async (forceRefresh = false, showLoading = true) => {
    if (showLoading) {
      setLoading(true);
    }
    let sbtDetails = [];
    let latestBlock;

    const networkID = network?.id;
    if (!networkID) {
      console.error('Network ID is undefined in SBTsList. Cannot proceed.');
      if (showLoading) {
        setLoading(false);
      }
      return;
    }

    try {
      const cachedNetworkData = cache[networkID];
      const cachedSbtList = cachedNetworkData ? cachedNetworkData.sbtList : {};
      const cacheLatestBlock = cachedNetworkData ? cachedNetworkData.lastBlock : 0;
      latestBlock = await contractScripts.getLatestBlockNumber(provider);

      // Fetch new SBTs if there are new blocks or force refresh
      let newSbts = [];
      if (cacheLatestBlock < latestBlock || forceRefresh) {
        newSbts = await contractScripts.getSbtsCreated(
          provider,
          cacheLatestBlock + 1,
          latestBlock
        );
      }

      // Combine existing and new SBT addresses
      const existingSbtAddresses = Object.keys(cachedSbtList).map(addr => addr.toLowerCase());
      const newSbtAddresses = newSbts.map((sbt) => sbt.sbtAddress.toLowerCase());
      const allSbtAddresses = [...new Set([...existingSbtAddresses, ...newSbtAddresses])];

      // Process SBTs, consulting cache first
      const processedSbts = {};
      for (let i = 0; i < allSbtAddresses.length; i += BATCH_SIZE) {
        const batch = allSbtAddresses.slice(i, i + BATCH_SIZE);
        const batchPromises = batch.map(async (sbtAddressLower) => {
          try {
            const cachedSBT = cachedSbtList[sbtAddressLower];

            if (cachedSBT && cachedSBT.sbtInfo && cachedSBT.mintedAddresses && cachedSBT.burnedAddresses) {
              // Use cached data
              return cachedSBT;
            } else {
              // Fetch data for new or incomplete SBTs
              const sbtInfo = await contractScripts.getSbtMetadata(provider, sbtAddressLower);
              const mintedAddresses = await contractScripts.getAddressesWhoMintedSBT(
                provider,
                sbtAddressLower
              );
              const burnedAddresses = await contractScripts.getAddressesWhoBurnedSBT(
                provider,
                sbtAddressLower
              );

              // Normalize addresses to lowercase
              const normalizedMintedAddresses = mintedAddresses.map((addr) => addr.toLowerCase());
              const normalizedBurnedAddresses = burnedAddresses.map((addr) => addr.toLowerCase());

              const result = {
                sbtAddress: sbtAddressLower,
                sbtInfo,
                mintedAddresses: normalizedMintedAddresses,
                burnedAddresses: normalizedBurnedAddresses,
                blockNumber: latestBlock,
              };

              return result;
            }
          } catch (error) {
            console.error(`Error processing SBT ${sbtAddressLower}:`, error);
            return null;
          }
        });

        const batchResults = await Promise.all(batchPromises);

        // Update processed results
        batchResults.forEach((result) => {
          if (result) {
            const sbtAddressLower = result.sbtAddress.toLowerCase();
            processedSbts[sbtAddressLower] = result;
          }
        });

        // Update UI with progress if component is still mounted
        if (isMounted.current) {
          sbtDetails = Object.values({ ...cachedSbtList, ...processedSbts });
          setSbtList(sbtDetails);
        }
      }

      const newCache = {
        ...cache,
        [networkID]: {
          ...cache[networkID],
          lastBlock: latestBlock,
          sbtList: {
            ...cachedSbtList,
            ...processedSbts,
          },
        },
      };

      localStorage.setItem('sbtCache', JSON.stringify(newCache));
      console.log('Updated sbtCache in SBTsList:', newCache); // Added console log for debugging
      setCache(newCache);
      setLastFetchBlock(latestBlock);
      sbtDetails = Object.values(newCache[networkID].sbtList);

      // Filter out incomplete SBTs
      sbtDetails = sbtDetails.filter(
        (sbt) => sbt && sbt.sbtInfo && sbt.mintedAddresses && sbt.burnedAddresses
      );
      sbtDetails.sort((a, b) => {
        const netMintedA = a.mintedAddresses.length - a.burnedAddresses.length;
        const netMintedB = b.mintedAddresses.length - b.burnedAddresses.length;
        return netMintedB - netMintedA;
      });

      // Remove duplicates
      const uniqueSbtDetails = [];
      const seenAddresses = new Set();
      for (const sbt of sbtDetails) {
        const address = sbt.sbtAddress.toLowerCase();
        if (!seenAddresses.has(address)) {
          seenAddresses.add(address);
          uniqueSbtDetails.push(sbt);
        }
      }

      if (isMounted.current) {
        setSbtList(uniqueSbtDetails);
        if (showLoading) {
          setLoading(false);
        }
      }
    } catch (error) {
      console.error('Error fetching SBTs:', error);
      if (isMounted.current) {
        if (showLoading) {
          setLoading(false);
        }
      }
    }
  };
 
  useEffect(() => {
    isMounted.current = true;
    let refreshInterval;
  
    const setupAutoRefresh = async () => {
      await fetchSBTs(false, true); // showLoading = true during initial load
  
      refreshInterval = setInterval(async () => {
        if (isMounted.current) {
          const currentBlock = await contractScripts.getLatestBlockNumber(provider);
          if (currentBlock > lastFetchBlock) {
            console.log("New blocks detected, updating...");
            await fetchSBTs(false, false); // showLoading = false during background refresh
          }
        }
      }, 30000); // Check every 30 seconds
    };
  
    setupAutoRefresh();
    initializeEventListener();
  
    return () => {
      isMounted.current = false;
      if (refreshInterval) {
        clearInterval(refreshInterval);
      }
      contractScripts.removeSBTEventListener(provider);
    };
  }, [provider, network]);
  

  const initializeEventListener = () => {
    contractScripts.listenForSBTEvents(provider, handleNewEvent);
  };

  const handleNewEvent = async (event) => {
    console.log('New SBT event detected:', event);
    if (event.type === 'SBTCreated') {
      await updateCacheFromEvent(event);
    }
  };

  const updateCacheFromEvent = async (event) => {
    const networkID = network?.id; // Updated here
    try {
      const sbtAddress = event.sbtAddress;
      const sbtInfo = await contractScripts.getSbtMetadata(provider, sbtAddress);
      let mintedAddresses = await contractScripts.getAddressesWhoMintedSBT(provider, sbtAddress);
      let burnedAddresses = await contractScripts.getAddressesWhoBurnedSBT(provider, sbtAddress);
  
      // Normalize addresses to lowercase
      mintedAddresses = mintedAddresses.map((addr) => addr.toLowerCase());
      burnedAddresses = burnedAddresses.map((addr) => addr.toLowerCase());
  
      const newCache = {
        ...cache,
        [networkID]: {
          ...cache[networkID],
          sbtList: {
            ...cache[networkID]?.sbtList,
            [sbtAddress]: {
              sbtAddress,
              sbtInfo,
              mintedAddresses,
              burnedAddresses,
            },
          },
        },
      };
  
      localStorage.setItem('sbtCache', JSON.stringify(newCache));
      setCache(newCache);
  
      if (isMounted.current) {
        setSbtList((prevList) => {
          const newList = [...prevList];
          const index = newList.findIndex((sbt) => sbt.sbtAddress === sbtAddress);
          if (index === -1) {
            newList.push({ sbtAddress, sbtInfo, mintedAddresses, burnedAddresses });
          } else {
            newList[index] = { sbtAddress, sbtInfo, mintedAddresses, burnedAddresses };
          }
          return newList;
        });
      }
    } catch (error) {
      console.error('Error handling new SBT event:', error);
    }
  };
  

  const handleRefresh = async () => {
    setRefreshing(true);
    await fetchSBTs(true);
    setRefreshing(false);
  };

  const handleClearCache = async () => {
    localStorage.removeItem('sbtCache');
    setCache({});
    await fetchSBTs(true);
  };

  // Existing render methods and helper functions remain unchanged
  const renderSBT = (sbt) => {
    const { sbtAddress, sbtInfo, mintedAddresses, burnedAddresses } = sbt;
    const { name, description, image } = sbtInfo;
    const netMinted = mintedAddresses.length - burnedAddresses.length;

    return (
      <button
        key={sbtAddress}
        className={styles.sbtItem}
        onClick={() => window.location.href = `/sbt/${sbtAddress}`}
      >
        <div className={styles.sbtImage}>
          <img src={image} alt="SBT Thumbnail" width="150" height="150" />
        </div>
        <div className={styles.sbtInfo}>
          <p className={styles.sbtName}>{name}</p>
          <p className={styles.sbtDescription}>{description}</p>
          <p className={styles.sbtNetMinted}>Net Minted: {netMinted}</p>
        </div>
      </button>
    );
  };

  const isMintingLive = (sbt) => {
    const now = Math.floor(Date.now() / 1000);
    console.log("sbt:", sbt);
    if (sbt.sbtInfo && sbt.sbtInfo.mintingEndTime !== undefined) {
      return sbt.sbtInfo.mintingEndTime === 0 || sbt.sbtInfo.mintingEndTime > now;
    } else {
      console.log(`sbt.sbtInfo or sbt.sbtInfo.mintingEndTime is undefined for sbtAddress: ${sbt.sbtAddress}`);
      return false;
    }
  };

  if (loading) {
    return (
      <div className={styles.spinner}>
        <FontAwesomeIcon icon={faSpinner} spin size="2x" />
      </div>
    );
  }

  const mintingLiveSBTs = sbtList.filter(isMintingLive);
  const expiredSBTs = sbtList.filter(sbt => !isMintingLive(sbt));

  if (miniaturized) {
    return (
      <div id={styles.sbtListContainer}>
        <h2 className={styles.sectionTitle}>Featured SBTs</h2>
        <div className={styles.sbtGrid}>
          {featured_SBTs_LIST.map((sbtAddress, index) => (
            <SBTPage
              key={index}
              SBTAddress={sbtAddress}
              account={account}
              provider={provider}
              network={network}
              miniaturized={true}
              loginComplete={loginComplete}
            />
          ))}
        </div>
        <h2 className={styles.sectionTitle}>Minting Live</h2>
        <div className={styles.sbtGrid}>
          {mintingLiveSBTs.map((sbt) => (
            <SBTPage
              key={sbt.sbtAddress}
              SBTAddress={sbt.sbtAddress}
              account={account}
              provider={provider}
              network={network}
              miniaturized={true}
              loginComplete={loginComplete}
            />
          ))}
        </div>
        <div className={styles.container}>
          <button onClick={() => window.open("/sbts", "_blank")} className={styles.expandButton}>
            <FontAwesomeIcon icon={faExpand} /> View All SBTs
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.sbtListContainer}>
      <div className={styles.header}>
        <Button
          className={styles.refreshButton}
          onClick={handleRefresh}
          disabled={refreshing}
        >
          <FontAwesomeIcon icon={faSync} spin={refreshing} /> Refresh
        </Button>
        <Button
          className={styles.clearCacheButton}
          onClick={handleClearCache}
          disabled={refreshing}
        >
          <FontAwesomeIcon icon={faTrash} /> Clear Cache
        </Button>
      </div>

      <h2 className={styles.sectionTitle}>Featured SBTs</h2>
      <div id={styles.featuredSBTsContainer}>
        {featured_SBTs_LIST.map((sbtAddress, index) => (
          <SBTPage
            key={index}
            SBTAddress={sbtAddress}
            account={account}
            provider={provider}
            network={network}
            miniaturized={true}
            loginComplete={loginComplete}
          />
        ))}
      </div>

      <h2 className={styles.sectionTitle}>Minting Live</h2>
      {mintingLiveSBTs.map(renderSBT)}

      <h2 className={styles.sectionTitle}>Minting Expired</h2>
      {expiredSBTs.map(renderSBT)}
    </div>
  );
};

export default SBTsList;
