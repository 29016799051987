import React, { Component } from 'react';

// CSS
import 'assets/css/m_w.css';
import styles from "./MemeMatch.module.scss";

// images
import defaultThumbnail from '../../assets/img/seedsmanTransparent.png';

// Scripts
import memeMatchScripts from './memeMatchScripts.js';
import contractScripts from '../Buttons/contractScripts.js'

// reactstrap components
import { Card, CardBody, CardFooter, Col } from 'reactstrap';


// Components
// import CollapseBidPrompt from './CollapseBidPrompt.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faExpandArrowsAlt, faCaretDown, faArrowUp, faStar, faUser } from '@fortawesome/free-solid-svg-icons'
// import VoteOnMatchButton from 'components/Buttons/VoteOnMatchButton.jsx';

// import BN from 'bn.js';
// const BN = require('bn.js');


var pendingCardGlowUpdate = styles.pendingCardGlowUpdateLoad;

class PendingMemeCard extends Component {
  state = {
    highBidder: false,
    entryClicked: false,
    competitorBid: '',
    submission: '',
    youreHighestBidderNoticeMode: true, // TODO: the janky "you and your xx can chill for now"
  };

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    
    if (prevProps.match.competitors[this.props.entryNumber].address != this.props.match.competitors[this.props.entryNumber].address ) {
      // console.log("competitor changed");
      if (pendingCardGlowUpdate == styles.pendingCardGlowUpdateLoad) pendingCardGlowUpdate = styles.pendingCardGlowUpdateOne;
      else pendingCardGlowUpdate = pendingCardGlowUpdate == styles.pendingCardGlowUpdateOne ? styles.pendingCardGlowUpdateTwo : styles.pendingCardGlowUpdateOne;
    }
  }

  onSubmit = e => {
    e.preventDefault();
  };

  buildThumbnail = memeFormat => {};

  cardClicked = () => {
    // console.log('entry / meme clicked');
    // window.mixpanel.track("MEME_MATCH_INTERACT", {"MATCH_STATE": "PENDING"});
    // window.mixpanel.time_event('LOGIN_STARTED');

    this.setState({ entryClicked: !this.state.entryClicked });
  };

  getBidderMessage = () => {
    const currencyIcon = this.props.match.paid ? <div id="minBidIcon"><i class="fab fa-ethereum"></i></div> : <div id="minBidIcon"><i id="coinIcon" class="fas fa-up-down"></i></div>;
    switch (this.props.match.competitors[this.props.entryNumber].address.toLowerCase()) {
        case undefined:
          return null;

        case "0x0000000000000000000000000000000000000000":
          return (
              <h3 id={styles.noCompetitorMessage}> 
                <FontAwesomeIcon icon={faSpinner} pulse id={styles.noCompetitorIcon} /> 
                <div id={styles.noCompetitorText}>
                  {/* No Competitor Yet */}
                  No Contestant
                </div>
              </h3> 
          );
        // case toLowerCase(this.props.profile.account) == toLowerCase(this.props.match.competitors[this.props.entryNumber].address):
        case this.props.account.toLowerCase():
            const yourBid = contractScripts.decimalEighteen(this.props.match.competitors[this.props.entryNumber].competitorBid, "PendingMemeCard");
            return (
                <>
                <FontAwesomeIcon icon={faSpinner} pulse id={styles.noCompetitorIcon} /> 
                <div id={styles.currentHighBidText}> Your bid is </div>
                <div id={styles.currentHighBidTextNumber}> { yourBid } </div> {" "}
                <div id={styles.highBidIcon}> {" "} { currencyIcon } </div>
                </>
            );
    
        default:
            const highBid = contractScripts.decimalEighteen(this.props.match.competitors[this.props.entryNumber].competitorBid, "PendingMemeCard");
            const isTopSlot = this.props.entryNumber == 0;
            const bidderMessage = isTopSlot ? "Top Bid is" : "Next bid is";
            return (
            <>
            <FontAwesomeIcon icon={faSpinner} pulse id={styles.noCompetitorIcon} /> 
            <div id={styles.currentHighBidText}> { bidderMessage } </div> 
            <div id={styles.currentHighBidTextNumber}> { highBid } </div> {" "}
             <div id={styles.highBidIcon}>  { currencyIcon } </div>
            </>
            );
    }
  };

  render() {
    var match = this.props.match;
    var proposal = this.props.match.proposal;

    const highBidder = this.props.account.toLowerCase() == this.props.match.competitors[0].address.toLowerCase() && this.props.entryNumber == 0;

    var cardColor = proposal.format !== undefined ? 'transparent' : memeMatchScripts.getMemeCardColor(proposal.format);

    var highBidderMessage = match == undefined ? <div> <i class="fas fa-spinner fa-pulse"></i> </div> : this.getBidderMessage();

    // TODO: Toggle/Convert ETH <---> USD
    var currencyIcon = proposal.paid ? <div id="minBidIcon"><i class="fab fa-ethereum"></i></div> : <div id="minBidIcon"><i id="coinIcon" class="fas fa-up-down"></i></div>;

    const leftRightID= this.props.entryNumber != 0 ? styles.pendingMemeCardLeft : styles.pendingMemeCardRight
    const cardID = this.props.match.competitors == null ? styles.pendingMemeCard : leftRightID;

    var highBid = contractScripts.decimalEighteen( this.props.match.competitors[0].competitorBid, "PendingMemeCard");

    var bidPromptDisplay = highBidder && this.state.youreHighestBidderNoticeMode ? 
    <div id={styles.youTheHighBidderText}> You and your { highBid } {" "} { currencyIcon } can chill for now...</div>
    :
    <> </> // TODO

    // <CollapseBidPrompt 
    //   loggedIn={this.props.loggedIn}
    //   match={this.props.match}
    //   entryNumber={this.props.entryNumber}
    //   // ********* TODO
    //   // changeFocusedTab={(newTabIndex) => this.props.changeFocusedTab(newTabIndex)} 
    //   updateXPBalance={(newXPBalance) => this.props.updateXPBalance(newXPBalance)} // TODO: have one function (here) which handles the newXP value (clients just call UpdateXPBalance())
    //   toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
    //   // updateLoginInfo={(newLoginStatus) => this.props.updateLoginInfo(newLoginStatus)}
    //   // updateCurrMatch={(latestMatchObj) => this.props.updateCurrMatch(latestMatchObj)}
    //   // updateMatches={(allMatchesObj) => this.props.updateMatches(allMatchesObj)}
    //   // ************************* 
    //   // profile={this.props.profile}
    //   lobbyInfo={this.props.lobbyInfo}
    //   sessionState={this.props.sessionState}
    //   // ************************* 
    //   account={this.props.account} 
    //   provider={this.props.provider}
    //   joined={this.props.joined}
    //   XPBalance={this.props.XPBalance} 
    //   ETHbalance={this.props.ETHBalance} 
    //   availableETH={this.props.availableETH}
    //   lobby={this.props.lobby}
    //   paid={this.props.paid} 
    //   currMatch={this.props.currMatch}
    //   // matches={this.props.matches}
    //   // proposals={this.props.proposals}
    //   // focusedTab={this.props.focusedTab}
    //   focusedMatchID={this.props.focusedMatchID}
    //   focusedEntry={this.props.focusedEntry}
    //   loginComplete={this.props.loginComplete}
    //   loginInProgress={this.props.loginInProgress}
    // />;

    // var bidPromptDisplay = this.props.loggedIn ? bidPrompt : null;
    // var bidPromptDisplay = this.props.loginComplete ? bidPrompt : null;

    if (highBidder ) {
      highBidderMessage =                 
        <>
          <FontAwesomeIcon icon={faSpinner} pulse id={styles.noCompetitorIcon} /> 
          <div id={styles.currentHighBidText}> You're the high bidder {" "} </div> 
        </>;
    }

    return (
        <Card id={cardID} data-background-color={cardColor}>
          <CardBody id={styles.pendingCardBody}>
            {/* { voterAppeal } */}
            <h3 id={pendingCardGlowUpdate} className={styles.glowUpdateText}>
            { highBidderMessage }
            </h3>
            
            { bidPromptDisplay }

              {/* <CardFooter id={styles.pendingMemeCardFooter}>
              </CardFooter> */}
          </CardBody>
        </Card>
    );
  }
}

export default PendingMemeCard;