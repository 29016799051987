import React, { Component } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard';

// CSS
import '../../assets/css/m_w.css'
import styles from "./Login.module.scss";

// .env variables TODO: none
import { ETH_FAUCET_LINK } from '../../variables/CONTRACT_ADDRESSES.js';


export class RPCFaucetNotice extends Component {
    state = {
      faucetLinkCopiedToClipboard: false,
    }
    render() {

        const rpcURLNoticeID = this.props.metamaskButtonMode ?
        styles.testnetFaucetNoticeSmall 
        :
        styles.testnetFaucetNotice;
        return (
            <div>
                <h4 id={rpcURLNoticeID}>
{/* <div id={styles.faucetLinkAndDetail}>
<a href="https://faucet.skale.network/" target="_blank" id={styles.faucetButton}> Get test ETH (for site interactions) 
  


    <div id={styles.testnetLinkDetail}>
    </div>
    </a> 
</div> */}

<div id={styles.rpcURLNotice}>
  <div id={styles.rpcURLTitle}>
    {/* Endpoint / RPC (paste at link) */}
    {/* RPC URL ("Custom RPC" input) */}
    {/* RPC URL  */}
    Network / RPC-URL
    </div>
    <h5 id={styles.rpcURL}> 
      <p id={styles.rpcURLText}> 
        { ETH_FAUCET_LINK }
      </p>


{/* COPY TO CLIPBOARD START */}

<div id={styles.copyLinkButton}>

      {/* <CopyToClipboard text={ ETH_FAUCET_LINK } */}
      <CopyToClipboard text={ ETH_FAUCET_LINK.toString() }
      onCopy={() => this.setState({faucetLinkCopiedToClipboard: true})}>
        {/* <h5 id={styles.rpcURL}>  */}
        <div>
      {/* https://meme-skale-rpc.skalelabs.com * TODO: hardcoded */}
        {/* TODO ^^ NO CSS */}
         {/* Copy  */}
         {!this.state.faucetLinkCopiedToClipboard ? <button id={styles.copyButtonBeforePress}> Copy </button> : null}
         {this.state.faucetLinkCopiedToClipboard ? <button id={styles.copyButtonAfterPress}> Copied </button> : null}
         {/* TODO ^^^ const copyLinkButtonID = this.state.faucetLinkCopiedToClipboard ? styles.copyButtonAfterPress : styles.copyButtonBeforePress; */}
      {/* { ETH_FAUCET_LINK } */}
      </div>
      {/* </h5> */}
{/* <button>Copy to clipboard with button</button> */}
</CopyToClipboard>
</div>




{/* COPY TO CLIPBOARD END */}

    </h5>
</div>
    {/* <a href="https://faucet.skale.network/" target="_blank" id={styles.testETHFaucetLink}> Get test ETH </a>  */}
  </h4>

            </div>
        )
    }
}

export default RPCFaucetNotice
