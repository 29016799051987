// TODO: get rid of most of this in favor of storing in Wagmi context: account, provider, network, availableETH

import { FETCH_ACCOUNT, LOGIN_ACCOUNT, CHANGE_NETWORK, UPDATE_XP_BALANCE, UPDATE_ETH_BALANCE } from '../actions/types';

const initialState = {
  account: '',          // ETH address connected to site
  provider: 'none',     // options are currently: wagmi (metamask / rainbow / etc.) and web3Auth (formerly Torus)
  network: null,        // TODO ??? Use for transitioning purchased ETH from mainnet -> SKALE
  joined: false,        // true iff user has interacted with m_w smart-contracts before (and been assigned XP)
  availableETH: {},     // TODO: empty obj? // Mak
  XPBalance: {},        // (ethers.js BigNumber Object): Quantity of XP held 
  ETHBalance: {},       // (ethers.js BigNumber Object): Quantity of ETH deposited to m_w 
  alerts: [],           // TODO: move to sessionState / for example: given an address, check if user has unclaimed winnings or has been outbid etc
  userImageURL: null,   // Currently used when user logs in with Torus
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOGIN_ACCOUNT:
      return {
        ...state,
        account: action.payload.account,
        provider: action.payload.provider,
        // web3: action.payload.web3,
        network: action.payload.network,
        joined: action.payload.joined,
        availableETH: action.payload.availableETH,
        XPBalance: action.payload.XPBalance,
        ETHBalance: action.payload.ETHBalance,
        userImageURL: action.payload.userImageURL,
      };
    case FETCH_ACCOUNT:
      return {
        ...state,
        account: action.payload.account,
        provider: action.payload.provider,
        // web3: action.payload.web3,
        network: action.payload.network,
        joined: action.payload.joined,
        availableETH: action.payload.availableETH,
        XPBalance: action.payload.XPBalance,
        ETHBalance: action.payload.ETHBalance,
        userImageURL: action.payload.userImageURL,
      };
    case UPDATE_XP_BALANCE:
      return {
        ...state,
        account: action.payload.account,
        provider: action.payload.provider,
        network: action.payload.network,
        joined: action.payload.joined,
        availableETH: action.payload.availableETH,
        XPBalance: action.payload.XPBalance,
        ETHBalance: action.payload.ETHBalance,
      };
    case UPDATE_ETH_BALANCE:
      return {
        ...state,
        account: action.payload.account,
        provider: action.payload.provider,
        network: action.payload.network,
        availableETH: action.payload.availableETH,
        XPBalance: action.payload.XPBalance,
        ETHBalance: action.payload.ETHBalance
        // TODO: does this whole thing need updating?
      };
    case CHANGE_NETWORK:
      return {
        ...state,
        network: action.payload.network, // TODO: handle changed network (ex: mainnet -> SKALE CHAIN -> BACK)
      };

    // case UPDATE_BALANCES:
    default:
      return state;
  }
}
