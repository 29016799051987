import React, { Component } from "react";

// CSS & Images
// import "./ContributorsArea.module.scss";
import styles from "../AboutPage/AboutPage.module.scss";

import RiskMatrix from "../MainContent/RiskMatrix.jsx";

class MatrixDemo extends Component {
    state = {
      hasNftAccess: false,
      password: ""
    };
  
    componentDidMount() {
    }
  
    componentDidUpdate(prevState) {
    }
  
    checkNFTStatus = () => {
      // Placeholder logic to set the hasNftAccess state
      // You will need to replace this with your own logic to check for the presence of the correct ERC-721 NFT
  
      if (this.state.password == "password") {
          return true;
          // this.setState({ hasNftAccess: true });
      }
  
      else {
          return false;
          // this.setState({ hasNftAccess: false });
      }
    };
  
    handlePasswordChange = event => {
      console.log("event: " + event);
      this.setState({ password: event.target.value });
      console.log("password: " + event.target.value);    
      this.checkNFTStatus();
    }
  
    handleSubmit = event => {
      event.preventDefault();
      const hasNFT = this.checkNFTStatus();
  
      if (hasNFT && !this.state.hasNftAccess) {
          this.setState({ hasNftAccess: true });
      }
  
      // logic to check password and update hasNftAccess state
      // ...
    }
  
    render() {
      const { hasNftAccess, password } = this.state;
  
      return (
      <>
         <RiskMatrix />
      </>
      );
    }
  }
  
  export default MatrixDemo;