import { FETCH_ACCOUNT, LOGIN_ACCOUNT, UPDATE_XP_BALANCE, UPDATE_ETH_BALANCE } from './types';
import store from '../store.js';
// import socketIOClient from "socket.io-client";
// import { SERVER, SERVER_HTTPS } from '../variables/CONTRACT_ADDRESSES.js';

var socket; // socketIO connection

export const fetchAccount = () => dispatch => {
  // Check redux store for state (TODO: safe?)
  var currAcct = store.getState().profile.account;
  var currWeb3provider = store.getState().profile.provider;
  var currNetwork = store.getState().profile.network;
  var currJoined = store.getState().profile.joined;
  var currAvailableETH = store.getState().profile.availableETH;
  var currXPBal = store.getState().profile.XPBalance;
  var currETHBal = store.getState().profile.ETHBalance;
  var currUserImageURL = store.getState().profile.userImageURL;

  // TODO: put XP balance info in here?

  // DETECT MM
  // const isMM = window.ethereum !== undefined;
  // const isMMLoggedIn = window.ethereum.selectedAddress !== null;

  // if (isMM && isMMLoggedIn) {
  //   // console.log("isMM && isMMLoggedIn")
  //   // TODO: this commandis also in MetamaskLoginButton2.jsx (consolidate logic?)
  //   // await window.ethereum.enable();
  // }

  const web3info = {
    account: currAcct,
    provider: currWeb3provider,
    network: currNetwork,
    joined: currJoined,
    availableETH: currAvailableETH,
    XPBalance: currXPBal,
    ETHBalance: currETHBal,
    userImageURL: currUserImageURL
  }; // TODO search what struct these should be / change name from MW

  dispatch({
    type: FETCH_ACCOUNT,
    payload: web3info,
  });
};

export const changeAccount = web3info => dispatch => {
  console.log('account changed to ' + web3info.account + ' provided by ' + web3info.provider);
  // console.log(web3info)
  // socket = socketIOClient(SERVER);
  // socket = socketIOClient(SERVER_HTTPS);
  // Send user test funds
  // socket.send(web3info.account.toString()); // TODO: Use 'ack" parameter?
  // socket.send("MESSAGE from accountActions.js"); // TODO: Use 'ack" parameter?
  // console.log("sent socket message about " + web3info.account.toString())
  // console.log("sent socket message to server")
  // socket.disconnect();



  // const web3info = {
  //     accountMW: acct,
  //     providerMW: web3provider
  // };

  // const wasTorus = sessionStorage.getItem("pageUsingTorus"); //TODO don't use sessionStorage, move to redux?
  // const MMIncoming = web3info.providerMW==="Metamask";

  // TORUS LOGOUT

  // if (wasTorus && MMIncoming) {
  //     // torus.cleanUp();
  // }

  dispatch({
    type: LOGIN_ACCOUNT,
    payload: web3info,
  });
};

export const updateXPBalance = newXP => dispatch => {

  console.log("updateXPBalance invoked, newXP:")
  console.log(newXP.toString())

  // Check redux store for state (TODO: safe?)
  var currAcct = store.getState().profile.account;
  var currWeb3provider = store.getState().profile.provider;
  var currAvailableETH = store.getState().profile.availableETH;
  var currNetwork = store.getState().profile.network;
  var currETHBal = store.getState().profile.ETHBalance; // TODO: do you need to update all non-related store entries?

  // indicates user has joined MW iff newXP != '-1' (placeholder in BalanceDisplay.jsx which allows for "Joining..." message)) TODO: put this is in its own function
  var joinedMW = newXP === '-1' ? false : true;

  const web3info = {
    account: currAcct,
    provider: currWeb3provider,
    network: currNetwork,
    joined: joinedMW,                       
    availableETH: currAvailableETH,
    XPBalance: newXP,
    ETHBalance: currETHBal
  }; 

  dispatch({
    type: UPDATE_XP_BALANCE,
    payload: web3info,
  });
};

export const updateETHBalance = newETH => dispatch => {
  // Check redux store for state (TODO: safe?)
  var currAcct = store.getState().profile.account;
  var currWeb3provider = store.getState().profile.provider;
  var currNetwork = store.getState().profile.network;
  var currAvailableETH = store.getState().profile.availableETH;
  var currXP = store.getState().profile.XPBalance;

  const web3info = {
    account: currAcct,
    provider: currWeb3provider,
    network: currNetwork,
    availableETH: currAvailableETH,
    XPBalance: currXP,
    ETHBalance: newETH
  }; 
  
  dispatch({
    type: UPDATE_ETH_BALANCE,
    payload: web3info,
  });
};

