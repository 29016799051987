import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

// styles
import styles from './ExplorerTab.module.scss';

const ExplorerTabPluginExpanded = ({ name, subtext, explainText, disabled, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const aboutText = <>  </>;

  return (
    <>
      <div className={styles.secureAddressDisplay} onClick={toggle}>
       
        <span className={styles.secureAddress}>

        {isOpen ? 
          <FontAwesomeIcon icon={faCaretUp} id={styles.aboutCaret} /> : 
          <FontAwesomeIcon icon={faCaretDown} id={styles.aboutCaret} />
        }

          {isOpen? 
           explainText :
           <div id={styles.aboutText}> ABOUT </div>
          }
           </span>

      </div>      
    </>
  );
};

ExplorerTabPluginExpanded.propTypes = {
  name: PropTypes.string,
  subtext: PropTypes.string,
  explainText: PropTypes.string,
  disabled: PropTypes.bool,
  content: PropTypes.node
};

export default ExplorerTabPluginExpanded;
