import React, { Component } from "react";
// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
// import { fetchAccount } from '../../actions/accountActions.js';

// CSS + images
import styles from "./Navbar.module.scss";
// import Logo from "assets/img/header_logo.png";
// import Logo from "assets/img/zuzalu_header_text_logo.png";
// import Logo from "assets/img/ukHeader.png";
// import Logo from "assets/img/ukHeaderTextWhite.png";
// import Logo from "assets/img/rxc_header_logo_white.png";
import Logo from "assets/img/ce_header_logo.png";
// import Logo from "assets/img/ce_icon_logo.png";
// import Logo from "assets/img/butterfly.png";
// import Logo from "assets/img/ce_butterfly_logo.png";
import upLeftArrow from "assets/img/upLeftArrow.png";


// Components (TODO))
// import FreePaidToggle from './FreePaidToggle.jsx';
// import AccountDisplay from '../Navbar/AccountDisplay.jsx';
import AccountSection from "./AccountSection.jsx";

class Navbar extends Component {
  state = {
    // loggedIn = false, 
    // NOTE ^ : info stored in global `this.props.loginComplete`
  }
  
  componentDidMount() { }

  componentDidUpdate(prevProps) { 
  }

  logoClicked = () => {
    console.log("logo clicked");
    // navigate to home page
    window.location.href = "/";
  }

  render() {
 
  const beforeLogin = (
    <>
        <div id={styles.navbarContainer}>
          <div id={styles.navbarLogoCol}>
            <img id={styles.mainLogo} src={Logo} fluid="true" alt="logo" onClick={this.logoClicked}>

            </img>
            {/* {this.props.focusedTab == 3 ? 
              <div id={styles.upLeftArrowContainer}>
                <img id={styles.upLeftArrow} src={upLeftArrow} fluid="true" alt="upLeftArrow"></img>
              </div>
            : null} */}
          </div>
          <div id={styles.accountSection}>
              <AccountSection 
                demoMode={this.props.demoMode}
                toggleDemoMode={(demoModeOn) => this.props.toggleDemoMode(demoModeOn)} 
                //
                // network={this.props.network} // this comes through WAGMI HOC
                // loginComplete={this.props.loginComplete}
              />
          </div>
        </div>
    </>
  )

  const afterLogin = (
    <>
        <div id={styles.navbarContainerLoggedIn}>
            <div id={styles.navbarLogoColLoggedIn}>
              <img id={styles.mainLogoLoggedIn} src={Logo} fluid="true" alt="mw_logo"></img>
            </div>
            <div id={styles.accountSectionLoggedIn}>
              <AccountSection 
                account={this.props.account} 
                provider={this.props.provider}
                joined={this.props.joined}
                paid={this.props.paid} 
                XPBalance={this.props.XPBalance} 
                ETHbalance={this.props.ETHBalance} 
                availableETH={this.props.availableETH}
                loginComplete={this.props.loginComplete}
                loginInProgress={this.props.loginInProgress}
                sendTestETH={(amountToSend) => this.props.sendTestETH(amountToSend)} 
                demoMode={this.props.demoMode}
                toggleDemoMode={(demoModeOn) => this.props.toggleDemoMode(demoModeOn)} 
                //
                // network={this.props.network}
                />
            </div>
          </div>
    </>
  )

  var navBarDisplay = this.props.loginComplete ? afterLogin : beforeLogin;

  return ( 
    navBarDisplay 
    );
  }
}

export default Navbar;
