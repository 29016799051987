const web2DemoData = [
    {
        "ID": 1,
        "lobby": "main",
        "matchStatus": "OVER",
        "proposal": {
            "ID": "0x38615001c7b48d61e77d2e59209b71fdc5d1316858159dcfb9d7212ac9b5dffe",
            "submitter": "0x0000000000000000000000000000000000000000",
            "paid": false,
            "format": "IMAGE",
            "prompt": "Our AI Strategy",
            "voterEntryCost": {
                "type": "BigNumber",
                "hex": "0x4563918244f40000"
            },
            "minCompetitorBid": {
                "type": "BigNumber",
                "hex": "0x8ac7230489e80000"
            },
            "allowedJoiningTime": 300,
            "allowedVotingTime": 120,
            "timeSubmitted": 1693715996,
            "upvotes": 68,
            "chosen": false,
            "defaultProposal": true
        },
        "numVoters": 42,
        "voters": [
            "0xF876394a516c95b75cbd9c2a42B0334c4a8ae832" * 42
        ],
        "votes": [
            42,
            0
        ],
        "competitors": [
            {
                "address": "0x32bebb1Ae942cdE4d1bE5C01Fa2A9D131FFb4729",
                "competitorBid": {
                    "type": "BigNumber",
                    "hex": "0x01e5b8fa8fe2ac0000"
                },
                // "submissionLink": "imgur.com/examplePic2"
                "submissionLink": "https://media.tenor.com/8EIM9j-q-iEAAAAd/creation-of-adam-fist-bump.gif"
            },
            {
                "address": "0x1787C84ae967d3C90e189416cD198d01e012cd05",
                "competitorBid": {
                    "type": "BigNumber",
                    "hex": "0x8ac7230489e80000"
                },
                "submissionLink": "https://media1.giphy.com/media/v1.Y2lkPTc5MGI3NjExN2hmaXViZG81ZTI0Znpyb3B0aHM0MTRqNzdiM2U3MWliOWd0MnVoZCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3oKIPwoeGErMmaI43S/giphy.gif"
            }
        ],
        "prizePool": "0.0",
        "prizeShare": "5.0",
        "competitorPrize": "10.0",
        "matchStartTime": 1693722607,
        "votingStartTime": 1693723077,
        "isJackpotMatch": false
    },
    {
        "ID": 2,
        "lobby": "main",
        "matchStatus": "ACTIVE",
        "proposal": {
            "ID": "0x38615001c7b48d61e77d2e59209b71fdc5d1316858159dcfb9d7212ac9b5dffe",
            "submitter": "0x0000000000000000000000000000000000000000",
            "paid": false,
            "format": "TEXT",
            "prompt": "Should we try to pause AI?",
            "voterEntryCost": {
                "type": "BigNumber",
                "hex": "0x4563918244f40000"
            },
            "minCompetitorBid": {
                "type": "BigNumber",
                "hex": "0x8ac7230489e80000"
            },
            "allowedJoiningTime": 300,
            "allowedVotingTime": 120,
            "timeSubmitted": 1693715996,
            "upvotes": 7,
            "chosen": false,
            "defaultProposal": true
        },
        "numVoters": 41,
        "voters": [
            "0xF876394a516c95b75cbd9c2a42B0334c4a8ae832",
            "0xF876394a516c95b75cbd9c2a42B0334c4a8ae832",
            "0xF876394a516c95b75cbd9c2a42B0334c4a8ae832",
            "0xF876394a516c95b75cbd9c2a42B0334c4a8ae832",
            "0xF876394a516c95b75cbd9c2a42B0334c4a8ae832",
            "0xF876394a516c95b75cbd9c2a42B0334c4a8ae832",
            "0xF876394a516c95b75cbd9c2a42B0334c4a8ae832",
            "0xF876394a516c95b75cbd9c2a42B0334c4a8ae832",
            "0xF876394a516c95b75cbd9c2a42B0334c4a8ae832",
            "0xF876394a516c95b75cbd9c2a42B0334c4a8ae832",
            "0xF876394a516c95b75cbd9c2a42B0334c4a8ae832",
        ],
        "votes": [
            5,
            6
        ],
        "competitors": [
            {
                "address": "0x32bebb1Ae942cdE4d1bE5C01Fa2A9D131FFb4729",
                "competitorBid": {
                    "type": "BigNumber",
                    "hex": "0x01e5b8fa8fe2ac0000"
                },
                // "submissionLink": "https://i.stack.imgur.com/pN1nt.gif"
                // "submissionLink": "https://media.tenor.com/8EIM9j-q-iEAAAAd/creation-of-adam-fist-bump.gif"
                submissionLink: "YES"
            },
            {
                "address": "0x1787C84ae967d3C90e189416cD198d01e012cd05",
                "competitorBid": {
                    "type": "BigNumber",
                    "hex": "0x8ac7230489e80000"
                },
                // "submissionLink": "https://media.tenor.com/8EIM9j-q-iEAAAAd/creation-of-adam-fist-bump.gif"
                // "submissionLink": "https://media1.giphy.com/media/v1.Y2lkPTc5MGI3NjExN2hmaXViZG81ZTI0Znpyb3B0aHM0MTRqNzdiM2U3MWliOWd0MnVoZCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3oKIPwoeGErMmaI43S/giphy.gif"
                // "submissionLink": "../assets/img/etherum.png"
                submissionLink: "NO"
            }
        ],
        "prizePool": "55.0",
        "prizeShare": "0.0",
        "competitorPrize": "10.0",
        "matchStartTime": 1693722607,
        "votingStartTime": 1693723077,
        "isJackpotMatch": false
    },
    // {
    //     "ID": 3,
    //     "lobby": "main",
    //     "matchStatus": "PENDING",
    //     "proposal": {
    //         "ID": "0x38615001c7b48d61e77d2e59209b71fdc5d1316858159dcfb9d7212ac9b5dffe",
    //         "submitter": "0x0000000000000000000000000000000000000000",
    //         "paid": false,
    //         "format": "IMAGE",
    //         "prompt": "",
    //         "voterEntryCost": {
    //             "type": "BigNumber",
    //             "hex": "0x4563918244f40000"
    //         },
    //         "minCompetitorBid": {
    //             "type": "BigNumber",
    //             "hex": "0x8ac7230489e80000"
    //         },
    //         "allowedJoiningTime": 300,
    //         "allowedVotingTime": 120,
    //         "timeSubmitted": 1693715996,
    //         "upvotes": 3,
    //         "chosen": false,
    //         "defaultProposal": true
    //     },
    //     "numVoters": 0,
    //     "voters": [],
    //     "votes": [
    //         0,
    //         0
    //     ],
    //     "competitors": [
    //         {
    //             "address": "0x32bebb1Ae942cdE4d1bE5C01Fa2A9D131FFb4729",
    //             "competitorBid": {
    //                 "type": "BigNumber",
    //                 "hex": "0x01e5b8fa8fe2ac0000"
    //             },
    //             "submissionLink": "imgur.com/examplePic2"
    //         },
    //         {
    //             "address": "0x1787C84ae967d3C90e189416cD198d01e012cd05",
    //             "competitorBid": {
    //                 "type": "BigNumber",
    //                 "hex": "0x8ac7230489e80000"
    //             },
    //             "submissionLink": "imgur.com/examplePic1"
    //         }
    //     ],
    //     "prizePool": "0.0",
    //     "prizeShare": "0.0",
    //     "competitorPrize": "0.0",
    //     "matchStartTime": 1693722607,
    //     "votingStartTime": 0,
    //     "isJackpotMatch": false
    // }
  ];
  
  export default web2DemoData;
  