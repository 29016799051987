import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import styles from './UserPage.module.scss'; // Ensure correct import path
import proposalScripts from 'components/UpcomingMatches/proposalScripts';
// import aiScripts from 'path-to/aiScripts.js'; // Adjust this import path as needed

const CompareAddress = ({ firstAddress }) => {
  const [compareAddresses, setCompareAddresses] = useState([]);
  const [showComparison, setShowComparison] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Extract addresses from the URL or use firstAddress
    const pathAddresses = location.pathname.split('/compare/')[1]?.split('&');
    if (pathAddresses && pathAddresses.length > 0) {
      setCompareAddresses(pathAddresses);
      if (pathAddresses.length > 1 || location.pathname.includes('&')) {
        setShowComparison(true);
      }
    } else {
      setCompareAddresses([firstAddress, '']);
    }
  }, [firstAddress, location.pathname]);

  const handleCompareAddressChange = (index, event) => {
    let newCompareAddresses = [...compareAddresses];
    newCompareAddresses[index] = event.target.value.trim();
    setCompareAddresses(newCompareAddresses);
  };

  const addCompareAddress = () => {
    setCompareAddresses([...compareAddresses, '']);
  };

  const isValidAddress = (address) => {
    // Placeholder logic for Ethereum address or ENS name validation
    return address.length === 42 && address.startsWith('0x') || address.endsWith('.eth');
  };

  const performComparison = () => {
    const validAddresses = compareAddresses.filter(address => isValidAddress(address) && address !== '');
    if (validAddresses.length > 1) {
      setShowComparison(true);
      const path = `/compare/${validAddresses.join('&')}`;
      navigate(path);

      // Call AI script for generating comparison (Placeholder)
      // aiScripts.generateComparison(validAddresses.map(address => 
      //   contractScripts.getSurveyAndAccountDataByAddress(address)));

    } else {
      alert("Please enter at least one valid Ethereum address or ENS name for comparison.");
    }
  };

  const renderComparisonSections = () => {
    return compareAddresses.map((address, index) => (
      <div key={index} className={styles.comparisonSection}>
        <h4 id={styles.compareAddressesEntry}>Address {index + 1}: {proposalScripts.getShortenedAddress(address)}</h4>
        {/* Placeholder for graphical comparison */}
        <div className={styles.dot} style={{ backgroundColor: `hsl(${index * 50}, 70%, 50%)` }}></div>
        {/* Additional comparison details can be added here */}
      </div>
    ));
  };

  return (
    <div className={styles.compareSection}>
      {compareAddresses.map((address, index) => (
        <div key={index} className={styles.addressInput}>
          <input
            type="text"
            placeholder="Enter Ethereum address / ENS"
            value={address}
            onChange={(e) => handleCompareAddressChange(index, e)}
          />
          {index === compareAddresses.length - 1 && (
            <FontAwesomeIcon icon={faPlus} onClick={addCompareAddress} className={styles.plusIcon} />
          )}
        </div>
      ))}
      <button onClick={performComparison}>Compare Views & Activity</button>
      <Collapse isOpen={showComparison}>
        <div className={styles.comparisonSummary}>
          {renderComparisonSections()}
          <div className={styles.agreementSection}>
            <h5>Main Agreements</h5>
            <p>Placeholder for main agreements between addresses...</p>
          </div>
          <div className={styles.disagreementSection}>
            <h5>Main Disagreements</h5>
            <p>Placeholder for main disagreements between addresses...</p>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default CompareAddress;
