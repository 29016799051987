import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons"; // TODO: fa-library

// import proposalScripts from '../UpcomingMatches/proposalScripts.js';

import styles from "./Navbar.module.scss";

export const AccountDisplayTorus = (props) => {

  const shortenedAddress = `${props.account.slice(0, 4)}...${props.account.slice(-4)}`;
//   const shortenedAddress = proposalScripts.getShortenedAddress(props.account);

  return (
    <>
        <button className={styles.addressButton} style={{
        display: 'flex',
        alignItems: 'center', 
        justifyContent: 'space-between', 
        backgroundColor: '#ffffffbf',
        borderRadius: '20px',
        color: 'black',
        fontSize: '16px',
        fontWeight: `650`,
        padding: '6px 20px',
        width: '250px'
        }} onClick={() => {
        props.launchWeb3Settings();
        }
        }>
        <img 
            src={props.userImageURL} 
            // alt="address avatar" 
            style={{
            borderRadius: '50%',
            height: '35px',
            marginRight: '10px',
            width: '35px'
            }} 
        />
        {shortenedAddress}
        <FontAwesomeIcon icon={faCaretDown} style={{ fontSize: '30px', marginLeft: '10px', marginRight:'10px' }} />
        </button>
    </>
  );
};
