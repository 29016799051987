import React, { Component } from 'react';
import { FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import styles from './QuestionFilter.module.scss';
import SBTFilter from '../SBTs/SBTFilter.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faChevronDown, faStar, faRobot } from '@fortawesome/free-solid-svg-icons';

// Define modalStyles at the top
const modalStyles = {
  backgroundColor: 'white',
  fontSize: '16px',
};

// Define questionTypes at the top
const questionTypes = ['freeform', 'multichoice', 'rating', 'binary'];

class QuestionFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTypes: this.props.initialSelectedTypes || [],
      sortByImportance: false,
      sbtFilteredQuestions: null,
      filterLoading: false,
      expandedSections: {
        types: true,
        sbts: false,
        popular: false,
        ai: false
      },
      aiSearchQuery: '',
      pendingSelectedTypes: this.props.initialSelectedTypes || [],
      pendingSortByImportance: false,
      pendingSbtFilteredQuestions: null,
      filteredQuestionsCount: 0,
      // Initialize pending and actual state for showTopQuestions and topQuestionsCount
      pendingShowTopQuestions: false,
      pendingTopQuestionsCount: 10,
      showTopQuestions: false,
      topQuestionsCount: 10,
    };
  }

  componentDidMount() {
    console.log('QuestionFilter network:', this.props.network);
    this.computeFilteredQuestionsCount();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.questions !== this.props.questions ||
      prevState.pendingSelectedTypes !== this.state.pendingSelectedTypes ||
      prevState.pendingSortByImportance !== this.state.pendingSortByImportance ||
      prevState.pendingSbtFilteredQuestions !== this.state.pendingSbtFilteredQuestions ||
      prevState.aiSearchQuery !== this.state.aiSearchQuery ||
      prevState.pendingShowTopQuestions !== this.state.pendingShowTopQuestions ||
      prevState.pendingTopQuestionsCount !== this.state.pendingTopQuestionsCount
    ) {
      this.computeFilteredQuestionsCount();
    }
  }

  handleTypeSelection = (type) => {
    let pendingSelectedTypes = [...this.state.pendingSelectedTypes];
    if (pendingSelectedTypes.includes(type)) {
      pendingSelectedTypes = pendingSelectedTypes.filter(t => t !== type);
    } else {
      pendingSelectedTypes.push(type);
    }
    this.setState({ pendingSelectedTypes });
  };

  handleSortByImportance = () => {
    this.setState(prevState => ({ pendingSortByImportance: !prevState.pendingSortByImportance }));
  };

  handleFilteredQuestions = (filteredQuestions) => {
    this.setState({ pendingSbtFilteredQuestions: filteredQuestions });
  };

  setFilterLoading = (loading) => {
    this.setState({ filterLoading: loading });
    if (this.props.setFilterLoading) {
      this.props.setFilterLoading(loading);
    }
  };

  toggleSection = (section) => {
    this.setState(prevState => ({
      expandedSections: {
        ...prevState.expandedSections,
        [section]: !prevState.expandedSections[section]
      }
    }));
  };

  handleApplyFilters = () => {
    this.setState({
      selectedTypes: this.state.pendingSelectedTypes,
      sortByImportance: this.state.pendingSortByImportance,
      sbtFilteredQuestions: this.state.pendingSbtFilteredQuestions,
      showTopQuestions: this.state.pendingShowTopQuestions,
      topQuestionsCount: this.state.pendingTopQuestionsCount,
    }, () => {
      this.applyFilters();
      this.props.toggleFilterModal();
    });
  };

  handleCancelFilters = () => {
    this.setState({
      pendingSelectedTypes: this.state.selectedTypes,
      pendingSortByImportance: this.state.sortByImportance,
      pendingSbtFilteredQuestions: this.state.sbtFilteredQuestions,
      pendingShowTopQuestions: this.state.showTopQuestions,
      pendingTopQuestionsCount: this.state.topQuestionsCount,
    }, () => {
      this.props.toggleFilterModal();
    });
  };

  applyFilters = () => {
    let { questions } = this.props;
    const { 
      selectedTypes, 
      sortByImportance, 
      sbtFilteredQuestions, 
      showTopQuestions, 
      topQuestionsCount, 
      aiSearchQuery 
    } = this.state;
  
    if (showTopQuestions) {
      // Calculate cumulative importance for each question
      const { questionResponses } = this.props;
      const questionsWithImportance = questions.map(question => {
        const responses = questionResponses[question.id] || [];
        const totalImportance = responses.reduce((sum, response) => {
          const parsedResponse = this.parseResponse(response.response);
          return sum + (parsedResponse?.importance || 0);
        }, 0);
        return {
          ...question,
          totalImportance,
        };
      });
  
      // Sort questions by cumulative importance in descending order
      questionsWithImportance.sort((a, b) => b.totalImportance - a.totalImportance);
  
      // Select top X questions based on user input
      questions = questionsWithImportance.slice(0, topQuestionsCount);
  
    } else {
      // Apply SBT filters if any
      if (sbtFilteredQuestions !== null) {
        const sbtFilteredIds = sbtFilteredQuestions.map(q => q.id);
        questions = questions.filter(q => sbtFilteredIds.includes(q.id));
      }
  
      // Apply question types filter
      if (selectedTypes.length > 0) {
        questions = questions.filter(q => selectedTypes.includes(q.type));
      }
  
      // Apply AI filter based on search query
      if (aiSearchQuery.trim() !== '') {
        const query = aiSearchQuery.trim().toLowerCase();
        questions = questions.filter(q => q.prompt.toLowerCase().includes(query));
      }
  
      // Apply sort by importance if enabled
      if (sortByImportance) {
        const { questionResponses } = this.props;
        const questionsWithImportance = questions.map(question => {
          const responses = questionResponses[question.id] || [];
          const totalImportance = responses.reduce((sum, response) => {
            const parsedResponse = this.parseResponse(response.response);
            return sum + (parsedResponse?.importance || 0);
          }, 0);
          return {
            ...question,
            totalImportance,
          };
        });
  
        // Sort questions by cumulative importance in descending order
        questionsWithImportance.sort((a, b) => b.totalImportance - a.totalImportance);
        questions = questionsWithImportance;
      }
    }
  
    // Pass the filtered questions back to the parent component
    if (this.props.onFilter) {
      this.props.onFilter(questions);
    }
  };  

  computeFilteredQuestionsCount = () => {
    let { questions } = this.props;
    const { pendingSelectedTypes, pendingSbtFilteredQuestions, pendingShowTopQuestions, pendingTopQuestionsCount, aiSearchQuery } = this.state;

    if (pendingShowTopQuestions) {
      // Calculate cumulative importance
      const { questionResponses } = this.props;
      const questionsWithImportance = questions.map(question => {
        const responses = questionResponses[question.id] || [];
        const totalImportance = responses.reduce((sum, response) => sum + (response.importance || 0), 0);
        return {
          ...question,
          totalImportance,
        };
      });

      // Sort questions by cumulative importance
      questionsWithImportance.sort((a, b) => b.totalImportance - a.totalImportance);

      // Take top X questions
      questions = questionsWithImportance.slice(0, pendingTopQuestionsCount);

    } else {
      // Apply SBT filters if any
      if (pendingSbtFilteredQuestions !== null) {
        const sbtFilteredIds = pendingSbtFilteredQuestions.map(q => q.id);
        questions = questions.filter(q => sbtFilteredIds.includes(q.id));
      }

      // Filter questions by selected types
      if (pendingSelectedTypes.length > 0) {
        questions = questions.filter(q => pendingSelectedTypes.includes(q.type));
      }

      // Apply AI filter
      if (aiSearchQuery.trim() !== '') {
        const query = aiSearchQuery.trim().toLowerCase();
        questions = questions.filter(q => q.prompt.toLowerCase().includes(query));
      }
    }

    // Update the filteredQuestionsCount
    this.setState({ filteredQuestionsCount: questions.length });
  };

  renderCollapsibleSection = (title, sectionKey, icon, content) => {
    const { expandedSections } = this.state;
    return (
      <div className={styles.filterSection}>
        <div 
          className={styles.sectionHeader} 
          onClick={() => this.toggleSection(sectionKey)}
        >
          <h3>
            <FontAwesomeIcon icon={icon} className="me-2" />
            {title}
          </h3>
          <FontAwesomeIcon 
            icon={faChevronDown} 
            className={`${styles.icon} ${expandedSections[sectionKey] ? styles.expanded : ''}`}
          />
        </div>
        {expandedSections[sectionKey] && (
          <div className={styles.sectionContent}>
            {content}
          </div>
        )}
      </div>
    );
  };

  render() {
    const { 
      pendingSelectedTypes, 
      aiSearchQuery,
      filteredQuestionsCount,
      pendingShowTopQuestions,
      pendingTopQuestionsCount,
      expandedSections,
    } = this.state;

    const isOtherFiltersDisabled = pendingShowTopQuestions;

    return (
      <Modal isOpen={this.props.filterModalOpen} toggle={this.handleCancelFilters} style={modalStyles}>
        <ModalHeader toggle={this.handleCancelFilters} className={styles.modalHeader}>
          Filter Questions ({filteredQuestionsCount})
        </ModalHeader>
        <ModalBody className={styles.modalBody}>
          {/* Popular Questions Section */}
          {this.renderCollapsibleSection(
            'Most Popular',
            'popular',
            faStar,
            <FormGroup>
              <Label className={styles.filterOption}>
                <Input
                  type="checkbox"
                  checked={pendingShowTopQuestions}
                  onChange={() => this.setState({ pendingShowTopQuestions: !pendingShowTopQuestions })}
                />
                Show top
                <Input
                  type="number"
                  min="1"
                  value={pendingTopQuestionsCount}
                  onChange={(e) => this.setState({ pendingTopQuestionsCount: e.target.value })}
                  disabled={!pendingShowTopQuestions}
                  id={styles.topQuestionsCountInput}
                />
                questions (by # of responses)
              </Label>
              {pendingShowTopQuestions && (
                <small className="text-muted">
                  This option supersedes other filters.
                </small>
              )}
            </FormGroup>
          )}

          {/* Disable other sections if showTopQuestions is true */}
          <div className={isOtherFiltersDisabled ? styles.disabledSection : ''}>
            {/* Question Types Section */}
            {this.renderCollapsibleSection(
              'Question Types',
              'types',
              faFilter,
              <FormGroup>
                {questionTypes.map((type, index) => (
                  <Label key={index} className={styles.filterOption}>
                    <Input
                      type="checkbox"
                      value={type}
                      checked={pendingSelectedTypes.includes(type)}
                      onChange={() => this.handleTypeSelection(type)}
                      disabled={isOtherFiltersDisabled}
                    />
                    {type.charAt(0).toUpperCase() + type.slice(1)}
                  </Label>
                ))}
              </FormGroup>
            )}

            {/* SBTs Section */}
            {this.renderCollapsibleSection(
              'SBT Group(s) of Question Creator',
              'sbts',
              faStar,
              isOtherFiltersDisabled ? (
                <p className={styles.disabledText}>Disabled due to "Show top X questions" selection.</p>
              ) : (
                <SBTFilter
                  items={this.props.questions}
                  provider={this.props.provider}
                  network={this.props.network}
                  onFilter={this.handleFilteredQuestions}
                  setFilterLoading={this.setFilterLoading}
                  mode="creator"
                  autoExpand={true}
                />
              )
            )}

            {/* AI Filter Section */}
            {this.renderCollapsibleSection(
              'AI Filter',
              'ai',
              faRobot,
              isOtherFiltersDisabled ? (
                <p className={styles.disabledText}>Disabled due to "Show top X questions" selection.</p>
              ) : (
                <FormGroup>
                  <Input
                    type="text"
                    className={styles.searchInput}
                    placeholder="Enter AI filter criteria..."
                    value={aiSearchQuery}
                    onChange={(e) => this.setState({ aiSearchQuery: e.target.value })}
                  />
                  <small className="text-muted">
                    Soon: Use AI to find specific questions based on context or meaning
                  </small>
                </FormGroup>
              )
            )}
          </div>
        </ModalBody>
        <ModalFooter className={styles.modalFooter}>
          <Button color="primary" onClick={this.handleApplyFilters}>Apply Filter</Button>
        </ModalFooter>
      </Modal>
    );
  }  
}

export default QuestionFilter;
