import axios from 'axios';
import {
  USER_ANTHROPIC_KEY,
  DEFAULT_ANTHROPIC_KEY,
  CLOUDFLARE_CORS_WORKER_URL,
} from '../variables/CONTRACT_ADDRESSES.js';

const ANTHROPIC_API_URL = 'https://api.anthropic.com/v1/messages';

// Utility function to extract main content from HTML string
const extractMainContent = (htmlString) => {
  // Create a DOM parser
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  // Remove unwanted elements
  const elementsToRemove = [
    'script',
    'style',
    'iframe',
    'nav',
    'footer',
    'header',
    'aside'
  ];
  
  elementsToRemove.forEach(tag => {
    doc.querySelectorAll(tag).forEach(el => el.remove());
  });

  // Try to find main content
  const contentSelectors = [
    'main',
    'article',
    '.content',
    '#content',
    '.main-content',
    '#main-content',
    'body'
  ];

  for (const selector of contentSelectors) {
    const element = doc.querySelector(selector);
    if (element) {
      const text = element.textContent
        .replace(/\s+/g, ' ')
        .trim();
      
      if (text.length > 100) {
        return text;
      }
    }
  }

  // Fallback: return cleaned body text
  return doc.body.textContent
    .replace(/\s+/g, ' ')
    .trim();
};

export const callAnthropicAI = async (prompt) => {
  try {
    const requestBody = {
      prompt: prompt,
      model: 'claude-3-5-sonnet-20240620',
      max_tokens_to_sample: 4096,
      temperature: 0.7,
    }

    console.log('Sending request:', requestBody)

    const response = await fetch(CLOUDFLARE_CORS_WORKER_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody)
    })

    const data = await response.json()

    if (!response.ok) {
      console.error('API Error:', data)
      throw new Error(data.error || 'API request failed')
    }

    console.log('API Response:', data)

    if (!data.completion && data.content) {
      return data.content[0].text
    } else if (data.completion) {
      return data.completion
    } else {
      throw new Error('Unexpected response format')
    }
  } catch (error) {
    console.error('Error calling Anthropic AI:', error)
    throw error
  }
}

export const fetchContentFromURL = async (url) => {
  try {
    // Validate URL
    const validatedUrl = new URL(url);
    if (!validatedUrl.protocol.match(/^https?:$/)) {
      throw new Error('URL must start with http:// or https://');
    }

    // Try fetching directly first
    try {
      const response = await fetch(validatedUrl.href);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('text/html')) {
        const htmlContent = await response.text();
        const extractedContent = extractMainContent(htmlContent);
        if (extractedContent && extractedContent.length > 100) {
          return extractedContent;
        }
      }
    } catch (directFetchError) {
      console.warn('Direct fetch failed, falling back to worker:', directFetchError);
    }

    // Fallback to worker if direct fetch fails or returns invalid content
    const workerResponse = await fetch(CLOUDFLARE_CORS_WORKER_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        url: validatedUrl.href,
        action: 'fetch_url'
      }),
    });

    if (!workerResponse.ok) {
      const errorData = await workerResponse.json();
      throw new Error(errorData.error || 'Failed to fetch URL content');
    }

    const data = await workerResponse.json();
    
    if (!data.content) {
      throw new Error('No content received from URL');
    }

    // Clean up the content if it's HTML
    if (typeof data.content === 'string' && data.content.includes('<')) {
      return extractMainContent(data.content);
    }

    return data.content;
  } catch (error) {
    console.error('Error fetching URL content:', error);
    throw new Error(`URL Error: ${error.message}`);
  }
};

export const analyzeSurveyResponses = async (responses) => {
  const prompt = `Analyze the following survey responses and provide a summary of the key findings: ${JSON.stringify(responses)}`;
  try {
    const analysis = await callAnthropicAI(prompt);
    return analysis;
  } catch (error) {
    console.error('Error analyzing survey responses:', error);
    throw error;
  }
};