import React, { Component } from 'react';

// Scripts
import proposalScripts from '../UpcomingMatches/proposalScripts.js';
import contractScripts from '../Buttons/contractScripts.js';

// CSS
import 'assets/css/m_w.css';
import styles from "./MemeMatch.module.scss";

// reactstrap components
import { Card, CardHeader, CardBody, Collapse, Progress} from 'reactstrap';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' // TODO: FA Library fa-library
import { faSpinner, faExpandArrowsAlt, faCaretDown, faArrowUp, faStar, faUser } from '@fortawesome/free-solid-svg-icons'

class OverMemeCardCollapse extends Component {
  state = {
    visible: false,
    openedCollapses: [''],
  };

  componentDidMount() {
    // Enter a custom ID into DOM?
  }

  componentDidUpdate() {}

  componentWillUnmount() {}

  collapsesToggle = collapse => {
    // keeps track of how long someone looks at meme entry for TODO: mixpanel
    // window.mixpanel.track("ENTRY_DETAILS_CLICKED", 
    // { 
    // "SELECTED_ENTRY_INDEX": this.props.entryNumber, 
    // "MATCH_ID": this.props.match.ID, 
    // "OPENING_ENTRY_DETAILS": !this.state.visible,
    // }
    // );
    // window.mixpanel.time_event('ENTRY_DETAILS_CLICKED');
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: this.state.openedCollapses.filter(prop => prop !== collapse),
        visible: false
      });
    } else {
      this.setState({
        openedCollapses: [...this.state.openedCollapses, collapse],
        visible: true
      });
    }
  };

  render() {

    var match = this.props.match;

    const leftRightClassname = this.props.entryNumber != 0 ? 'collapseOneleft' : 'collapseOneright';
    // const className = this.props.entryNumber != 0 ? "card-collapse-left" :"card-collapse-right";
    // const headingNumber = this.props.entryNumber != 0 ? "headingZero" :"headingOne";
    const accordionID = this.props.entryNumber != 0 ? 'accordionZero' : 'accordionOne';

    const votePercentage = (match.votes[this.props.entryNumber] / match.numVoters) * 100;

    const voteBarClassname = votePercentage >= 50 ? 'progress-container progress-success' : 'progress-container progress-info';

    const submitterAddressDisplay = proposalScripts.getShortenedAddress(this.props.match.competitors[this.props.entryNumber].address);
    // const submitterAddressLink = proposalScripts.getAddressPageURL(this.props.match.competitors[this.props.entryNumber].address);

    const submitterDisplay = 
    <div id={styles.submitterDisplay}>
      <FontAwesomeIcon id={styles.submitterIcon} icon={faUser} style={{marginRight: '.5em'}}/>
      <div id={styles.submitterAddressText}>
        {/* Submitted by:  */}
        { submitterAddressDisplay }
        {/* { submitterAddressLink } */}
      </div>
    </div>

    return (
      <div aria-multiselectable={true} className="card-collapse" id={accordionID} role="tablist">
        <Card id={styles.entryDetailsCard} >
        {/* <Card id={styles.entryDetailsCard} role="tab"> */}
        
          <CardHeader id={styles.entryDetailsCardHeader} role="tab">
            <a
              // className="d-flex"
              // href="#" // TODO
              data-toggle="collapse"
              aria-expanded={this.state.openedCollapses.includes(leftRightClassname)}
              onClick={e => {
                e.preventDefault();
                this.collapsesToggle(leftRightClassname);
              }}
              id={styles.toggleEntryDetailsLink}
            >
              <div id={styles.progressAndArrow}>
                          <Progress id={styles.voteCountProgressBar} className={voteBarClassname} max="100" value={votePercentage}>
              <span className="progress-value"></span>
            </Progress>
              {/* <div id={styles.overCollapseSubmitterPrompt}>
                Entry Details   <FontAwesomeIcon id={styles.collapseCompetitorIcon} icon={faCaretDown} />
              </div> */}
              <div id={styles.overCollapseSubmitterPromptMinimal}>
                <div id={styles.overCollapseDetailsText}> Details </div> <FontAwesomeIcon id={styles.collapseCompetitorIcon} icon={faCaretDown} />
                {/* Details <FontAwesomeIcon id={styles.collapseCompetitorIcon} icon={faCaretDown} /> */}
              </div>
              </div>
            </a>
          </CardHeader>
          <Collapse 
            role="tabpanel" isOpen={this.state.openedCollapses.includes(leftRightClassname)}
          >
            <CardBody id={styles.entryDetailsCardBody}>
              { submitterDisplay }
            </CardBody>
          </Collapse>
            {/* <CardBody id={styles.entryDetailsCardBody}>
              { submitterDisplay }
            </CardBody> */}
        </Card>
      </div>
    );
  }
}

export default OverMemeCardCollapse;
