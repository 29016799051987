import React, { Component } from "react";
// import Torus from '@toruslabs/torus-embed';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { changeAccount } from '../../actions/accountActions.js';
import { toggleLoginModal, updateLoginInfo } from '../../actions/sessionStateActions.js';
import { ConnectButton } from '@rainbow-me/rainbowkit';

// Footer-HooksHOC is a function component (allowed to use Hooks from wagmi and RainbowKit) 
// it passes props to this class-component so that this component can use React Hooks
import { WagmiHooksHOC } from '../HooksHOC/WagmiHooksHOC.jsx'

// CSS & Icons & 
import '../../assets/css/m_w.css'
import styles from "./Login.module.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' // TODO: use library 
import { faWindowClose, faCoins, faSpinner, faCopy, faCheck, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
// import { faTwitch, faGoogle, faRedditAlien, faDiscord, faFacebook, faEthereum } from '@fortawesome/free-brands-svg-icons'
// Logos
import MetaMaskLogo from "assets/img/metamask_icon_white.png";
import RainbowLogo from "assets/img/rainbow_icon.png";
import CoinbaseLogo from "assets/img/coinbase_icon.png";
import WalletConnectLogo from "assets/img/walletconnect_icon.png";

// reactstrap components
import { Button, Card, CardHeader, CardBody, CardFooter, Form, Modal, UncontrolledTooltip} from "reactstrap";

// Components
import TorusLoginButton from "./TorusLoginButton.jsx";
// import TorusLoginButton from "./Web3AuthLoginButton.jsx"
// import TorusLoginButton from "./FunctionalWeb3AuthConnect";
import RPCFaucetNotice from "./RPCFaucetNotice.jsx";
// import FunctionComponent from "./FunctionComponent.jsx";
import SendTestnetFundsButton from "../Buttons/SendTestnetFundsButton.jsx";
import DemoModeSelect from "./DemoModeSelect.jsx";
import UserPage from "components/UserPage/UserPage.jsx";

// Smart Contract Interactions
import { CORRECT_NETWORK, AUTOMATIC_TESTNET_FUNDS } from '../../variables/CONTRACT_ADDRESSES.js';
import contractScripts from '../Buttons/contractScripts.js';
import proposalScripts from '../UpcomingMatches/proposalScripts.js';

class Web3Modal extends Component {
    state = {
        wagmiLoginUpdateNeeded: true,
        afterLoginDialogue: true,
        firstModalAfterLogin: false,
        copied: false,
        sendingTestFunds: false,
        autoSendTestFundsEnabled: AUTOMATIC_TESTNET_FUNDS,
        autoSendTriggered: false,
    };

    componentDidMount() {}

    componentDidUpdate(prevProps) {
        console.log("Web3Modal.jsx - Updated");

        if (this.props.network != this.props.wagmiNetwork) {
            console.log("Network mismatch detected");
            // this.props.network – m_w redux global state
            // this.props.wagmiNetwork – wagmi-injected React Context value from HOC component
        } else if (this.props.account != this.props.wagmiAddress) {
            this.updateStateUponWagmiLogin();
            this.setState({ wagmiLoginUpdateNeeded: true });
        }

        // Detect new login, trigger test funds check
        if (this.props.loginComplete && !prevProps.loginComplete) {
            console.log("Login completed, checking for test funds need...");
            this.checkAndSendTestFunds();
        }

        // Check for network changes
        if (this.props.network?.chainId !== prevProps.network?.chainId) {
            console.log("Network changed, checking for test funds need...");
            this.checkAndSendTestFunds();
        }

        // Check for account changes
        if (this.props.account && this.props.account !== prevProps.account) {
            console.log("Account changed, checking for test funds need...");
            this.checkAndSendTestFunds();
        }

        if (this.props.loginComplete && this.props.loginComplete != prevProps.loginComplete) {
            this.setState({ firstModalAfterLogin: true });
        }
    }

    addCorrectNetwork = async () => {
        if (window.ethereum && this.props.provider === "wagmi") {
            try {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [{
                        chainId: CORRECT_NETWORK.chainId,
                        chainName: CORRECT_NETWORK.name,
                        nativeCurrency: CORRECT_NETWORK.currency,
                        rpcUrls: CORRECT_NETWORK.rpcUrls,
                        blockExplorerUrls: [CORRECT_NETWORK.blockExplorers.default.url]
                    }]
                });
            } catch (error) {
                console.error("Error adding network:", error);
            }
        }
    };

    switchToCorrectNetwork = async () => {
        if (window.ethereum && this.props.provider === "wagmi") {
            try {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: CORRECT_NETWORK.chainId }],
                });
            } catch (error) {
                if (error.code === 4902) {
                    await this.addCorrectNetwork();
                } else {
                    console.error("Error switching network:", error);
                }
            }
        }
    };

    checkAndSendTestFunds = async () => {
        if (this.props.loginComplete && this.props.account && this.state.autoSendTestFundsEnabled && !this.state.autoSendTriggered) {
            try {
                const balance = await this.props.wagmiProvider.getBalance(this.props.account);
                console.log("Current balance:", balance.toString());

                if (balance.isZero()) {
                    console.log("Attempting to send test funds automatically...");
                    this.setState({ autoSendTriggered: true });
                }
            } catch (error) {
                console.error("Error in checkAndSendTestFunds:", error);
            }
        }
    };

    getSettingsDisplay = () => {
        const networkName = this.props.network?.name || "not connected";
        const isCorrectNetwork = networkName === CORRECT_NETWORK.name;
        const needsNetworkSwitch = this.props.provider === "wagmi" && !isCorrectNetwork;

        const hasZeroBalance = this.props.ETHBalance === "0" ||
            this.props.ETHBalance?.isZero?.() ||
            !this.props.ETHBalance;

        return (
            <div className={styles.settingsContainer}>
                    {(hasZeroBalance && this.props.loginComplete) && (
                        <SendTestnetFundsButton
                            homepage={false}
                            changeFocusedTab={this.props.changeFocusedTab}
                            toggleLoginModal={this.props.toggleLoginModal}
                            focusedTab={this.props.focusedTab}
                            lobby={this.props.lobby}
                            account={this.props.account}
                            paid={this.props.paid}
                            provider={this.props.provider}
                            loginComplete={this.props.loginComplete}
                            network={this.props.network}
                            autoSendEnabled={this.state.autoSendTestFundsEnabled}
                            autoSend={this.state.autoSendTriggered}
                            disabled={this.state.sendingTestFunds}
                        />
                    )}

                {/* Network Info - Kept in middle */}
                <div className={styles.networkInfo}>
                    <span className={styles.networkLabel}>network:</span>
                    <span className={styles.networkValue}>{networkName}</span>
                    <FontAwesomeIcon
                        icon={faInfoCircle}
                        className={styles.infoIcon}
                        id="networkInfoTooltip"
                    />
                    <UncontrolledTooltip
                        placement="right"
                        target="networkInfoTooltip"
                        delay={{ show: 0, hide: 2000 }}
                        trigger="hover click"
                        autohide={false}
                    >
                        <div style={{ padding: "10px" }}>
                            The Ethereum-based network where
                            <a
                                href={CORRECT_NETWORK.contractsUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: '#00adb5',
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                    display: 'block',
                                    marginTop: '8px'
                                }}
                            >
                                CE smart contracts
                            </a>
                            are deployed
                        </div>
                    </UncontrolledTooltip>
                </div>

                {/* Network Switch Button - Kept on right */}
                {needsNetworkSwitch && (
                    <Button
                        onClick={this.switchToCorrectNetwork}
                        className={`${styles.networkSwitchButton} ${styles.glow}`}
                    >
                        Switch to Correct Network
                    </Button>
                )}
            </div>
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        // console.log(nextProps);
        // console.log(nextState);

        // UPDATE IF LOGIN IN PROGRESS OR ENDING
        const loginStageDifferent = nextProps.loginInProgress != this.props.loginInProgress;
        if (loginStageDifferent) {
            return true;
        }

        // INTENDED TO FORCE UPDATE IF MODAL TOGGLED FROM ANOTHER COMPONENT
        if (nextProps.loginModalToggled != this.props.loginModalToggled) {
            // console.log("Global Modal Toggle Detected")
            // console.log("Next loginModalToggled:" + nextProps.loginModalToggled)
            // console.log("Current loginModalToggled:" + this.props.loginModalToggled)
            return true;
        }

        if (this.props.network != this.props.wagmiNetwork) {
            // console.log("nextProps.network != this.props.wagmiNetwork")
            return true;
        }

        return true;
    }

    openLoginModal = () => {
        console.log("Web3Panel: Opened")
        document.body.classList.add('modal-open');

        const open = true;
        this.props.toggleLoginModal(open);
    };

    closeLoginModal = () => {
        document.body.classList.remove('modal-open');

        const closed = false;
        this.props.toggleLoginModal(closed);

        // get rid of any one-time notices / messages
        if (this.state.firstModalAfterLogin === true) {
            this.setState({ firstModalAfterLogin: false })
        }
    };

    updateStateUponWagmiLogin = async () => {
        if (this.state.wagmiLoginUpdateNeeded === true) {
            this.setState({ wagmiLoginUpdateNeeded: false })
            console.log("updateStateUponWagmiLogin() – connected address is: " + this.props.wagmiAddress)

            var loginObject = {
                loginInProgress: true,
                loginComplete: false, // only if this is called 
                provider: "wagmi",
            };

            this.props.updateLoginInfo(loginObject);

            let connectedAddress = this.props.wagmiAddress
            console.log("connectedAddress:")
            console.log(connectedAddress)

            let availableETH = this.props.wagmiBalance;
            let ETHBalance = this.props.demoMode ? contractScripts.toEighteenDecimals("0") : await contractScripts.getETHBalance(connectedAddress);
            let XPBalance = this.props.demoMode ? contractScripts.toEighteenDecimals("100") : await contractScripts.getXPBalance(connectedAddress);
            // console.log("XPBalance: " + XPBalance.toString())
            let networkName = this.props.network.name
            // console.log("this.props.wagmiNetwork: ")
            // console.log(this.props.wagmiNetwork)

            // TODO: use better way of detecting whether player has joined
            var hasJoined = (XPBalance.toString() == "0") ? false : true;
            console.log("hasJoined: " + hasJoined)

            // Update Account State (when balance info available)
            const web3info = {
                account: connectedAddress,
                provider: "wagmi", // TODO?
                network: networkName,
                joined: hasJoined,
                availableETH: availableETH,
                XPBalance: XPBalance,
                ETHBalance: ETHBalance
            };

            this.props.changeAccount(web3info);

            // update sessionState variables
            loginObject = {
                loginInProgress: false,
                loginComplete: true,
            };

            this.props.updateLoginInfo(loginObject);
        }

        else {
            // console.log("else-block: YOU SHOULDN'T SEE THIS")
        }

    }

    updateStateTorusLogin = () => {
    }

    getModalDisplay = () => {
        // console.log("getModalDisplay() invoked");

        // check if there is any special login-message to display
        var newLoginMessage = "";
        if (this.state.firstModalAfterLogin && this.state.afterLoginDialogue) {
            if (this.props.provider == "Torus") {
                newLoginMessage = "Torus Crypto Wallet: Connected"
            }
        }

        // if LOGIN INCOMPLETE
        if (!this.props.loginComplete) {
            return (
                <CardBody>
                    <div id={styles.modalLoginOptions}>
                        <div id={styles.metamaskInstructions}>
                            <Button
                                onClick={this.props.openConnectModal}
                                id={styles.modalMetamaskButton}
                                color="orange"
                                type="button"
                            >
                                <img
                                    src={MetaMaskLogo}
                                    id={styles.metamaskModalIcon}
                                    alt="Metamask Logo"
                                />

                                <img
                                    src={RainbowLogo}
                                    id={styles.metamaskModalIcon}
                                    alt="Rainbow Logo"
                                />

                                <img
                                    src={CoinbaseLogo}
                                    id={styles.metamaskModalIcon}
                                    alt="Coinbase Logo"
                                />

                                <img
                                    src={WalletConnectLogo}
                                    id={styles.metamaskModalIcon}
                                    alt="WalletConnect Logo"
                                />

                                <h1 id={styles.metamaskConnectText}>
                                    {/* CONNECT  */}
                                </h1>
                            </Button>
                        </div>


                        <div id={styles.torusLoginOption}>
                            <TorusLoginButton loginInProgress={this.props.loginInProgress} demoMode={this.props.demoMode} />
                        </div>
                    </div>
                </CardBody>
            );
        }

        // if LOGIN IN PROGRESS
        if (this.props.loginInProgress) {
            <div id={styles.loadingIconContainer}>
                <h3 id={styles.verifyingtext}> logging in... </h3>
                <FontAwesomeIcon icon={faSpinner} pulse id={styles.verifyingTXloadingIcon} />
            </div>
        }

        // if LOGIN COMPLETE + provider is "wagmi"
        else if (this.props.loginComplete && this.props.provider == "wagmi") {
            return (
                <CardBody id={styles.metamaskModalPanel}>
                    <ConnectButton accountStatus={{
                        smallScreen: 'avatar',
                        largeScreen: 'full',
                    }}
                        id={styles.metamaskModalPanel}
                    />

                    <UserPage
                        viewAddress={this.props.account}
                        provider={this.props.provider}
                        minimized={true}
                        network={this.props.network}
                    />

                </CardBody>


            );
        }

        // if LOGIN COMPLETE + provider is "Torus"
        else if (this.props.loginComplete && this.props.provider == "Torus") {

            console.log()

            const addressDisplay = this.props.account != undefined ?
                proposalScripts.getShortenedAddress(this.props.account)
                :
                <FontAwesomeIcon icon={faSpinner} pulse id={styles.verifyingTXloadingIcon} />
                ;

            return (
                <CardBody id={styles.torusModalCard}>
                    <div id={styles.torusModal}>


                        {/* <div id={styles.torusInfoModalTitle}> { newLoginMessage } </div> */}

                        <div id={styles.torusModalBody}>

                            <h1 id={styles.userModalAddress}>

                                <UserPage
                                    viewAddress={this.props.account}
                                    provider={this.props.provider}
                                    minimized={true}
                                />

                            </h1>

                            {/* TORUS WALLET BUTTON / WIDGET SHOWS UP IN BOTTOM LEFT ONLY WHEN MODAL IS OPEN */}
                        </div>
                    </div>
                </CardBody>
            );
        }
    }

    getConnectedNetworkDisplay = () => {
    }

    getModalTitle = () => {
        if (!this.props.loginComplete) {
            return "LOGIN";
        }

        else if (this.props.provider == "wagmi") {
            return "WALLET";
        }

        else if (this.props.provider == "Torus") {
            const torusWalletNotice = (
                <h3 id={styles.loginNotice}>
                    Click on "T" (bottom left) to use
                    {/* Torus wallet */}
                </h3>
            )

            const torusWalletRow = (
                <div id={styles.torusWalletRow}>
                    {"WALLET"} {" "} {torusWalletNotice}
                </div>
            )

            return torusWalletRow;
        }
    }

    render() {

        const modalTitle = this.getModalTitle();
        const modalContent = this.getModalDisplay();
        const modalFooter = this.getSettingsDisplay();

        return (
            <div id={styles.loginModal}>
                <Modal isOpen={this.props.loginModalToggled} modalClassName="modal-login">
                    <Card id={styles.loginModalCard}>
                        <CardHeader>
                            <div className={styles.Web3SettingsModalTitle}>
                                {modalTitle}
                            </div>

                            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={this.closeLoginModal}>
                                <FontAwesomeIcon id={styles.closeModalIcon} icon={faWindowClose} />
                            </button>
                        </CardHeader>

                        {modalContent}

                        <div className={styles.Web3SettingsModalSubtitle}>
                            SETTINGS
                        </div>

                        <CardFooter className="text-center" id={styles.settingsFooter}>

                            {modalFooter}

                        </CardFooter>
                    </Card>
                </Modal>
            </div>
        )
    }
}

Web3Modal.propTypes = {
  loginModalToggled: PropTypes.bool,
  loginInProgress: PropTypes.bool,
  loginComplete: PropTypes.bool,
  // loginWeb3Provider: PropTypes.string, // TODO: get rid of when modal is fixed
  provider: PropTypes.string,
  changeAccount: PropTypes.func.isRequired,
  toggleLoginModal: PropTypes.func.isRequired,
  updateLoginInfo: PropTypes.func.isRequired,  
};
  
const mapStateToProps = state => ({
  // profile state
  provider: state.profile.provider,
  network: state.profile.network, 
//   address: state.profile.address, 
  account: state.profile.account,
  // session state
  loginModalToggled: state.sessionState.loginModalToggled,
  loginInProgress: state.sessionState.loginInProgress,
  loginComplete: state.sessionState.loginComplete,
  // loginWeb3Provider: state.sessionState.loginWeb3Provider,
  });
  
  const LoginModalWithWagmiHooks = WagmiHooksHOC(Web3Modal)
  
  export default connect(mapStateToProps, { changeAccount, toggleLoginModal, updateLoginInfo })(LoginModalWithWagmiHooks);

