import React, { Component } from 'react';

// CSS
import 'assets/css/m_w.css';
import styles from "./UpcomingMatches.module.scss";

// Scripts
import contractScripts from '../Buttons/contractScripts.js'

// reactstrap components
import { Button } from 'reactstrap';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'

class ProposalVote extends Component {
    componentDidMount() { }

    getVoteDisplay = () => {
      switch (this.props.proposal.defaultProposal) {

          case true:
              return ( 
                <h3 id={styles.defaultUpvoteCount}>
                  { this.props.proposal.upvotes } 
                    <FontAwesomeIcon icon={faArrowUp} id={styles.defaultUpvoteArrow} />
                </h3>
              );

          case false:
          return (
              <h3 id={styles.nonDefaultUpvoteCount}>
                <Button onClick={this.submitUpvote}  id={styles.nonDefaultUpvoteArrow} className="btn-link proposalButton" color={"upvote"}> 
                  <FontAwesomeIcon icon={faArrowUp} />
                </Button >

                <div id={styles.proposalVoteNumber}> { this.props.proposal.upvotes } </div>

                <Button onClick={this.submitDownvote} id={styles.nonDefaultDownvoteArrow}  className="btn-link proposalButton" color={"downvote"}> 
                  <FontAwesomeIcon icon={faArrowDown} />
                </Button> 
                <FontAwesomeIcon icon={faSpinner} pulse id={styles.proposalVoteLoadingIcon} /> 
              </h3>
          );    
        }
    }

    submitUpvote = async () => {
      // if user is not logged in, open login modal
      if (!this.props.loginComplete) {
        const open = true;
        this.props.toggleLoginModal(open);
        return;
      }
      
      else {
        const upvote = true;
        await contractScripts.voteOnProposal(this.props.account, this.props.provider, this.props.proposal.ID, upvote);
      }
    }
  
    submitDownvote = async () => {
      // if user is not logged in, open login modal
      if (!this.props.loginComplete) {
        const open = true;
        this.props.toggleLoginModal(open);
        return;
      }
      
      else {
        const downvote = false;
        await contractScripts.voteOnProposal(this.props.account, this.props.provider, this.props.proposal.ID, downvote);
      }
    }

    render() {

      const loadingIcon = <h1> <FontAwesomeIcon icon={faSpinner} pulse /> </h1>;
      var voteInterface = this.props.proposal == null && !this.props.loginComplete ? loadingIcon : this.getVoteDisplay();

      return voteInterface;
    }
  }
  
  export default ProposalVote 


