// ACCOUNT OPERATIONS

export const FETCH_ACCOUNT = 'FETCH_ACCOUNT';
export const LOGIN_ACCOUNT = 'LOGIN_ACCOUNT';
export const UPDATE_XP_BALANCE = 'UPDATE_XP_BALANCE';
export const UPDATE_ETH_BALANCE = 'UPDATE_ETH_BALANCE';
export const CHANGE_NETWORK = 'CHANGE_NETWORK';

// LOBBY OPERATIONS

export const FETCH_LOBBY = 'FETCH_LOBBY';
export const CHANGE_LOBBY = 'CHANGE_LOBBY';
export const FETCH_PROPOSALS = 'FETCH_PROPOSALS';
export const NEW_PROPOSAL = 'NEW_PROPOSAL';
export const LOAD_MATCHES = 'LOAD_MATCHES';
export const FETCH_MATCH = 'FETCH_MATCH';
export const NEW_MATCH = 'NEW_MATCH';
// export const FETCH_MATCH = 'FETCH_MATCH' // GET_MATCHES? & GET_LATEST_MATCH ?

// SITE-STATE OPERATIONS
export const CHANGE_METRICS_CHOICE = 'CHANGE_METRICS_CHOICE';
export const FETCH_SESSION_STATE = 'FETCH_SESSION_STATE';
export const UPDATE_SESSION_STATE = 'UPDATE_SESSION_STATE';
export const TOGGLE_LOGIN_MODAL = 'TOGGLE_LOGIN_MODAL';
export const TOGGLE_SETTINGS_MODAL = 'TOGGLE_SETTINGS_MODAL';
export const LOGIN_IN_PROGRESS = 'LOGIN_IN_PROGRESS';
export const CHANGE_FOCUSED_TAB = 'CHANGE_FOCUSED_TAB';
export const CHANGE_FOCUSED_MATCH = 'CHANGE_FOCUSED_MATCH';
export const CHANGE_FOCUSED_ENTRY = 'CHANGE_FOCUSED_ENTRY';
export const TOGGLE_DEMO_MODE = 'TOGGLE_DEMO_MODE';

