import React from 'react';
import { ConnectButton } from '@rainbow-me/rainbowkit';


// CSS 
import "assets/css/m_w.css";
import styles from "./Navbar.module.scss";

export const AccountDisplay = (props) => (

  <ConnectButton 
    chainStatus="none"  
    showBalance={false}
    accountStatus={{
      smallScreen: 'avatar',
      largeScreen: 'full',
    }}
  />
);

//   <>

// TODO: clicking on account should open up Pane in Search Explorer
//       and there should be an option to edit profile (Change "Search" -> "Explorer?")


// Custom Connect Button: https://www.rainbowkit.com/docs/custom-connect-button



