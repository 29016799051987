import React, { Component } from 'react';

// CSS
import 'assets/css/m_w.css';
import styles from "./UpcomingMatches.module.scss";

// Components
import { AccountDisplay } from '../Navbar/AccountDisplay.jsx'; // TODO: make a display for proposalcards + chat
import ProposalVote from './ProposalVote';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faExpandArrowsAlt, faCaretDown, faArrowUp, faStar, faUser } from '@fortawesome/free-solid-svg-icons'

// Scripts
import proposalScripts from './proposalScripts.js';

// reactstrap components
import { Badge, Button, Card, CardBody, CardFooter } from 'reactstrap';


class ProposalCard extends Component {
  componentDidMount() {}

  getSubmitterDisplay = () => {
    switch (this.props.proposal.defaultProposal) {
      case null:
        return ( <h1> <FontAwesomeIcon icon={faSpinner} pulse /> </h1> );
      case true:
        return (
            <Badge 
            color="transparent"  
            // color={this.props.proposal.format == 'IMAGE' ? 'image' : 'tiktok'}>  // TODO: MORE FORMATS (
            id={styles.defaultProposalFooterBadge}>
              <FontAwesomeIcon icon={faStar} id={styles.lobbyDefaultIcon} />
              <div id={styles.defaultLobbyText}>
                DEFAULT
              </div>
            </Badge>
        );
      case false:
        return (
            <Button 
              // id={styles.submitterAddressButton}
              className="btn-link"
              /* TODO: below in color propoerty for more formats 
                 TODO: make a link to this submitter's profile */
              color={this.props.proposal.format == 'IMAGE' ? 'image' : 'tiktok'}>
                <FontAwesomeIcon id={styles.submitterIcon} icon={faUser} style={{marginRight: '.5em'}}/>
                <div id={styles.submitterAddressText}>
                  {proposalScripts.getShortenedAddress(this.props.proposal.submitter)}
                </div>
            </Button>
        );
    }
  };

  render() {

    const loadingIcon = <h1> <FontAwesomeIcon icon={faSpinner} pulse /> </h1>;
    const forMemeMatchTopper = this.props.renderingIn === 'memeMatchTopper';
    const proposal = this.props.proposal;

    const cardColor = proposalScripts.getProposalCardColor(proposal.format);
    const cardColorSecondary = proposalScripts.getProposalCardColor(proposal.format);
    const badgeColor = proposalScripts.getProposalBadgeColor(proposal.format);
    const expandIcon = <FontAwesomeIcon icon={faCaretDown} />
    const cardIcon = proposalScripts.getProposalCardIcon(proposal.format);
    // const badgeClassname = proposalScripts.getProposalClassname(proposal.format);

    var formatDisplay = forMemeMatchTopper ?
      <h2 className={styles.topperBadge}>
        <Badge color={badgeColor} className={styles.matchTopperBadge}>
          <div id={styles.cardIcon}>
            {cardIcon} 
          </div>
          <div id={styles.cardFormat}>
            {proposal.format} 
          </div>
          <div id={styles.cardExpandIcon}>
            {expandIcon}
          </div>
        </Badge>
      </h2>
      :
      <Badge color={badgeColor} id={styles.proposalCardFormatBadge} >
        <div id={styles.proposalCardIcon}>
          {cardIcon} 
        </div>
        <div id={styles.proposalCardFormat}>
          {proposal.format}
        </div>
      </Badge>;

    // const cardClassName = this.props.proposal.defaultProposal ? 'defaultProposalCard' : 'proposalCard'; // TODO: if you want to customise default
    const footerClassIsDefault = this.props.proposal.defaultProposal ? styles.defaultProposalFooter : styles.normalProposalFooter;

    var promptDisplay = this.props.proposal.prompt == '' ? 'No Prompt' : proposal.prompt;

    // promptDisplay = <div id={styles.topRightPrompt}> { promptDisplay } </div>;

    var submitter = this.props.proposal == null || this.props.proposal == undefined ? loadingIcon : this.getSubmitterDisplay();

    var voteDisplay = (this.props.proposal == null || this.props.proposal == undefined) ? loadingIcon
    : 
    <ProposalVote
      updateProposals={(lobbyProposals) => this.props.updateProposals(lobbyProposals)}
      changeFocusedTab={(newTabIndex) => this.props.changeFocusedTab(newTabIndex)} 
      updateXPBalance={(newXPBalance) => this.props.updateXPBalance(newXPBalance)} 
      toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
      //
      joined={this.props.joined}
      loginComplete={this.props.loginComplete}
      lobby={this.props.lobby}
      account={this.props.account}
      paid={this.props.paid}
      provider={this.props.provider}
      proposal={proposal}
    />;

    if (this.props.proposal !== undefined && forMemeMatchTopper) { // TODO: make smoother / by region of card
      voteDisplay = <div id={styles.topperProposalUpvoteCount}> { this.props.proposal.upvotes } {" "} <FontAwesomeIcon icon={faArrowUp} /> </div>;
      submitter = promptDisplay;
      promptDisplay = "";
    }

    const relevantCardClassname = forMemeMatchTopper ? styles.topperProposalCard : styles.proposalCard;
    const defaultCardID = this.props.proposal.defaultProposal ? styles.defaultProposalCard : styles.nonDefaultProposalCard; // TODO
    const proposalHeaderID = this.props.renderingIn == 'memeMatchTopper' ? "topperProposalHeader" : "proposalHeader";
    // TODO (below?)
    const proposalTextID = this.props.format == "IMAGE" ? styles.proposalPrompTextContainerImage : styles.proposalPrompTextContainer;

    const relevantCardBodyStyle = forMemeMatchTopper ? styles.topperProposalCardBody : styles.proposalCardBody;

    return (
      <Card className={relevantCardClassname} id={defaultCardID} data-background-color={cardColor}>
        <CardBody id={relevantCardBodyStyle}>
          <div id={proposalHeaderID}>
            {formatDisplay}
            <div id={styles.proposalPromptTextContainer}>
              <p id="proposalPromptText" className={styles.proposalPromptText}>
                { promptDisplay }
                </p>
            </div>
          </div>
            <CardFooter id={footerClassIsDefault}>
              { submitter }
              { voteDisplay }
            </CardFooter>
        </CardBody>
      </Card>
    );
  }
}

export default ProposalCard;
