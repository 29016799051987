import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

// styles
import 'assets/css/nucleo-icons.css';
import 'assets/scss/blk-design-system-pro-react.scss?v1.0.0';
import "assets/css/m_w.css";

// views
// import App from 'views/App.jsx';
import App from 'components/App.jsx';

ReactDOM.render(
  <BrowserRouter>
   <App />
  </BrowserRouter>,
  document.getElementById('root'),
);
