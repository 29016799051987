import React from 'react';
import { Container, Jumbotron, Row, Col } from 'reactstrap';

// CSS
import 'assets/css/m_w.css';

// Components
import InitiateMatchButton from 'components/Buttons/InitiateMatchButton.jsx';
import CancelMatchButton from 'components/Buttons/CancelMatchButton.jsx';
import EndMatchButton from 'components/Buttons/EndMatchButton.jsx';
import JoinMWButton from 'components/Buttons/JoinMWButton.jsx'

class SettingsTab extends React.Component {
  componentDidMount() {}

  componentDidUpdate() {}

  render() {
    return (
      <div className="block-gradient">
        {/* <div className="gradient-border"> */}
        
          <Container id="settings-container">
          <Col lg="6">
            <InitiateMatchButton text="Start Next Match" />
            <CancelMatchButton text="Cancel Current Match" />
            <EndMatchButton text="End Current Match" />
            </Col>
          </Container>
        {/* </div> */}
      </div>
    );
  }
}

export default SettingsTab;
