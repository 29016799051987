import React, { Component } from 'react';

// CSS
import styles from "./MemeMatch.module.scss";

// reactstrap components
import { Button } from 'reactstrap';

// Components
import MemeMatchTopper from './MemeMatchTopper.jsx';
import InitiateMatchButton from 'components/Buttons/InitiateMatchButton.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

class MemeMatchLoadingScreen extends Component {
  state = {

  }

  componentDidUpdate() {}

  getLoadingMessage = () => {
    // console.log("getLoadingMessage - invoked")
    if (this.props.matches !== undefined && !this.props.demoMode) {

      if (this.props.matches.length == 0) {
        return "No Votes Yet...";
      }

      else return "Coming Soon..."
    }

    else if (this.props.demoMode) {
      return "Coming soon..."
    }

    else return "Loading..."
    // switch(this.props.matches) {

    //   case undefined:
    //     return "Loading..."

    //   case []:
    //     return "No matches yet...";
    //     // return "Loading..."

    //   default: 
    //     return "Loading..."
    // }
  }

  getButtonMessage = () => {
    // console.log("getButtonMessage - invoked")

    if (this.props.matches !== undefined) {

      // console.log("this.props.matches")
      // console.log(this.props.matches)
      // console.log(this.props.matches.length == 0)

      if (this.props.matches.length == 0) {
        return "Kick things off";
      }

      else return "See Topics"
    }

    else return "..."
    // switch(this.props.matches) {

    //   case undefined:
    //     return "..."

    //   case []:
    //     return "Start first match";
    //     // return "Loading..."

    //   default: 
    //     return "! ! !"
    // }
  }



  render() {

    const loadingMessage = this.getLoadingMessage();
    const loadingIcon = 
      <FontAwesomeIcon 
        icon={faSpinner} 
        pulse 
        id={styles.loadingScreenSpinner} 
        // style={{color: "white"}} 
      />;

    const buttonMessage = this.getButtonMessage();

    var startMatchButton = 
    <InitiateMatchButton   
      homepage={true} 
      changeFocusedTab={(newTabIndex) => this.props.changeFocusedTab(newTabIndex)} 
      toggleLoginModal={(loginModalIsOpen) => this.props.toggleLoginModal(loginModalIsOpen)}
      focusedTab={this.props.focusedTab}
      loginComplete={this.props.loginComplete}
      // text="Kick things off"
      text={ buttonMessage }
      lobby={this.props.lobby}
      account={this.props.account}
      paid={this.props.paid}
      provider={this.props.provider} 
      // text="Start A Match"  
      // id="loadingScreenInitButton" 
      // TODO: id={styles.loadingScreenInitButton} (css module would be in Buttons folder)
    />; 
    
    return (
      <>
      <center>
        {' '}
        <h1 id={styles.memeMatchLoadingScreen}> 
          { loadingMessage } 
          { loadingIcon } 
        </h1>
        {startMatchButton}
        </center>
      </>
    );
  }
}

export default MemeMatchLoadingScreen;
