import React from 'react';
import styles from './ModelDirectory.module.scss';

import modelsData from '../../variables/modelsData.js';

const AIDirectory = ({ models = modelsData }) => {
    return (
      <table className={styles.leaderboard}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Benchmarks</th>
            <th>OSS?</th>
            <th>Credentials</th>
            <th>Links</th>
          </tr>
        </thead>
        <tbody>
          {models.map((model, index) => (
            <tr key={index}>
              <td>{model.name}</td>
              <td>
                {(model.evaluations || []).map((evalLink, i) => (
                  <a key={i} href={evalLink} target="_blank" rel="noopener noreferrer">
                    Evaluation {i + 1}
                  </a>
                ))}
              </td>
              <td>{model.oss ? "OSS" : "Private"}</td>
              <td>{model.credentials}</td>
              <td>
                {(model.links || []).map((link, i) => (
                  <a key={i} href={link} target="_blank" rel="noopener noreferrer">
                    Link {i + 1}
                  </a>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
}

export default AIDirectory;
