import React from 'react';
import { ethers } from 'ethers';
// import socketIOClient from "socket.io-client";
import Torus from '@toruslabs/torus-embed';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { changeAccount } from '../../actions/accountActions.js';
import { toggleLoginModal, updateLoginInfo } from '../../actions/sessionStateActions.js';
// import {Helmet} from "react-helmet";

// CSS + Icons
import styles from "./Login.module.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' // TODO: fa-library
import { faTwitch, faGoogle, faRedditAlien, faDiscord, faFacebook, faApple, faTwitter, faGithub } from '@fortawesome/free-brands-svg-icons'
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

// .env variables + Smart-Contract interaction
import contractScripts from '../Buttons/contractScripts.js';
import { HTTPS_BLOCKCHAIN, DEFAULT_BLOCKCHAIN_ID, NETWORK_NAME, SERVER, SERVER_HTTPS } from '../../variables/CONTRACT_ADDRESSES.js';

// reactstrap Components
import { Button } from 'reactstrap';

// Global variables TODO
// var socket; // socket connection is passed down from App.jsx

class TorusLoginButton extends React.Component {
  
  componentDidMount() {  
    // socket = socketIOClient(SERVER_HTTPS);
    this.loadTorus();
    // this.enableTorus();
    // console.log(window.torus)
  };
    
  componentWillUnmount() {
    // socket.disconnect()
  }

  loadTorus = async () => {
    // NOTE: The Torus Embed is imported in index.html (async)
    // console.log("LOAD TORUS BY SCRIPT START");
  
    // const TorusScript = document.createElement("script");
  
    // TorusScript.src = "https://cdn.jsdelivr.net/npm/@toruslabs/torus-embed";
    // TorusScript.async = true;
  
    // TorusScript.onload = () => {
    //   console.log("Torus script loaded");
    //   // this.enableTorus(); // Call enableTorus after Torus is loaded
    // };
  
    // document.body.appendChild(TorusScript);
  
    // console.log("LOAD TORUS BY SCRIPT END");
  }
  

  // fundETHAddress = (torusETHAddress) => {
  //   socket.send(torusETHAddress); // TODO: Use 'ack" parameter?
  // }

  enableTorus = async () => {
    try {
      // const torus = new window.Torus({
      //   // buttonPosition: 'bottom-left', // default: bottom-left
      //   buttonPosition: 'top-right', // default: bottom-left

      // });

      console.log("window.Torus");
      console.log(window.Torus);
      console.log("window.torus");
      console.log(window.torus);
      const torus = new Torus();
      // console.log("torus:");
      // console.log(torus);
      window.torus = torus;
      // const torus = new window.Torus();
      // const torus = new window.torus.Torus();
      // const torus = new window.Torus.default;

      // window.torus = torus;
      await torus.init({
        buildEnv: 'production',      // default: production 
        // buildEnv: 'testing',      // default: production 
        enableLogging: true,         // default: false
        network: {
          host: HTTPS_BLOCKCHAIN,
          chainId: DEFAULT_BLOCKCHAIN_ID,
          networkName: NETWORK_NAME, // default: Main Ethereum Network
          // networkName: "Main Ethereum Network", // default: Main Ethereum Network
        },
        // TODO: would show button if it was possible to control placement
        // showTorusButton: false,       // default: true
        showTorusButton: true,       // default: true
      });

      var loginObject = {
        loginInProgress: true,
        loginComplete: false,
        provider: "Torus",
      };

      this.props.updateLoginInfo(loginObject);

      // // track how long login takes from start to finish TODO: mixpanel
      // window.mixpanel.time_event('LOGIN_COMPLETE');
      // window.mixpanel.track("LOGIN_STARTED", {"WEB3_PROVIDER": "Torus"});

      await torus.login(); // await torus.ethereum.enable()

      var web3 = new ethers.providers.Web3Provider(torus.provider);
      console.log("web3: ")
      console.log(web3)

      // var web3 = new Web3(torus.provider);
      console.log("web3: ")
      console.log(web3)
      window.web3 = web3;
      window.web4 = web3;
      window.torusProvider = torus.provider;
      // window.web5 = web5;

      // const modalToggledAfterLogin = false;
      // this.props.toggleLoginModal(modalToggledAfterLogin); // TODO: change display to information about Torus

      const torusETHAddress = web3.provider.selectedAddress;
      console.log("torusETHAddress: " + torusETHAddress)

      // let availableETH = await contractScripts.getAvailableETH(torusETHAddress); // TODO: use the wagmi hook, this func don't exist anymore
      let XPBalance = this.props.demoMode ? contractScripts.toEighteenDecimals("100") : await contractScripts.getXPBalance(torusETHAddress);
      let ETHBalance =  this.props.demoMode ? contractScripts.toEighteenDecimals("0") : await contractScripts.getETHBalance(torusETHAddress);
      // console.log("TORUS READ INFO");

      // user is assumed to have joined if XPBalance TODO: this could lead to weirdness when/if player gets back to 0 naturally
      // also could cause trouble when state gets re-hydrated and user doesn't finish joining flow (and has their XPbalance set to -1)
      // var hasJoined = (XPBalance.toString() == 0) ? false : true;
      var hasJoined = (XPBalance == contractScripts.getBigNumber("0")) ? false : true;
      console.log("hasJoined Torus: ")
      console.log(hasJoined)
      // if (!hasJoined) XPBalance = new BN("100")
      // var hasJoined = true;

      const userInfo = await torus.getUserInfo();

      console.log("userInfo: ")
      console.log(userInfo.profileImage)
              
      const web3info = {
          account: torusETHAddress,
          provider: 'Torus',
          // web3: 1,
          // network: NETWORK_NAME, // TODO
          joined: hasJoined,
          // availableETH: availableETH,
          XPBalance: XPBalance,
          ETHBalance: ETHBalance,
          userImageURL: userInfo.profileImage,
        };

        this.props.changeAccount(web3info);

        // update sessionState variables
        loginObject = {
          loginInProgress: false,
          loginComplete: true,
          provider: "Torus",
        };
  
        this.props.updateLoginInfo(loginObject);


        // // TODO: mixpanel

        // var USER_ID = torusETHAddress;
        // // var USER_SIGNUP_DATE = "2020-01-02T21:07:03Z"; // TODO: if they have 0XP
        // window.mixpanel.identify(USER_ID);

        // window.mixpanel.people.set({
        //   // "$email": "jsmith@example.com",    // only reserved properties need the $
        //   "USER_ID": USER_ID,                // use human-readable names
        //   "NEW_USER": hasJoined,
        //   "XP Balance": contractScripts.decimalEighteen(XPBalance, "TorusLoginButton").toString()  // ...or numbers
        // });
        

        // window.mixpanel.track("LOGIN_COMPLETE", 
        // {"WEB3_PROVIDER": "Torus", 
        // "ETH_ADDRESS": USER_ID, 
        // "secondsSince_OPENED_SITE": "TODO", 
        // "secondsSince_LOGIN_BUTTON_CLICK": "TODO", 
        // "secondsSince_TORUS_OPTION_CLICK": "TODO"
        // });
    } 
    catch (error) {
      console.log("ERROR IN TORUS LOGIN: ")
      console.error(error);
    }
  };

  // disableTorus = async () => {
  //     try {
  //       await web3Obj.cleanup();
  //     } catch (error) {
  //       console.error(error);
  //     }
  // };

  getButtonDisplay = () => {
    const loadingIcon =  <i className="fas fa-spinner fa-pulse" id="xmssLoadingIcon" />;

    // NOTE: this if-block is disabled / placeholder for future use
    if (this.props.loginInProgress && !this.props.loginInProgress) {
      return (

      <div id={styles.loadingIconContainer}> 
        <h3 id={styles.verifyingtext}> logging in... </h3> 
        <FontAwesomeIcon icon={faSpinner} pulse id={styles.verifyingTXloadingIcon} /> 
      </div>
      );}

    else {
      return (
        <Button 
        onClick={this.enableTorus} 
        id={styles.inModalTorusButton} type="button">
          {/* <h1 id={styles.torusConnectText}> CONNECT </h1> */}
          <h1 id={styles.loginIconsInsideModal}>
            <FontAwesomeIcon id={styles.loginIconInModal} icon={faGoogle} />
            <FontAwesomeIcon id={styles.loginIconInModal} icon={faDiscord} />
            <FontAwesomeIcon id={styles.loginIconInModal} icon={faApple} />
            {/* <FontAwesomeIcon id={styles.loginIconInModal} icon={faGithub} />
            <FontAwesomeIcon id={styles.loginIconInModal} icon={faTwitch} /> */}
            <FontAwesomeIcon id={styles.loginIconInModal} icon={faTwitter} />
            {/* <FontAwesomeIcon id={styles.loginIconInModal} icon={faFacebook} /> */}
            <FontAwesomeIcon id={styles.loginIconInModal} icon={faRedditAlien} />
          </h1>
        </Button>
      );
    }
  }


  render() {

    const buttonDisplay = this.getButtonDisplay();
    // const iframe = window.torus.torusIframe;

    return (
      // <div id={styles.navConnectContainer}>
      <div id={styles.torusButtonContainer}>
        { buttonDisplay }
      </div>
    );
  }
}

TorusLoginButton.propTypes = {
  changeAccount: PropTypes.func.isRequired,
  toggleLoginModal: PropTypes.func.isRequired,
  updateLoginInfo: PropTypes.func.isRequired,
  account: PropTypes.string,
  provider: PropTypes.string,
  web3: PropTypes.object,
  network: PropTypes.string,
};

const mapStateToProps = state => ({
  account: state.profile.account,
  provider: state.profile.provider,
  web3: state.profile.web3,
  network: state.profile.network,
  XPBalance: state.profile.XPBalance
});

export default connect(mapStateToProps, { changeAccount, toggleLoginModal, updateLoginInfo })(TorusLoginButton);
