import { FETCH_SESSION_STATE, UPDATE_SESSION_STATE, CHANGE_METRICS_CHOICE, CHANGE_FOCUSED_TAB, CHANGE_FOCUSED_MATCH, CHANGE_FOCUSED_ENTRY, TOGGLE_LOGIN_MODAL, LOGIN_IN_PROGRESS, TOGGLE_DEMO_MODE } from '../actions/types';

const initialState = {
    metricsOptIn: true,       // TODO: for tracking with mixpanel, allow opt-out
    focusedTab: 5,            // TODO: default tab (between 1-5), set to 5 for "Beta" tab
    focusedMatchID: null,     // TODO: was 0
    focusedEntry: null,       // TODO: has value when user clicks on one of two meme entries (for picture --> enlarges, for video --> plays, etc), false when they return to standard view
    loginInProgress: false,   // TODO: when true, user is logging in with Torus or Metamask 
    loginComplete: false,     // To know when user can exit modal and instantly use web3
    explorerHistory: [],
    ETHUSDToggle: false,      // false = $USD denominations, true = ETH denominations TODO: ?
    votedOnCurrMatch: false,  // TODO: hide vote option button once true, reset when currMatch changes
    explainerMode: true,      // TODO: allow people to turn off rules modals
    web2DemoMode: true,       // web2demo mode makes it so that no reads are done from smart contracts / dummy data is populated
    // MODALS BELOW
    loginModalToggled: false,
    afterLoginModalToggled: false,
    settingsModalToggled: false,
};

export default function(state = initialState, action) {
    switch (action.type) {
      case FETCH_SESSION_STATE:
        return {
          ...state,
          focusedTab: action.payload.focusedTab,
          focusedMatch: action.payload.focusedMatch,
          loginModalToggled: action.payload.loginModalToggled,
          explorerHistory: action.payload.explorerHistory,
        };
      case CHANGE_METRICS_CHOICE:
        return {
          ...state,
          metricsOptIn: action.payload
        };
      case CHANGE_FOCUSED_TAB:
        return {
          ...state,
          focusedTab: action.payload
        };
      case CHANGE_FOCUSED_ENTRY:
        return {
          ...state,
          focusedTab: action.payload
        };
      case CHANGE_FOCUSED_MATCH:
        return {
          ...state,
          focusedMatchID: action.payload
        };
      case TOGGLE_LOGIN_MODAL:
        return {
          ...state,
          loginModalToggled: action.payload
        };
      case LOGIN_IN_PROGRESS:
          return {
            ...state,
            loginInProgress: action.payload.loginInProgress,
            loginComplete: action.payload.loginComplete,
            // loginWeb3Provider: action.payload.loginWeb3Provider
        }; 
      case TOGGLE_DEMO_MODE:
        return {
          ...state,
          web2DemoMode: action.payload
        };
      default:
        return state;
    }
  }
