import React, { Component } from 'react';
import styles from './UserPage.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faCheck, faExpand, faSpinner, faExternalLinkAlt, faBookmark, faChevronDown, faChevronUp, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import contractScripts from 'components/Buttons/contractScripts';
import proposalScripts from 'components/UpcomingMatches/proposalScripts';
import StatsSection from './UserStats';
import CompareAddressSection from './CompareAddresses';
import SBTPage from '../SBTs/SBTPage';
import { Collapse, UncontrolledTooltip, Modal, ModalHeader, ModalBody } from 'reactstrap';

class UserPage extends Component {
  state = {
    viewAddress: '',
    surveyResponseInfo: [],
    surveyCreationInfo: [],
    questionCreationInfo: [],
    questionResponseInfo: [],
    userStats: {
      surveysResponded: 0,
      surveysCreated: 0,
      questionsResponded: 0,
      questionsCreated: 0,
      mostUniqueIdea: ' ... ',
      badgesReceived: 0,
      worryScore: 'x%',
      enthusiasmScore: 'y%',
      topTags: ['#cybersecurity', '#ubi', '#mechinterp'],
    },
    copied: false,
    collapseOpen: false,
    username: '',
    usernameError: '',
    bookmarked: false,
    sbtList: [],
    loadingSBTs: true,
    loadingSurveys: true,
    loadingQuestions: true,
    showAnalysisModal: false,
    aiAnalysis: '',
    showFullProfileModal: false,
    isSimulated: false,
    selectedTab: 'surveys', // For the toggle between surveys and questions
  };

  componentDidMount() {
    this.setState({ viewAddress: this.props.viewAddress });
    this.getSurveyHashesByAddress();
    this.getQuestionHashesByAddress();
    this.getUsernameByAddress();
    this.getSBTsByUserAddress();
    this.checkIfBookmarked();
    this.checkIfSimulated();
    this.checkCacheLatestBlock();
  }  

  updateCache = (cacheData) => {
    // This is a stub function that will eventually modify the cache in SurveyTool.jsx
    // For now, we can console.log what the update would be
    console.log('Updating cache with data:', cacheData);
    // localStorage.setItem('surveyCache', JSON.stringify(cacheData));
  }

  checkIfSimulated = async () => {
    const isSimulated = await contractScripts.userIsSimulated(this.props.viewAddress);
    this.setState({ isSimulated });
  }

  getSurveyHashesByAddress = async () => {
    const { viewAddress } = this.props;
    this.setState({ loadingSurveys: true });

    try {
      // Load the cache
      let surveyCache = JSON.parse(localStorage.getItem('surveyCache')) || {
        surveyIDs: [],
        questionIDs: [],
        questionResponses: {},
        arweaveContent: {},
        latestBlock: 0,
        userSurveyResponses: {},
        userSurveysCreated: {},
      };

      const latestBlock = await contractScripts.getLatestBlockNumber(this.props.provider);

      // Check if the cache is up to date
      if (surveyCache.latestBlock < latestBlock) {
        this.setState({ isCacheUpToDate: false });
      } else {
        this.setState({ isCacheUpToDate: true });
      }

      let surveyResponseHashes = [];
      let surveyCreatedHashes = [];

      // Fetch survey responses by the user from cache if possible
      const cachedSurveyResponseHashes = surveyCache.userSurveyResponses && surveyCache.userSurveyResponses[viewAddress];
      if (cachedSurveyResponseHashes) {
        surveyResponseHashes = cachedSurveyResponseHashes;
      } else {
        // Fetch from contractScripts
        surveyResponseHashes = await contractScripts.getSurveyResponsesByAddress(this.props.provider, viewAddress);
        // Update cache
        surveyCache.userSurveyResponses = surveyCache.userSurveyResponses || {};
        surveyCache.userSurveyResponses[viewAddress] = surveyResponseHashes;
        this.updateCache(surveyCache);
      }

      // Fetch surveys created by the user from cache if possible
      const cachedSurveyCreatedHashes = surveyCache.userSurveysCreated && surveyCache.userSurveysCreated[viewAddress];
      if (cachedSurveyCreatedHashes) {
        surveyCreatedHashes = cachedSurveyCreatedHashes;
      } else {
        // Fetch from contractScripts
        surveyCreatedHashes = await contractScripts.getSurveysCreatedByAddress(this.props.provider, viewAddress);
        // Update cache
        surveyCache.userSurveysCreated = surveyCache.userSurveysCreated || {};
        surveyCache.userSurveysCreated[viewAddress] = surveyCreatedHashes;
        this.updateCache(surveyCache);
      }

      // Update cache with new survey IDs
      surveyCache.surveyIDs = [...new Set([...surveyCache.surveyIDs, ...surveyResponseHashes, ...surveyCreatedHashes])];

      // Fetch survey data for new survey IDs and update cache
      for (let surveyId of [...surveyResponseHashes, ...surveyCreatedHashes]) {
        if (!surveyCache.arweaveContent[surveyId]) {
          const surveyData = await contractScripts.getSurveyDataById(this.props.provider, surveyId);
          if (surveyData) {
            surveyCache.arweaveContent[surveyId] = surveyData;
            this.updateCache(surveyCache);
          }
        }
      }

      this.setState({ surveysResponded: surveyResponseHashes.length, surveysCreated: surveyCreatedHashes.length });
      this.getSurveyInfoFromHashes(surveyResponseHashes, surveyCreatedHashes);

      // Update the cache's latestBlock
      surveyCache.latestBlock = latestBlock;
      this.updateCache(surveyCache);

    } catch (error) {
      console.error("Error fetching survey hashes: ", error);
    } finally {
      this.setState({ loadingSurveys: false });
    }
  }

  getSurveyInfoFromHashes = async (surveyResponseHashes, surveyCreatedHashes) => {
    const surveyResponseInfo = [];
    const surveyCreationInfo = [];

    try {
      let surveyCache = JSON.parse(localStorage.getItem('surveyCache')) || {
        surveyIDs: [],
        questionIDs: [],
        questionResponses: {},
        arweaveContent: {}
      };

      for (const surveyId of surveyResponseHashes) {
        const surveyData = surveyCache.arweaveContent[surveyId];
        if (surveyData) {
          surveyResponseInfo.push({
            title: surveyData.title,
            questionsCount: surveyData.questionIDs.length,
            id: surveyId,
          });
        } else {
          // Fetch survey data from contractScripts and update cache
          const surveyData = await contractScripts.getSurveyDataById(this.props.provider, surveyId);
          if (surveyData) {
            surveyResponseInfo.push({
              title: surveyData.title,
              questionsCount: surveyData.questionIDs.length,
              id: surveyId,
            });
            // Update cache
            surveyCache.arweaveContent[surveyId] = surveyData;
            this.updateCache(surveyCache);
          }
        }
      }

      for (const surveyId of surveyCreatedHashes) {
        const surveyData = surveyCache.arweaveContent[surveyId];
        if (surveyData) {
          surveyCreationInfo.push({
            title: surveyData.title,
            questionsCount: surveyData.questionIDs.length,
            id: surveyId,
          });
        } else {
          // Fetch survey data from contractScripts and update cache
          const surveyData = await contractScripts.getSurveyDataById(this.props.provider, surveyId);
          if (surveyData) {
            surveyCreationInfo.push({
              title: surveyData.title,
              questionsCount: surveyData.questionIDs.length,
              id: surveyId,
            });
            // Update cache
            surveyCache.arweaveContent[surveyId] = surveyData;
            this.updateCache(surveyCache);
          }
        }
      }

      this.setState({ surveyResponseInfo, surveyCreationInfo });
    } catch (error) {
      console.error("Error fetching survey data: ", error);
    }
  }

  getQuestionHashesByAddress = async () => {
    const { viewAddress } = this.props;
    this.setState({ loadingQuestions: true });

    try {
      // Load the cache
      let surveyCache = JSON.parse(localStorage.getItem('surveyCache')) || {
        surveyIDs: [],
        questionIDs: [],
        questionResponses: {},
        arweaveContent: {},
        latestBlock: 0,
        userQuestionResponses: {},
        userQuestionsCreated: {},
      };

      const latestBlock = await contractScripts.getLatestBlockNumber(this.props.provider);

      // Check if the cache is up to date
      if (surveyCache.latestBlock < latestBlock) {
        this.setState({ isCacheUpToDate: false });
      } else {
        this.setState({ isCacheUpToDate: true });
      }

      let questionCreationHashes = [];
      let questionResponseHashes = [];

      // Fetch questions created by the user from cache if possible
      const cachedQuestionCreationHashes = surveyCache.userQuestionsCreated && surveyCache.userQuestionsCreated[viewAddress];
      if (cachedQuestionCreationHashes) {
        questionCreationHashes = cachedQuestionCreationHashes;
      } else {
        // Fetch from contractScripts
        questionCreationHashes = await contractScripts.getQuestionsCreatedByAddress(this.props.provider, viewAddress);
        // Update cache
        surveyCache.userQuestionsCreated = surveyCache.userQuestionsCreated || {};
        surveyCache.userQuestionsCreated[viewAddress] = questionCreationHashes;
        this.updateCache(surveyCache);
      }

      // Fetch question responses by the user from cache if possible
      const cachedQuestionResponseHashes = surveyCache.userQuestionResponses && surveyCache.userQuestionResponses[viewAddress];
      if (cachedQuestionResponseHashes) {
        questionResponseHashes = cachedQuestionResponseHashes;
      } else {
        // Fetch from contractScripts
        questionResponseHashes = await contractScripts.getQuestionResponsesByAddress(this.props.provider, viewAddress);
        // Update cache
        surveyCache.userQuestionResponses = surveyCache.userQuestionResponses || {};
        surveyCache.userQuestionResponses[viewAddress] = questionResponseHashes;
        this.updateCache(surveyCache);
      }

      // Update cache with new question IDs
      surveyCache.questionIDs = [...new Set([...surveyCache.questionIDs, ...questionCreationHashes, ...questionResponseHashes])];

      // Fetch question data for new question IDs and update cache
      for (let questionId of [...questionCreationHashes, ...questionResponseHashes]) {
        if (!surveyCache.arweaveContent[questionId]) {
          const questionData = await contractScripts.getQuestionData(this.props.provider, questionId);
          if (questionData) {
            surveyCache.arweaveContent[questionId] = questionData;
            this.updateCache(surveyCache);
          }
        }
      }

      this.getQuestionInfoFromHashes(questionCreationHashes, questionResponseHashes);

      // Update the cache's latestBlock
      surveyCache.latestBlock = latestBlock;
      this.updateCache(surveyCache);

    } catch (error) {
      console.error("Error fetching question hashes: ", error);
    } finally {
      this.setState({ loadingQuestions: false });
    }
  }

  getQuestionInfoFromHashes = async (questionCreationHashes, questionResponseHashes) => {
    const questionCreationInfo = [];
    const questionResponseInfo = [];

    try {
      let surveyCache = JSON.parse(localStorage.getItem('surveyCache')) || {
        surveyIDs: [],
        questionIDs: [],
        questionResponses: {},
        arweaveContent: {}
      };

      for (const questionId of questionCreationHashes) {
        const questionData = surveyCache.arweaveContent[questionId];
        if (questionData) {
          questionCreationInfo.push({
            prompt: questionData.prompt,
            type: questionData.type,
            id: questionId,
          });
        } else {
          // Fetch question data from contractScripts and update cache
          const questionData = await contractScripts.getQuestionData(this.props.provider, questionId);
          if (questionData) {
            questionCreationInfo.push({
              prompt: questionData.prompt,
              type: questionData.type,
              id: questionId,
            });
            // Update cache
            surveyCache.arweaveContent[questionId] = questionData;
            this.updateCache(surveyCache);
          }
        }
      }

      for (const questionId of questionResponseHashes) {
        const questionData = surveyCache.arweaveContent[questionId];
        if (questionData) {
          questionResponseInfo.push({
            prompt: questionData.prompt,
            type: questionData.type,
            id: questionId,
          });
        } else {
          // Fetch question data from contractScripts and update cache
          const questionData = await contractScripts.getQuestionData(this.props.provider, questionId);
          if (questionData) {
            questionResponseInfo.push({
              prompt: questionData.prompt,
              type: questionData.type,
              id: questionId,
            });
            // Update cache
            surveyCache.arweaveContent[questionId] = questionData;
            this.updateCache(surveyCache);
          }
        }
      }

      this.setState({ questionCreationInfo, questionResponseInfo });
    } catch (error) {
      console.error("Error fetching question data: ", error);
    }
  }

  checkCacheLatestBlock = async () => {
    const latestBlock = await contractScripts.getLatestBlockNumber(this.props.provider);
    let surveyCache = JSON.parse(localStorage.getItem('surveyCache')) || {};

    if (surveyCache.latestBlock && surveyCache.latestBlock === latestBlock) {
      // Cache is up to date
      this.setState({ isCacheUpToDate: true });
    } else {
      // Cache is not up to date
      this.setState({ isCacheUpToDate: false });
      // Optionally, update the cache's latestBlock
      surveyCache.latestBlock = latestBlock;
      this.updateCache(surveyCache);
    }
  }

  getSBTsByUserAddress = async () => {
    const { viewAddress } = this.props;
    this.setState({ loadingSBTs: true });
    
    try {
      const cache = JSON.parse(localStorage.getItem('sbtCache')) || {};
      const networkID = this.props.network?.id;
      const sbtList = cache[networkID]?.sbtList || {};
      
      let userSBTs = [];
      
      // Check cache first
      for (const [sbtAddress, sbtData] of Object.entries(sbtList)) {
        const mintedAddresses = sbtData.mintedAddresses || [];
        const burnedAddresses = sbtData.burnedAddresses || [];
        
        if (mintedAddresses.includes(viewAddress) && !burnedAddresses.includes(viewAddress)) {
          userSBTs.push({
            sbtAddress: sbtAddress,
            sbtInfo: sbtData.sbtInfo
          });
        }
      }
      
      // If cache is empty or incomplete, fetch from contract
      if (userSBTs.length === 0) {
        const sbtAddresses = await contractScripts.getSBTsByUserAddress(this.props.provider, viewAddress);
        // since above also contains tokenURI ^
        const extractedAddresses = sbtAddresses.map(sbt => sbt.sbtAddress);

        for (const sbtAddress of extractedAddresses) {
          if (!sbtList[sbtAddress] || !sbtList[sbtAddress].sbtInfo) {
            // Fetch SBT info if not in cache
            const sbtInfo = await contractScripts.getSbtMetadata(this.props.provider, sbtAddress);
            userSBTs.push({
              sbtAddress: sbtAddress,
              sbtInfo: sbtInfo
            });
            
            // Update cache
            sbtList[sbtAddress] = {
              ...sbtList[sbtAddress],
              sbtInfo: sbtInfo,
              mintedAddresses: [...(sbtList[sbtAddress]?.mintedAddresses || []), viewAddress],
              burnedAddresses: sbtList[sbtAddress]?.burnedAddresses || []
            };
          } else {
            userSBTs.push({
              sbtAddress: sbtAddress,
              sbtInfo: sbtList[sbtAddress].sbtInfo
            });
          }
        }
        
        // Update cache
        cache[networkID] = { ...cache[networkID], sbtList };
        localStorage.setItem('sbtCache', JSON.stringify(cache));
      }
      
      console.log("User SBTs: ", userSBTs);
      this.setState({ sbtList: userSBTs });
    } catch (error) {
      console.error("Error fetching SBTs: ", error);
    } finally {
      this.setState({ loadingSBTs: false });
    }
  }

  copyToClipboard = () => {
    navigator.clipboard.writeText(this.props.viewAddress).then(() => {
      this.setState({ copied: true }, () => {
        setTimeout(() => this.setState({ copied: false }), 2500);
      });
    });
  }

  toggleCollapse = () => {
    this.setState(prevState => ({
      collapseOpen: !prevState.collapseOpen
    }));
  }

  openFullPage = () => {
    window.open(`/u/${this.props.viewAddress}`);
  }

  getUsernameByAddress = async () => {
    try {
      const username = await contractScripts.getUsernameByAddress(this.props.provider, this.props.viewAddress);
      this.setState({ username });
    } catch (error) {
      console.error("Error fetching username: ", error);
    }
  }

  handleUsernameChange = (event) => {
    this.setState({ username: event.target.value, usernameError: '' });
  }

  setUsername = async () => {
    const { username } = this.state;
    try {
      await contractScripts.setUsernameForAddress(this.props.provider, username);
      this.setState({ usernameError: '' });
    } catch (error) {
      console.error("Error setting username: ", error);
      this.setState({ usernameError: 'Error setting username' });
    }
  }

  toggleBookmark = () => {
    const bookmarks = JSON.parse(localStorage.getItem('bookmarkedUsers')) || [];
    if (bookmarks.includes(this.props.viewAddress)) {
      const index = bookmarks.indexOf(this.props.viewAddress);
      bookmarks.splice(index, 1);
    } else {
      bookmarks.push(this.props.viewAddress);
    }
    localStorage.setItem('bookmarkedUsers', JSON.stringify(bookmarks));
    this.setState({ bookmarked: !this.state.bookmarked });
  }

  checkIfBookmarked = () => {
    const bookmarks = JSON.parse(localStorage.getItem('bookmarkedUsers')) || [];
    this.setState({ bookmarked: bookmarks.includes(this.props.viewAddress) });
  }

  analyzeUser = () => {
    // const analysis = "This is a placeholder analysis. In the future, this will be generated by AI based on the user's survey responses and collected SBTs.";
    const analysis = "";
    this.setState({ aiAnalysis: analysis, showAnalysisModal: true });
  }

  getExplorerUrl = () => {
    const network = this.props.network;
    const address = this.props.viewAddress;
    const chainId = network.chainId;
  
    const getLink = (address, chainId) => {
      if (chainId === 8543) {
        return `https://basescan.org/address/${address}`;
      } else if (chainId === 84532) {
        return `https://sepolia.basescan.org/address/${address}`;
      }
      return `https://sepolia.basescan.org/address/${address}`;
    };
  
    return getLink(address, chainId);
  }

  render() {
    const {
      viewAddress,
      surveyResponseInfo,
      surveyCreationInfo,
      questionResponseInfo,
      questionCreationInfo,
      userStats,
      copied,
      collapseOpen,
      username,
      usernameError,
      bookmarked,
      sbtList,
      loadingSBTs,
      loadingSurveys,
      loadingQuestions,
      showAnalysisModal,
      aiAnalysis,
      showFullProfileModal,
      isSimulated
    } = this.state;
    const { minimized, account } = this.props;
    const addressDisplay = isSimulated ? username : proposalScripts.getShortenedAddress(viewAddress);

    const surveysQuestionsToggle = (
      <>
        {!minimized && (
          <div className={styles.tabContainer}>
            <button
              className={this.state.selectedTab === 'surveys' ? styles.activeTab : ''}
              onClick={() => this.setState({ selectedTab: 'surveys' })}
            >
              Surveys
            </button>
            <button
              className={this.state.selectedTab === 'questions' ? styles.activeTab : ''}
              onClick={() => this.setState({ selectedTab: 'questions' })}
            >
              Questions
            </button>
          </div>
        )}  
        </>
    );

    return (
      <div className={`${styles.userPage} ${minimized ? styles.minimized : ''}`}>
        <div className={styles.header}>
          <div className={styles.userInfo}>
            <div className={styles.avatarContainer}>
              <div className={styles.avatar}></div>
            </div>
            <h1 id={styles.userPageAddress}>
              {addressDisplay}
              {isSimulated && (
                <span className={styles.simulatedBadge} id="simulatedUserTooltip">
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                </span>
              )}
              {isSimulated && (
                <UncontrolledTooltip placement="right" target="simulatedUserTooltip">
                  This is a simulated user whose answers are generated based on documents.
                </UncontrolledTooltip>
              )}
              {!isSimulated && (
                <button onClick={this.copyToClipboard} className={styles.copyButton}>
                  <FontAwesomeIcon icon={copied ? faCheck : faCopy} />
                </button>
              )}
              {minimized && (
                <button onClick={this.openFullPage} className={styles.expandButton}>
                  <FontAwesomeIcon icon={faExpand} id={styles.expandIcon} />
                </button>
              )}

              {!minimized && (
              <a href={this.getExplorerUrl()} target="_blank" rel="noopener noreferrer" className={styles.explorerLink}>
                <FontAwesomeIcon icon={faExternalLinkAlt} id={styles.externalLinkIcon} />
              </a>
            )}
            </h1>
          </div>

          {account === viewAddress && (
            <div className={styles.userActions}>
              <a href="/bookmarks" className={styles.bookmarksLink}>
                My Bookmarks <FontAwesomeIcon icon={faExternalLinkAlt} />
              </a>
              <div className={styles.usernameSection}>
                <input
                  id={styles.usernameInput}
                  type="text"
                  value={username}
                  onChange={this.handleUsernameChange}
                  placeholder="Enter username"
                />
                <button onClick={this.setUsername}>Set Username</button>
                {usernameError && <span className={styles.error}>{usernameError}</span>}
              </div>
            </div>
          )}
          {!minimized && (
            <button onClick={this.toggleBookmark} className={styles.bookmarkButton} style={{ color: bookmarked ? '#00adb5' : undefined }}>
              <FontAwesomeIcon icon={faBookmark} />
            </button>
          )}
        </div>    

        {!minimized && (
          <div className={styles.content}>
            {this.state.selectedTab === 'surveys' && (
              <div className={styles.leftColumn}>
                { surveysQuestionsToggle }
                <div className={styles.surveySection}>
                  <h2>Survey Responses:</h2>
                  {loadingSurveys ? (
                    <FontAwesomeIcon icon={faSpinner} spin id={styles.loadingIcon}/>
                  ) : (
                    surveyResponseInfo.map((survey, index) => (
                      <a key={index} href={`/survey/${survey.id}/${viewAddress}`} target="_blank" rel="noopener noreferrer" className={styles.surveyPreview}>
                        <div className={styles.surveyTitle}>{survey.title}</div>
                        <div className={styles.surveyInfo}>Questions: {survey.questionsCount}</div>
                      </a>
                    ))
                  )}
                  <h2>Surveys Created:</h2>
                  {loadingSurveys ? (
                    <FontAwesomeIcon icon={faSpinner} spin id={styles.loadingIcon} />
                  ) : (
                    surveyCreationInfo.map((survey, index) => (
                      <a key={index} href={`/survey/${survey.id}`} target="_blank" rel="noopener noreferrer" className={styles.surveyPreview}>
                        <div className={styles.surveyTitle}>{survey.title}</div>
                        <div className={styles.surveyInfo}>Questions: {survey.questionsCount}</div>
                      </a>
                    ))
                  )}
                </div>
              </div>
            )}

            {this.state.selectedTab === 'questions' && (
              <div className={styles.leftColumn}>
                { surveysQuestionsToggle }
                <div className={styles.questionSection}>
                  <h2>Question Responses:</h2>
                  {loadingQuestions ? (
                    <FontAwesomeIcon icon={faSpinner} spin id={styles.loadingIcon} />
                  ) : (
                    questionResponseInfo.map((question, index) => (
                      <a key={index} href={`/question/${question.id}/${viewAddress}`} target="_blank" rel="noopener noreferrer" className={styles.questionPreview}>
                        <div className={styles.questionPrompt}>{question.prompt}</div>
                        <div className={styles.questionInfo}>Type: {question.type}</div>
                        <div className={styles.questionID}>
                          ID: {proposalScripts.getShortenedQuestionID(question.id, false)}
                        </div>
                      </a>
                    ))
                  )}
                  <h2>Questions Created:</h2>
                  {loadingQuestions ? (
                    <FontAwesomeIcon icon={faSpinner} spin id={styles.loadingIcon} />
                  ) : (
                    questionCreationInfo.map((question, index) => (
                      <a key={index} href={`/question/${question.id}`} target="_blank" rel="noopener noreferrer" className={styles.questionPreview}>
                        <div className={styles.questionPrompt}>{question.prompt}</div>
                        <div className={styles.questionInfo}>Type: {question.type}</div>
                        <div className={styles.questionID}>
                          ID: {proposalScripts.getShortenedQuestionID(question.id, false)}
                        </div>
                      </a>
                    ))
                  )}
                </div>
              </div>
            )}

            <div className={styles.rightColumn}>
              <div className={styles.sbtSection}>
                <h2>Collected SBTs:</h2>
                {loadingSBTs ? (
                  <FontAwesomeIcon icon={faSpinner} spin id={styles.loadingIcon} />
                ) : (
                  <div className={styles.sbtGrid}>
                    {sbtList.map((sbtAddress, index) => (
                      <SBTPage
                        key={index}
                        SBTAddress={sbtAddress}
                        account={this.props.account}
                        provider={this.props.provider}
                        network={this.props.network}
                        miniaturized={true}   
                        loginComplete={this.props.loginComplete}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {!minimized && (
          <>
            <div className={styles.actionButtons}>
              <button onClick={this.toggleCollapse} className={styles.collapseButton}>
                Compare Views {collapseOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
              </button>
              <button onClick={this.analyzeUser} className={styles.analyzeButton}>
                Analyze
                <sup className={styles.comingSoon}>soon</sup>
              </button>
            </div>

            <Collapse isOpen={collapseOpen}>
              <CompareAddressSection firstAddress={viewAddress} />
            </Collapse>
          </>
        )}

        {isSimulated && (
          <div className={styles.simulatedUserActions}>
            <button onClick={() => this.setState({ showFullProfileModal: true })}>View Simulated Responses</button>
            <button>View Simulated SBTs</button>
          </div>
        )}

        <Modal isOpen={showAnalysisModal} toggle={() => this.setState({ showAnalysisModal: false })} className={styles.modalContent}>
          <ModalHeader toggle={() => this.setState({ showAnalysisModal: false })} className={styles.modalHeader}>User Analysis (Placeholder)</ModalHeader>
          <ModalBody className={styles.modalBody}>
            <p className={styles.placeholderNote}>Note: This is a placeholder analysis. In the future, this feature will use AI to provide insights based on the user's survey responses and collected SBTs.</p>
            {aiAnalysis}
          </ModalBody>
        </Modal>

        <Modal isOpen={showFullProfileModal} toggle={() => this.setState({ showFullProfileModal: false })} size="lg" className={styles.modalContent}>
          <ModalHeader toggle={() => this.setState({ showFullProfileModal: false })} className={styles.modalHeader}>Full User Profile</ModalHeader>
          <ModalBody className={styles.modalBody}>
            <div className={styles.modalSummary}>
              <h3>User Summary</h3>
              <p>{aiAnalysis}</p>
            </div>
            <StatsSection
              userStats={userStats}
              collapseOpen={collapseOpen}
              toggleCollapse={this.toggleCollapse}
            />
            <div className={styles.modalSurveys}>
              <h3>Survey Responses</h3>
              {loadingSurveys ? (
                <FontAwesomeIcon icon={faSpinner} spin id={styles.loadingIcon} />
              ) : (
                surveyResponseInfo.map((survey, index) => (
                  <div key={index} className={styles.surveyPreview}>
                    <div className={styles.surveyTitle}>{survey.title}</div>
                    <div className={styles.surveyInfo}>Questions: {survey.questionsCount}</div>
                  </div>
                ))
              )}
            </div>
            <div className={styles.modalSBTs}>
              <h3>Collected SBTs</h3>
              {loadingSBTs ? (
                <FontAwesomeIcon icon={faSpinner} spin id={styles.loadingIcon} />
              ) : (
                sbtList.map((sbtAddress, index) => (
                  <SBTPage
                    key={index}
                    SBTAddress={sbtAddress}
                    provider={this.props.provider}
                    network={this.props.network}
                    miniaturized={true}
                    loginComplete={this.props.loginComplete}
                  />
                ))
              )}
            </div>
            {!minimized && (
            <div className={styles.modalActions}>
              <a href="/bookmarks" className={styles.bookmarksLink}>
                My Bookmarks <FontAwesomeIcon icon={faExternalLinkAlt} />
              </a>
              <a href={this.getExplorerUrl()} target="_blank" rel="noopener noreferrer" className={styles.explorerLink}>
                View on Explorer <FontAwesomeIcon icon={faExternalLinkAlt} />
              </a>
            </div>
          )}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default UserPage;
