import React from 'react';
import Iframe from 'react-iframe'; //TODO

// CSS
import 'assets/css/m_w.css';
import styles from "./MemeMatch.module.scss";

// Scripts
import memeMatchScripts from './memeMatchScripts.js';

// reactstrap components
import { Card, CardBody, CardFooter } from 'reactstrap';

// Components

// Default thumbnail image in case rendering fails TODO: present link?
const defaultThumbnail = require('../../assets/img/seedsmanTransparent.png');

class CancelledMemeCard extends React.Component {
  componentDidMount() {}

  componentDidUpdate() {}

  //   loadThumbnail = async () => {
  //     const format = this.props.match.proposal.format;
  //     const submission = this.props.match.competitors[this.props.entryNumber].submissionLink;
  //     // let buildThumbnail = await memeMatchScripts.makeThumbnail(format, submission); TODO: ASYNC
  //     var thumbnailURLReturn = await memeMatchScripts.makeThumbnail(format, submission);  // TODO: AWAIT
  //     if (this.props.entryNumber == 0) {
  //       thumbnail0 = "url(" + thumbnailURLReturn +")";
  //       this.setState({ thumbnailURL: thumbnail0, thumbnailStatus: 'loaded'});
  //     }

  //     else {
  //       thumbnail1 = "url(" + thumbnailURLReturn +")";
  //       this.setState({ thumbnailURL: thumbnail1, thumbnailStatus: 'loaded'});
  //     }

  //     console.log("THUMBNAIL " + this.props.entryNumber + " LOADED");
  //   };

  render() {

    const leftRightID = this.props.entryNumber == 0 ? styles.activeMemeCardLeft : styles.activeMemeCardRight;
    // var cardColor = this.props.proposal.format !== undefined ? 'transparent' : memeMatchScripts.getMemeCardColor(this.props.proposal.format);

    const thumbnailInsert = defaultThumbnail;

    const interiorCard = (
      <Card id={styles.cancelledMemeCard} className="card-blog card-background" data-animation={true}>
        <div
          onClick={this.entryClicked}
          className="full-background"
          style={{
            backgroundImage: 'url(' + thumbnailInsert + ')',
            opacity: '0.3'
          }}
        />
        {/* <CardBody> 
        </CardBody> */}
        {/* <CardFooter id="activeCardFooter">

    </CardFooter> */}
      </Card>
    );

    return (
        <Card id={leftRightID} className="activeMemeCard">
          <CardBody className={styles.activeCardContent}>
            {interiorCard}
          </CardBody>
          <CardFooter id={styles.activeMemeCardFooter}>
          {/* <div  id={styles.activeMemeCardFooter}> */}
              {/* {footerDisplay} */}
              {/* </div> */}
          </CardFooter>
        </Card>
    );
  }
}

export default CancelledMemeCard;
