import React, { Component } from "react";
import { NavLink } from "reactstrap";

// CSS & Images
// import "./ContributorsArea.module.scss";
import styles from "./AboutPage.module.scss";
import nftImage from '../../assets/img/seedsmanTransparent.png';

// Components
// import PublicTrello from "./PublicTrello";

class AboutPage extends Component {
  state = {
    hasNftAccess: false,
    password: ""
  };

  componentDidMount() {
  }

  componentDidUpdate(prevState) {
  }

  checkNFTStatus = () => {
    // Placeholder logic to set the hasNftAccess state
    // You will need to replace this with your own logic to check for the presence of the correct ERC-721 NFT

    if (this.state.password == "password") {
        return true;
        // this.setState({ hasNftAccess: true });
    }

    else {
        return false;
        // this.setState({ hasNftAccess: false });
    }
  };

  handlePasswordChange = event => {
    console.log("event: " + event);
    this.setState({ password: event.target.value });
    console.log("password: " + event.target.value);    
    this.checkNFTStatus();
  }

  handleSubmit = event => {
    event.preventDefault();
    const hasNFT = this.checkNFTStatus();

    if (hasNFT && !this.state.hasNftAccess) {
        this.setState({ hasNftAccess: true });
    }

    // logic to check password and update hasNftAccess state
    // ...
  }

  render() {
    const { hasNftAccess, password } = this.state;

    return (
    <>
      <div className={styles.AboutPage}>
        {!hasNftAccess ? (
          <div className={styles.notAbleToView}>


              <h1>Click robot for more information</h1>

              <NavLink
                    // href="https://docsend.com/view/28x54q8ez7pccsqq"
                    href="https://docs.google.com/document/d/18LTCMOQ8wpjkwc8XIN4TCmjCbtz7AgfCWCDi3Ka1ei8/edit?usp=sharing"
                    target="_blank"
                    // href="/ideas"
                    // target=""
                    // onClick={}
                    // id={styles.footerLink}
                    >
                    <img src={nftImage} alt="NFT Preview" id={styles.nftImage} />
              </NavLink>

            {/* <iframe src="https://lumalabs.ai/embed/5fcdbaf3-6c4f-45e7-8bc4-735c20d3fd7b?mode=sparkles&background=%23ffffff&color=%23000000&showTitle=true&loadBg=true&logoPosition=bottom-left&infoPosition=bottom-right&cinematicVideo=undefined&showMenu=false" width="281" height="500" frameborder="0" title="luma embed" style={{border: "none"}}></iframe> */}
            <form onSubmit={this.handleSubmit} id={styles.nftPasswordInputForm}>
            <div class={styles.inputContainer}>
                
                {/* <input type="text" 
                       placeholder="enter password"
                       class={styles.inputBar} 
                       value={password}
                       onChange={this.handlePasswordChange}/> */}
                </div>
                {/* <SlantedInputBar /> */}
              {/* <button type="submit" id={styles.nftPasswordSubmitButton}>Mint</button> */}
            </form>
          </div>
        ) : (
          <div className={styles.ableToView}>
            <div className={styles.leftSide}>
              <h1>About</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                placerat, nibh non commodo feugiat, lacus sem blandit lectus,
                id mollis sapien enim at ipsum.
              </p>
              <p>
                <span className={styles.highlighted}>Clickable words</span>
              </p>
            </div>
            <div className={styles.rightSide}>
              <h1>Explorer</h1>
            </div>
          </div>
        )}
      </div>

    </>
    );
  }
}

export default AboutPage;