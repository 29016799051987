import { ethers, utils, EventFilter } from 'ethers';
import { encrypt } from '@metamask/eth-sig-util';
// import { ecrecover, toBuffer, toUtf8 } from 'ethereumjs-util'
// import axios from 'axios';

// Arweave data storage
import Arweave from 'arweave';
import { JWKInterface } from 'arweave/node/lib/wallet';
import ARWEAVE_KEY from '../../variables/arweaveKey.json';

// Smart Contract Addresses (for interactions)
import {
  MW_XP_ADDRESS,
  ACCOUNTS_ADDRESS,
  LOBBIESANDPROPOSALS_ADDRESS,
  MATCHES_ADDRESS,
  SURVEYS_ADDRESS,
  SBT_FACTORY_ADDRESS,
  TORUS_GASPRICE,
  BLOCKCHAIN_ID,
  // FOR TORUS:
  WS_BLOCKCHAIN,
  ARWEAVE_ACTIVE,
  // ARWEAVE_KEY,
  FIRST_BLOCKNUMBER_BASE_SEPOLIA,
  FIRST_BLOCKNUMBER_BASE,
  START_BLOCKNUMBER_FILTER,
  FAUCET_PRIVKEY,
  //
  BLOCKED_SURVEY_IDS, // ADMIN option - TODO (to remove improperly uploaded hash for a given ID)
  //
  CORRECT_NETWORK,
  TESTNET_AMOUNT,
} from '../../variables/CONTRACT_ADDRESSES.js';

// Smart Contract ABI JSON
import MW_XP from '../../contractsABI/MW_XP_ABI.json';
import ACCOUNTS from '../../contractsABI/ACCOUNTS_ABI.json';
import LOBBIESANDPROPOSALS from '../../contractsABI/LOBBIESANDPROPOSALS_ABI.json';
import MATCHES from '../../contractsABI/MATCHES_ABI.json';
import SURVEYS from '../../contractsABI/SURVEYS_ABI.json';
import SBT_FACTORY_ABI from '../../contractsABI/SBT_FACTORY_ABI.json';
import CUSTOM_SBT_ABI from '../../contractsABI/CUSTOM_SBT_ABI.json';

const contractScripts = {

// EVENTS DETECTION

  listenForNewEvents: async function (callbackFunction) {

      // console.log("listenForNewEvents() - invoked")

      // Connect to the provider
      // TODO: make websockets
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      // TODO: wsProvider not working here for some reason
      // TODO: try wsProvider again when no longer using truffle
      // TODO: AFTERTRUFFLE
      // const wsProvider = new ethers.providers.WebSocketProvider('ws://localhost:8545', 'localhost')
      // TODO: this should detect what network wagmi is on and create a websockets URL
      const wsProvider = new ethers.providers.WebSocketProvider(WS_BLOCKCHAIN) 
      console.log("wsProvider:")
      console.log(wsProvider)
      // wsProvider._websocket.once('error', (error) => {console.log(error)}); 
      wsProvider._websocket.onerror = function(error) {
        console.log("WebSocket Error: ")
        console.log(error)
    };

      // Instantiate the Matches contract
      const MatchesContract = new ethers.Contract(MATCHES_ADDRESS, MATCHES, provider);
      // TODO: AFTERTRUFFLE

      // const MatchesContract = new ethers.Contract(MATCHES_ADDRESS, MATCHES, wsProvider);


      // const filter = MatchesContract.filters.NewMatch

      // create an ethers.js filter object which detects new (after this.getLatestBlockNumber())
      // events, which will fire and update the MemewarsSite.jsx component 
      const currentBlockNumber = await provider.getBlockNumber();

      console.log("currentBlockNumber: ")
      console.log(currentBlockNumber)

      const nextBlockNumber = currentBlockNumber + 1;

      // console.log("nextBlockNumber")
      // console.log(nextBlockNumber)


      // for topics argument
      // let ABI = ["event NewMatch(uint indexed newMatchID, bytes32 indexed lobbyID, bool indexed forRealMoney)"];
      // let iface = new ethers.utils.Interface(ABI);
      // let eventSignature = iface.encodeFunctionData("NewMatch", ["newMatchID", "lobbyID", "forRealMoney"]);

      const filter = {
          address: MATCHES_ADDRESS,
          fromBlock: nextBlockNumber, // makes sure to only detect NEW events (after site-load)
          // topics: [utils.id("NewMatch(uint,bytes32,bool)")]
      };
      
      // HTTP PROVIDER MDOE (when provider is used in contract)
      MatchesContract.on(filter, (event) => {
        console.log(event);
        callbackFunction(event);
      });

      // // WEBSOCKET PROVIDER MDOE (when wsProvider is used in contract instantiation)
      // // MatchesContract.on(filter, (event) => {
      // //   let parsedEvent = ethers.utils.defaultAbiCoder.decode(['uint', 'bytes32', 'bool'], event.data);
      // //   console.log("parsedEvent")
      // //   console.log(parsedEvent);
      // //   // callbackFunction(event);
      // // });
      // wsProvider.on(filter, (event) => {
      //   console.log("wsEvent:")
      //   console.log(event);
      //   // callbackFunction(event);
      // });


      // // Listen for events that match the filter
      // MatchesContract.on("NewMatch", (event) => {

      // });
  },

  listenForSBTEvents: async function(providerName, handleNewEvent) {
    // Get provider based on the sign-in method
    const providerLocation = this.getProviderLocation(providerName);
    const provider = providerName === 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);

    const contract = new ethers.Contract(SBT_FACTORY_ADDRESS, SBT_FACTORY_ABI, provider);

    // Listen for SBTCreated events
    contract.on("SBTCreated", (sbtAddress, event) => {
      console.log("New SBT created:", sbtAddress);
      handleNewEvent({
        type: 'SBTCreated',
        sbtAddress,
        transactionHash: event.transactionHash
      });
    });

    console.log("Listening for SBT events...");
  },

  removeSBTEventListener: function(providerName) {
    // Get provider based on the sign-in method
    const providerLocation = this.getProviderLocation(providerName);
    const provider = providerName === 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);

    const contract = new ethers.Contract(SBT_FACTORY_ADDRESS, SBT_FACTORY_ABI, provider);

    // Remove all listeners for SBTCreated event
    contract.removeAllListeners("SBTCreated");
  },

  listenForSurveyEvents: async function(providerName, handleNewEvent) {

    // const provider = new ethers.providers.Web3Provider(window.ethereum);

    // get provider based on which sign-in method was used (wagmi or torus)
    const providerLocation = this.getProviderLocation(providerName);
    const provider = providerName == 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);


    const contract = new ethers.Contract(SURVEYS_ADDRESS, SURVEYS, provider);
  
    // Listen for SurveyAdded events
    contract.on("SurveyAdded", (creator, surveyId, event) => {
      console.log("New survey added:", { creator, surveyId });
      handleNewEvent({
        type: 'SurveyAdded',
        creator,
        // surveyId: surveyId.toHexString(),
        surveyId: surveyId.toString(),
        transactionHash: event.transactionHash
      });
    });
  
    // Listen for QuestionsAdded events
    contract.on("QuestionsAdded", (creator, questionIds, surveyIds, event) => {
      console.log("New questions added:", { creator, questionIds, surveyIds });
      handleNewEvent({
        type: 'QuestionsAdded',
        creator,
        questionIds: questionIds.map(id => id.toString()),
        surveyIds: surveyIds.map(id => id.toString()),
        transactionHash: event.transactionHash
      });
    });
  
    // Listen for ResponsesSubmitted events
    contract.on("ResponsesSubmitted", (responder, questionIds, surveyId, event) => {
      console.log("New responses submitted:", { responder, questionIds, surveyId });
      handleNewEvent({
        type: 'ResponsesSubmitted',
        responder,
        questionIds: questionIds.map(id => id.toString()),
        surveyId: surveyId.toString(),
        transactionHash: event.transactionHash
      });
    });
  
    console.log("Listening for Survey events...");
  },

//-----------------------------------------------------**** ACCOUNTS.sol ****-------------------------------------------------------//


  // get balance of ETH user has deposited to m_w (TODO: Necessary in forRealMoneyMode?) 
  // TODO: change the name of this so it's not confused with the amount of ETH in user's wallet (it's ETH they've deposited to MW)
  // TODO: REALMONEYMODE
  getETHBalance: async function(address) {
    // Connect to the provider
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    // Instantiate the Accounts contract
    const AccountsContract = new ethers.Contract(ACCOUNTS_ADDRESS, ACCOUNTS, provider);

    // Call the ETHbalance function
    let ETHbalance = await AccountsContract.functions.ETHbalance(address);
    
    // returned result is already an ethers.js BN (BigNumber), so no need to format
    // NOTE: after switching from web3.js --> ethers.js, response object is an array rather than number
    return ETHbalance[0]
    // return ethers.BigNumber.from(ETHbalance)
    // return this.getBigNumber(XPbalance, "this.getETHbalance()")
  },


  // get player's XP balance (as "wei" in ethers.js BigNumber format)
  // TODO: MULTILOBBIESTODO
  getXPBalance: async function(address) {
    // console.log("getXPBalance invoked")

    // Connect to the provider
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    // Instantiate the XP contract
    const XPContract = new ethers.Contract(MW_XP_ADDRESS, MW_XP, provider);

    // Call the XPbalance function
    let XPbalance = await XPContract.functions.XPbalance(address);
    // console.log("XPbalance: ")
    // console.log(XPbalance[0])

    // returned result is already an ethers.js BN (BigNumber), so no need to format
    // NOTE: after switching from web3.js --> ethers.js, response object is an array rather than number
    return XPbalance[0]
    // return ethers.BigNumber.from(XPbalance)
    // return this.getBigNumber(XPbalance, "this.getXPBalance()")
  },

//-----------------------------------------------------**** MATCHES.sol ****--------------------------------------------------------//


  // initiate the next-up match in a given lobby
  initiateMatch: async function(initiatorAddress, providerName, lobbyName, forRealMoney) {

    // get provider based on which sign-in method was used (wagmi or torus)
    const providerLocation = this.getProviderLocation(providerName);

    // get bytes32 / string version of lobbyID
    const lobbyID = this.getLobbyIDfromString(lobbyName);

    // Connect to the provider
    const provider = providerName == 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);
    const signer = provider.getSigner();

    // Instantiate the smart contract
    const MatchesContract = new ethers.Contract(MATCHES_ADDRESS, MATCHES, signer);

    // Call the initiateMatch function
    const txUnsigned = await MatchesContract.initiateMatch(lobbyID, forRealMoney);

    const receipt = await txUnsigned.wait();
    console.log("tx receipt:");
    console.log(receipt);
  },

  // used to free lobby / cancel a match which did not receive enough competitors (2)
  cancelMatch: async function(userAddress, providerName, lobbyName, forRealMoney) {

    // get provider based on which sign-in method was used (wagmi or torus)
    const providerLocation = this.getProviderLocation(providerName);

    // get bytes32 / string version of lobbyID
    const lobbyID = this.getLobbyIDfromString(lobbyName);

    // Connect to the provider
    const provider = providerName == 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);
    const signer = provider.getSigner();

    // Instantiate the smart contract
    const MatchesContract = new ethers.Contract(MATCHES_ADDRESS, MATCHES, signer);

    // Call the makeMatchActiveOrCancel function
    const tx = await MatchesContract.makeMatchActiveOrCancel(lobbyID, forRealMoney);

    // Wait for the transaction to be mined
    const receipt = await tx.wait();

    console.log(receipt);
  },

  // enter as a competitor for a given match 
  joinPendingMatch: async function(joinerAddress, providerName, lobbyName, forRealMoney, asCompetitor, competitorEntry, voterDepositOrCompetitorBid) {

      // get provider based on which sign-in method was used (wagmi or torus)
      const providerLocation = this.getProviderLocation(providerName);
   
      // get bytes32 / string version of lobbyID
      const lobbyID = this.getLobbyIDfromString(lobbyName);

      // Connect to the provider
      const provider = providerName == 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);
      const signer = provider.getSigner();

      // Instantiate the smart contract
      const MatchesContract = new ethers.Contract(MATCHES_ADDRESS, MATCHES, signer);

      // Call the joinPendingMatch function
      const tx = await MatchesContract.joinPendingMatch(lobbyID, forRealMoney, competitorEntry, voterDepositOrCompetitorBid);

      // Wait for the transaction to be mined
      const receipt = await tx.wait();
      console.log(receipt);
  },

  // vote on an ACTIVE match
  voteOnMatch: async function(voterAddress, providerName, lobbyName, forRealMoney, vote) {

      // get provider based on which sign-in method was used (wagmi or torus)
      const providerLocation = this.getProviderLocation(providerName);
    
      // Connect to the provider
      const provider = providerName == 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);
      const signer = provider.getSigner();

      // Instantiate the Matches contract
      const MatchesContract = new ethers.Contract(MATCHES_ADDRESS, MATCHES, signer);

      // get bytes32 / string version of lobbyID
      const lobbyID = this.getLobbyIDfromString(lobbyName);

      // Call the voteOnMatch() function
      const tx = await MatchesContract.voteOnMatch(lobbyID, forRealMoney, vote);

      // Wait for the transaction to be mined
      const receipt = await tx.wait();

      console.log(receipt);
  },

  // formally end a match whose allowedVotingTime is over, distributing prize funds and freeing lobby
  endActiveMatch: async function(enderAddress, providerName, lobbyName, forRealMoney) {

      // get provider based on which sign-in method was used (wagmi or torus)
      const providerLocation = this.getProviderLocation(providerName);

      // Connect to the provider
      const provider = providerName == 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);
      const signer = provider.getSigner();

      // Instantiate the Matches contract
      const MatchesContract = new ethers.Contract(MATCHES_ADDRESS, MATCHES, signer);

      // get bytes32 / string version of lobbyID
      const lobbyID = this.getLobbyIDfromString(lobbyName);

      // Call the endActiveMatch function
      const tx = await MatchesContract.endActiveMatch(lobbyID, forRealMoney);

      // Wait for the transaction to be mined
      const receipt = await tx.wait();

      console.log(receipt);
  },

  // get the details of a match by its ID number
  // Input: Number
  // TODO: should also include lobby MULTILOBBYTODO
  getMatchInfoByID: async function(matchID) {

      // Connect to the provider
      const provider = new ethers.providers.Web3Provider(window.ethereum);

      // Instantiate the Matches contract
      const MatchesContract = new ethers.Contract(MATCHES_ADDRESS, MATCHES, provider);

      // Call the getMatchInfoByID function
      let matchInfo = await MatchesContract.functions.getMatchInfoByID(matchID);
      // console.log("matchInfo:")
      // console.log(matchInfo)

      // Call the getProposalInfoByID function
      const proposalInfo = await this.getProposalInfoByID(matchInfo.proposalID);

      // Call the getMatchStatusFromEnum function
      const matchStatusString = this.getJsNumberFromBN(matchInfo.matchStatus, "this.matchInfoByID()").toString()
      const matchStatus = this.getMatchStatusFromEnum(matchStatusString);

      // Convert matchInfo.votes to normal JS numbers (from BN)
      var votesArray = new Array(2);
      votesArray[0] = this.getJsNumberFromBN(matchInfo.votes[0], "this.matchInfoByID()")
      votesArray[1] = this.getJsNumberFromBN(matchInfo.votes[1], "this.matchInfoByID()")

      const match = {
        ID: matchID,
        lobby: "main",                                // TODO: MULTILOBBIESTODO
        matchStatus: matchStatus,
        proposal: proposalInfo,
        numVoters: matchInfo.voters.length,
        voters: matchInfo.voters,
        votes: votesArray,                            // TODO PRIVATEVOTE: update for commit-reveal // voteCount[0] for competitor0, voteCount[1] for competitor[1]
        competitors: [],
        prizePool: this.decimalEighteen(matchInfo.prizePool, "this.matchInfoByID()"),
        prizeShare: this.decimalEighteen(matchInfo.prizeShare, "this.matchInfoByID()"),     
        competitorPrize: this.decimalEighteen(matchInfo.competitorPrize, "this.matchInfoByID()"), 
        matchStartTime: this.getJsNumberFromBN(matchInfo.matchInitTime, "this.matchInfoByID()"),
        votingStartTime: this.getJsNumberFromBN(matchInfo.votingStartTime, "this.matchInfoByID()"),  
        isJackpotMatch: matchInfo.isJackpotMatch,
    };

    console.log("match:")
    console.log(match)

    // Add entry-related details (competitor bids / submissions)
    const noCompetitor0 = (matchInfo.competitor0 == "0x0000000000000000000000000000000000000000");
    const noCompetitor1 = (matchInfo.competitor1 == "0x0000000000000000000000000000000000000000");

    const emptyCompetitor = {
      address: "0x0000000000000000000000000000000000000000",
      competitorBid: this.getBigNumber(0),
      submissionLink: ""
    }

    match.competitors[0] = emptyCompetitor;
    match.competitors[1] = emptyCompetitor;

    if (!noCompetitor0) { 
      let competitor0info = await this.getEntryByAddress(matchID, matchInfo.competitor0);
      
      match.competitors[0] =  {
        address: matchInfo.competitor0,
        competitorBid: this.getBigNumber(competitor0info.competitorBid),
        submissionLink: competitor0info.submissionLink
      }
    }

    if (!noCompetitor1) { 
      let competitor1info = await this.getEntryByAddress(matchID, matchInfo.competitor1);

      match.competitors[1] = {
        address: matchInfo.competitor1,
        competitorBid: this.getBigNumber(competitor1info.competitorBid),
        submissionLink: competitor1info.submissionLink
      }
    }

    return match;
  },

  // match status is returned as an integer from contract, convert back to descriptive string
  getMatchStatusFromEnum: function(matchStatusEnum) {
  // (0 = NONE, 1 = PENDING, 2 = ACTIVE, 3 = OVER, 4 = CANCELLED) 
    switch (matchStatusEnum) {
      case '0':
        return 'NONE';
      case '1':
        return 'PENDING';
      case '2':
        return 'ACTIVE';
      case '3':
        return 'OVER';
      case '4':
        return 'CANCELLED';
      }
    },
  
  // returns ID(#) of lobby's latest Match, or 0 if there is no latest match 
  getLatestMatchByLobby: async function(lobbyName, lobbyPaid) {

      // Connect to the provider
      const provider = new ethers.providers.Web3Provider(window.ethereum);

      // get bytes32 / string version of lobbyID
      const lobbyID = this.getLobbyIDfromString(lobbyName);

      // Instantiate the LobbiesAndProposals contract
      const LobbiesAndProposalsContract = new ethers.Contract(LOBBIESANDPROPOSALS_ADDRESS, LOBBIESANDPROPOSALS, provider);

      // Call the getNumberOfMatchesByLobby function
      let numLobbyMatches = await this.getNumberOfMatchesByLobby(lobbyName, lobbyPaid);
      console.log("numLobbyMatches: " + numLobbyMatches)

      if (numLobbyMatches > 0) {
          // NOTE: Object returned from contract is an array of BigNumbers 
          let latestLobbyMatchBN = await LobbiesAndProposalsContract.functions.getMostRecentMatchByLobby(lobbyID, lobbyPaid);
          // console.log("latestLobbyMatchBN")
          // console.log(latestLobbyMatchBN)
          const latestLobbyMatch = this.getJsNumberFromBN(latestLobbyMatchBN[0], "this.getLatestMatchByLobby()");
          return latestLobbyMatch;
        }
        else { 
          // console.log("getLatestMatchByLobby() – else-block #1")
          return 0; 
        }
  },

  // get the entire collection of a lobby's matches (TODO: read from IPFS or server)
  getMatchesByLobby: async function(lobbyName, lobbyPaid) {

    // Connect to the provider (stored in browser, initiated in MemewarsSite.jsx)
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    // get bytes32 / string version of lobbyID
    const lobbyID = this.getLobbyIDfromString(lobbyName);

    // const correctEndpoint = this.getCorrectEndpoint(provider)
    const LobbiesAndProposalsContract = new ethers.Contract(LOBBIESANDPROPOSALS_ADDRESS, LOBBIESANDPROPOSALS, provider);


    let returnedLobbyMatchIDsObj = await LobbiesAndProposalsContract.functions.getMatchesByLobby(lobbyID, lobbyPaid);
    // Array of ethers.js BigNumber objects, cast to number in for-loop below
    var returnedLobbyMatchIDs = returnedLobbyMatchIDsObj[0]
    console.log("returnedLobbyMatchIDs: ")
    console.log(returnedLobbyMatchIDs)

    const lobbyMatches = new Array(returnedLobbyMatchIDs.length);

    var i;
    for(i = 0; i < lobbyMatches.length; i++) {
        let matchInfo = await this.getMatchInfoByID(returnedLobbyMatchIDs[i].toNumber());
        lobbyMatches[i] = matchInfo;
    }

    return lobbyMatches;
  },

  // return number of matches which have occured in a given lobby
  getNumberOfMatchesByLobby: async function(lobbyName, lobbyPaid) {

    // Connect to the provider (stored in browser, initiated in MemewarsSite.jsx)
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    // get bytes32 / string version of lobbyID
    const lobbyID = this.getLobbyIDfromString(lobbyName);

    // Instantiate the LobbiesAndProposals contract
    const LobbiesAndProposalsContract = new ethers.Contract(LOBBIESANDPROPOSALS_ADDRESS, LOBBIESANDPROPOSALS, provider);

    // Call the numberOfMatchesByLobby function
    // NOTE: after switching from web3.js --> ethers.js, response object is an array rather than number
    let numLobbyMatchesObj = await LobbiesAndProposalsContract.functions.numberOfMatchesByLobby(lobbyID, lobbyPaid);
    const numLobbyMatchesBN = numLobbyMatchesObj[0];
    console.log("numLobbyMatchesBN: ")
    console.log(numLobbyMatchesBN)
    const numLobbyMatches = numLobbyMatchesBN.toNumber()

    return numLobbyMatches;
  },

  // get the details of an entry (submission hash for competitors / vote hash for voters)
  getEntryByAddress: async function(matchID, address) {

    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const MatchesContract = new ethers.Contract(MATCHES_ADDRESS, MATCHES, provider);

    let entry = await MatchesContract.functions.getEntryByAddress(matchID, address);

    console.log("entry: ")
    console.log(entry)

    return entry;
  },

//------------------------------------------------**** LOBBIESANDPROPOSALS.sol ****-------------------------------------------------//

  // get all valid (non-expired) proposals from a given lobby
  getProposalsByLobby: async function(lobbyName, lobbyPaid) {

    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const LobbiesAndProposalsContract = new ethers.Contract(LOBBIESANDPROPOSALS_ADDRESS, LOBBIESANDPROPOSALS, provider);

    const lobbyID = this.getLobbyIDfromString(lobbyName);

    let returnedProposalIDs = await LobbiesAndProposalsContract.getProposalsByLobby(lobbyID, lobbyPaid);    
    var proposalArray = new Array(returnedProposalIDs.length);

    var i;
    for (i = 0; i < returnedProposalIDs.length; i++) {
      const proposalInfo = await this.getProposalInfoByID(returnedProposalIDs[i]); 
      proposalArray[i] = proposalInfo;
    }

    console.log("proposalArray: ")
    console.log(proposalArray)

    return proposalArray;
  },

  // get next proposal which would be chosen if match was initiated 
  getNextProposalByLobby: async function(MatchesContractAddress, lobbyID, lobbyPaid) {}, // TODO

  getProposalInfoByID: async function(proposalID) {

    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const LobbiesAndProposalsContract = new ethers.Contract(LOBBIESANDPROPOSALS_ADDRESS, LOBBIESANDPROPOSALS, provider);

    let proposalInfo = await LobbiesAndProposalsContract.getProposalInfoByID(proposalID);
    // console.log(proposalInfo)

    const proposalObject = {
      ID: proposalID,
      submitter: proposalInfo.submitter,
      paid: proposalInfo.forRealMoney,
      format: this.getProposalFormat(proposalInfo.info),
      prompt: this.getProposalPrompt(proposalInfo.info),
      voterEntryCost: proposalInfo.voterEntryCost,                // should stay BN
      minCompetitorBid: proposalInfo.minCompetitorBid,            // should stay BN
      allowedJoiningTime: this.getJsNumberFromBN(proposalInfo.allowedJoiningTime, "this.getProposalInfoByID()"), 
      allowedVotingTime: this.getJsNumberFromBN(proposalInfo.allowedVotingTime, "this.getProposalInfoByID()"),
      timeSubmitted: this.getJsNumberFromBN(proposalInfo.timeSubmitted, "this.getProposalInfoByID()"),
      upvotes: this.getJsNumberFromBN(proposalInfo.upvotes, "this.getProposalInfoByID()"),
      chosen: proposalInfo.chosen,
      defaultProposal: proposalInfo.defaultProposal
    }

    return proposalObject;
  },

  // submit a proposal to a given lobby
  submitProposal: async function (userAddress, providerName, lobbyName, forRealMoney, format, prompt) {

  // TODO: allow more customization:

      // const newProposal = {
      //   lobbyID, 
      //   forRealMoney, 
      //   info, 
      //   entryCost, 
      //   minCompetitorBid, 
      //   restrictedContestants, 
      //   approvedContestants, 
      //   allowedJoiningTime, 
      //   allowedVotingTime, 
      //   userSubmitted, 
      //   updateLobbyDefault
      // }

    // get provider based on which sign-in method was used (wagmi or torus)
    const providerLocation = this.getProviderLocation(providerName);

    // Connect to the provider
    const provider = providerName == 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);
    const signer = provider.getSigner();

    // Instantiate the smart contract
    const LobbiesAndProposalContract = new ethers.Contract(LOBBIESANDPROPOSALS_ADDRESS, LOBBIESANDPROPOSALS, signer);

    let proposalDefaults = await this.getGlobalProposalDefaults(forRealMoney);

    // get bytes32 / string version of lobbyID
    const lobbyID = this.getLobbyIDfromString(lobbyName);

    const info = format + "/" + prompt;

    const entryCost = proposalDefaults.voterEntryCost;
    const minCompetitorBid = proposalDefaults.minCompetitorBid;
    const restrictedContestants = false;  // TODO: allow option
    const approvedContestants = [];
    const allowedJoiningTime = proposalDefaults.allowedJoiningTime;
    const allowedVotingTime = proposalDefaults.allowedVotingTime;
    const userSubmitted = true;           // TODO: 
    const updateLobbyDefault = false;     // TODO: allow admin to do from web console

    // Call the createProposal function
    const tx = await LobbiesAndProposalContract.createProposal(lobbyID,
      forRealMoney,
      info,
      entryCost,
      minCompetitorBid,
      restrictedContestants,
      approvedContestants,
      allowedJoiningTime,
      allowedVotingTime,
      userSubmitted,
      updateLobbyDefault);

    // Wait for the transaction to be mined
    const receipt = await tx.wait();

    console.log(receipt);

    return 0;
  },

  // vote on a given proposal (by ID)
  // upvote is a bool -> if true, upvote, else downvote
  voteOnProposal: async function(voterAddress, providerName, proposalID, upvote) {

    // get provider based on which sign-in method was used (wagmi or torus)
    const providerLocation = this.getProviderLocation(providerName);

    // Connect to the provider
    const provider = providerName == 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);
    const signer = provider.getSigner();

    // Instantiate the LobbiesAndProposals contract
    const LobbiesAndProposalsContract = new ethers.Contract(LOBBIESANDPROPOSALS_ADDRESS, LOBBIESANDPROPOSALS, signer);

    // Call the voteOnProposal function
    const txUnsigned = await LobbiesAndProposalsContract.voteOnProposal(proposalID, upvote);

    // Wait for the transaction to be mined
    const receipt = await txUnsigned.wait();
    console.log(receipt);
  },

  getLobbyInfo: async function(lobbyID) {}, // TODO

  // get default proposals for a given mode (paid / unpaid)
  getGlobalProposalDefaults: async function(forRealMoney) {

    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const LobbiesAndProposalsContract = new ethers.Contract(LOBBIESANDPROPOSALS_ADDRESS, LOBBIESANDPROPOSALS, provider);
    let globalDefaults = await LobbiesAndProposalsContract.getDefaultGlobals(forRealMoney);
    
    const defaultGlobals = {
      voterEntryCost: globalDefaults.defaultVoterEntry,
      minCompetitorBid: globalDefaults.defaultMinCompetitorBid,
      allowedJoiningTime: globalDefaults.defaultAllowedJoiningTime,
      allowedVotingTime: globalDefaults.defaultAllowedVotingTime,
    }

    return defaultGlobals;
  },

  // returns first string before "/" character - for example: RETURNED/NOT-RETURNED/NOT-RETURNED
  getProposalFormat: function(infoString) {
    const info = infoString.split('/');
    return info[0];
  },

  // returns first string after "/" character - for example: NOT-RETURNED/RETURNED/NOT-RETURNED
  getProposalPrompt: function(infoString) {
    const info = infoString.split('/');
    return info[1];
  },

//------------------------------------------------------**** SURVEYS.sol ****--------------------------------------------------------//

fetchAllQuestionIDs: async function(providerName, fromBlock = null, toBlock = null) {
  const providerLocation = this.getProviderLocation(providerName);
  const provider = providerName === 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);
  const SurveyContract = new ethers.Contract(SURVEYS_ADDRESS, SURVEYS, provider);

  // Use the correct event signature: QuestionsAdded
  const questionsAddedEventFilter = SurveyContract.filters.QuestionsAdded(null, null, null);

  if (fromBlock === null && START_BLOCKNUMBER_FILTER) {
    fromBlock = await this.getRelevantBlocknumberForFilter(provider);
  }

  if (toBlock === null) {
    toBlock = 'latest';
  }

  let events;
  try {
    events = await SurveyContract.queryFilter(questionsAddedEventFilter, fromBlock, toBlock);
  } catch (error) {
    const result = await this.getPastEventsInChunks({
      provider,
      contract: SurveyContract,
      eventFilter: questionsAddedEventFilter,
      fromBlock: fromBlock,
      toBlock: toBlock
    });
    events = result.events;
  }

  // Use a Set to store unique question IDs
  const questionIDSet = new Set();

  events.forEach(event => {
    // QuestionsAdded event emits an array of question IDs
    const questionIds = event.args.questionIds;
    questionIds.forEach(id => {
      if (id && id !== ethers.constants.HashZero) {
        questionIDSet.add(id);
      }
    });
  });

  // Convert Set back to an array
  const uniqueQuestionIDs = Array.from(questionIDSet);

  return uniqueQuestionIDs;
},

getResponsesByQuestionID: async function(providerName, questionId, fromBlock = null, toBlock = null) {
  const providerLocation = this.getProviderLocation(providerName);
  const provider = providerName === 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);
  const SurveyContract = new ethers.Contract(SURVEYS_ADDRESS, SURVEYS, provider);

  // Can't filter on questionIds since it's not indexed
  const responseSubmittedEventFilter = SurveyContract.filters.ResponsesSubmitted();

  if (fromBlock === null && START_BLOCKNUMBER_FILTER) {
    fromBlock = await this.getRelevantBlocknumberForFilter(provider);
  }

  if (toBlock === null) {
    toBlock = 'latest';
  }

  let events;
  try {
    events = await SurveyContract.queryFilter(responseSubmittedEventFilter, fromBlock, toBlock);
  } catch (error) {
    const result = await this.getPastEventsInChunks({
      provider,
      contract: SurveyContract,
      eventFilter: responseSubmittedEventFilter,
      fromBlock: fromBlock,
      toBlock: toBlock
    });
    events = result.events;
  }

  const responses = await Promise.all(
    events
      .filter(event => event.args.questionIds.includes(questionId))
      .map(async event => {
        const responder = event.args.responder;
        const surveyId = event.args.surveyId;
        const timestamp = event.args.timestamp;

        // Fetch the specific response for this questionId
        const responseData = await this.getResponse(providerName, responder, questionId);

        return {
          responder,
          questionId,
          surveyId,
          response: responseData,
          timestamp
        };
      })
  );

  return responses;
},

submitSurveyResponse: async function(providerName, surveyId, arweaveHash) {
  const providerLocation = this.getProviderLocation(providerName);
  const provider = providerName == 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);  

  const signer = provider.getSigner();
  const SurveyContract = new ethers.Contract(SURVEYS_ADDRESS, SURVEYS, signer);

  // console.log("surveyId", surveyId);
  // console.log("arweaveHash", arweaveHash);

  const tx = await SurveyContract.submitResponse(surveyId, arweaveHash);
  // console.log("tx", tx);
  const receipt = await tx.wait();
  // console.log(receipt);

  return receipt;
},

getSurveyResponsesByAddress: async function(providerName, userAddress, fromBlock = null, toBlock = null) {
  const providerLocation = this.getProviderLocation(providerName);
  const provider = providerName == 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);
  const SurveyContract = new ethers.Contract(SURVEYS_ADDRESS, SURVEYS, provider);

  const responseSubmittedEventTopic = SurveyContract.filters.ResponsesSubmitted(userAddress, null, null);

  if (fromBlock === null && START_BLOCKNUMBER_FILTER) {
    fromBlock = await this.getRelevantBlocknumberForFilter(provider);
  }

  if (toBlock === null) {
    toBlock = 'latest';
  }

  let surveyResponseIDs;
  try {
    surveyResponseIDs = await SurveyContract.queryFilter(responseSubmittedEventTopic, fromBlock, toBlock);
  } catch (error) {
    const result = await this.getPastEventsInChunks({
      provider,
      contract: SurveyContract,
      eventFilter: responseSubmittedEventTopic,
      fromBlock: fromBlock,
      toBlock: toBlock
    });
    surveyResponseIDs = result.events;
  }

  var surveyIDs = surveyResponseIDs.map(event => event.args.surveyId);
  return surveyIDs;
},

getSurveysCreatedByAddress: async function(providerName, userAddress, fromBlock = null, toBlock = null) {
  const providerLocation = this.getProviderLocation(providerName);
  const provider = providerName == 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);
  const SurveyContract = new ethers.Contract(SURVEYS_ADDRESS, SURVEYS, provider);

  const surveyCreatedEventTopic = SurveyContract.filters.SurveyAdded(userAddress, null);

  if (fromBlock === null && START_BLOCKNUMBER_FILTER) {
    fromBlock = await this.getRelevantBlocknumberForFilter(provider);
  }

  if (toBlock === null) {
    toBlock = 'latest';
  }

  let events;
  try {
    events = await SurveyContract.queryFilter(surveyCreatedEventTopic, fromBlock, toBlock);
  } catch (error) {
    const result = await this.getPastEventsInChunks({
      provider,
      contract: SurveyContract,
      eventFilter: surveyCreatedEventTopic,
      fromBlock: fromBlock,
      toBlock: toBlock
    });
    events = result.events;
  }

  var surveyIDs = events.map(event => event.args.surveyId);
  return surveyIDs;
},

getQuestionsCreatedByAddress: async function(providerName, userAddress, fromBlock = null, toBlock = null) {
  const providerLocation = this.getProviderLocation(providerName);
  const provider = providerName === 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);
  const SurveyContract = new ethers.Contract(SURVEYS_ADDRESS, SURVEYS, provider);

  // Use the correct event signature: QuestionsAdded(address creator, bytes32[] questionIds)
  const questionsAddedEventFilter = SurveyContract.filters.QuestionsAdded(userAddress, null, null);

  if (fromBlock === null && START_BLOCKNUMBER_FILTER) {
    fromBlock = await this.getRelevantBlocknumberForFilter(provider);
  }

  if (toBlock === null) {
    toBlock = 'latest';
  }

  let events;
  try {
    events = await SurveyContract.queryFilter(questionsAddedEventFilter, fromBlock, toBlock);
  } catch (error) {
    const result = await this.getPastEventsInChunks({
      provider,
      contract: SurveyContract,
      eventFilter: questionsAddedEventFilter,
      fromBlock: fromBlock,
      toBlock: toBlock
    });
    events = result.events;
  }

  const questionIDs = [];
  events.forEach(event => {
    const questionIds = event.args.questionIds;
    questionIds.forEach(id => {
      if (id && id !== ethers.constants.HashZero) {
        questionIDs.push(id);
      }
    });
  });

  return questionIDs;
},

getQuestionResponsesByAddress: async function(providerName, userAddress, fromBlock = null, toBlock = null) {
  const providerLocation = this.getProviderLocation(providerName);
  const provider = providerName === 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);
  const SurveyContract = new ethers.Contract(SURVEYS_ADDRESS, SURVEYS, provider);

  // Use the correct event signature: ResponsesSubmitted(address responder, bytes32[] questionIds)
  const responsesSubmittedEventFilter = SurveyContract.filters.ResponsesSubmitted(userAddress, null);

  if (fromBlock === null && START_BLOCKNUMBER_FILTER) {
    fromBlock = await this.getRelevantBlocknumberForFilter(provider);
  }

  if (toBlock === null) {
    toBlock = 'latest';
  }

  let events;
  try {
    events = await SurveyContract.queryFilter(responsesSubmittedEventFilter, fromBlock, toBlock);
  } catch (error) {
    const result = await this.getPastEventsInChunks({
      provider,
      contract: SurveyContract,
      eventFilter: responsesSubmittedEventFilter,
      fromBlock: fromBlock,
      toBlock: toBlock
    });
    events = result.events;
  }

  const questionIDs = new Set();
  events.forEach(event => {
    const questionIds = event.args.questionIds;
    questionIds.forEach(id => {
      if (id && id !== ethers.constants.HashZero) {
        questionIDs.add(id);
      }
    });
  });

  return Array.from(questionIDs);
},

fetchUserSubmittedSurveyIDs: async function(providerName, fromBlock = null, toBlock = null) {
  const blocklist = new Set(BLOCKED_SURVEY_IDS);
  const providerLocation = this.getProviderLocation(providerName);
  const provider = providerName === 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);
  const SurveyContract = new ethers.Contract(SURVEYS_ADDRESS, SURVEYS, provider);

  const surveyAddedEventFilter = SurveyContract.filters.SurveyAdded(null, null);

  if (fromBlock === null && START_BLOCKNUMBER_FILTER) {
    fromBlock = await this.getRelevantBlocknumberForFilter(provider);
  }

  if (toBlock === null) {
    toBlock = 'latest';
  }

  let events;
  try {
    events = await SurveyContract.queryFilter(surveyAddedEventFilter, fromBlock, toBlock);
  } catch (error) {
    const result = await this.getPastEventsInChunks({
      provider,
      contract: SurveyContract,
      eventFilter: surveyAddedEventFilter,
      fromBlock: fromBlock,
      toBlock: toBlock
    });
    events = result.events;
  }

  // Filter out undefined survey IDs
  const surveyIDSet = new Set(
    events
      .map(event => event.args.surveyId)
      .filter(id => id && !blocklist.has(id))
  );

  const surveyIDs = Array.from(surveyIDSet);
  return surveyIDs;
},

fetchAllSurveyResponses: async function(providerName, surveyId) {
  const providerLocation = this.getProviderLocation(providerName);
  const provider = providerName === 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);
  const SurveyContract = new ethers.Contract(SURVEYS_ADDRESS, SURVEYS, provider);

  const responseSubmittedEventTopic = SurveyContract.filters.ResponsesSubmitted(null, null, surveyId);

  let surveyResponseEvents;
  if (START_BLOCKNUMBER_FILTER) {
    const startBlock = await this.getRelevantBlocknumberForFilter(provider);
    try {
      surveyResponseEvents = await SurveyContract.queryFilter(responseSubmittedEventTopic, startBlock);
    } catch (error) {
      const result = await this.getPastEventsInChunks({
        provider,
        contract: SurveyContract,
        eventFilter: responseSubmittedEventTopic,
        fromBlock: startBlock
      });
      surveyResponseEvents = result.events;
    }
  } else {
    surveyResponseEvents = await SurveyContract.queryFilter(responseSubmittedEventTopic);
  }

  // console.log('Survey Response Events:', surveyResponseEvents);

  const responses = await Promise.all(
    surveyResponseEvents.map(async event => {
      const responder = event.args.responder;
      const surveyId = event.args.surveyId;
      const timestamp = event.args.timestamp;

      // console.log('Event Data:', { responder, surveyId, timestamp });

      const surveyResponseData = await this.getSurveyResponse(providerName, responder, surveyId);

      // console.log('Survey Response Data:', surveyResponseData);

      return {
        responder,
        surveyId,
        response: surveyResponseData,
        timestamp
      };
    })
  );

  return responses;
},

getSurveyDataById: async function(providerName, surveyId) {
  if (!surveyId || surveyId === ethers.constants.HashZero) {
    console.warn("Survey ID is null or HashZero, returning null");
    return null;
  }

  try {
    const providerLocation = this.getProviderLocation(providerName);
    const provider = providerName == 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);
    const SurveyContract = new ethers.Contract(SURVEYS_ADDRESS, SURVEYS, provider);

    const surveyArweaveHashBytes = await SurveyContract.getSurveyHash(surveyId);
    const arweaveSurveyHash = this.hexToBase64url(surveyArweaveHashBytes);

    if (!ARWEAVE_ACTIVE) {
      console.log("Arweave is not active. Unable to fetch survey data.");
      return null;
    }

    const arweaveSurveyData = await this.downloadDataFromArweave(arweaveSurveyHash);

    try {
      const surveyData = JSON.parse(arweaveSurveyData);
      return surveyData;
    } catch (jsonError) {
      console.error("Error parsing survey data JSON:", jsonError);
      return null;
    }
  } catch (error) {
    console.error("Error fetching survey data:", error);
    return null;
  }
},

getSurveyResponse: async function(providerName, userAddress, surveyId) {
  const response = await this.getResponse(providerName, userAddress, surveyId);
  return response;
},

addSurveyWithQuestions: async function(providerName, surveyId, surveyData, questionIds, questionDataArray) {
  const providerLocation = this.getProviderLocation(providerName);
  const provider = providerName === 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);
  const signer = provider.getSigner();
  const SurveyContract = new ethers.Contract(SURVEYS_ADDRESS, SURVEYS, signer);

  let surveyArweaveHash, questionArweaveHashes = [];

  if (ARWEAVE_ACTIVE) {
    const surveyDataString = JSON.stringify(surveyData);
    surveyArweaveHash = await this.uploadDataToArweave(surveyDataString, 'json');
    for (let questionData of questionDataArray) {
      const questionDataString = JSON.stringify(questionData);
      const questionArweaveHash = await this.uploadDataToArweave(questionDataString, 'json');
      questionArweaveHashes.push(questionArweaveHash);
    }
  } else {
    console.log("Arweave is not active. Survey and question data will not be uploaded.");
    return;
  }

  const surveyArweaveHashBytes = this.base64urlToHex(surveyArweaveHash);
  const questionArweaveHashesBytes = questionArweaveHashes.map(hash => this.base64urlToHex(hash));

  const tx = await SurveyContract.addSurvey(surveyId, surveyArweaveHashBytes, questionIds, questionArweaveHashesBytes);
  const receipt = await tx.wait();

  return { receipt };
},

addQuestions: async function(providerName, questionIds, questionDataArray, surveyIds) {
  const providerLocation = this.getProviderLocation(providerName);
  const provider = providerName === 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);
  const signer = provider.getSigner();
  const SurveyContract = new ethers.Contract(SURVEYS_ADDRESS, SURVEYS, signer);

  let questionArweaveHashes = [];

  if (ARWEAVE_ACTIVE) {
    for (let questionData of questionDataArray) {
      const questionDataString = JSON.stringify(questionData);
      const questionArweaveHash = await this.uploadDataToArweave(questionDataString, 'json');
      questionArweaveHashes.push(questionArweaveHash);
    }
  } else {
    console.log("Arweave is not active. Question data will not be uploaded.");
    return;
  }

  const questionArweaveHashBytesArray = questionArweaveHashes.map(hash => this.base64urlToHex(hash));

  // Now call the addQuestions function in the smart contract
  const tx = await SurveyContract.addQuestions(
    questionIds,
    questionArweaveHashBytesArray,
    surveyIds
  );

  const receipt = await tx.wait();
  console.log("Transaction Receipt:", receipt);

  // Prepare mapping of questionIds to arweaveHashes
  const uploadedQuestions = questionIds.map((id, index) => {
    return { questionId: id, arweaveTxId: questionArweaveHashes[index] };
  });

  return { receipt, uploadedQuestions };
},

submitResponses: async function(providerName, questionIds, questionResponses, surveyId, surveyResponse) {
  const providerLocation = this.getProviderLocation(providerName);
  const provider = providerName == 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);
  const signer = provider.getSigner();
  const SurveyContract = new ethers.Contract(SURVEYS_ADDRESS, SURVEYS, signer);

  let questionResponseHashes = [], surveyResponseHashBytes = ethers.constants.HashZero;

  if (ARWEAVE_ACTIVE) {
    for (let response of questionResponses) {
      const responseString = JSON.stringify(response);
      const responseHash = await this.uploadDataToArweave(responseString, 'json');
      questionResponseHashes.push(responseHash);
    }
    if (surveyResponse) {
      const surveyResponseString = JSON.stringify(surveyResponse);
      const surveyResponseHash = await this.uploadDataToArweave(surveyResponseString, 'json');
      surveyResponseHashBytes = this.base64urlToHex(surveyResponseHash);
    }
  } else {
    console.log("Arweave is not active. Response data will not be uploaded.");
    return;
  }

  const questionResponseHashesBytes = questionResponseHashes.map(hash => this.base64urlToHex(hash));

  const tx = await SurveyContract.submitResponses(questionIds, questionResponseHashesBytes, surveyId, surveyResponseHashBytes);
  const receipt = await tx.wait();

  return receipt;
},

getResponse: async function(providerName, userAddress, id) {
  const providerLocation = this.getProviderLocation(providerName);
  const provider = providerName === 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);
  const SurveyContract = new ethers.Contract(SURVEYS_ADDRESS, SURVEYS, provider);

  // Fetch the response hash (could be survey or question)
  const arweaveHash = await SurveyContract.getResponse(userAddress, id);

  if (!arweaveHash || arweaveHash === ethers.constants.HashZero) {
    console.log("No response found for this ID and user.");
    return null; // Return null to indicate no response
  }

  if (!ARWEAVE_ACTIVE) {
    console.log("Arweave is not active. Unable to fetch response data.");
    return null;
  }

  const arweaveHashBase64 = this.hexToBase64url(arweaveHash);

  try {
    const arweaveData = await this.downloadDataFromArweave(arweaveHashBase64);
    return JSON.parse(arweaveData);
  } catch (error) {
    console.error("Error fetching or parsing response:", error);
    return null;
  }
},

getQuestionHash: async function(providerName, questionId) {
  const providerLocation = this.getProviderLocation(providerName);
  const provider = providerName == 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);
  const SurveyContract = new ethers.Contract(SURVEYS_ADDRESS, SURVEYS, provider);

  try {
    const arweaveHashBytes = await SurveyContract.getQuestionHash(questionId);
    if (!arweaveHashBytes || arweaveHashBytes === ethers.constants.HashZero) {
      console.warn(`No Arweave hash found for question ID: ${questionId}`);
      return null;
    }
    return this.hexToBase64url(arweaveHashBytes);
  } catch (error) {
    console.error("Error getting question hash:", error);
    return null;
  }
},

getSurveyHash: async function(providerName, surveyId) {
  if (!surveyId || surveyId === ethers.constants.HashZero) {
    console.warn("Survey ID is null or HashZero, returning null");
    return null;
  }

  const providerLocation = this.getProviderLocation(providerName);
  const provider = providerName == 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);
  const SurveyContract = new ethers.Contract(SURVEYS_ADDRESS, SURVEYS, provider);

  try {
    const arweaveHashBytes = await SurveyContract.getSurveyHash(surveyId);
    return this.hexToBase64url(arweaveHashBytes);
  } catch (error) {
    console.error("Error getting survey hash:", error);
    return null;
  }
},

getQuestionSurvey: async function(providerName, questionId) {
  const providerLocation = this.getProviderLocation(providerName);
  const provider = providerName == 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);
  const SurveyContract = new ethers.Contract(SURVEYS_ADDRESS, SURVEYS, provider);

  try {
    const surveyId = await SurveyContract.getQuestionSurvey(questionId);
    return surveyId;
  } catch (error) {
    console.error("Error getting question's associated survey:", error);
    return null;
  }
},

// Helper functions

getQuestionData: async function(providerName, questionId) {
  const arweaveHash = await this.getQuestionHash(providerName, questionId);
  if (!arweaveHash) {
    console.warn(`Arweave hash is null or undefined for question ID: ${questionId}`);
    return null;
  }

  if (!ARWEAVE_ACTIVE) {
    console.log("Arweave is not active. Unable to fetch question data.");
    return null;
  }

  try {
    const questionData = await this.downloadDataFromArweave(arweaveHash);
    if (!questionData) {
      console.error(`No data found on Arweave for hash: ${arweaveHash}`);
      return null;
    }
    return JSON.parse(questionData);
  } catch (error) {
    console.error("Error fetching question data from Arweave:", error);
    return null;
  }
},


getSurveyData: async function(providerName, surveyId) {
  const arweaveHash = await this.getSurveyHash(providerName, surveyId);
  if (!arweaveHash) return null;

  if (!ARWEAVE_ACTIVE) {
    console.log("Arweave is not active. Unable to fetch survey data.");
    return null;
  }

  try {
    const surveyData = await this.downloadDataFromArweave(arweaveHash);
    return JSON.parse(surveyData);
  } catch (error) {
    console.error("Error fetching survey data from Arweave:", error);
    return null;
  }
},


//-----------------------------------------------------**** SBTFactory.sol ****--------------------------------------------------------//

// Create SBT
createSBT: async function(providerName, name, symbol, limitedNumber, adminAddress, mintingEndTime, hasPasswordMint, burnAuth, hashedPasswords, tokenURI) {
  const providerLocation = this.getProviderLocation(providerName);
  const provider = providerName == 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);
  const signer = provider.getSigner();
  const SBTFactory = new ethers.Contract(SBT_FACTORY_ADDRESS, SBT_FACTORY_ABI, signer);

  const tx = await SBTFactory.createSBT(name, symbol, limitedNumber, adminAddress, mintingEndTime, hasPasswordMint, burnAuth, hashedPasswords, tokenURI);
  const receipt = await tx.wait();

  return receipt;
},

// Count SBTs Created
// Used in CreateSBTGroup.jsx to get the number of SBTs created 
// (not subject to START_BLOCKNUMBER_FILTER) for accuracy
 countSBTCreated: async function(providerName) {
  try {
    // console.log('Starting countSBTCreated function');
    // console.log('Using providerName:', providerName);

    const providerLocation = this.getProviderLocation(providerName);
    // console.log('Provider location:', providerLocation);

    // const provider = providerName == 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);
    const provider = window.defaultProvider; // This is faster, need to standardize
    // console.log('Provider initialized:', provider);

    const SBTFactory = new ethers.Contract(SBT_FACTORY_ADDRESS, SBT_FACTORY_ABI, provider);
    // console.log('Connected to SBTFactory contract:', SBTFactory);

    // Directly call the sbtCount function
    const sbtCount = await SBTFactory.sbtCount();
    // console.log('sbtCount:', sbtCount.toNumber());

    return sbtCount.toNumber();
  } catch (error) {
    console.error('Error in countSBTCreated function:', error);
    throw error;
  }
},

// Get a list of SBTs which have been created within a specified block range
getSbtsCreated: async function(providerName, fromCustomBlock = 0, toCustomBlock = 'latest') {
  const providerLocation = this.getProviderLocation(providerName);
  const provider = window.defaultProvider;
  const SBTFactory = new ethers.Contract(SBT_FACTORY_ADDRESS, SBT_FACTORY_ABI, provider);

  const sbtCreatedEventFilter = SBTFactory.filters.SBTCreated();

  let sbtCreatedEvents;
  if (START_BLOCKNUMBER_FILTER) {
    const startBlock = await this.getRelevantBlocknumberForFilter(provider);
    const effectiveFromBlock = Math.max(startBlock, fromCustomBlock);
    
    try {
      sbtCreatedEvents = await SBTFactory.queryFilter(sbtCreatedEventFilter, effectiveFromBlock, toCustomBlock);
    } catch (error) {
      // Fallback to chunked retrieval if querying within range fails
      const result = await this.getPastEventsInChunks({
        provider,
        contract: SBTFactory,
        eventFilter: sbtCreatedEventFilter,
        fromBlock: effectiveFromBlock,
        toBlock: toCustomBlock
      });
      sbtCreatedEvents = result.events;
    }
  } else {
    try {
      sbtCreatedEvents = await SBTFactory.queryFilter(sbtCreatedEventFilter, fromCustomBlock, toCustomBlock);
    } catch (error) {
      // Fallback to chunked retrieval if querying within range fails
      const result = await this.getPastEventsInChunks({
        provider,
        contract: SBTFactory,
        eventFilter: sbtCreatedEventFilter,
        fromBlock: fromCustomBlock,
        toBlock: toCustomBlock
      });
      sbtCreatedEvents = result.events;
    }
  }

  console.log("getSBTsCreated – sbtCreatedEvents:");
  console.log(sbtCreatedEvents);

  const sbts = sbtCreatedEvents.map(event => ({
    sbtAddress: event.args.sbtAddress,
    tokenURI: event.args.tokenURI
  }));

  console.log("getSBTsCreated – sbts:");
  console.log(sbts);

  return sbts;
},

getSBTsByUserAddress: async function(providerName, userAddress, fromBlock = null) {
  const providerLocation = this.getProviderLocation(providerName);
  const provider = providerName == 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);

  if (fromBlock === null && this.START_BLOCKNUMBER_FILTER) {
    fromBlock = await this.getRelevantBlocknumberForFilter(provider);
  }

  // Get the list of all SBTs created
  const sbts = await this.getSbtsCreated(providerName, fromBlock);

  // Initialize an array to hold the SBTs claimed by the user
  let claimedSBTs = [];

  // Loop through each SBT and check if the user has claimed it
  for (let sbt of sbts) {
    const userHasClaimed = await this.userHasSBT(providerName, sbt.sbtAddress, userAddress, fromBlock);
    if (userHasClaimed) {
      // Get addresses who have minted and burned the SBT
      const addressesWhoMinted = await this.getAddressesWhoMintedSBT(providerName, sbt.sbtAddress, fromBlock);
      const addressesWhoBurned = await this.getAddressesWhoBurnedSBT(providerName, sbt.sbtAddress, fromBlock);

      // Check if the user address is in the list of those who minted and not in the list of those who burned
      if (addressesWhoMinted.includes(userAddress) && !addressesWhoBurned.includes(userAddress)) {
        claimedSBTs.push(sbt);
      }
    }
  }

  // Return the SBTs claimed by the user
  console.log("claimedSBTs:");
  console.log(claimedSBTs);
  return claimedSBTs;
},

//-----------------------------------------------------**** CustomSBT.sol ****--------------------------------------------------------//

// Get SBT Metadata
getSbtMetadata: async function(providerName, SBTAddress) {
  const providerLocation = this.getProviderLocation(providerName);
  const provider = providerName == 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);
  const CustomSBT = new ethers.Contract(SBTAddress, CUSTOM_SBT_ABI, provider);

  const metadata = await CustomSBT.getSBTMetadata();

  console.log("metadata")
  console.log(metadata)

  const maxTokens = metadata.maxTokens_.toString();
  const mintedTokens = metadata.mintedTokens_.toString();
  const admin = metadata.admin_;
  const mintingEndTime = metadata.mintingEndTime_.toNumber();
  const hasPasswordMint = metadata.hasPasswordMint_;
  const burnAuth = metadata.burnAuth_;
  const tokenURI = metadata.tokenURI_;

  let metadataJson;
  try {
    const response = await fetch(tokenURI);
    metadataJson = await response.json();
  } catch (error) {
    console.error("Failed to fetch token metadata:", error);
    throw new Error("Failed to fetch token metadata.");
  }

  const name = metadataJson.name;
  const description = metadataJson.description;
  const image = metadataJson.image;

  return {
    name,
    description,
    image,
    maxTokens,
    mintedTokens,
    admin,
    mintingEndTime,
    hasPasswordMint,
    burnAuth,
    tokenURI
  };
},

// Start Claim (only relevant if minting with password)
startClaim: async function(providerName, SBTAddress) {
  const providerLocation = this.getProviderLocation(providerName);
  const provider = providerName == 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);
  const signer = provider.getSigner();
  const CustomSBT = new ethers.Contract(SBTAddress, CUSTOM_SBT_ABI, signer);

  const tx = await CustomSBT.startClaim();
  const receipt = await tx.wait();

  return receipt;
},

// Mint SBT with Password
claimWithPassword: async function(providerName, SBTAddress, password) {
  const providerLocation = this.getProviderLocation(providerName);
  const provider = providerName == 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);
  const signer = provider.getSigner();
  const CustomSBT = new ethers.Contract(SBTAddress, CUSTOM_SBT_ABI, signer);

  const tx = await CustomSBT.claimWithPassword(password);
  const receipt = await tx.wait();

  return receipt;
},

// Mint SBT without Password
claim: async function(providerName, SBTAddress) {
  const providerLocation = this.getProviderLocation(providerName);
  const provider = providerName == 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);
  const signer = provider.getSigner();
  const CustomSBT = new ethers.Contract(SBTAddress, CUSTOM_SBT_ABI, signer);

  const tx = await CustomSBT.claim();
  const receipt = await tx.wait();

  return receipt;
},

// Delegate Votes
delegateVotes: async function(providerName, SBTAddress, delegatee) {
  const providerLocation = this.getProviderLocation(providerName);
  const provider = providerName == 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);
  const signer = provider.getSigner();
  const CustomSBT = new ethers.Contract(SBTAddress, CUSTOM_SBT_ABI, signer);

  const tx = await CustomSBT.delegate(delegatee);
  const receipt = await tx.wait();

  return receipt;
},

// Add Hashed Passwords
addHashedPasswords: async function(providerName, SBTAddress, hashedPasswords) {
  const providerLocation = this.getProviderLocation(providerName);
  const provider = providerName == 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);
  const signer = provider.getSigner();
  const CustomSBT = new ethers.Contract(SBTAddress, CUSTOM_SBT_ABI, signer);

  const tx = await CustomSBT.addHashedPasswords(hashedPasswords);
  const receipt = await tx.wait();

  return receipt;
},

// Burn Token
burnToken: async function(providerName, SBTAddress, tokenId) {
  const providerLocation = this.getProviderLocation(providerName);
  const provider = providerName == 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);
  const signer = provider.getSigner();
  const CustomSBT = new ethers.Contract(SBTAddress, CUSTOM_SBT_ABI, signer);

  const tx = await CustomSBT.burn(tokenId);
  const receipt = await tx.wait();

  return receipt;
},


// Check if User Has SBT
userHasSBT: async function(providerName, SBTAddress, userAddress) {
  const providerLocation = this.getProviderLocation(providerName);
  const provider = providerName == 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);
  const CustomSBT = new ethers.Contract(SBTAddress, CUSTOM_SBT_ABI, provider);

  const transferEventFilter = CustomSBT.filters.Transfer(null, userAddress);
  const mintEventFilter = CustomSBT.filters.Issued(userAddress, null);
  const transferEvents = await CustomSBT.queryFilter(transferEventFilter);
  const mintEvents = await CustomSBT.queryFilter(mintEventFilter);

  return transferEvents.length > 0 || mintEvents.length > 0;
},

// Check if User Can Burn SBTs
userCanBurnSBTs: async function(providerName, SBTAddress, userAddress) {
  const providerLocation = this.getProviderLocation(providerName);
  const provider = providerName == 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);
  const CustomSBT = new ethers.Contract(SBTAddress, CUSTOM_SBT_ABI, provider);

  const admin = await CustomSBT.admin();
  const burnAuth = await CustomSBT.burnAuth();
  const hasSBT = await this.userHasSBT(providerName, SBTAddress, userAddress);

  return admin === userAddress || hasSBT || burnAuth === 1 || burnAuth === 2; // IssuerOnly, Both
},

// Get addresses who have minted a given SBT within a specified block range
getAddressesWhoMintedSBT: async function(providerName, SBTAddress, fromBlock = 0, toBlock = "latest") {
  const providerLocation = this.getProviderLocation(providerName);
  const provider = providerName === 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);
  const CustomSBT = new ethers.Contract(SBTAddress, CUSTOM_SBT_ABI, provider);

  const issuedEventFilter = CustomSBT.filters.Issued();

  let issuedEvents;
  if (START_BLOCKNUMBER_FILTER) {
    const startBlock = fromBlock > 0 ? fromBlock : await this.getRelevantBlocknumberForFilter(provider);
    try {
      issuedEvents = await CustomSBT.queryFilter(issuedEventFilter, startBlock, toBlock);
    } catch (error) {
      // Fallback to chunked retrieval if querying within range fails
      const result = await this.getPastEventsInChunks({
        provider,
        contract: CustomSBT,
        eventFilter: issuedEventFilter,
        fromBlock: startBlock,
        toBlock: toBlock
      });
      issuedEvents = result.events;
    }
  } else {
    // Fetch all events if no start block is specified
    try {
      issuedEvents = await CustomSBT.queryFilter(issuedEventFilter, fromBlock, toBlock);
    } catch (error) {
      // Fallback to chunked retrieval if querying within range fails
      const result = await this.getPastEventsInChunks({
        provider,
        contract: CustomSBT,
        eventFilter: issuedEventFilter,
        fromBlock: fromBlock,
        toBlock: toBlock
      });
      issuedEvents = result.events;
    }
  }

  const addresses = issuedEvents.map(event => event.args.to);
  return addresses;
},

// Get addresses who have burned a given SBT within a specified block range
getAddressesWhoBurnedSBT: async function(providerName, SBTAddress, fromBlock = 0, toBlock = "latest") {
  const providerLocation = this.getProviderLocation(providerName);
  const provider = providerName === 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);
  const CustomSBT = new ethers.Contract(SBTAddress, CUSTOM_SBT_ABI, provider);

  const burnEventFilter = CustomSBT.filters.Transfer(null, ethers.constants.AddressZero);

  let burnEvents;
  if (START_BLOCKNUMBER_FILTER) {
    const startBlock = fromBlock > 0 ? fromBlock : await this.getRelevantBlocknumberForFilter(provider);
    try {
      burnEvents = await CustomSBT.queryFilter(burnEventFilter, startBlock, toBlock);
    } catch (error) {
      // Fallback to chunked retrieval if querying within range fails
      const result = await this.getPastEventsInChunks({
        provider,
        contract: CustomSBT,
        eventFilter: burnEventFilter,
        fromBlock: startBlock,
        toBlock: toBlock
      });
      burnEvents = result.events;
    }
  } else {
    // Fetch all events if no start block is specified
    try {
      burnEvents = await CustomSBT.queryFilter(burnEventFilter, fromBlock, toBlock);
    } catch (error) {
      // Fallback to chunked retrieval if querying within range fails
      const result = await this.getPastEventsInChunks({
        provider,
        contract: CustomSBT,
        eventFilter: burnEventFilter,
        fromBlock: fromBlock,
        toBlock: toBlock
      });
      burnEvents = result.events;
    }
  }

  const addresses = burnEvents.map(event => event.args.from);
  return addresses;
},

// Get token ID for a specific owner's SBT
getSBTTokenIdByOwner: async function(providerName, SBTAddress, ownerAddress) {
  const providerLocation = this.getProviderLocation(providerName);
  const provider = providerName == 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);
  const CustomSBT = new ethers.Contract(SBTAddress, CUSTOM_SBT_ABI, provider);
  
  try {
    const tokenId = await CustomSBT.getTokenIdByOwner(ownerAddress);
    return tokenId.toString();
  } catch (error) {
    console.error("Error getting token ID for owner:", error);
    return null;
  }
},

// Add to contractScripts.js - Get owner address for a token ID
getOwnerByTokenId: async function(providerName, SBTAddress, tokenId) {
  const providerLocation = this.getProviderLocation(providerName);
  const provider = providerName == 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);
  const CustomSBT = new ethers.Contract(SBTAddress, CUSTOM_SBT_ABI, provider);

  try {
    const ownerAddress = await CustomSBT.ownerOf(tokenId);
    return ownerAddress;
  } catch (error) {
    console.error("Error getting owner for token ID:", error);
    return null;
  }
},


//-----------------------------------------------------**** Accounts.sol ****--------------------------------------------------------//

// function to get username for a given address
// TODO: placeholder value
getUsernameByAddress: async function(providerName, userAddress) {
  const providerLocation = this.getProviderLocation(providerName);
  const provider = providerName == 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);
  const Accounts = new ethers.Contract(ACCOUNTS_ADDRESS, ACCOUNTS, provider);

  // const username = await Accounts.getUsernameByAddress(userAddress);
  // return username;

  return "testUsername";
},

setUsernameForAddress: async function(providerName, username) {
  // placeholder, TODO
},

addSimulatedUser: async function(providerName, userAddress) {
  // placeholder, TODO
},

userIsSimulated: async function(providerName, userAddress) {
  // placeholder for now, return false
  return false;
},

//--------------------------------------------------**** ARWEAVE UP / DOWNLOAD ****--------------------------------------------------//

  // get Arweave instance if one exists, otherwise create one
  getArweaveInstance: function() {
    if (window.arweave == undefined) {
      const arweave = Arweave.init({
        host: 'arweave.net',
        port: 443,
        protocol: 'https'
      });

      window.arweave = arweave;
      return arweave;
    }
    else { return window.arweave }
  },

  // uploads data to Arweave (with optional data format)
  // returns transaction ID
  uploadDataToArweave: async function(data, format) {
    if (!data) {
      throw new Error("No data provided for Arweave upload.");
    }
    // console.log("uploadDataToArweave() invoked")
    console.log("data: ");
    console.log(data);
    console.log("format: ", format);

    const arweave = this.getArweaveInstance();
    const key = ARWEAVE_KEY;
    // let key = await arweave.wallets.generate();
    // console.log("key");
    // console.log(key);

    const address = await arweave.wallets.jwkToAddress(key);
    // console.log("address");
    console.log("Arweave address: ", address);

    // Test Data
    // const testDataRaw = {
    //   title: "Test Data",
    // };
    // const testData = JSON.stringify(testDataRaw);

    let contentType;
    if (format === undefined) {
      // Default to application/json if no format is specified
      contentType = 'application/json';
    } else {
      // Set the content type based on the provided format
      switch (format.toLowerCase()) {
        case 'json':
          contentType = 'application/json';
          break;
        case 'png':
          contentType = 'image/png';
          break;
        case 'jpg':
        case 'jpeg':
          contentType = 'image/jpeg';
          break;
        case 'gif':
          contentType = 'image/gif';
          break;
        case 'mp4':
          contentType = 'video/mp4';
          break;
        default:
          throw new Error(`Unsupported format: ${format}`);
      }
    }

    console.log("contentType: ", contentType);

    let transactionData;
    if (data instanceof File) {
      // Read the File object as ArrayBuffer
      transactionData = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(new Uint8Array(reader.result));
        reader.onerror = reject;
        reader.readAsArrayBuffer(data);
      });
    } else if (contentType === 'application/json') {
      // Stringify JSON data
      // transactionData = JSON.stringify(data);
      // transactionData = JSON.parse(data);
      transactionData = data;

    } else {
      // Use the data as-is for other formats
      transactionData = data;
    }

    console.log("transactionData: ", transactionData);
    console.log("typeof transactionData: ", typeof transactionData);

    // Create the transaction
    const transaction = await arweave.createTransaction({
      data: transactionData
    }, key);

    // Set the content type tag
    transaction.addTag('Content-Type', contentType);

    // Sign the transaction
    await arweave.transactions.sign(transaction, key);

    // console.log("Arweave transaction before signing: ");
    console.log("Arweave transaction before signing: ", transaction);

    // console.log("Arweave transaction after signing: ");
    console.log("Arweave transaction after signing: ", transaction);

    // Post the transaction
    const response = await arweave.transactions.post(transaction);

    // console.log("Arweave transaction response: ");
    console.log("Arweave transaction response: ", response);

    console.log("Arweave transaction: ");
    console.log(transaction);

    return transaction.id;
  },

  // download a file from Arweave
  // https://github.com/ArweaveTeam/arweave-js#get-transaction-data
  downloadDataFromArweave: async function(txID) {
    // console.log("downloadFileFromArweave() invoked")
    // console.log("txID: ")
    // console.log(txID);

    const arweave = this.getArweaveInstance();

    const data = await arweave.transactions.getData(txID, {decode: true, string: true});
    // console.log("data: ")
    // console.log(data)

    return data;
  },

  // convert base64url to hex (for Arweave transaction):
  // see functions: hexToBase64url() and base64urlToHex() below

//--------------------------------------------------**** GENERAL WEB3 / HELPER ****--------------------------------------------------//

  // when using different web3 providers (Wagmi / Metamask / Torus / etc), some store 
  // their provider in different locations. This will allow easier addition of other 
  // web3 providers
  getProviderLocation: function(provider) {
    switch(provider) {
      case "Torus":
        return window.torus.ethereum;
        // return window.torusProvider; (this can be set manually in TorusLoginButton.jsx if needed)

      case "wagmi":
        return window.ethereum;

      case "none":
        return window.ethereum;

      // default case (works when provider is undefined / if metamask not installed)
      default:
        return window.defaultProvider;
    }
  },

  getRelevantBlocknumberForFilter: async function (provider) {
    const network = await provider.getNetwork();
    // console.log("getRelevantBlocknumberForFilter – network: ");
    // console.log(network);
    const chainId = network.chainId.toString();
    // console.log("chainId: " + chainId);
    
    switch (chainId) {
      case '8453':
        return this.getBigNumber(FIRST_BLOCKNUMBER_BASE.toString())._hex;
      case '84532':
        return this.getBigNumber(FIRST_BLOCKNUMBER_BASE_SEPOLIA.toString())._hex;
      default:
        return this.getBigNumber(FIRST_BLOCKNUMBER_BASE_SEPOLIA.toString())._hex;
    }
  },

  getPastEventsInChunks: async function({
    provider,
    contract,
    eventFilter,
    fromBlock,
    chunkSize = 1024
  }) {
    try {
      const toBlockNumber = await provider.getBlockNumber();
      const fromBlockNumber = +fromBlock;
      const totalBlocks = toBlockNumber - fromBlockNumber;
      const chunks = [];
  
      for (let start = fromBlockNumber; start <= toBlockNumber; start += chunkSize) {
        const end = Math.min(start + chunkSize - 1, toBlockNumber);
        chunks.push({ fromBlock: start, toBlock: end });
      }
  
      const events = [];
      const errors = [];
      for (const chunk of chunks) {
        try {
          const chunkEvents = await contract.queryFilter(eventFilter, chunk.fromBlock, chunk.toBlock);
          events.push(...chunkEvents);
        } catch (error) {
          errors.push(error);
        }
      }
  
      return { events, errors, lastBlock: toBlockNumber };
    } catch (error) {
      return { events: [], errors: [error], lastBlock: null };
    }
  },
  
  getLatestBlockNumber: async function(providerName) {
    // console.log("getLatestBlockNumber() invoked")
    // console.log("getLatestBlockNumber() providerName: " + providerName)
    const providerLocation = this.getProviderLocation(providerName);
    // connect to provider
    const provider = providerName == 'none' ? window.defaultProvider : new ethers.providers.Web3Provider(providerLocation);
    const latestBlockNumber = await provider.getBlockNumber();

    console.log("latestBlockNumber: " + latestBlockNumber)
    return latestBlockNumber;
  },

  sendTestnetFunds: async function(recipientAddress) {
    // Use the correct network's RPC URL
    const provider = new ethers.providers.JsonRpcProvider(CORRECT_NETWORK.rpcUrls[0]);
  
    // Use the faucet's private key to create a wallet connected to this provider
    const privKey = FAUCET_PRIVKEY;
    const wallet = new ethers.Wallet(privKey, provider);
  
    // Check recipient balance
    const recipientBalance = await provider.getBalance(recipientAddress);
    const minimumBalance = ethers.utils.parseEther(TESTNET_AMOUNT);
  
    if (recipientBalance.lt(minimumBalance)) {
      const tx = {
        to: recipientAddress,
        value: ethers.utils.parseEther(TESTNET_AMOUNT),
      };
  
      try {
        const transaction = await wallet.sendTransaction(tx);
        const receipt = await transaction.wait();
        return receipt;
      } catch (error) {
        throw new Error(`Failed to send transaction: ${error.message}`);
      }
    } else {
      throw new Error('Recipient balance above min threshold.');
    }
  },
  

  // Output: ethers.js BN object (denominated in wei)
  // TODO: factor in network switching by reading chain.id from provider
  getGasPrice: async function() {
    // connect to provider
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const gasPriceBN = await provider.getGasPrice();
    return gasPriceBN;
  },

  // Output: String (for display on front-end)
  getGasPriceToDisplay: async function() {
    const gasPriceBN = await this.getGasPrice()
    const gasPrice = utils.formatUnits(gasPriceBN, "gwei")
    return gasPrice;
  },

  // converts plaintext lobby name (like "cryptotwitter") into bytes32 string (as lobbies are referenced in smart contract)
  // NOTE: main lobby is currently called "main"
  getLobbyIDfromString: function(lobbyNameString) {
    if (lobbyNameString == undefined  || lobbyNameString == "") {
      const hashedLobbyName = ethers.utils.id("0x")
      // console.log("ethers.utils.id('0x') :" + hashedLobbyName);
      return hashedLobbyName;
      // return "0xc5d2460186f7233c927e7db2dcc703c0e500b653ca82273b7bfad8045d85a470"
    }

    const hashedLobbyName = ethers.utils.id(lobbyNameString);
    // console.log("ethers.js hashedLobbyName:")
    // console.log(hashedLobbyName)

    return hashedLobbyName;
  },

  // wei (18 decimals) --> ETH (similar for XP)
  // NOTE: needs a BN passed in?
  // Input: coinAmount must be ethers.js BN / String / Number
  // Output: String
  decimalEighteen: function(coinAmount, callingFunc) {
    // console.log("decimalEighteen() - invoked – callingFunc: " + callingFunc)
    // console.log("coinAmount:")
    // console.log(coinAmount)

    const coinAmountIsBN = ethers.BigNumber.isBigNumber(coinAmount)
    // console.log("coinAmountIsBN: " + coinAmountIsBN)

    // if number is BN, convert on the fly
    if (coinAmountIsBN) {
      return ethers.utils.formatEther(coinAmount)
    }

    else if (typeof coinAmount === 'string' || coinAmount instanceof String) {
      return ethers.utils.formatEther(coinAmount)
    }

    else if (typeof coinAmount === 'number' || coinAmount instanceof Number) {
      return ethers.utils.formatEther(coinAmount)
    }

    else  {
      console.log("WRONG TYPE OF BIGNUMBER Passed to decimalEighteen()")
    }
  },

  // Convert from ETH --> wei (18 decimals)
  // NOTE: Both XP and ETH use 18 decimals
  // Input: String
  // Output: ethers.js BN Object
  toEighteenDecimals: function(coinAmount) {
    // console.log("toEighteenDecimals() – invoked")
    // console.log(coinAmount);
    // console.log(ethers.utils.parseEther(coinAmount))
    console.log("ethers.utils.parseEther(coinAmount): ")
    console.log(ethers.utils.parseEther(coinAmount))

    return ethers.utils.parseEther(coinAmount);
  },

  // Input: String Number
  // Output: ethers.js BN Object
  getBigNumber: function(numString, callingFunc) {
    // console.log("getBigNumber() - invoked – input numString: " + numString + "– callingFunc: " + callingFunc)

    if (numString !== undefined) {
      // console.log("ethers.BigNumber.from(numString): ")
      // console.log(ethers.BigNumber.from(numString))
      return ethers.BigNumber.from(numString)
    }

    else { console.log("CHECK – getBigNumber() – undefined numString passed")}
  },

  // Input to this number must be type: ethers.js BN
  // Output is javascript Number
  getJsNumberFromBN: function(BNObject, callingFunc) {
    // console.log("getJsNumberFromBN() – invoked – callingFunc:" + callingFunc)
    // console.log("BNObject: ")
    // console.log(BNObject)

    const objectIsBN = this.objectIsBN(BNObject)
    // console.log("objectIsBN: " + objectIsBN)

    if (objectIsBN) { return BNObject.toNumber() }
    else { console.log("BAD BN VALUE Passed to getJsNumberFromBN()")}
    
    // return ethers.BigNumber(BNObject).toNumber()
  },

  // TODO: call this from other functions rather than having duplicate calls to ethers.BigNumer.isBigNumber()
  objectIsBN: function(object) {
    // console.log("object is ethers.js BN instance: " + ethers.BigNumber.isBigNumber(object))
    return ethers.BigNumber.isBigNumber(object)
  },

  hexToBase64url: function(hexString) {
    // Convert the hex string to a byte array using ethers' utilities
    let byteArray = ethers.utils.arrayify(hexString);
    
    // Encode the byte array to a base64 string
    let b64string = Buffer.from(byteArray).toString('base64');
    
    // Convert the base64 string to base64URL format
    let b64urlstring = b64string.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    
    return b64urlstring;
  },

  base64urlToHex: function(b64urlstring) {
    // Decode the base64URL string to a byte array
    let byteArray = this.base64DecodeURL(b64urlstring);
    
    // Convert the byte array to a hex string using ethers' utilities
    let hexString = ethers.utils.hexlify(byteArray);
    
    return hexString;
  },

  base64DecodeURL: function(b64urlstring) {
    // Convert the base64URL string to a standard base64 string by replacing characters
    let b64string = b64urlstring.replace(/-/g, '+').replace(/_/g, '/');
    
    // Decode the base64 string to a byte array
    let byteArray = Buffer.from(b64string, 'base64');
    
    return byteArray;
  },

  base64urlToBase64: function(b64urlstring) {
    // Convert the base64URL string to a standard base64 string by replacing characters
    let b64string = b64urlstring.replace(/-/g, '+').replace(/_/g, '/');
    return b64string;
  },

  // hexZeroPad: function(hexString, byteLength) {
  //   const hexLength = byteLength * 2;
  //   const hex = ethers.BigNumber.from(hexString).toHexString().slice(2); // Remove the '0x' prefix
  //   const paddedHex = '0x' + hex.padStart(hexLength, '0');
  //   return paddedHex;
  // },

  // cause a timeout in a script (for testing Metamask Login Flow)
  timeout: async function(delay) {
    console.log("TIMEOUT of " + delay + " MS")
    return new Promise( res => setTimeout(res, delay) );
  },

//--------------------------------------------------**** ENCRYPTION / DECRYPTION ****--------------------------------------------------//

  getPublicKey: async function(providerName, address) {
    console.log("providerName: " + providerName)
    const providerLocation = this.getProviderLocation(providerName);
    const provider = new ethers.providers.Web3Provider(providerLocation);

    try {
      const pubkey = await provider.request({
        "method": "eth_getEncryptionPublicKey",
        "params": [address]
      });
      return pubkey;
    } catch (error) {
      console.error("Error - Getting Public Key:", error);
      throw error;
    }
  },

  encryptDataForPublicKey: async function(publicKey, data) {
    const encrypted = await encrypt({
      publicKey: publicKey,
      data: data,
      version: 'x25519-xsalsa20-poly1305'
    });

    const encryptedFormatted = `0x${Buffer.from(JSON.stringify(encrypted), 'utf8').toString('hex')}`;
    return encryptedFormatted;
  },

  decryptData: async function(providerName, encryptedData, account) {
    const providerLocation = this.getProviderLocation(providerName);
    const provider = new ethers.providers.Web3Provider(providerLocation);

    const hexCypher = encryptedData;

    try {
      const unencryptedData = await provider.request({
        "method": "eth_decrypt",
        "params": [
          hexCypher,
          account, // address (whose public key data is encrypted for)
        ]
      });

      return unencryptedData;
    } catch (error) {
      console.error("Error - Data Decryption:", error);
      throw error;
    }
  },
};

export default contractScripts;


// TODO: reference window.provider instead of instantiating new ethers providers over and over (if ethers.js continues to work with Torus?)
// TODO: put user's PubKey in global redux state


// getPublicKeyFromSignature = async () => {
//   const message = "Please sign this message to confirm your identity.";
//   const messageHash = ethers.utils.arrayify(ethers.utils.id(message));

//   var provider;

//   if (this.props.provider == "Torus") {
//     provider = window.torusProvider
//   }

//   else {
//     provider = new ethers.providers.Web3Provider(window.ethereum);
//   }

//   // NOTE: might need to change below for Metamask
//   const sig = await provider.send("personal_sign", [message, this.props.account]);
//   const signature = sig.result
//   // console.log("sig:");
//   // console.log(sig);
//   // console.log("signature:");
//   // console.log(signature);

//   // Convert signature to its R, S, V components
//   const r = toBuffer(signature.slice(0, 66));
//   const s = toBuffer('0x' + signature.slice(66, 130));
//   const v = parseInt(signature.slice(130, 132), 16);

//   // Using ecrecover from ethereumjs-util to get the public key
//   const publicKeyBuffer = ecrecover(messageHash, v, r, s);
//   // const recoveredPublicKey = '0x' + publicKeyBuffer.toString('hex');
//   // const recoveredPublicKey = publicKeyBuffer.toString('hex');

//   // const base64EncodedPublicKey = publicKeyBuffer.toString('base64');
//   // console.log("base64EncodedPublicKey: ");
//   // console.log(base64EncodedPublicKey);

//   //const utf8pubkey = publicKeyBuffer.toString('utf8');
//   // console.log("utf8pubkey: ");
//   // console.log(utf8pubkey);

//   return publicKeyBuffer;
// };