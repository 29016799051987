import React, { Component } from 'react';

// reactstrap components
import { Button, Card, CardHeader, CardBody, CardFooter, Form, Modal} from "reactstrap";

// CSS & Icons & Images
import '../../assets/css/m_w.css'
import styles from "../LoginAndWeb3/Login.module.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' // TODO: use library 
import { faTwitch, faGoogle, faRedditAlien, faDiscord, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faWindowClose, faCoins } from '@fortawesome/free-solid-svg-icons'
import MetaMaskLogo from "assets/img/metamask_icon_white.png";


class LoginButton extends Component {

    openLoginModal = () => {
        // console.log("LoginButton.jsx – LOGIN MODAL OPENED")
        this.props.launchWeb3Modal()
        // this.setState({modalOpen: true})
    
        // const open = true;
        // this.props.toggleLoginModal(open);

            // TODO: mixpanel
            // window.mixpanel.track("LOGIN_BUTTON_CLICKED");
            // window.mixpanel.time_event('LOGIN_STARTED');
      };

    render() {
        return (
        <div id={styles.navConnectContainer}>
          <Button color="none" onClick={this.openLoginModal} id={styles.navConnectButton}>
            <h1 id={styles.loginPromptText}> LOG IN </h1>
            <h1 id={styles.loginIcons}>
            <FontAwesomeIcon id={styles.loginIcon} icon={faTwitter} />
            <FontAwesomeIcon id={styles.loginIcon} icon={faGoogle} />
            {/* <FontAwesomeIcon id={styles.loginIcon} icon={faFacebook} /> */}
            <FontAwesomeIcon id={styles.loginIcon} icon={faTwitch} />
            <FontAwesomeIcon id={styles.loginIcon} icon={faRedditAlien} />
            <FontAwesomeIcon id={styles.loginIcon} icon={faDiscord} />  
              <img
                src={MetaMaskLogo}
                id={styles.loginIcon}
                height="32px"
                width="32px"
                alt="Metamask Logo"
              />
            </h1>
          </Button>
        </div>
        );
    }
}

export default LoginButton;