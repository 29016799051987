import React, { Component } from 'react';
import moment from 'moment';

// CSS
import 'assets/css/m_w.css';
import styles from "./MemeMatch.module.scss";

// Scripts
import contractScripts from '../Buttons/contractScripts.js'

// reactstrap components
import { Card } from 'reactstrap';

// Components
import ProposalCard from '../UpcomingMatches/ProposalCard.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' // TODO: fa-library
import { faSpinner, faExpandArrowsAlt, faCaretDown, faArrowUp, faStar, faUser } from '@fortawesome/free-solid-svg-icons'

// Global variables 
var userHasHoveredProposal = false; // (TODO: kept for mixpanel)
var matchTopInfoClassName = styles.pendingMatchTopInfo; // TODO: default

class MemeMatchTopper extends Component {
  state = {
    now: null,                        // moment.js object (contains "current time")
    timerRefreshRate: 100,            // variable rate @ which topper re-renders (useful + increased for countdowns)
    msCountdownStart: 30,             // starts millisecond-countdown when 30 seconds remain (for joining match as contestant / voter)
    lastXSeconds: false,              // true when it's the last `X` seconds in this.props.match (PENDING or ACTIVE)

    // THE BELOW FIELDS ARE PROVIDED (by MemeMatch.jsx) as props:

    // matchLoading: false,           // bool
    // matchLoaded: false,            // bool
    // matchStage: "NONE",            // one of [ PENDING / ACTIVE / CANCELLED / OVER ] when: matchLoaded == true

    // matchIsCancelled: false,       // bool
    // isAfterVotingPeriod: false,    // bool
    // isAfterJoiningPeriod: false,   // bool

    // activeSwitch: false,           // bool - true when [ ACTIVE --> OVER ] transition has occured
    // pendingSwitch: false,          // bool - true when [ PENDING --> CANCELLED or ACTIVE ] transition has occured

    // matchStartTime: null,          // moment.js timestamp object ( .unix().utc )
    // joiningEndsTime: null,         // moment.js timestamp object ( .unix().utc )
    // votingStartTime: null,         // moment.js timestamp object ( .unix().utc )
    // votingEndsTime: null,          // moment.js timestamp object ( .unix().utc )

  };

  componentDidMount() {
    // TODO: make this less regular outside of last 30 secs
    this.interval = setInterval(() => {
      const now = moment.utc();
      this.setState({ now: now });
    }, this.state.timerRefreshRate);
  }



  // Populates (#1) and/or refreshes (#2) topper with relevant match details,
  //     
  //     ---#1: Populates (on first run) all state fields (relevant match details, etc.)
  //     ---#2: Forces refresh in MemeMatch.jsx by calling this.props.resetMatchState() (upon match-stage transitions)
  //
  componentDidUpdate(prevProps) {

    const { now, timerRefreshRate, msCountdownStart } = this.state;

    // const lastPropsNotUndefined = prevProps.match !== undefined && prevProps.match != null; // this measure relevant if match replacement occurs (not first-load)
    const currentPropsNotUndefined = this.props.match !== undefined && this.props.focusedMatchID !== undefined && this.props.match !== undefined;
    const joiningTimestampsDefined = this.props.joiningEndsTime != null;
    const votingTimestampsDefined = this.props.votingEndsTime != null;

    const focusedMatchIsLatestMatch = this.props.focusedMatchID == this.props.match.ID;
    const newMatchIDInProps = prevProps.match.ID != this.props.match.ID;
    // const newMatchStatusInProps = focusedMatchIsLatestMatch && (this.props.match.matchStatus != prevProps.currMatch.matchStatus)
    const newMatchStatusInProps = focusedMatchIsLatestMatch && (this.props.match.matchStatus != prevProps.match.matchStatus)


    // if .matchStatus (PENDING / ACTIVE / CANCELLED / OVER) of focused-match changes, reset component state
    if (currentPropsNotUndefined && focusedMatchIsLatestMatch && newMatchStatusInProps) {
      console.log("Focused Match STATUS CHANGE - calling resetMatchState()");
      this.resetTopperCountdownTimer();
      this.props.resetMatchState()
    }

    // if new match (by ID #) is passed into topper, reset component state
    else if (currentPropsNotUndefined && newMatchIDInProps) {
      console.log("DIFFERENT MATCH (by ID #) ARRIVED - calling resetMatchState()");
      this.resetTopperCountdownTimer();
      this.props.resetMatchState()
    }

    // notifies MemeMatch.jsx of transition [ PENDING --> CANCELLED ] or [ PENDING --> ACTIVE ] + reset component state
    else if (joiningTimestampsDefined && moment.utc().isAfter(this.props.joiningEndsTime) && this.props.matchStage == "PENDING") {
      if (!this.props.pendingSwitch) {
        console.log("Transition Detected: [ PENDING --> CANCELLED OR ACTIVE ] - calling resetMatchState()");
        this.resetTopperCountdownTimer();
        this.props.resetMatchState()
      }
    }

    // notifies MemeMatch.jsx of transition [ ACTIVE --> OVER ] + reset component state
    else if (votingTimestampsDefined && moment.utc().isAfter(this.props.votingEndsTime) && this.props.matchStage == "ACTIVE") {
      if (!this.props.activeSwitch) {
        console.log("Transition Detected: [ ACTIVE --> OVER ] - calling resetMatchState()");
        this.resetTopperCountdownTimer();
        this.props.resetMatchState();
      }
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Set topper refresh-rate to appropriate speed for this.props.match / this.props.matchStage
    //
    //   - match is PENDING or ACTIVE (> 30 seconds remaining in countdown): 100 (default - one refresh per second)
    //   - match is PENDING or ACTIVE (< 30 seconds): 1000 (allows millisecond time-granularity in last X-seconds topper countdown)
    //   - match is OVER or CANCELLED (no countdown clock): 0
    //

    const isTimeForCountdown_PENDING = joiningTimestampsDefined && moment.utc().add(this.state.msCountdownStart, "seconds").isAfter(this.props.joiningEndsTime);
    const isTimeForCountdown_ACTIVE = votingTimestampsDefined && moment.utc().add(this.state.msCountdownStart, "seconds").isAfter(this.props.joiningEndsTime);

    // Set up countdown timer for PENDING match in last 30 seconds
    if (this.props.matchStage == "PENDING" && !this.state.lastXSeconds && isTimeForCountdown_PENDING) {
      this.setState({
        timerRefreshRate: 1000,
        lastXSeconds: true,
      });
    }

    else if (this.props.matchStage == "ACTIVE" && !this.state.lastXSeconds && isTimeForCountdown_ACTIVE) {
      this.setState({
        timerRefreshRate: 1000,
        lastXSeconds: true,
      });
    }

    else if (!this.lastXSeconds && timerRefreshRate != 0) {
      this.setState({
        timerRefreshRate: 0, // no need for rapid-refresh
        lastXSeconds: true,
      });
    }
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////

  }

  shouldComponentUpdate(nextState, nextProps) {
    return true;
  }

  resetTopperCountdownTimer = () => {
    console.log("resetTopperCountdownTimer() - invoked")
    this.setState({
      timerRefreshRate: 100,            // variable rate @ which topper re-renders (useful + increased for countdowns)
      lastXSeconds: false,              // true when it's the last `X` seconds in this.props.match (PENDING or ACTIVE)
    });
  }


  getTimeMessage = () => {
    // console.log("getTimeMessage - invoked");

    // const { now, timerRefreshRate, msCountdownStart } = this.state; 
    // const { match, matchStartTime, joiningEndsTime, isAfterJoiningPeriod, votingStartTime, votingEndsTime, isAfterVotingPeriod, matchIsCancelled } = this.props;

    if (this.props !== undefined && this.props.match !== undefined && this.props.focusedMatchID != null && this.props.matchLoaded) {
      // console.log("getTimeMessage - #1 - props defined && this.props.matchLoaded ");
      // const focusedMatchIsLatestMatch = this.props.match.ID.toString() == this.props.focusedMatchID.toString();
      // const relevantMatch = focusedMatchIsLatestMatch && this.props.match !== undefined ? this.props.match : this.props.match; // currMatch gets live updates when contract events detected (in MemewarsSite.jsx)
      const relevantMatch = this.props.match; // currMatch gets live updates when contract events detected (in MemewarsSite.jsx)

      if (relevantMatch !== undefined) { // implied when relevantMatch == this.props.match, but not this.props.match


        switch (this.props.matchStage) {
          case 'PENDING': {

            const lastXSeconds = !this.props.isAfterJoiningPeriod && moment.utc().add(this.state.msCountdownStart, "seconds").isAfter(this.props.joiningEndsTime);

            // const timeLeft = moment.utc(joiningEndsTime - this.state.now);
            const timeLeft = moment.utc(this.props.joiningEndsTime - this.state.now);
            var timeDisplay = lastXSeconds ? moment.utc(timeLeft).format("m:ss.S") : moment.utc(timeLeft).format("m:ss");

            var timeToJoin = moment.utc(timeLeft).isValid() ?
              timeDisplay
              :
              <FontAwesomeIcon icon={faSpinner} pulse />;

            // const timeLeftToVote = moment.utc(votingEndsTime - this.state.now);
            // var timeToVoteDisplay = Number(this.props.match.proposal.allowedVotingTime.toString()) > 600 ? moment.utc(timeLeftToVote).format("mm:ss") : moment.utc(timeLeftToVote).format("m:ss");
            // timeToVoteDisplay = lastXSeconds ? moment.utc(timeLeftToVote).format("m:ss.S") : timeToVoteDisplay;

            const matchStartTimeDisplay = moment.utc(this.props.matchStartTime).format("LLL");

            var timeToVote = moment.unix(this.props.match.proposal.allowedVotingTime.toString()).utc();

            var timeToVoteDisplay = Number(this.props.match.proposal.allowedVotingTime.toString()) > 600 ?
              moment.utc(timeToVote).format("mm:ss")
              :
              moment.utc(timeToVote).format("m:ss"); // TODO matches longer than 10m



            // const timeBasedDisplay = moment.utc().isAfter(this.propsjoiningEndsTime) ?
            const timeBasedDisplay = this.props.isAfterJoiningPeriod ? // && this.props.pendingSwitch
              <h3 className={styles.matchTopperCountdown}>
                {/* <div id={styles.votingTimeLeftText}> 
                Voting ends in 
              </div>  */}
                <h3 id={styles.startsOnFirstVoteText}>
                  {/* Voting time left (starts on first vote)   */}
                  {/* Voting time left (after first vote)   */}
                  {/* First Vote Starts Countdown */}
                  First Vote Starts Countdown
                </h3>
                <div id={styles.countdownTime}>
                  {timeToVoteDisplay}
                </div>
              </h3>
              :
              <h3 className={styles.matchTopperCountdown}>
                <div id={styles.countdownText}>
                  Voting Begins In
                </div>
                <div id={styles.countdownTime}>
                  {timeToJoin}
                </div>
              </h3>;

            const pendingTimeDisplay = this.props.matchIsCancelled ? // TODO: notEnoughCompetitors = matchIsCancelled?
              <h3 className={styles.matchTopperCountdown}>
                <div id={styles.cancelledMatchTimestamp}>
                  {matchStartTimeDisplay}
                </div>
              </h3>
              :
              timeBasedDisplay;


            return pendingTimeDisplay;

          }
          case 'ACTIVE': {

            if (this.props.votingEndsTime != null) { // && this.props.match !== undefined (above)
              // console.log("ACTIVE: votingEndsTime !== undefined") // TODO

              const lastXSeconds = !this.props.isAfterVotingPeriod && moment.utc().add(this.state.msCountdownStart, "seconds").isAfter(this.props.votingEndsTime);
              var timeLeftToVote = moment.utc(this.props.votingEndsTime - moment.utc());
              var timeToVoteDisplay = Number(this.props.match.proposal.allowedVotingTime.toString()) > 600 ? moment.utc(timeLeftToVote).format("mm:ss") : moment.utc(timeLeftToVote).format("m:ss");
              timeToVoteDisplay = lastXSeconds ? moment.utc(timeLeftToVote).format("m:ss.S") : timeToVoteDisplay;

              const activeTimeDisplay = this.props.isAfterVotingPeriod ? // && this.props.activeSwitch
                <h3 className={styles.matchTopperCountdown}>
                  <div id={styles.cancelledMatchTimestamp}>
                    {moment.utc(this.props.votingEndsTime).format("LLL")}
                  </div>
                </h3>
                :
                <h3 className={styles.matchTopperCountdown}>
                  <div id={styles.countdownText}>
                    Voting Ends In
                  </div>
                  <div id={styles.countdownTime}>
                    {timeToVoteDisplay}
                  </div>
                </h3>;

              return activeTimeDisplay;
            }

            else {
              console.log("getTimeDisplay - this.props.match == undefined or MATCH NOT LOADED")

              return <FontAwesomeIcon icon={faSpinner} pulse />

            }
          }

          case 'OVER': {
            return <h3 className={styles.matchTopperCountdown}> <div id={styles.cancelledMatchTimestamp}>  {moment.utc(this.props.votingEndsTime).format("LLL")} </div> </h3>;
          }

          case 'CANCELLED': {
            var timeStartDisplay = moment.utc(this.props.matchStartTime).format("LLL");
            return (<h3 className={styles.matchTopperCountdown}> <div id={styles.cancelledMatchTimestamp}> {timeStartDisplay} </div></h3>);
          }

          default:
            return <FontAwesomeIcon icon={faSpinner} pulse />
        }
      }

      else { console.log("getTimeMessage #666 - relevantMatch !== undefined") }
    }

    else {
      console.log("getTimeMessage - ELSE-BLOCK - !this.props.matchLoaded")
      return <FontAwesomeIcon icon={faSpinner} pulse />; // TODO: fa-library
    }
  };

  getStatusDisplay = () => {
    // console.log("getStatusDisplay - invoked");
    const { now, matchStartTime, joiningEndsTime, isAfterJoiningPeriod, votingStartTime, votingEndsTime, isAfterVotingPeriod, matchIsCancelled } = this.props;


    if (this.props !== undefined && this.props.match !== undefined) {
      // console.log("getStatusDisplay - #1");

      const focusedMatchIsLatestMatch = this.props != undefined && this.props.match.ID == this.props.focusedMatchID;
      const relevantMatch = focusedMatchIsLatestMatch ? this.props.match : this.props.match; // currMatch gets live updates when contract events detected (in MemewarsSite.jsx)


      if (this.props.matchLoaded !== undefined && this.props.matchLoaded) {
        // console.log("getStatusDisplay - #1 #1");

        switch (relevantMatch.matchStatus) {
          // switch (this.props.matchStage) {
          case 'PENDING': {

            const pendingOrActive = isAfterJoiningPeriod ? "PENDING" : "ACTIVE";
            const pendingStatusDisplay = matchIsCancelled ? "CANCELLED" : pendingOrActive; // TODO: notEnoughCompetitors = matchIsCancelled?

            return pendingStatusDisplay;
          }
          case 'ACTIVE': {

            // const activeTimeDisplay = !isAfterVotingPeriod ? "ACTIVE" : "SOMEONE, CLICK THE BUTTON..."
            const activeTimeDisplay = isAfterVotingPeriod ? "OVER" : "ACTIVE"
            return activeTimeDisplay;
          }
          case 'OVER': {
            return "OVER"
          }
          case 'CANCELLED': {
            return "CANCELLED";

          }
        }
      }

      else {
        console.log("getStatusDisplay - ELSE-BLOCK #1 #1 = this.props.matchLoaded:")
        console.log(this.props.matchLoaded)
      }
    }

    else { console.log("getStatusDisplay - ELSE-BLOCK (this.props.match == undefined") }
  };

  getTopperClassname = () => {
    // console.log("getTopperClassname - invoked");

    // console.log("old matchTopInfoClassName:")
    // console.log(matchTopInfoClassName)
    const { now, matchStartTime, joiningEndsTime, isAfterJoiningPeriod, votingStartTime, votingEndsTime, isAfterVotingPeriod, matchIsCancelled } = this.props;

    // console.log("getTopperClassname - invoked");

    if (this.props.matchLoaded) {
      // console.log("getTopperClassname - #1");

      // if true, match is PENDING
      var matchTopInfoClassNameToReturn = !isAfterJoiningPeriod ? styles.pendingMatchTopInfo : styles.matchTopInfo;
      // if true, match is ACTIVE
      matchTopInfoClassNameToReturn = isAfterJoiningPeriod && !isAfterVotingPeriod ? styles.activeMatchTopInfo : matchTopInfoClassNameToReturn;
      // if true, match is OVER
      const matchIsOver = isAfterJoiningPeriod && isAfterVotingPeriod;
      matchTopInfoClassNameToReturn = matchIsOver ? styles.overMatchTopInfo : matchTopInfoClassNameToReturn;
      // if true, match is CANCELLED
      matchTopInfoClassNameToReturn = matchIsCancelled ? styles.matchTopInfo : matchTopInfoClassNameToReturn;

      // console.log("new matchTopInfoClassName:")
      // console.log(matchTopInfoClassName)
      // this.setState({ matchTopInfoClassName: matchTopInfoClassName})
      return matchTopInfoClassNameToReturn;


    }

    // switch (this.props.matchStage) {
    // // switch (this.props.match.matchStatus) {

    // case "PENDING":
    //   return styles.pendingMatchTopInfo;

    // case "ACTIVE":
    //   return styles.activeMatchTopInfo;

    // case "OVER":
    //   return styles.overMatchTopInfo;

    // case "CANCELLED": 
    //   return styles.matchTopInfo;

    // default:
    //     // case "NONE":
    //     return <h1> <FontAwesomeIcon icon={faSpinner} pulse /> </h1>; // TODO: fa-library

    // }
    // }
  };

  // invoked when user hovers proposalCard (top right corner: match / prompt details)
  proposalCardHovered = () => {
    // console.log("proposalCardHovered - invoked");

    if (userHasHoveredProposal == false) {
      // window.mixpanel.track("FORMAT_HOVER_START"); 
    }

    // TODO: mixpanel 
    // fires when someone hovers over proposalCard in MemeMatch top right corner
    // window.mixpanel.track("FORMAT_HOVER", { "HOVER_START": true }); 
    // starts timer that ends when user stops hovering component(TODO: mixpanel)
    userHasHoveredProposal = true;
    // window.mixpanel.time_event('FORMAT_HOVER');
  };

  // invoked when user *un*-hovers proposalCard (top right corner: match / prompt details)
  proposalCardUnhovered = () => {
    // console.log("proposalCardUnhovered - invoked");

    // TODO: mixpanel
    // window.mixpanel.track("FORMAT_HOVER", { "HOVER_START": false }); 
    // window.mixpanel.track("FORMAT_HOVER"); 
    // window.mixpanel.time_event('FORMAT_HOVER');
  };

  render() {

    // component state
    // const { 
    //   now, 
    //   matchStartTime, 
    //   joiningEndsTime, 
    //   isAfterJoiningPeriod, 
    //   votingStartTime, 
    //   votingEndsTime, 
    //   isAfterVotingPeriod, 
    //   matchIsCancelled, 
    //   matchLoaded } = this.props;

    const loadingIcon = <h1> <FontAwesomeIcon icon={faSpinner} pulse /> </h1>; // TODO: fa-library

    // const stateDefined = this.props.matchStartTime != null && this.props.joiningEndsTime != null;
    // const matchPendingOrActive = stateDefined && (moment.utc().isAfter(matchStartTime) && moment.utc().isBefore(votingEndsTime));
    // const match = matchPendingOrActive ? this.props.match : this.props.match;
    // const match = matchPendingOrActive ? this.props.match : this.props.match;
    const match = this.props.match;
    // console.log("match:")
    // console.log(match)
    const proposal = match.proposal;

    const timeDisplay = match === undefined ? loadingIcon : this.getTimeMessage();
    const proposalCard = match === undefined ? loadingIcon : <div onMouseEnter={this.proposalCardHovered} onMouseLeave={this.proposalCardUnhovered}> <ProposalCard proposal={proposal} renderingIn={'memeMatchTopper'} /> </div>;

    // const matchStatusIcon = this.props.match == undefined ? loadingIcon : memeCardScripts.getTopperIcon(match.matchStatus);
    const matchStatusDisplay = match === undefined ? loadingIcon : this.getStatusDisplay();

    matchTopInfoClassName = this.getTopperClassname();

    // console.log("matchTopInfoClassName: ")
    // console.log(matchTopInfoClassName)

    return (
      <div id={styles.memeMatchTopper}>
        <Card id={styles.matchTopInfo} className={matchTopInfoClassName}>
          <div id={styles.statusAndTime}>
            <h3 id={styles.matchStatusSection}>
              <div id={styles.matchNumberText}> Vote </div>
              <div id={styles.matchNumberSign}> # </div>
              <div id={styles.matchID}> {match.ID}</div>
              <div id={styles.matchStatusColon}>
                :
              </div>
              <div id={styles.matchStatus}>
                {matchStatusDisplay}
              </div>
            </h3>
            {timeDisplay}
          </div>
          <div id={styles.matchFormat}>
            {/* <div id={styles.proposalCard}> */}
            <div id="topperProposalCard">
              {proposalCard}
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

export default MemeMatchTopper;
